import { ValidationErrors, AbstractControl } from '@angular/forms';

export function createNoWhitespaceValidator() {
  return (control: AbstractControl): ValidationErrors => {
    if (control.value === null || control.value === undefined) {
      return null;
    }
    const regExp = /^[\s]+$/g;
    return regExp.test(control.value.toString())
      ? {
          noWhitespace: true,
        }
      : null;
  };
}
