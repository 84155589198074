import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, Subject } from 'rxjs';
import * as Hammer from 'hammerjs';
import { FaqDialogComponent } from '../../../components/faq-dialog/faq-dialog.component';
import { ResponsiveService } from '../../../services/responsive/responsive.service';
import { StepState } from '../../../services/step-navigation/step-state';
import { StepStateService } from '../../../services/step-navigation/step-state.service';
import { ConfigAssetService } from '../../../services/config/config-asset.service';
import { takeUntil, distinctUntilChanged, map } from 'rxjs/operators';
import { DataPrivacyDialogComponent } from '../../../authentication/data-privacy-dialog/data-privacy-dialog.component';

@Component({
  selector: 'idv-step-navigation',
  templateUrl: './step-navigation.component.html',
  styleUrls: ['./step-navigation.component.scss'],
})
export class StepNavigationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() stepStates: StepState[];
  @Input() pseudoSteps: boolean;

  isCompact$: Observable<boolean>;

  @Input()
  applicantInfo: string;

  @Input()
  showPaperDownload: boolean;

  @Output()
  downloadPaper = new EventEmitter<void>();

  @ViewChild(MatSidenav, { static: true })
  private sidenav: MatSidenav;
  @ViewChild('drawer', { read: ElementRef, static: true })
  private drawer: ElementRef;

  public logo$: Observable<string>;

  private _isCurrentlyCompact = false;
  private _isCompactSubscription: Subscription;
  private _destroyed$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private stepStateService: StepStateService,
    private translateService: TranslateService,
    private router: Router,
    private _configAssetService: ConfigAssetService
  ) {}

  ngOnInit(): void {
    this.isCompact$ = this.responsiveService.isCompact$;

    this._isCompactSubscription = this.isCompact$.subscribe((val) => {
      this._isCurrentlyCompact = val;
    });

    this.logo$ = this._configAssetService.logoUrl.pipe(
      takeUntil(this._destroyed$),
      distinctUntilChanged(),
      map((url) => `url(${url})`)
    );
  }

  ngAfterViewInit(): void {
    // see https://github.com/angular/material2/issues/5712
    if (this.drawer && this.sidenav) {
      const hammerManager = new Hammer(this.drawer.nativeElement, {});
      hammerManager.get('swipe').set({ direction: Hammer.DIRECTION_LEFT });
      hammerManager.on('swipeleft', () => {
        if (this._isCurrentlyCompact) {
          this.sidenav.close();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this._isCompactSubscription) {
      this._isCompactSubscription.unsubscribe();
    }

    this._destroyed$.next();
    this._destroyed$.complete();
  }

  openFaqModal() {
    this.dialog.open(
      FaqDialogComponent,
      this.responsiveService.enhanceDialogConfig({
        data: {},
        panelClass: 'super-big-modal',
      })
    );
  }

  openGDPRDialog() {
    this.dialog.open(
      DataPrivacyDialogComponent,
      this.responsiveService.enhanceDialogConfig({
        panelClass: 'super-big-modal',
      })
    );
  }

  logOut() {
    this.stepStateService.navigateToLogout(this.translateService.currentLang);
  }

  get isCurrentlyCompact(): boolean {
    return this._isCurrentlyCompact;
  }

  private isActive(stepName: string): boolean {
    return !!this.router.url.includes(stepName);
  }

  getActiveStepNumber(): number {
    return this.stepStateService
      .getFlatStepStates()
      .findIndex((step: StepState) => this.isActive(step.name));
  }

  getStepCount(): number {
    return this.stepStateService.getFlatStepStates().length;
  }

  private getActiveStep(): StepState {
    return this.stepStateService
      .getFlatStepStates()
      .find((step: StepState) => this.isActive(step.name));
  }

  getActiveStepLabel() {
    if (this.pseudoSteps) {
      const key = this.stepStates[0].name;
      return this.translateService.instant(key);
    }
    const activeStep = this.getActiveStep();
    if (activeStep) {
      if (activeStep.isRisk) {
        return activeStep.risk['label'];
      }
      return this.translateService.instant(
        'step-navigation.step-labels.' + activeStep.name
      );
    }

    return '';
  }
}
