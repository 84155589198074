import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MtCaptchaState } from '../interfaces/mt-captcha-state';
import { MtCaptchaDomService } from '../services/mt-captcha-dom.service';

@Component({
  selector: 'idv-mt-captcha',
  templateUrl: './mt-captcha.component.html',
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MtCaptchaComponent),
      multi: true,
    },
  ],
})
export class MtCaptchaComponent implements ControlValueAccessor {
  @Input() sitekey: string;
  @Input() lang = 'en';
  @Input() action: string;

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public id: string;

  constructor(private _mtCaptchaDomService: MtCaptchaDomService) {
    const renderQueue = _mtCaptchaDomService.getCaptchaRenderQueue();
    this.id = `idv-captcha-${renderQueue?.length ?? 'x'}`;
  }

  ngOnInit() {
    this._mtCaptchaDomService.registerCaptcha(this.id, (data: MtCaptchaState) =>
      this.onVerified(data)
    );
  }

  writeValue(_: any): void {
    this._mtCaptchaDomService.resetCaptcha(this.id);
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public setDisabledState(_isDisabled: boolean): void {}

  public onVerified(data: any) {
    data?.isVerified && this.onChange(data?.verifiedToken);
  }
}
