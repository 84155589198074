<idv-close-modal-header></idv-close-modal-header>

<div mat-dialog-content>
  <h4
    *ngIf="!successfullyDownloaded"
    class="mt-0"
  >
    {{ 'paper-download.modal-heading' | translate }}
  </h4>
  <h4
    *ngIf="successfullyDownloaded"
    class="mt-0"
  >
    {{ 'paper-download.download-successful' | translate }}
  </h4>

  <form
    [formGroup]="formGroup"
    *ngIf="!successfullyDownloaded"
  >
    <div class="row">
      <div class="col">
        {{ 'paper-download.explanation' | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="form-field-full-width">
          <textarea
            formControlName="feedback"
            class="form-field-freetext"
            matInput
            rows="10"
          ></textarea>
          <mat-hint
            align="start"
            class="form-field-hint-primary"
            >{{ 'paper-download.privacy-hint' | translate }}</mat-hint
          >
          <mat-error *ngIf="getErrorsToDisplayFor('feedback')">
            <idv-error-help-block
              [errors]="getErrorsToDisplayFor('feedback')"
            ></idv-error-help-block>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div *ngIf="successfullyDownloaded">
    <div [innerHTML]="afterDownloadText"></div>
  </div>
</div>

<div mat-dialog-actions>
  <button
    *ngIf="!successfullyDownloaded"
    class="button-full-width"
    mat-button
    mat-raised-button
    color="primary"
    (click)="downloadPaper()"
    idvClickTracker="idv_payment_download_paper_clicked"
  >
    {{ 'paper-download.download' | translate }}
  </button>
  <button
    *ngIf="successfullyDownloaded"
    class="button-full-width"
    mat-button
    mat-raised-button
    color="primary"
    (click)="closeDialog()"
  >
    {{ 'shared.close' | translate }}
  </button>
</div>
