import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'idv-reanswer-questions-dialog',
  templateUrl: './reanswer-questions-dialog.component.html',
  styleUrls: ['./reanswer-questions-dialog.component.scss'],
})
export class ReanswerQuestionsDialogComponent {
  public reanswerMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { labels: Array<string> },
    private _translate: TranslateService
  ) {}

  ngOnInit() {
    this.reanswerMessage = this.buildReanswerMessage(this._data?.labels);
  }

  private buildReanswerMessage(labels = new Array<string>()): string {
    const labelString = labels.reduce((accumulator, currVal, idx) => {
      accumulator += `"${currVal}"`;

      if (idx === labels.length - 2) {
        accumulator += ` ${this._translate.instant('shared.and')} `;
      } else if (idx < labels.length - 2) {
        accumulator += ', ';
      }
      return accumulator;
    }, '');

    return this._translate.instant('medical-risks-reanswer.text', {
      riskLabels: labelString,
    });
  }
}
