<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    [fixedInViewport]="isCompact$ | async"
    [attr.role]="(isCompact$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isCompact$ | async) ? 'over' : 'side'"
    [opened]="!(isCompact$ | async)"
    [disableClose]="!(isCompact$ | async)"
  >
    <div
      class="logo tenant-logo d-lg-none d-xl-none"
      [style.backgroundImage]="logo$ | async"
    ></div>

    <div class="patient-info d-lg-none d-xl-none">
      <div class="heading">{{ 'case-header.labels.title' | translate }}</div>
      <div
        *ngIf="applicantInfo"
        class="info-content"
      >
        {{ applicantInfo }}
      </div>
    </div>

    <idv-step-navigation-list
      (clickNavigationItem)="isCurrentlyCompact && drawer.close()"
      [stepStates]="stepStates"
      [pseudoSteps]="pseudoSteps"
    >
    </idv-step-navigation-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar
      *ngIf="isCompact$ | async"
      color="primary"
      class="paper-shadow-elevation-3"
    >
      <button
        class="btn-no-fixed-width"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        idvClickTracker="idv_sidenav_toggle_clicked"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <div class="step-indicator-container">
        <span
          *ngIf="!pseudoSteps && getStepCount() > 1"
          class="step-indicator"
          >{{ getActiveStepNumber() + 1 }}/{{ getStepCount() }}</span
        >&nbsp;<span>{{ getActiveStepLabel() }}</span>
        <span class="horizontal-spacer"></span>
      </div>

      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        class="menu-trigger doener-button"
        id="idv_main_component_menu_trigger"
        idvClickTracker="idv_more_menu_btn_clicked"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu
        #menu="matMenu"
        class="menu"
        [overlapTrigger]="true"
        xPosition="before"
      >
        <div
          mat-menu-item
          (click)="openGDPRDialog()"
          class="menu-item-wrapper"
          idvClickTracker="idv_open_gdpr_dialog?source=more_menu"
        >
          <span class="menu-item">{{
            'case-header.labels.data-privacy' | translate
          }}</span>
        </div>
        <div
          mat-menu-item
          (click)="openFaqModal()"
          class="menu-item-wrapper"
          idvClickTracker="idv_open_faq?source=more_menu"
        >
          <span class="menu-item">{{
            'case-header.labels.faq' | translate
          }}</span>
        </div>
        <div
          *ngIf="showPaperDownload"
          id="btn_idv_menu_paper_download"
          mat-menu-item
          (click)="downloadPaper.emit()"
          class="menu-item-wrapper"
          idvClickTracker="idv_open_download_paper_popup?source=more_menu"
        >
          <span class="menu-item">{{
            'case-header.labels.download' | translate
          }}</span>
        </div>
        <div
          mat-menu-item
          (click)="logOut()"
          class="menu-item-wrapper"
          idvClickTracker="idv_click_logout?source=more_menu"
        >
          <span class="menu-item">{{
            'case-header.labels.logout' | translate
          }}</span>
        </div>
      </mat-menu>
    </mat-toolbar>
    <div class="container question-container">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
