import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { FaqDialogComponent } from '../../../../../idv-lib/src/lib/components/faq-dialog/faq-dialog.component';
import { DemoModeService } from '../../../../../idv-lib/src/lib/services/config/demo-mode.service';
import { ResponsiveService } from '../../../../../idv-lib/src/lib/services/responsive/responsive.service';
import { StepStateService } from '../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { ConfigAssetService } from '../../../../../idv-lib/src/lib/services/config/config-asset.service';
import { takeUntil, distinctUntilChanged, map } from 'rxjs/operators';
import { DataPrivacyDialogComponent } from '../../../../../idv-lib/src/lib/authentication/data-privacy-dialog/data-privacy-dialog.component';
import { LanguageService } from '../../../../../core-lib/src/lib/core/services/language/language.service';

@Component({
  selector: 'phy-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  showPaperDownload: boolean;

  @Output()
  downloadPaper = new EventEmitter<void>();

  @Input()
  applicantInfo: string;

  public logo$: Observable<string>;
  public isCompact$: Observable<boolean>;
  private _destroyed$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private stepStateService: StepStateService,
    private demoModeService: DemoModeService,
    private _configAssetService: ConfigAssetService,
    private _languageService: LanguageService
  ) {}

  ngOnInit() {
    this.isCompact$ = this.responsiveService.isCompact$;

    this.logo$ = this._configAssetService.logoUrl.pipe(
      takeUntil(this._destroyed$),
      distinctUntilChanged(),
      map((url) => `url(${url})`)
    );
  }

  get isDemo() {
    return this.demoModeService.isDemoTenant;
  }

  openFaqModal() {
    this.dialog.open(
      FaqDialogComponent,
      this.responsiveService.enhanceDialogConfig({
        data: {},
        panelClass: 'faq-big-modal',
        position: {
          top: '32px',
        },
      })
    );
  }

  openGDPRDialog() {
    this.dialog.open(
      DataPrivacyDialogComponent,
      this.responsiveService.enhanceDialogConfig({
        panelClass: 'super-big-modal',
      })
    );
  }

  logOut() {
    this.stepStateService.navigateToLogout(
      this._languageService.getCurrentLanguage()
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
