import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ExpectedErrorService } from '../../../../../core-lib';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { StepStateService } from '../../services/step-navigation/step-state.service';

@Component({
  selector: 'idv-changed-medical-risks-message',
  templateUrl: './changed-medical-risks-message.component.html',
})
export class ChangedMedicalRisksMessageComponent implements OnInit, OnDestroy {
  private expectedErrorServiceSubscription: Subscription;
  private requestServiceSubscription: Subscription;
  private dialogAfterClosedSubscription: Subscription;
  private conflictedRiskId: string;

  @ViewChild('addRiskModal', { static: true }) addRiskModal: TemplateRef<any>;
  @ViewChild('reanswerQuestionModal', { static: true })
  reanswerQuestionModal: TemplateRef<any>;

  constructor(
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private expectedErrorService: ExpectedErrorService,
    private stepStateService: StepStateService,
    private _ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.expectedErrorServiceSubscription =
      this.expectedErrorService.expectedError.subscribe((error) => {
        if (error && error['questionChanged']) {
          this.conflictedRiskId = error['riskId'];
          this._ngZone.run(() => this.showReanswerQuestionModal());
        } else if (error && error['ruleChanges']) {
          this._ngZone.run(() => this.showAddRiskModal());
        }
      });
  }

  showAddRiskModal(): void {
    this.showModal(this.addRiskModal);
    this.dialogAfterClosedSubscription = this.showModal(this.addRiskModal)
      .afterClosed()
      .subscribe(() => {
        this.goToAddDisease();
      });
  }

  showReanswerQuestionModal(): void {
    this.dialogAfterClosedSubscription = this.showModal(
      this.reanswerQuestionModal
    )
      .afterClosed()
      .subscribe(() => {
        this.goToQuestion();
      });
  }

  showModal(templateRef: TemplateRef<any>): MatDialogRef<any> {
    return this.dialog.open(
      templateRef,
      this.responsiveService.enhanceDialogConfig({
        panelClass: 'small-modal',
        disableClose: true,
      })
    );
  }

  goToAddDisease() {
    this.stepStateService.goToStepAfterRiskSteps();
  }

  goToQuestion() {
    // gets next open question from risk questionnaire
    this.stepStateService.goToRisk(this.conflictedRiskId);
  }

  ngOnDestroy(): void {
    if (this.dialogAfterClosedSubscription) {
      this.dialogAfterClosedSubscription.unsubscribe();
    }
    if (this.expectedErrorServiceSubscription) {
      this.expectedErrorServiceSubscription.unsubscribe();
    }
    if (this.requestServiceSubscription) {
      this.requestServiceSubscription.unsubscribe();
    }
  }
}
