import { TrackingServiceConfig } from '../services/misc/tracking.service';
import { PaymentMethodType } from './payment-method.type';
import { LinksConfiguration } from './links-configuration';

export interface Config {
  versionInfo: {
    [applicationName: string]: string;
  };
  tracking: TrackingServiceConfig;
  preferredLanguage: string;
  showLanguageSelector: boolean;
  allowsPaperDownload: boolean;
  links: LinksConfiguration;
  isTest: boolean;
  captcha: {
    siteKey: string;
    captchaIsRequired: boolean;
  };
  texts: {
    [textKey: string]: string;
  };
  bulletPointsPerProductParagraphTwo: {
    [key: string]: Array<string>;
  };
  bulletPointsPerProductParagraphThree: {
    [key: string]: Array<string>;
  };
  displayCertificateIcon: boolean;
  preContractualDisclosurePdfDownloadName?: string;
  adobeAnalyticsUrl: string;
  paymentInformationFormat: PaymentMethodType;
  allowRisksWithGdqForPhysician: boolean;
}
