import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { LanguageService } from '../../../../../core-lib/src/lib/core/services/language/language.service';
import { Request, Config } from '../../interfaces';
import { TenantConfigService } from '../config/tenant-config.service';
import { RequestService } from '../request/request.service';

@Injectable({ providedIn: 'root' })
export class IdvLanguageService {
  constructor(
    private _languageService: LanguageService,
    private _requestService: RequestService,
    private _tenantConfigService: TenantConfigService,
    private _route: ActivatedRoute
  ) {}

  public init() {
    const requestLanguage$ = this._requestService.request.pipe(
      startWith(null as Request),
      map((request) => request?.createdWithLanguage ?? null)
    );

    const tenantLanguage$ = this._tenantConfigService.tenantConfig$.pipe(
      startWith(null as Config),
      map((config) => config?.preferredLanguage ?? null)
    );

    const urlLanguage$ = this._route.queryParams.pipe(
      startWith(null as Params),
      map((params) => params?.lang ?? null)
    );

    combineLatest([requestLanguage$, tenantLanguage$, urlLanguage$])
      .pipe(
        tap(([requestLanguage, tenantLanguage, urlLanguage]) => {
          const applicationLanguage = this.determineApplicationLanguage(
            requestLanguage,
            tenantLanguage,
            urlLanguage
          );
          this._languageService.setLanguage(applicationLanguage);
        })
      )
      .subscribe();
  }

  private determineApplicationLanguage(
    requestLanguage: string,
    tenantLanguage: string,
    urlLanguage: string
  ): string {
    if (!!requestLanguage) {
      // 1st priority: requestLanguage
      return requestLanguage;
    } else if (!!urlLanguage) {
      // 2nd priority: url language
      return urlLanguage;
    }
    // 3rd priority: tenant language
    return tenantLanguage;
  }
}
