import { Component, Input } from '@angular/core';

@Component({
  selector: 'idv-questionnaire-previous-next',
  templateUrl: './questionnaire-previous-next.component.html',
  styleUrls: ['./questionnaire-previous-next.component.scss'],
})
export class QuestionnairePreviousNextComponent {
  @Input()
  backButtonRouterLink: any[];

  @Input()
  canProceed = false;

  @Input()
  hideBackButton = false;

  constructor() {}
}
