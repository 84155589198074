import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationInsights,
  IConfig,
  IConfiguration,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { Request as IdvRequest } from '../../../../../idv-lib';

import { Subject } from 'rxjs';
import { MrcTrackingService, WindowService } from '../../../../../core-lib';
import { IDV_ENV, IdvEnvironment } from '../../interfaces/idv-environment';
import { RequestService } from '../request/request.service';
import { TenantService } from '../tenant/tenant.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { TenantConfigService } from '../config/tenant-config.service';

export type TrackingServiceConfig = IConfig & IConfiguration;

@Injectable({ providedIn: 'root' })
export class TrackingService extends MrcTrackingService implements OnDestroy {
  private _destroyed$ = new Subject<void>();
  private _correlationId: string;
  private _appInsights: ApplicationInsights;

  constructor(
    private tenantConfigService: TenantConfigService,
    private router: Router,
    windowService: WindowService,
    private tenantService: TenantService,
    private requestService: RequestService,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {
    super();

    this.setupAppInsightsConfig();
    this.requestService.request
      .pipe(
        takeUntil(this._destroyed$),
        filter((request) => !!request),
        tap((request: IdvRequest) => {
          this._correlationId = request.correlationId;
        })
      )
      .subscribe();

    this.setupRouteChangeTracking(this.router);
    this.setupPageUnloadTracking(windowService);
    this.trackInitialPageLoad(windowService, 'idv_initial_page_load');
  }

  private setupAppInsightsConfig() {
    if (!this._appInsights) {
      this.tenantConfigService.tenantConfig$
        .pipe(takeUntil(this._destroyed$))
        .subscribe((config) => {
          const trackingConfig = config.tracking;
          trackingConfig.isStorageUseDisabled =
            this.environment.defaultConfig.tracking.isStorageUseDisabled;
          trackingConfig.maxBatchInterval =
            this.environment.defaultConfig.tracking.maxBatchInterval;

          this._appInsights = new ApplicationInsights({
            config: trackingConfig,
          });
          if (this._appInsights.config.instrumentationKey) {
            this._appInsights.loadAppInsights();
          }
        });
    }
  }

  protected enrichProps(properties: { [name: string]: string }): {
    [name: string]: string;
  } {
    if (!properties) {
      properties = {};
    }
    properties.app = 'idv';
    properties.flavor = this.environment.flavor.name;
    if (!('pageName' in properties)) {
      properties.pageName = this.findPageName(this.router);
    }

    if (this.tenantService.current) {
      if (!properties.tenant) {
        properties.tenant = this.tenantService.current;
      }
    }
    if (this._correlationId) {
      if (!properties.correlationId) {
        properties.correlationId = this._correlationId;
      }
    }
    return properties;
  }

  trackPageView(
    name?: string,
    uri?: string,
    properties?: { [name: string]: string },
    pageType?: string,
    refUri?: string,
    isLoggedIn?: boolean
  ) {
    this._appInsights?.trackPageView({
      name,
      uri,
      properties: this.enrichProps(properties),
      pageType,
      refUri,
      isLoggedIn,
    });
  }

  startTrackPage(name?: string) {
    this._appInsights?.startTrackPage(name);
  }

  stopTrackPage(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ) {
    this._appInsights?.stopTrackPage(
      name,
      url,
      this.enrichProps(properties),
      measurements
    );
  }

  trackEvent(name: string, properties?: { [name: string]: string }) {
    this._appInsights?.trackEvent({
      name,
      properties: this.enrichProps(properties),
    });
  }

  trackMetric(
    name: string,
    average: number,
    sampleCount?: number,
    min?: number,
    max?: number,
    properties?: { [name: string]: string }
  ) {
    this._appInsights?.trackMetric({
      name,
      average,
      sampleCount,
      min,
      max,
      properties: this.enrichProps(properties),
    });
  }

  trackException(
    exception: Error,
    properties?: { [name: string]: string },
    severityLevel?: SeverityLevel,
    id?: string
  ) {
    this._appInsights?.trackException({
      exception,
      properties: this.enrichProps(properties),
      severityLevel,
      id,
    });
  }

  trackTrace(
    message: string,
    properties?: { [name: string]: string },
    severityLevel?: SeverityLevel
  ) {
    this._appInsights?.trackTrace({
      message,
      properties: this.enrichProps(properties),
      severityLevel,
    });
  }

  trackDependency(
    id: string,
    responseCode: number,
    name?: string,
    duration?: number,
    success?: boolean,
    correlationContext?: string,
    target?: string,
    type?: string,
    properties?: { [key: string]: any },
    startTime?: Date,
    data?: string
  ) {
    this._appInsights?.trackDependencyData({
      id,
      responseCode,
      name,
      success,
      duration,
      correlationContext,
      target,
      type,
      properties,
      startTime,
      data,
    });
  }

  flush() {
    this._appInsights?.flush();
  }

  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string) {
    this._appInsights?.setAuthenticatedUserContext(
      authenticatedUserId,
      accountId
    );
  }

  clearAuthenticatedUserContext() {
    this._appInsights?.clearAuthenticatedUserContext();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
