import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { FaqDialogComponent } from '../faq-dialog/faq-dialog.component';
import { TenantConfigService } from '../../services/config/tenant-config.service';
import { LinksConfiguration } from '../../interfaces/links-configuration';

@Component({
  selector: 'idv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  private configAssetServiceSubscription: Subscription;

  public linksConfig: LinksConfiguration;

  constructor(
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private tenantConfigService: TenantConfigService
  ) {}

  ngOnInit() {
    this.configAssetServiceSubscription =
      this.tenantConfigService.tenantConfig$.subscribe((config) => {
        this.linksConfig = config.links;
      });
  }

  openFaqModal() {
    this.dialog.open(
      FaqDialogComponent,
      this.responsiveService.enhanceDialogConfig({
        data: {},
        panelClass: 'super-big-modal',
      })
    );
  }

  ngOnDestroy(): void {
    if (this.configAssetServiceSubscription) {
      this.configAssetServiceSubscription.unsubscribe();
    }
  }
}
