<footer
  *ngIf="
    linksConfig?.imprint.isShown ||
    linksConfig?.legalNotice.isShown ||
    linksConfig?.privacyStatement.isShown ||
    linksConfig?.faq.isShown
  "
  class="footer container-fluid small"
>
  <a
    *ngIf="linksConfig?.imprint.isShown"
    class="mr-5 d-inline-block"
    [href]="linksConfig.imprint.url"
    target="_blank"
    idvClickTracker="idv_open_imprint?source=footer"
    rel="noopener noreferrer"
  >
    {{ 'footer.imprint' | translate }}
  </a>

  <a
    *ngIf="linksConfig?.legalNotice.isShown"
    class="mr-5 d-inline-block"
    [href]="linksConfig.legalNotice.url"
    target="_blank"
    idvClickTracker="idv_open_legal_notice?source=footer"
    rel="noopener noreferrer"
  >
    {{ 'footer.legal-notice' | translate }}
  </a>

  <a
    *ngIf="linksConfig?.privacyStatement.isShown"
    class="mr-5 d-inline-block"
    [href]="linksConfig.privacyStatement.url"
    target="_blank"
    idvClickTracker="idv_open_privacy_statement?source=footer"
    rel="noopener noreferrer"
  >
    {{ 'footer.privacy-statement' | translate }}
  </a>

  <a
    *ngIf="linksConfig.faq.isShown"
    class="mr-5 d-inline-block"
    (click)="openFaqModal()"
    href="javascript:void(0)"
    idvClickTracker="idv_open_faq?source=footer"
    rel="noopener noreferrer"
  >
    {{ 'footer.faq' | translate }}
  </a>
</footer>
