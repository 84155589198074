import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MrcTrackingService } from '../../../../../../../core-lib/src/lib/core/services/tracking/tracking.service';
import { Risk } from '../../../../../../../idv-lib/src/lib/interfaces';
import { RequestService } from '../../../../../../../idv-lib/src/lib/services/request/request.service';
import { StepName, StepStateService } from '../../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';

@Component({
  selector: 'idv-add-diseases',
  templateUrl: './add-diseases.component.html',
})
export class AddDiseasesComponent implements OnInit, OnDestroy {
  isSuccessDialogShown = false;
  addedRiskLabel: string;
  startQuestionnaireRouterLink: any[];
  displayValidationErrors: boolean;
  hasExistingDiseases: boolean;
  selectedProducts: Array<string>;
  isGeneralPhysicianRequest: boolean;

  private navigationServiceSubscription: Subscription;
  private requestServiceSubscription: Subscription;

  constructor(
    private requestService: RequestService,
    private stepStateService: StepStateService,
    private trackingService: MrcTrackingService
  ) {
    this.displayValidationErrors = false;
  }

  ngOnInit(): void {
    this.requestServiceSubscription = this.requestService.request
      .pipe(filter((request) => !!request))
      .subscribe((request) => {
        this.hasExistingDiseases =
          request.medicalRisks &&
          request.medicalRisks.risks &&
          request.medicalRisks.risks.length > 0;
        this.selectedProducts = request.products.map(
          (product) => product.productId
        );
        this.isGeneralPhysicianRequest = this.requestService.isGeneralPhysicianRequest(request);
      });
  }

  goToRisk(risk: Risk): void {
    this.trackingService.trackEvent('idv_add_risk', {
      riskClass: risk.riskClass,
    });
    this.stepStateService.uncomplete(StepName.add);
    this.navigateToRiskQuestionnaire(risk.id);
  }

  goToNextStep(): void {
    this.stepStateService.complete(StepName.add);
    this.stepStateService.navigateToNextStep();
  }

  private navigateToRiskQuestionnaire(riskId: string) {
    if (this.stepStateService.stepExists(riskId)) {
      this.stepStateService.goToRisk(riskId);
    }
  }

  ngOnDestroy(): void {
    if (this.navigationServiceSubscription) {
      this.navigationServiceSubscription.unsubscribe();
    }
    if (this.requestServiceSubscription) {
      this.requestServiceSubscription.unsubscribe();
    }
  }
}
