import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { conditionalValidator } from '../../../../../../core-lib';
import { BrowserSnifferService } from '../../../../../../core-lib/src/lib/core/services/misc/browser-sniffer.service';
import { PrecisionDateService } from '../../../../../../core-lib/src/lib/core/services/misc/precision-date.service';
import { MrcTrackingService } from '../../../../../../core-lib/src/lib/core/services/tracking/tracking.service';
import { PrecisionDate } from '../../../interfaces';
import { ResponsiveService } from '../../../services/responsive/responsive.service';
import { AuthenticationService } from '../../authentication.service';
import {
  IAuthenticationResult,
  AuthenticationResultType,
} from '../../model/authentication-result';
import { BaseSecondFactorComponent } from '../base-second-factor-component/base.second-factor-component';
import { StepStateService } from '../../../services/step-navigation/step-state.service';
import { TenantConfigService } from '../../../services/config/tenant-config.service';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from '../../../services/tenant/tenant.service';
import { MfaStrategy } from '../../model/mfa-strategy';
import { IBirthDateSecondFactorAuthenticationFormValue } from '../../model/form-value/birthdate.second-factor-authentication.form-value';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  templateUrl: './birthdate-factor.component.html',
  styleUrls: ['./birthdate-factor.component.scss'],
})
export class BirthdateFactorComponent
  extends BaseSecondFactorComponent
  implements OnInit, OnDestroy
{
  formFieldAppearance: string;
  textMask: string; // input for the textMask directive

  private _customValidationErrors: ValidationErrors = null;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private browserSnifferService: BrowserSnifferService,
    private precisionDateService: PrecisionDateService,
    tenantConfigService: TenantConfigService,
    authenticationService: AuthenticationService,
    trackingService: MrcTrackingService,
    location: Location,
    dialog: MatDialog,
    responsiveService: ResponsiveService,
    stepStateService: StepStateService,
    translateService: TranslateService,
    tenantService: TenantService
  ) {
    super(
      tenantConfigService,
      authenticationService,
      trackingService,
      location,
      dialog,
      responsiveService,
      stepStateService,
      translateService,
      tenantService
    );
  }

  ngOnInit() {
    super.onInit();

    this.currentLanguage
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => {
          this.textMask = this.precisionDateService.createTextMask('day');
        })
      )
      .subscribe();

    // see https://github.com/angular/components/issues/11650
    const problemBrowser =
      this.browserSnifferService.isFirefox() ||
      this.browserSnifferService.isMsie();
    this.formFieldAppearance = problemBrowser ? 'legacy' : 'fill';

    this.formGroup.controls.birthDate.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => {
          this._customValidationErrors = null;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get errors() {
    return (
      this.formGroup.controls.birthDate.errors || this._customValidationErrors
    );
  }

  getEndpointSuffix(): string {
    return MfaStrategy.BirthDate;
  }

  datePicked(value: any) {
    if (value instanceof Date) {
      // add 8 hours to ensure that date is not 23:00 of previous day in UTC
      const date = new Date(value.getTime() + 8 * 60 * 60 * 1000);
      const precisionDate = new PrecisionDate(date);
      const dateString =
        this.precisionDateService.formatPrecisionDate(precisionDate);
      this.formGroup.controls.birthDate.setValue(dateString);
    } else {
      this.formGroup.controls.birthDate.setValue(null);
    }
  }

  createFormGroup() {
    return this._formBuilder.group({
      birthDate: [null, this.requiredBirthDate],
      captchaToken: [
        '',
        {
          validator: conditionalValidator(
            () => this.showCaptcha,
            Validators.required
          ),
        },
      ],
    });
  }

  getFormResult() {
    const localDateStr: string = this.formGroup.controls.birthDate.value;
    const date: PrecisionDate =
      this.precisionDateService.parsePrecisionDate(localDateStr);
    const birthDate = date && date.isExistingDate ? date : null;
    const captchaToken = this.formGroup.controls.captchaToken.value;

    const result: IBirthDateSecondFactorAuthenticationFormValue = {
      birthDate, // will be formatted to ISO-Date when serialized to JSON
    };

    if (captchaToken) {
      result.captchaToken = captchaToken;
    }

    return result;
  }

  setAccessTokenErrors(result: IAuthenticationResult): void {
    if (result.resultType === AuthenticationResultType.secondFactorInvalid) {
      this._customValidationErrors = { birthdayInvalid: null };
    } else {
      this._customValidationErrors = { unknownError: null };
    }
  }

  private requiredBirthDate = (control: UntypedFormControl) => {
    const localDateStr = control.value as string;
    const date: PrecisionDate =
      this.precisionDateService.parsePrecisionDate(localDateStr);
    if (date && date.isExistingDate) {
      const age = calculateAge(date);
      if (age > 0 && age <= 115) {
        return null;
      }
    }
    return { invalidDate: true };
  };
}

function calculateAge(precisionDate: PrecisionDate): number {
  return (
    (Date.now() - precisionDate.getDate().getTime()) /
    (365.25 * 24 * 3600 * 1000)
  );
}
