<mat-radio-group [formControl]="formControlToUse">
  <mat-radio-button
    class="full-width"
    *ngFor="let selectableValue of selectableValues"
    [value]="selectableValue.id"
    [name]="radioListName"
  >
    {{ selectableValue.displayName }}
  </mat-radio-button>
</mat-radio-group>
