import * as _ from 'lodash/index';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: any): string {
    const pattern = _.escapeRegExp(search);
    const regex = new RegExp(pattern, 'gi');

    return search
      ? text.replace(
          regex,
          (match) => `<strong class="highlighted">${match}</strong>`
        )
      : text;
  }
}
