import { QuestionGroup } from './question-group';
import { Request } from './request';

export interface RiskQuestionGroupContext {
  questionGroupRiskId: string;
  questionGroupRiskLabel: string;
  questionGroup: QuestionGroup;
  previousQuestionGroupId: string;
  previousQuestionGroupRiskId: string;
  request: Request;
  reopenedRiskIds: Array<string>;
}
