import { Injectable } from '@angular/core';
const linebreak: any = require('@craigmorton/linebreak');

interface Break {
  position: number;
  required: boolean;
}

declare class LineBreaker {
  constructor(input: string);
  nextBreak(): Break;
}

@Injectable({ providedIn: 'root' })
export class WordBreakOpportunityService {
  public enhanceWithLineBreakOpportunities(input: string): string {
    const breaker = new linebreak(input) as LineBreaker;
    let last: number;
    let result = '';
    let breakPoint: Break;
    while ((breakPoint = breaker.nextBreak())) {
      const word = input.slice(last, breakPoint.position);
      result = `${result}<wbr>${word}`;
      last = breakPoint.position;
    }
    return result;
  }
}
