<form
  [formGroup]="formGroup"
  (ngSubmit)="validateAndSavePaymentData()"
>
  <h2 class="screen-header">
    {{ 'payment-data-view.account.headline' | translate }}
  </h2>

  <p class="info-text">
    {{ 'payment-data-view.account.data-compliance' | translate }}
  </p>

  <input
    type="hidden"
    name="paymentInformationFormat"
    formControlName="paymentInformationFormat"
  />

  <p>
    <mat-form-field
      appearance="fill"
      class="form-field-m"
    >
      <mat-label>{{
        'shared.payment.account.physician-name' | translate
      }}</mat-label>
      <input
        id="txt_payment_data_name"
        type="text"
        matInput
        formControlName="name"
        name="name"
        #name
        [pattern]="allowedCharacterPattern"
        [placeholder]="'shared.payment.account.physician-name' | translate"
        required
        maxlength="70"
      />
      <mat-hint align="end">{{ name.value?.length || 0 }}/70</mat-hint>
      <mat-error *ngIf="hasErrorToDisplay('name')">
        <idv-error-help-block
          [errors]="getErrorToDisplayFor('name')"
        ></idv-error-help-block>
      </mat-error>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field
      appearance="fill"
      class="form-field-m"
    >
      <mat-label>{{
        'shared.payment.account.account-holder' | translate
      }}</mat-label>
      <input
        type="text"
        matInput
        id="txt_payment_data_account_holder_name"
        formControlName="accountHolderName"
        name="accountHolderName"
        #accountHolderName
        [pattern]="allowedCharacterPattern"
        [placeholder]="'shared.payment.account.account-holder' | translate"
        maxlength="70"
      />
      <mat-hint align="end"
        >{{ accountHolderName.value?.length || 0 }}/70</mat-hint
      >
      <mat-error *ngIf="hasErrorToDisplay('accountHolderName')">
        <idv-error-help-block
          [errors]="getErrorToDisplayFor('accountHolderName')"
        ></idv-error-help-block>
      </mat-error>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field
      appearance="fill"
      class="form-field-m"
    >
      <mat-label>{{
        'shared.payment.account.sort-code' | translate
      }}</mat-label>
      <input
        type="text"
        matInput
        numberFormat
        id="txt_payment_data_sort_code"
        formControlName="sortCode"
        name="sortCode"
        [groupSize]="2"
        [digitsCount]="6"
        [placeholder]="'shared.payment.account.sort-code' | translate"
        required
      />
      <mat-error *ngIf="hasErrorToDisplay('sortCode')">
        <idv-error-help-block
          [errors]="getErrorToDisplayFor('sortCode')"
        ></idv-error-help-block>
      </mat-error>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field
      appearance="fill"
      class="form-field-m"
    >
      <mat-label>{{
        'shared.payment.account.account-number' | translate
      }}</mat-label>
      <input
        type="text"
        matInput
        numberFormat
        id="txt_payment_data_account_number"
        formControlName="accountNumber"
        name="accountNumber"
        [placeholder]="'shared.payment.account.account-number' | translate"
        [digitsCount]="8"
        required
      />
      <mat-error *ngIf="hasErrorToDisplay('accountNumber')">
        <idv-error-help-block
          [errors]="getErrorToDisplayFor('accountNumber')"
        ></idv-error-help-block>
      </mat-error>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field
      appearance="fill"
      class="form-field-m"
    >
      <mat-label>{{
        'shared.payment.account.reference' | translate
      }}</mat-label>
      <textarea
        type="text"
        matInput
        #reference
        id="txt_payment_data_reference"
        formControlName="reference"
        name="reference"
        rows="2"
        maxlength="140"
        [placeholder]="'shared.payment.account.reference' | translate"
      ></textarea>
      <mat-hint
        align="start"
        class="font-bold"
        >{{ 'payment-data-view.account.reference-hint' | translate }}</mat-hint
      >
      <mat-hint align="end">{{ reference.value?.length || 0 }}/140</mat-hint>
      <mat-error *ngIf="hasErrorToDisplay('reference')">
        <idv-error-help-block
          [errors]="getErrorToDisplayFor('reference')"
        ></idv-error-help-block>
      </mat-error>
    </mat-form-field>
  </p>
  <br />
  <p class="d-flex flex-row form-field-m">
    <button
      id="btn_idv_skip_payment"
      type="button"
      mat-stroked-button
      color="primary"
      class="flex-fill idv-form-button"
      data-placement="bottom"
      container="body"
      [style.display]="canSkip ? 'block' : 'none'"
      (click)="onSkip()"
      idvClickTracker="idv_payment_skip"
    >
      {{ 'payment-data-view.skip' | translate }}
    </button>
    <button
      type="submit"
      id="btn_idv_submit_payment"
      mat-raised-button
      color="primary"
      class="flex-fill idv-form-button"
      idvClickTracker="idv_payment_submit"
    >
      <span>{{ 'payment-data-view.submit' | translate }}</span>
      <idv-demo-label inBrackets></idv-demo-label>
    </button>
  </p>
</form>
