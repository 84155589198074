import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../../../idv-lib/src/lib/authentication/authentication.service';
import { Observable, Subject } from 'rxjs';
import { TenantService } from '../../services/tenant/tenant.service';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'idv-good-bye-view',
  templateUrl: './good-bye-view.component.html',
  styleUrls: ['./good-bye-view.component.scss'],
})
export class GoodByeViewComponent implements OnInit, OnDestroy {
  public tenant$: Observable<string>;

  private _destroyed$ = new Subject<void>();

  constructor(
    private _authenticationService: AuthenticationService,
    private _tenantService: TenantService
  ) {}

  ngOnInit() {
    this.tenant$ = this._tenantService.tenant.pipe(
      takeUntil(this._destroyed$),
      tap(() => this._authenticationService.logOut())
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
