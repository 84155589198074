import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from '../../../../../core-lib';
import { languageDisplayNames } from './language-display-names';
import { SessionKey } from '../../constants/session-key.constants';

@Component({
  selector: 'idv-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  languages = this.translateService.getLangs();
  selectedLanguage = this.translateService.currentLang;

  constructor(
    private translateService: TranslateService,
    private sessionStorageService: SessionStorageService
  ) {}

  getLanguageDisplayName(languageKey: string) {
    return languageDisplayNames[languageKey] || languageKey;
  }

  onSelectLanguage() {
    this.translateService.use(this.selectedLanguage);
    this.sessionStorageService.setItem(
      SessionKey.preferredLanguage,
      this.selectedLanguage
    );
  }
}
