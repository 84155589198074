<div
  class="d-flex flex-row justify-content-between align-items-top questionnaire-heading"
>
  <div>
    <h2 class="header">
      <span
        class="summary-prefix"
        *ngIf="showSummary"
        >{{ 'summary.headline' | translate }}&nbsp;</span
      >
      <span [ngClass]="{ 'label-small': isDescendantQuestionGroup }">{{
        riskLabels
      }}</span>
      <span *ngIf="isDescendantQuestionGroup">{{
        questionGroupRiskLabel
      }}</span>
    </h2>
  </div>
  <div
    class="edit-button-container"
    id="div_idv_change_question_context_menu"
    *ngIf="displayContextMenu"
  >
    <button
      mat-stroked-button
      color="primary"
      class="edit-risk-button"
      id="btn_idv_edit_risk"
      [disableRipple]="true"
      [disabled]="isEditingDisabled"
      [routerLink]="replaceRiskLink$ | async"
    >
      {{ 'risk-questionnaire.replace-risk' | translate }}
    </button>

    <button
      mat-stroked-button
      color="primary"
      class="edit-risk-button"
      id="btn_idv_edit_risk"
      [disableRipple]="true"
      [disabled]="isEditingDisabled"
      (click)="onClickRemoveRisk()"
    >
      {{ 'shared.delete' | translate }}
    </button>

    <button
      mat-mini-fab
      mat-stroked-button
      [disableRipple]="true"
      color="primary"
      [disabled]="isEditingDisabled"
      [routerLink]="replaceRiskLink$ | async"
      class="edit-risk-icon"
    >
      <mat-icon color="primary">edit</mat-icon>
    </button>

    <button
      mat-mini-fab
      mat-stroked-button
      [disableRipple]="true"
      color="primary"
      [disabled]="isEditingDisabled"
      (click)="onClickRemoveRisk()"
      class="edit-risk-icon"
    >
      <mat-icon color="primary">delete</mat-icon>
    </button>
  </div>
</div>
