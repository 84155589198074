import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'idv-demo-indicator',
  template: '<div></div>',
  styles: [
    'div { height: 5px; position: fixed; top:0; left:0; width: 100%; z-index:2000; background-color: #ffb3b3 }',
  ],
})
export class DemoIndicatorComponent implements OnInit {
  ngOnInit() {}
}
