import {
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';

// If on checkbox is madatory
export function tickIsMandatory(
  formArray: UntypedFormArray
): ValidationErrors | null {
  const minSelectedTicks = 1;
  const totalSelected = formArray.controls
    .map((control) => control.value)
    .reduce((prev, next) => (next ? prev + next : prev), 0);

  return totalSelected >= minSelectedTicks
    ? null
    : { tickIsMandatoryErrorMessage: true };
}

// If only exclusive checkbox is allowed
export function tickIsExclusive(exclusivesIndexArray: any): ValidatorFn | null {
  return (control: UntypedFormControl) => {
    let error: any = null;

    // Reset errors on field
    control['controls'].forEach((tickbox: AbstractControl) => {
      tickbox.setErrors(null);
    });

    for (let i = 0; i < exclusivesIndexArray.length; i++) {
      const exclusiveTickBox = control['controls'][exclusivesIndexArray[i]];
      const remainingTickBoxes = control['controls'].filter(
        (tickbox: AbstractControl) => tickbox !== exclusiveTickBox
      );

      if (exclusiveTickBox.value) {
        remainingTickBoxes.forEach((tickbox: AbstractControl) => {
          if (tickbox.value) {
            error = { tickIsExclusiveErrorMessage: true };
            tickbox.setErrors(error);
          }
        });
      }
    }

    return error ? error : null;
  };
}
