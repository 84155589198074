<div class="row">
  <div class="col data-protection-confirmation">
    <mat-checkbox
      #checkBoxValue
      type="checkbox"
      (change)="updateCheckboxValue(checkBoxValue.checked)"
      required
    >
      <p
        [innerHtml]="dataPrivacyStatement"
        idvClickTracker="idv_confirmation_open_data_privacy"
      ></p>
    </mat-checkbox>
  </div>
</div>
