import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import {
  createMaxDecimalPlacesValidator,
  createNumericValueRangeValidator,
  requiredNumber,
} from '../../../../../core-lib/src/lib/core/validation';
import { PrecisionDate, ValueRange } from '../../interfaces';

const requiredCheckedErrors = { requiredMonthUnknownChecked: true };
const noEmit = { emitEvent: false };
const onlySelf = { onlySelf: true };

@Component({
  selector: 'idv-month-year-input',
  templateUrl: './month-year-input.component.html',
  styleUrls: ['./month-year-input.component.scss'],
  providers: [
    // tslint:disable-next-line:no-use-before-declare
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthYearInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MonthYearInputComponent),
      multi: true,
    },
  ],
})
export class MonthYearInputComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator
{
  // when truthy, errors should be shown (e.g. when outer form is submitted)
  @Input() showErrors: boolean;
  // to reduce complexity, between input is mandatory
  @Input() between: ValueRange<PrecisionDate>;
  // the maximum year where month is optional; after that, month is required
  @Input() monthRequiredFromYear: number = null;

  readonly formGroup: UntypedFormGroup;
  private readonly yearControl: UntypedFormControl;
  private readonly monthControl: UntypedFormControl;
  readonly monthUnknownControl: UntypedFormControl;

  private precisionDate: PrecisionDate;

  onTouched = () => {};
  onChange: (date: PrecisionDate) => void = () => {};

  constructor(private fb: UntypedFormBuilder) {
    this.formGroup = this.fb.group({
      year: [],
      month: [],
      monthUnknown: new UntypedFormControl({ value: false, disabled: true }),
    });
    this.yearControl = this.formGroup.get('year') as UntypedFormControl;
    this.monthControl = this.formGroup.get('month') as UntypedFormControl;
    this.monthUnknownControl = this.formGroup.get(
      'monthUnknown'
    ) as UntypedFormControl;

    this.formGroup.valueChanges.subscribe(() => this.handleValueChange(true));
  }

  private handleValueChange(emitChangeEvent = false) {
    const errors = this.validateAndChange();
    this.formGroup.setErrors(errors);

    //if there is not even a value in year, the value of the whole control is set to null
    this.precisionDate = this.parseFormDataToPrecisionDate();

    if (emitChangeEvent) {
      this.onChange(this.precisionDate);
      this.onTouched();
    }
  }

  private validateAndChange(): ValidationErrors | null {
    const formGroup = this.formGroup;

    if (formGroup.disabled) {
      return null;
    }

    const { year, month, monthUnknown } = formGroup.getRawValue();

    if ((year == null || year === '') && (month == null || month === '')) {
      this.clearFieldErrors(['year', 'month', 'monthUnknown']);
      this.monthUnknownControl.disable(noEmit);
      this.monthUnknownControl.setValue(false, noEmit);
      return null; // [C1] validate `required` is the job of the consumer of the component
    }

    const yearErrors = transformToYearErrors(
      runValidations(this.yearControl, this.getYearValidators())
    );
    this.yearControl.setErrors(yearErrors, noEmit);

    if (yearErrors) {
      this.yearControl.markAsTouched(onlySelf); // show errors also when only month-field causes empty year invalid

      this.monthUnknownControl.disable(noEmit);
      const monthErrors = transformToMonthErrors(
        runValidations(this.monthControl, createSimpleMonthValidators())
      );
      this.monthControl.setErrors(monthErrors, noEmit);
      // [C2] year and maybe month fields are invalid
      return monthErrors ? { ...yearErrors, ...monthErrors } : yearErrors;
    }

    // from here on, year is valid
    const monthErrors = transformToMonthErrors(
      runValidations(this.monthControl, this.getMonthValidators())
    );

    if (this.isMonthOptional(year)) {
      return this.validateAndChangeMonthOptional(
        monthErrors,
        month,
        monthUnknown
      );
    }

    return this.validateAndChangeMonthRequired(monthErrors);
  }

  private validateAndChangeMonthOptional(
    monthErrors: ValidationErrors | null,
    month: number | null,
    monthUnknown: boolean
  ) {
    if (this.monthUnknownControl.disabled) {
      this.monthUnknownControl.enable(noEmit);
    }

    if (monthUnknown) {
      if (this.monthControl.enabled) {
        this.monthControl.disable(noEmit);
      }
      this.monthControl.markAsTouched(onlySelf); // to show errors on this field
      this.monthControl.setValue(null, noEmit);
      this.monthControl.setErrors(null, noEmit);
      return null; // [C3] checkbox checked -> month field can be ignored and input is done
    }

    if (this.monthControl.disabled) {
      this.monthControl.enable(noEmit);
    }

    if (monthErrors) {
      // user should first fix month errors or clear the field (don't distract her with checkbox)
      this.monthUnknownControl.setErrors(null, noEmit);
      this.monthControl.setErrors(monthErrors, noEmit);
      return monthErrors; // [C4]
    } else if ((!month && monthUnknown) || (month && !monthUnknown)) {
      this.clearFieldErrors(['month', 'monthUnknown']);
      return null; // [C5] year valid, month empty, unknown checked -or- month set and unknown unchecked
    }

    this.monthUnknownControl.setErrors(requiredCheckedErrors, noEmit);
    this.monthControl.setErrors(requiredCheckedErrors, noEmit);
    return requiredCheckedErrors; // [C6] year valid, but either month must be filled or unknown checked;
  }

  // ----------------------------------------------------- month is required for this year
  private validateAndChangeMonthRequired(monthErrors: ValidationErrors | null) {
    this.monthUnknownControl.setErrors(null, noEmit);
    if (this.monthUnknownControl.enabled) {
      this.monthUnknownControl.disable(noEmit);
    }
    if (this.monthControl.disabled) {
      this.monthControl.enable(noEmit);
    }
    if (monthErrors) {
      this.monthControl.setErrors(monthErrors, noEmit);
      return monthErrors; // [C7] year is valid, month invalid, monthUnknown disabled=invisible
    }

    this.monthControl.setErrors(null, noEmit);
    return null; // [C8] year and month are valid, monthUnknown not visible
  }

  private isMonthOptional(year: number): boolean {
    const monthRequiredFromYear = toInt(this.monthRequiredFromYear);
    year = toInt(year);
    if (isNaN(monthRequiredFromYear) || isNaN(year)) {
      return false;
    }
    return year < monthRequiredFromYear;
  }

  ngOnInit(): void {
    if (!this.between) {
      throw Error('between input is mandatory'); // component could be extended to live w/out it, if need
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['between'] && !changes['between'].firstChange) {
      this.handleValueChange(false);
    }
  }

  private clearFieldErrors(fields: string[]) {
    fields.forEach((field) =>
      this[field + 'Control'].setErrors(null, { emitEvent: false })
    );
  }

  private getMonthValidators() {
    const year = toInt(this.yearControl.value);
    const required = this.isMonthOptional(year) ? [] : [requiredNumber];

    let minMonth = 1;
    let maxMonth = 12;
    // set month min/max if year is on edge of `between`-range
    if (this.between && !isNaN(year)) {
      const minYear = this.between.min.year;
      const maxYear = this.between.max.year;

      if (year === minYear) {
        minMonth = this.between.min.month;
      }
      if (year === maxYear) {
        maxMonth = this.between.max.month;
      }
    }

    return [
      ...required,
      createMaxDecimalPlacesValidator(0),
      createNumericValueRangeValidator(minMonth, maxMonth),
    ];
  }

  private getYearValidators() {
    const minYear = this.between ? this.between.min.year : 1900;
    const maxYear = this.between ? this.between.max.year : 2099;

    return [
      requiredNumber,
      createMaxDecimalPlacesValidator(0),
      createNumericValueRangeValidator(minYear, maxYear),
    ];
  }

  // takes a value and writes it to the form control element
  // (model -> view)
  writeValue(date: PrecisionDate) {
    if (date != null && !(date instanceof PrecisionDate)) {
      throw Error('wrong type for date');
    }
    const value = {
      year: date ? date.year : null,
      month: date && date.month ? ('0' + date.month).substr(-2) : null,
      monthUnknown: date
        ? date.monthUnknown !== undefined
          ? date.monthUnknown
          : false
        : false,
    };
    this.formGroup.setValue(value, { emitEvent: false });
    this.handleValueChange(false);
    if (value.monthUnknown) {
      this.monthControl.disable({ emitEvent: false });
    }
    if (date) {
      date.monthUnknown = value.monthUnknown;
    }
    this.precisionDate = date;
  }

  // takes a function that should be called with the value if the value changes in the form control element itself
  // (view -> model)
  registerOnChange(fn: (date: PrecisionDate) => void): void {
    this.onChange = fn;
  }

  // takes a function to be called when the form control has been touched
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  visibleErrors(
    formGroup: UntypedFormGroup,
    name: string
  ): ValidationErrors | null {
    const formControl = formGroup.get(name);
    if (this.showErrors || formControl.touched) {
      return formControl.errors;
    }
    return null;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.formGroup.errors;
  }

  private parseFormDataToPrecisionDate(): PrecisionDate | null {
    const yearFormValue = this.formGroup.controls.year.value || null;
    const monthFormValue = this.formGroup.controls.month.value || null;
    const monthUnknownFormValue =
      this.formGroup.controls.monthUnknown.value || !monthFormValue;

    const year = toInt(yearFormValue);
    const month = toInt(monthFormValue);

    if (isNaN(year)) {
      return null;
    }

    if (isNaN(month)) {
      //We don't have a month => only return a valid date if monthUnknown is true
      return monthUnknownFormValue
        ? new PrecisionDate(year, null, null, true)
        : null;
    }
    //We have a month, check if it's valid
    if (!isNaN(month) && month >= 1 && month <= 12) {
      return new PrecisionDate(year, month, null, false);
    }
    return null;
  }
}

function runValidations(
  control: AbstractControl,
  validatorFns: ValidatorFn[]
): ValidationErrors | null {
  const errors = validatorFns.reduce(
    (errs, fn) => Object.assign(errs, fn(control)),
    {} as ValidationErrors
  );
  return Object.keys(errors).length ? errors : null;
}

function transformErrors(
  errors: ValidationErrors | null,
  map: { [key: string]: string }
): ValidationErrors | null {
  if (!errors) {
    return null;
  }
  return Object.keys(errors).reduce((result, sourceErrorKey) => {
    const translatedKey = map[sourceErrorKey];
    result[translatedKey || sourceErrorKey] = errors[sourceErrorKey];
    return result;
  }, {} as ValidationErrors);
}

function transformToYearErrors(
  errors: ValidationErrors | null
): ValidationErrors | null {
  return transformErrors(errors, {
    requiredNumber: 'yearRequired',
    rangeError: 'invalidYearRange',
  });
}

function transformToMonthErrors(
  errors: ValidationErrors | null
): ValidationErrors | null {
  return transformErrors(errors, {
    requiredNumber: 'monthRequired',
    rangeError: 'invalidMonth',
  });
}

function createSimpleMonthValidators() {
  return [
    createMaxDecimalPlacesValidator(0),
    createNumericValueRangeValidator(1, 12),
  ];
}

function toInt(n: any): number | null {
  if (typeof n === 'number') {
    return Number.isInteger(n) ? n : NaN;
  }
  if (typeof n === 'string') {
    const nTrimmed = n.trim();
    return parseInt(nTrimmed, 10);
  }
  return NaN;
}
