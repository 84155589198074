import { RequestStateType } from './request-state.type';

export interface AccessTokenValidationResponse {
  isAccessTokenValid: boolean;
  isTenantValid: boolean;
  requestStatus?: RequestStateType;
}

export function instanceOfAccessTokenValidationResponse(
  object: any
): object is AccessTokenValidationResponse {
  return 'isAccessTokenValid' in object;
}
