import { Component, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConfigAssetService } from '../../services/config/config-asset.service';
import { MatDialog } from '@angular/material/dialog';
import { MunichReDataProcessingStatementDialogComponent } from '../munichre-data-processing-statement-dialog/munichre-data-processing-statement-dialog.component';

@Component({
  selector: 'idv-data-privacy-dialog',
  templateUrl: './data-privacy-dialog.component.html',
})
export class DataPrivacyDialogComponent implements OnInit {
  public htmlFile$: Observable<string>;

  constructor(
    private _configAssetService: ConfigAssetService,
    private _httpClient: HttpClient,
    private _elem: ElementRef,
    private _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.htmlFile$ =
      this._configAssetService.tenantDataPrivacyStatementUrl.pipe(
        switchMap((url) => this._httpClient.get(url, { responseType: 'text' })),
        tap(() => setTimeout(() => this.setDataPrivacyLinks()))
      );
  }

  private setDataPrivacyLinks() {
    const linkElems: NodeList = this._elem.nativeElement.querySelectorAll(
      '.mr-data-privacy-link'
    );
    linkElems.forEach((linkElem) => {
      linkElem.addEventListener('click', () => {
        this._dialog.open(MunichReDataProcessingStatementDialogComponent, {
          panelClass: 'super-big-modal',
        });
      });
    });
  }
}
