<div
  class="informal-warning"
  *ngIf="description"
>
  <p>
    {{ description }}
  </p>
</div>
<form>
  <mat-form-field
    class="form-field-l"
    floatLabel="never"
    hideRequiredMarker="true"
  >
    <input
      #search
      id="txt_search_disease"
      type="text"
      matInput
      maxlength="255"
      [formControl]="searchRiskControl"
      (focus)="scrollToPosition(search)"
    />
    <mat-icon>search</mat-icon
    ><mat-label>{{ 'search-disease.placeholder' | translate }}</mat-label>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="searchInProgress"
      class="search-progress-bar"
    ></mat-progress-bar>
  </mat-form-field>
  <div
    *ngIf="displayDropdown"
    class="search-results form-field-l d-flex flex-column"
  >
    <div *ngIf="searchInProgress">
      <div
        *ngFor="let i of ghostBlockArray"
        class="search-result ghost-result"
      >
        <div class="ghost-block"></div>
      </div>
    </div>
    <div
      *ngIf="hasApproximateSearchResults$ | async"
      class="alternatives-heading"
    >
      {{
        'search-disease.alternatives'
          | translate : { risk: searchRiskControl.value }
      }}
    </div>
    <div class="search-result-list">
      <div
        class="search-result"
        *ngFor="let risk of filteredRisks | async"
        (click)="onSelectionChanged(risk)"
      >
        <span
          [innerHTML]="risk.label | highlight : searchRiskControl.value"
        ></span>
      </div>
    </div>

    <div
      *ngIf="hasApproximateSearchResults$ | async"
      class="search-result add-new-disease-item"
      (click)="addUnknownDisease()"
    >
      <span
        class="add-unknown-link"
        [innerHTML]="
          'search-disease.add-unknown'
            | translate : { risk: searchRiskControl.value }
        "
      ></span>
    </div>

    <div *ngIf="(hasNoSearchResults$ | async) || !!unknownRisk">
      <div class="not-found">
        <p>{{ 'search-disease.not-found-prefix' | translate }}</p>
        <p>
          <span class="highlighted">{{ searchRiskControl.value }}</span>
        </p>
        <p>{{ 'search-disease.not-found-suffix' | translate }}</p>
        <p class="hint">{{ 'search-disease.hint' | translate }}</p>
        <p *ngIf="confirmUnknownRisks"></p>
      </div>
      <div
        class="search-result add-new-disease-item"
        *ngIf="tenantGdqSetting && confirmUnknownRisks"
        (click)="addUnknownDisease()"
      >
        <span
          class="add-unknown-link"
          [innerHTML]="
            'search-disease.add-unknown'
              | translate : { risk: searchRiskControl.value }
          "
        ></span>
      </div>
    </div>
  </div>
  <p
    *ngIf="
      tenantGdqSetting &&
      displayDropdown &&
      (hasNoSearchResults$ | async) &&
      !confirmUnknownRisks
    "
  >
    {{ 'search-disease.add-anyway-prefix' | translate }}
    <span class="highlighted">{{ searchRiskControl.value }}</span>
    {{ 'search-disease.add-anyway-suffix' | translate }}
  </p>
</form>
