import { AbstractControl, ValidationErrors } from '@angular/forms';

export function lipidValuesCholesterolRequiredValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (control.value[1] && !control.value[0]) {
    control['controls'][0].setErrors({ cholesterolValuesRequired: true });
    return { cholesterolValuesRequired: true };
  }
  const errorCholesterol = control['controls'][0].errors;
  if (errorCholesterol) {
    control['controls'][0].setErrors({ cholesterolValuesRequired: null });
    control['controls'][0].updateValueAndValidity({
      onlySelf: true,
      emitEvent: false,
    });
  }
  return null;
}

export function lipidsInputRequiredValidator(
  control: AbstractControl
): ValidationErrors | null {
  let containsValue = false;
  control.value.forEach((element: number[]) => {
    if (element[0] || element[1] || element[2]) {
      containsValue = true;
    }
  });
  if (!containsValue) {
    return { completeLipidsInputRequired: true };
  }
  return null;
}

export function lipidValueHdlNotGreaterCholesterolValueValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (control.value[0] && control.value[1]) {
    if (Number(control.value[0]) < Number(control.value[1])) {
      control['controls'][1].setErrors({ hdlValueNotGreaterCholesterol: true });
      return { hdlValueNotGreaterCholesterol: true };
    }
    const errorHdl = control['controls'][1].errors;
    if (errorHdl) {
      control['controls'][1].setErrors({ hdlValueNotGreaterCholesterol: null });
      control['controls'][1].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
    return null;
  }

  return null;
}
