<div class="steps-container">
  <ol
    class="steps-list"
    [ngClass]="{ entered: loginEntered }"
  >
    <li
      *ngFor="let stepIndex of loginSteps"
      class="step"
      [ngClass]="{ entered: stepIndex == 0 && loginEntered }"
    >
      {{ 'landing-page.steps.' + stepIndex | translate }}
    </li>
  </ol>
</div>
