import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
  HostBinding,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  distinctUntilChanged,
  filter,
  take,
} from 'rxjs/operators';
import { MrcTrackingService } from '../../../../../core-lib/src/lib/core/services/tracking/tracking.service';
import { DataPrivacyDialogComponent } from '../data-privacy-dialog/data-privacy-dialog.component';
import { TenantService } from '../../services/tenant/tenant.service';
import { SessionErrorMessageComponent } from '../session-error-message/session-error-message.component';
import {
  expirationQueryParameter,
  AuthenticationService,
} from '../authentication.service';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { IdvEnvironment, IDV_ENV } from '../../interfaces/idv-environment';
import { TenantConfigService } from '../../services/config/tenant-config.service';
import { ConfigAssetService } from '../../services/config/config-asset.service';
import { OverlayDialogComponent } from '../../components/overlay-dialog/overlay-dialog.component';
import { LinksConfiguration } from '../../interfaces/links-configuration';
import { Config } from '../../interfaces';
import { LandingText } from '../../interfaces/landing-text.interface';

@Component({
  selector: 'idv-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  public landingTextTranslation$ = new BehaviorSubject<LandingText | null>(
    null
  );
  public showLanguageSelector$: Observable<boolean>;
  public hasSessionExpired$: Observable<boolean>;
  public certificateIcon$: Observable<string>;
  public loginEntered = false;
  public tenant: string;
  public showLoginSteps: boolean;
  public flavor: string;
  public hasCertificateIcon$: Observable<boolean>;
  public accessToken$: Observable<string>;

  private _destroyed$ = new Subject<void>();
  private _isDefaultLandingPage = false;
  private _defaultLandingPageDialogRef: MatDialogRef<OverlayDialogComponent>;
  private _invalidTenantDialogRef: MatDialogRef<OverlayDialogComponent>;

  private langSub$ = new Subscription();

  constructor(
    private _dialog: MatDialog,
    private _responsiveService: ResponsiveService,
    private _activatedRoute: ActivatedRoute,
    private _tenantService: TenantService,
    private _translateService: TranslateService,
    private _trackingService: MrcTrackingService,
    private _authenticationService: AuthenticationService,
    private _tenantConfigService: TenantConfigService,
    private _configAssetService: ConfigAssetService,
    @Inject(IDV_ENV) environment: IdvEnvironment
  ) {
    this.showLoginSteps = environment.flavor.showLoginSteps;
    this.flavor = environment.flavor.name;
  }

  @HostBinding('class.default-landing-page') get isDefaultLandingPage() {
    return this._isDefaultLandingPage;
  }

  ngOnInit() {
    this.hasSessionExpired$ = this._activatedRoute.queryParams.pipe(
      map(({ origin }) => origin === expirationQueryParameter.origin)
    );

    this._activatedRoute.data
      .pipe(
        takeUntil(this._destroyed$),
        map((data) => !!data && !!data.isDefaultLandingPage),
        tap((isDefaultLandingPage) => {
          this._isDefaultLandingPage = isDefaultLandingPage;

          if (isDefaultLandingPage) {
            this.displayInvalidOrNoTenantDialog();
          } else {
            this._defaultLandingPageDialogRef?.close();
          }
        })
      )
      .subscribe();

    this.accessToken$ = this._activatedRoute.queryParams.pipe(
      takeUntil(this._destroyed$),
      filter((params) => !!params?.accessToken),
      map((params) => params.accessToken as string)
    );

    this.hasSessionExpired$
      .pipe(first(), takeUntil(this._destroyed$))
      .subscribe((hasExpired: boolean) => {
        if (hasExpired) {
          setTimeout(() => {
            this.showSessionExpiredModal();
          }, 0);
        }
      });

    //We need to logout as we can have come here via the browser's back button
    this._authenticationService.logOut();

    this._tenantService.tenant
      .pipe(takeUntil(this._destroyed$))
      .subscribe((tenantString) => {
        if (!!tenantString) {
          this._tenantService
            .checkTenant(tenantString)
            .subscribe((checkTenantResponse) => {
              if (!checkTenantResponse.isValid) {
                this.displayInvalidOrNoTenantDialog();
              } else {
                this._invalidTenantDialogRef?.close();
              }
            });
          this.tenant = tenantString;
          this._trackingService.trackEvent('idv_tenant_landing_page', {
            tenant: tenantString,
          });
        } else {
          this.displayInvalidOrNoTenantDialog();
        }
      });

    this.langSub$ = this._translateService.onLangChange
      .pipe(
        startWith(null as LangChangeEvent),
        switchMap(() =>
          this._tenantConfigService.tenantConfig$.pipe(
            startWith({ links: {} as LinksConfiguration })
          )
        )
      )
      .subscribe(({ links, texts }: Config) => {
        const tuevReportUrl = links?.tuevReport?.url || '';
        const tueLocalTranslation = this._translateService.instant(
          'landing-page.tuev-info',
          {
            uri: tuevReportUrl,
          }
        );
        const gdprLocalTranslation = this._translateService.instant(
          'landing-page.gdpr-info'
        );
        const gdprLinkLocalTranslation = this._translateService.instant(
          'landing-page.gdpr-info-link-text'
        );

        const tuevInfo = texts?.['landing-tuev-info'];
        const gdprInfo = texts?.['landing-gdpr-info'];
        const gdprLink = texts?.['landing-gdpr-info-link-text'];

        const tuevInfoTranslated = tuevInfo || tueLocalTranslation;
        const gdprInfoTranslated = gdprInfo || gdprLocalTranslation;
        const gdprLinkTranslated = gdprLink || gdprLinkLocalTranslation;

        this.landingTextTranslation$.next({
          gdprInfo: gdprInfoTranslated,
          tuevInfo: tuevInfoTranslated,
          gdprInfoLink: gdprLinkTranslated,
        });
      });

    this.showLanguageSelector$ = this._tenantConfigService.tenantConfig$.pipe(
      map((config) => config.showLanguageSelector)
    );

    this.certificateIcon$ = this._configAssetService.certificateIconUrl.pipe(
      takeUntil(this._destroyed$),
      distinctUntilChanged()
    );

    this.hasCertificateIcon$ = this._tenantConfigService.tenantConfig$.pipe(
      map((config) => config.displayCertificateIcon)
    );
  }

  openGDPRDialog() {
    this._dialog.open(
      DataPrivacyDialogComponent,
      this._responsiveService.enhanceDialogConfig({
        panelClass: 'super-big-modal',
      })
    );
  }

  showSessionExpiredModal() {
    this._trackingService.trackEvent('idv_session_expired');
    this._dialog.open(
      SessionErrorMessageComponent,
      this._responsiveService.enhanceDialogConfig({
        panelClass: 'big-modal',
        data: 'session-error',
      })
    );
  }

  private displayInvalidOrNoTenantDialog(): void {
    this._translateService
      .stream('landing-page.default-landing-page-overlay-text')
      .pipe(take(1))
      .subscribe((displayText) => {
        this._invalidTenantDialogRef = this._dialog.open(
          OverlayDialogComponent,
          {
            panelClass: 'super-big-modal',
            disableClose: true,
            data: {
              content: displayText,
            },
          }
        );
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.langSub$.unsubscribe();
  }
}
