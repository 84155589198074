import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  Injectable,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Config, RiskListItem } from '../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../../services/risks/search.service';
import {
  map,
  tap,
  startWith,
  takeUntil,
  debounceTime,
  filter,
  switchMap,
} from 'rxjs/operators';
import { UnreasonableRiskWarningComponent } from '../../questionaires/step-components/unreasonable-risk-warning/unreasonable-risk-warning.component';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { TenantConfigService } from '../../services/config/tenant-config.service';

@Component({
  selector: 'idv-search-risk-control',
  templateUrl: './search-risk-control.component.html',
  styleUrls: ['./search-risk-control.component.scss'],
})
@Injectable({ providedIn: 'root' })
export class SearchRiskControlComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output() onRiskSelected = new EventEmitter<string | RiskListItem>();

  @Input() description: string;
  @Input() confirmUnknownRisks = false;

  private _isInputWarningArmed: boolean;
  private _destroyed$ = new Subject<void>();
  private _currentSelection: string | RiskListItem;

  public hasNoSearchResults$: Observable<boolean>;
  public hasApproximateSearchResults$: Observable<boolean>;
  public hasExactSearchResult$: Observable<boolean>;

  public set currentSelection(currentSelection: string | RiskListItem) {
    this._currentSelection = currentSelection;
    this.onRiskSelected.emit(currentSelection);
  }

  public get currentSelection() {
    return this._currentSelection;
  }

  public searchRiskControl = new UntypedFormControl();
  public filteredRisks = new Subject<RiskListItem[]>();
  public ghostBlockArray = Array<number>(8).fill(0);
  public searchInProgress = false;
  public displayDropdown = false;
  public tenantGdqSetting: boolean;
  public isInsured: boolean;

  constructor(
    private _dialog: MatDialog,
    private _searchRiskService: SearchService,
    private responsiveService: ResponsiveService,
    private _tenantConfigService: TenantConfigService
  ) {}

  ngOnInit() {
    this._isInputWarningArmed = true;
    this.isInsured =
      this._searchRiskService.getApplicationFlavor().name === 'insured';
    this._tenantConfigService.tenantConfig$
      .pipe(
        startWith(null as Config),
        map((config) => config?.allowRisksWithGdqForPhysician ?? null)
      )
      .subscribe(
        (gdqSetting) => (this.tenantGdqSetting = gdqSetting && !this.isInsured)
      );

    this.hasNoSearchResults$ = this.filteredRisks.pipe(
      map((array) => array && array.length === 0),
      tap((hasNoSearchResults) => {
        if (hasNoSearchResults && this.tenantGdqSetting) {
          if (!this.confirmUnknownRisks) {
            this.currentSelection = this.searchRiskControl.value
              ? this.searchRiskControl.value.trim()
              : null;
          }
        }
      }),
      startWith(false)
    );

    this.hasApproximateSearchResults$ = this.filteredRisks.pipe(
      map(
        (val) =>
          val &&
          val.length > 0 &&
          !this.isSearchTermInArray(val, this.searchRiskControl.value)
      ),
      startWith(false)
    );

    this.hasExactSearchResult$ = this.filteredRisks.pipe(
      map(
        (val) =>
          val &&
          val.length > 0 &&
          this.isSearchTermInArray(val, this.searchRiskControl.value)
      ),
      startWith(false)
    );
  }

  ngAfterViewInit() {
    this.searchRiskControl.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        tap((inputValue: string) => {
          if (inputValue.trim().length > 0) {
            this.searchInProgress = true;
          }
        }),
        debounceTime(1000),
        tap((value) => {
          this.resetSelection();
          this.resetSearchIfRequired(value);
        }),
        tap((inputValue: string) => {
          if (inputValue.trim().length > 0) {
            this.filteredRisks.next(null);
            this.displayDropdown = true;
            this.analyzeInputForUnreasonableRisks(inputValue);
          } else {
            this.searchInProgress = false;
            this.displayDropdown = false;
          }
        }),
        filter((value) => !(value.trim().length === 0)),
        switchMap((value) => this.search(value))
      )
      .subscribe(
        (results) => {
          this.searchInProgress = false;
          if (results && this.searchRiskControl.value.trim().length > 0) {
            this.filteredRisks.next(this.isGdqAllowed(results.results));
          }
        },
        (_err) => {
          this.searchInProgress = false;
        }
      );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onSelectionChanged(riskListItem: RiskListItem) {
    this.searchRiskControl.setValue(riskListItem.label, { emitEvent: false });
    this.currentSelection = riskListItem;
    this.displayDropdown = false;
    this.filteredRisks.next(null);
    this._isInputWarningArmed = false;
  }

  public addUnknownDisease() {
    this.currentSelection = this.searchRiskControl.value;
    this.filteredRisks.next(null);
  }

  public scrollToPosition(el: Element) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  public armInputWarning() {
    this._isInputWarningArmed = true;
  }

  private isSearchTermInArray(
    riskArray: Array<RiskListItem>,
    searchTerm: string
  ) {
    return (
      !!searchTerm &&
      searchTerm.trim().length > 0 &&
      riskArray.findIndex(
        (risk) =>
          risk.label.toLowerCase().trim() === searchTerm.toLowerCase().trim()
      ) >= 0
    );
  }

  private isGdqAllowed(riskArray: Array<RiskListItem>) {
    if (!this.tenantGdqSetting) {
      return riskArray.filter((v) => v.leadsToGdq !== true);
    }
    return riskArray;
  }

  private resetSelection() {
    this.currentSelection = null;
  }

  private resetSearchIfRequired(searchTerm: string) {
    if (searchTerm.trim().length === 0) {
      this.resetSearchResults();
    }
  }

  private resetSearchResults() {
    this.filteredRisks.next([]);
  }

  private analyzeInputForUnreasonableRisks(inputValue: string) {
    if (
      this.shouldDisplayUnreasonableWarning(inputValue) &&
      this._isInputWarningArmed
    ) {
      this._dialog.open(
        UnreasonableRiskWarningComponent,
        this.responsiveService.enhanceDialogConfig({
          panelClass: 'small-modal',
        })
      );
      this._isInputWarningArmed = false;
    }
  }

  private search(searchTerm: string) {
    return this._searchRiskService.getMedicalRisksWithQuery(searchTerm);
  }

  private shouldDisplayUnreasonableWarning(inputValue: string): boolean {
    return inputValue.includes(',') || inputValue.includes(';');
  }
}
