import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private _applicationLanguageAvailable$ = new BehaviorSubject<boolean>(false);

  private _dateSeparatorMap = {
    de: '.',
    en: '/',
    es: '.',
    el: '/',
    fr: '/',
    it: '/',
    nl: '-',
  };

  private _thousandSeparatorMap = {
    de: '.',
    en: ',',
    es: '.',
    el: '.',
    fr: ' ',
    it: '.',
    nl: '.',
  };

  private _decimalSeparatorMap = {
    de: ',',
    en: '.',
    es: ',',
    el: ',',
    fr: ',',
    it: ',',
    nl: ',',
  };

  constructor(private _translateService: TranslateService) {}

  public init(): void {
    this._translateService.addLangs(['en', 'de', 'es', 'el', 'fr', 'it', 'nl']);
    this._translateService.setDefaultLang('en');
  }

  public get applicationLanguageAvailable$(): Observable<boolean> {
    return this._applicationLanguageAvailable$.asObservable();
  }

  public getCurrentLanguage(): string {
    return this._translateService.currentLang;
  }

  public getThousandSeparator() {
    return this._thousandSeparatorMap[this.getCurrentLanguage()];
  }

  public getDecimalSeparator() {
    return this._decimalSeparatorMap[this.getCurrentLanguage()];
  }

  public getDateSeparator(): string {
    return this._dateSeparatorMap[this.getCurrentLanguage()];
  }

  public setLanguage(language: string) {
    this.useLanguageOrDefault(language)
      .pipe(
        tap(() => {
          this._applicationLanguageAvailable$.next(true);
        })
      )
      .subscribe();
  }

  private useLanguageOrDefault(preferredLanguage: string): Observable<void> {
    const availableLanguages = this._translateService.getLangs();
    if (preferredLanguage && availableLanguages.includes(preferredLanguage)) {
      return this._translateService.use(preferredLanguage);
    }

    return this._translateService.use('de');
  }
}
