import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { silentHeader } from '../../../../../core-lib';
import { CorrelationIdService } from '../../../../../core-lib';
import {
  RiskListItem,
  RiskQuery,
  SearchQueryResult,
  UseCase,
} from '../../../../../idv-lib/src/lib/interfaces';
import { DemoModeService } from '../../../../../idv-lib/src/lib/services/config/demo-mode.service';
import {
  IDV_ENV,
  IdvEnvironment,
} from '../../../../../idv-lib/src/lib/interfaces/idv-environment';

@Injectable({ providedIn: 'root' })
export class SearchService implements OnDestroy {
  private searchResultsSubject = new BehaviorSubject<
    SearchQueryResult<RiskListItem>
  >(null);
  private readonly useCase: UseCase = 'physician';
  private startIndex = 0;
  private isTestTenant = false;
  private amount = 20;

  public searchResults = this.searchResultsSubject.asObservable();

  private demoTenantSubscription: Subscription;

  constructor(
    private httpClient: HttpClient,
    private demoModeService: DemoModeService,
    private correlationIdService: CorrelationIdService,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {
    this.demoTenantSubscription = this.demoModeService.isDemoTenant.subscribe(
      (isDemoTenant) => (this.isTestTenant = isDemoTenant)
    );
  }

  public getApplicationFlavor() {
    return this.environment.flavor;
  }

  public getMedicalRisksWithQuery(
    searchTerm: string
  ): Observable<SearchQueryResult<RiskListItem>> {
    const query = {
      startIndex: this.startIndex,
      useCase: this.useCase,
      searchTerm: encodeURIComponent(searchTerm),
      amount: this.amount,
      isTestTenant: this.isTestTenant,
    } as RiskQuery;

    if (!query || !query.searchTerm) {
      return of(null);
    }

    const headers = new HttpHeaders(silentHeader);
    return this.httpClient.get<SearchQueryResult<RiskListItem>>(
      `${
        this.environment.apiHost
      }/idv/${this.correlationIdService.getCorrelationIdFromSessionToken()}/search/risks/medical?searchTerm=${
        query.searchTerm
      }&startIndex=${query.startIndex}&amount=${query.amount}&isTestTenant=${
        query.isTestTenant
      }&useCase=${query.useCase}`,
      { headers }
    );
  }

  ngOnDestroy(): void {
    if (this.demoTenantSubscription) {
      this.demoTenantSubscription.unsubscribe();
    }
  }
}
