import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import {
  tickIsExclusive,
  tickIsMandatory,
} from '../../../../../../../../core-lib/src/lib/core/validation';
import { Question } from '../../../../../interfaces/question';
import { QuestionGroup } from '../../../../../interfaces/question-group';
import { isQuestionAnswerable } from '../../question-utils/is-question-answerable';

@Component({
  selector: 'idv-tickbox-group',
  templateUrl: './tickbox-group.component.html',
  styleUrls: ['./tickbox-group.component.scss'],
})
export class TickboxGroupComponent implements OnChanges {
  @Input() questionGroup: QuestionGroup;
  @Input() backButtonRouterLink: any[];
  @Input() readonlyMode = false;

  @Output() questionGroupAnswer = new EventEmitter<QuestionGroup>();

  isMandatory: boolean;
  headerText: string;
  formGroup: UntypedFormGroup;
  tickBoxControls: any;
  showErrors = false;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('questionGroup' in simpleChanges) {
      this.isMandatory =
        this.questionGroup.questionGroupType.endsWith('Mandatory');
      this.getTickBoxGroupHeader(this.questionGroup.questions);
      this.updateTickBoxFormGroup(this.questionGroup.questions);
      if (this.readonlyMode) {
        this.formGroup.disable();
      }
    }
  }

  private updateTickBoxFormGroup(questionsArray: Question[]) {
    this.tickBoxControls = questionsArray.map((question) => {
      const selection = !!question.selection;
      return new UntypedFormControl(selection);
    });

    if (this.isMandatory) {
      this.formGroup = this.formBuilder.group({
        tickQuestions: new UntypedFormArray(this.tickBoxControls, [
          tickIsMandatory,
          tickIsExclusive(
            this.getTickIsExclusiveIndex(this.questionGroup.questions)
          ),
        ]),
      });
    } else {
      this.formGroup = this.formBuilder.group({
        tickQuestions: new UntypedFormArray(
          this.tickBoxControls,
          tickIsExclusive(
            this.getTickIsExclusiveIndex(this.questionGroup.questions)
          )
        ),
      });
    }
  }

  getTickIsExclusiveIndex(group: any): Array<number> {
    const tickIsExclusiveIndex: number[] = [];
    for (let i = 1; i < group.length; i++) {
      if (group[i].isExclusive === true) {
        tickIsExclusiveIndex.push(i);
      }
    }
    return tickIsExclusiveIndex;
  }

  getTickBoxGroupHeader(questionsArray: any) {
    if (questionsArray[0].type === 'textOnly') {
      this.headerText = questionsArray[0].text ? questionsArray[0].text : '';
    }
  }

  // this is used to show the tickExclusiveErrorMessage immediately the mandatory message should only be shown on submit
  get containsTickExclusiveError() {
    if (this.formGroup.invalid) {
      return !!this.formGroup.controls.tickQuestions.errors[
        'tickIsExclusiveErrorMessage'
      ];
    }
    return false;
  }

  errorsOnTickBoxForm(): ValidationErrors {
    return this.formGroup.invalid
      ? this.formGroup.controls.tickQuestions.errors
      : null;
  }

  submitAnsweredQuestionGroup() {
    if (this.formGroup.valid) {
      const { id, questionGroupType, assessmentFactor, questions } =
        this.questionGroup;

      const answeredQuestionGroup: QuestionGroup = {
        id: id,
        assessmentFactor: assessmentFactor,
        questionGroupType: questionGroupType,
        questions: questions,
      };

      // Get checkbox values
      for (let i = 0; i < this.formGroup.value.tickQuestions.length; i++) {
        if (isQuestionAnswerable(questions[i])) {
          if (this.formGroup.value.tickQuestions[i]) {
            answeredQuestionGroup.questions[i]['selection'] = 'yes';
          } else {
            answeredQuestionGroup.questions[i]['selection'] = null;
          }
        }
      }
      this.questionGroupAnswer.emit(answeredQuestionGroup);
    } else {
      this.showErrors = true;
    }
  }
}
