import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  createMaxDecimalPlacesValidator,
  createNumericValueRangeValidator,
  requiredNumber,
  createNumberValidator,
} from '../../../../../../../core-lib/src/lib/core/validation';
import {
  Question,
  QuestionGroup,
  PrecisionDate,
  QuestionInputValue,
} from '../../../../interfaces';
import { isQuestionAnswerable } from '../question-utils/is-question-answerable';
import { monthOptionalYearValidator } from '../../../../../../../core-lib/src/lib/core/validation/month-optional-year-validator';
import { isArray } from 'lodash';
import { DateBetweenValidatorFactory } from '../../../services/date-between-validator.factory';

@Component({
  selector: 'idv-question-group',
  templateUrl: './question-group.component.html',
  styleUrls: ['./question-group.component.scss'],
})
export class QuestionGroupComponent implements OnChanges {
  @Input()
  questionGroup: QuestionGroup;
  @Input()
  disclaimerText: string;
  @Input()
  disclaimerStyle = 'info';
  @Input()
  backButtonRouterLink: any[];
  @Input()
  readonlyMode = false;

  @Output()
  questionGroupAnswer = new EventEmitter<QuestionGroup>();

  public formGroup: UntypedFormGroup;
  public areAllErrorMessagesShown = false;
  public displayForm = true;

  isMultiSelectQuestionGroup: boolean;

  constructor(
    private _dateBetweenValidatorFactory: DateBetweenValidatorFactory
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('questionGroup' in changes) {
      this.isMultiSelectQuestionGroup = this._isMultiSelectQuestionGroup();
      this.updateFormGroup();
      if (this.readonlyMode) {
        this.formGroup.disable();
      }
    }
  }

  private _isMultiSelectQuestionGroup() {
    return (
      this.questionGroup &&
      this.questionGroup.questions &&
      this.questionGroup.questions.filter(
        (question) => question.type === 'multipleChoice'
      ).length >= 2
    );
  }

  submitAnsweredQuestionGroup() {
    this.areAllErrorMessagesShown = true;

    if (this.formGroup.valid) {
      const { id, questionGroupType, questions, assessmentFactor } =
        this.questionGroup;
      const answeredQuestions = questions.map((question) =>
        question.type === 'date'
          ? this.createAnsweredDateQuestion(question)
          : this.createAnsweredQuestion(question)
      );

      const answeredQuestionGroup: QuestionGroup = {
        id,
        assessmentFactor,
        questionGroupType,
        questions: answeredQuestions,
      };

      this.questionGroupAnswer.emit(answeredQuestionGroup);
    }
  }

  hasError(formControlName: string): boolean {
    const formControl = this.getField(formControlName);
    return formControl && formControl.invalid && this.areAllErrorMessagesShown;
  }

  errorsOnField(formControlName: string): ValidationErrors {
    return this.hasError(formControlName)
      ? this.getField(formControlName).errors
      : null;
  }

  private getField(formControlName: string): AbstractControl {
    return this.formGroup && this.formGroup.get(formControlName);
  }

  private createAnsweredQuestion(question: Question): Question {
    return isQuestionAnswerable(question)
      ? {
          ...question,
          ...this.singleOrMultipleSelections(
            this.formGroup.controls[question.id].value
          ),
        }
      : {
          ...question,
        };
  }

  private singleOrMultipleSelections(
    selection: QuestionInputValue | Array<QuestionInputValue>
  ) {
    return isArray(selection)
      ? {
          selectionArray: selection,
        }
      : {
          selection: selection,
        };
  }

  private createAnsweredDateQuestion(question: Question): Question {
    return isQuestionAnswerable(question)
      ? {
          ...question,
          monthUnknown: (<PrecisionDate>(
            this.formGroup.controls[question.id].value
          )).monthUnknown,
          ...this.singleOrMultipleSelections(
            this.formGroup.controls[question.id].value
          ),
        }
      : {
          ...question,
        };
  }

  private buildValidators(question: Question): ValidatorFn[] {
    const result = [] as ValidatorFn[];

    if (question.type === 'number') {
      result.push(createNumberValidator);
      if (question.isMandatory) {
        result.push(requiredNumber);
      }
      if (typeof question.maxDecimalPlaces === 'number') {
        result.push(createMaxDecimalPlacesValidator(question.maxDecimalPlaces));
      }
      if (question.numericRange) {
        result.push(
          createNumericValueRangeValidator(
            question.numericRange.min,
            question.numericRange.max
          )
        );
      }
    } else if (question.type === 'date') {
      if (question.precision?.unit === 'monthOptional') {
        if (question.isMandatory) {
          //Month optional needs specific required validator
          result.push(monthOptionalYearValidator());
          result.push(Validators.required);
        }
      } else {
        result.push(
          this._dateBetweenValidatorFactory.createDateBetweenValidator(
            question.dateRange
              ? new PrecisionDate(question.dateRange.min)
              : null,
            question.dateRange
              ? new PrecisionDate(question.dateRange.max)
              : null,
            question.precision?.unit
          )
        );
      }
    } else {
      if (question.isMandatory) {
        result.push(Validators.required);
      }
    }
    // TO BE CONTINUED W/ OTHER question types ...

    return result;
  }

  private updateFormGroup() {
    this.refreshFormTemplate();

    this.areAllErrorMessagesShown = false;
    if (this.questionGroup && this.questionGroup.questions) {
      const formGroupStructure = this.questionGroup.questions.reduce(
        (accumulator, question) => {
          if (isQuestionAnswerable(question)) {
            accumulator[question.id] =
              question.type === 'date'
                ? new UntypedFormControl(
                    new PrecisionDate(
                      question.selection,
                      null,
                      null,
                      question.monthUnknown
                    ),
                    this.buildValidators(question)
                  )
                : new UntypedFormControl(
                    question.selection ?? question.selectionArray ?? null,
                    this.buildValidators(question)
                  );
          }
          return accumulator;
        },
        {}
      );
      this.formGroup = new UntypedFormGroup(formGroupStructure);
    } else {
      this.formGroup = new UntypedFormGroup({});
    }
  }

  private refreshFormTemplate() {
    this.displayForm = false;
    setTimeout(() => {
      this.displayForm = true;
    });
  }
}
