<div
  class="fullscreen-activity-indicator-backdrop"
  [class.visible]="isVisible | async"
  [class.semi-transparent]="isSemiTransparent"
>
  <div class="fullscreen-activity-indicator">
    <mrc-activity-indicator
      size="large"
      variant="primary"
    ></mrc-activity-indicator>
  </div>
</div>
