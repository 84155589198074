import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Applicant } from '../../../../../idv-lib/src/lib/interfaces';
import { TenantService } from '../../../../../idv-lib/src/lib/services/tenant/tenant.service';
import { NO_HTML_TAGS_ALLOWED_PATTERN } from '../../utilities/allowed-characters.const';
import { Subscription } from 'rxjs';
import { RequestService } from '../../../../../idv-lib/src/lib/services/request/request.service';
import { TranslateService } from '@ngx-translate/core';
import { daysBetween } from '../../../../../idv-lib/src/lib/utils/date.utils';
import { saveAs } from 'file-saver';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './download-pdf-dialog.component.html',
  styleUrls: ['./download-pdf-dialog.component.scss'],
})
export class DownloadPdfDialogComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  allowedCharacterPattern = NO_HTML_TAGS_ALLOWED_PATTERN;
  afterDownloadText: string;

  private _applicantData: Applicant = null;
  private _paperDownloadReason: any;
  private _successfullyDownloaded = false;
  private _requestSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DownloadPdfDialogComponent>,
    private requestService: RequestService,
    private translateService: TranslateService,
    private tenantService: TenantService
  ) {}

  // I do not think that those take(1)-constructs are really optimal. We subscribe to everything, although
  // most of the time the data is stable. Up for discussion?

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      feedback: ['', Validators.pattern(this.allowedCharacterPattern)],
    });

    this._requestSubscription = this.requestService.request
      .pipe(filter((request) => !!request))
      .subscribe((request) => {
        this._applicantData = request.applicant;
        const subStatus = request.subStatus;
        this._paperDownloadReason =
          subStatus && subStatus.paperDownloadMetadata
            ? subStatus.paperDownloadMetadata.downloadFeedback
            : null;

        const expirationDate: Date =
          subStatus &&
          subStatus.paperDownloadMetadata &&
          subStatus.paperDownloadMetadata.expirationDate
            ? new Date(subStatus.paperDownloadMetadata.expirationDate)
            : null;

        const remainingDays = expirationDate
          ? daysBetween(new Date(), expirationDate).toString()
          : '';

        this.afterDownloadText = this.translateService.instant(
          'paper-download.after-download-text',
          {
            remainingDays,
          }
        );

        this.formGroup.get('feedback').setValue(this._paperDownloadReason);
      });
  }

  ngOnDestroy(): void {
    if (this._requestSubscription) {
      this._requestSubscription.unsubscribe();
    }
  }

  get successfullyDownloaded(): boolean {
    return this._successfullyDownloaded;
  }

  private getFileName(applicant: Applicant = {} as Applicant): string {
    const label = this.translateService.instant('medical-report.filename');
    const { initials } = applicant;
    const tenant = this.tenantService.current;
    const baseName = [tenant, label, initials]
      .filter((s) => !!s)
      .map((s) => s.trim().replace(/ +/g, '-').replace(/\./g, ''))
      .join('-');
    return `${baseName}.pdf`;
  }

  downloadPaper() {
    if (this._applicantData && this.formGroup.valid) {
      this.requestService
        .downloadPaper(this.formGroup.get('feedback').value)
        .subscribe((blob: Blob) => {
          saveAs(blob, this.getFileName(this._applicantData));
          this._successfullyDownloaded = true;
        });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getErrorsToDisplayFor(formControlName: string) {
    return this.formGroup.controls[formControlName].errors;
  }
}
