<ng-container [ngSwitch]="question.type">
  <idv-radio-list-control
    *ngSwitchCase="'singleChoice'"
    [formControlToUse]="formControlToUse"
    [selectableValues]="question.selectableValues"
    [radioListName]="question.id"
  ></idv-radio-list-control>
  <idv-check-box-control
    *ngSwitchCase="'multipleChoice'"
    [formControlToUse]="formControlToUse"
    [selectableValues]="question.selectableValues"
  ></idv-check-box-control>
  <idv-number-control
    *ngSwitchCase="'number'"
    [formControlToUse]="formControlToUse"
    [maxDecimalPlaces]="question.maxDecimalPlaces"
  ></idv-number-control>
  <idv-date-control
    *ngSwitchCase="'date'"
    [formControlToUse]="formControlToUse"
    [precision]="question.precision"
    [dateRange]="question.dateRange"
    [monthRequiredFromYear]="monthRequiredFromYear"
    [showErrors]="showErrors"
  ></idv-date-control>
  <idv-enhanced-search-control
    *ngSwitchCase="'enhancedSearch'"
    [formControl]="formControlToUse"
  ></idv-enhanced-search-control>
</ng-container>
