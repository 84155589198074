import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { QuestionGroup } from '../../../../../../idv-lib/src/lib/interfaces';
import { RequestService } from '../../../../../../idv-lib/src/lib/services/request/request.service';
import { StepStateService } from '../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'idv-prologue-step',
  templateUrl: './prologue-step.component.html',
})
export class PrologueStepComponent implements OnInit, OnDestroy {
  private prologueServiceSubscription: Subscription;
  prologueQuestionGroup: QuestionGroup;

  constructor(
    private requestService: RequestService,
    private stepStateService: StepStateService
  ) {}

  ngOnInit(): void {
    this.requestService.request
      .pipe(filter((request) => !!request))
      .subscribe((request) => {
        if (!request.prologueQuestionnaire) {
          throw Error('ins token');
        }
        this.prologueQuestionGroup =
          request.prologueQuestionnaire.questionGroups[0];
      });
  }

  submitAnsweredPrologueQuestionGroup(answeredQuestionGroup: QuestionGroup) {
    this.requestService.refetchRequest().subscribe((request) => {
      if (request.prologueQuestionnaire.completed == false) {
        this.requestService
          .answerPrologueQuestionGroup(answeredQuestionGroup)
          .subscribe(() => {
            this.stepStateService.goToNextRiskStep();
          });
      } else {
        this.stepStateService.goToNextRiskStep();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.prologueServiceSubscription) {
      this.prologueServiceSubscription.unsubscribe();
    }
  }
}
