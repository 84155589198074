import { RequestStateType } from '../../interfaces';
import { IFirstFactorAuthenticationResponse } from './response/first-factor-authentication.response';
import { ISecondFactorAuthenticationResponse } from './response/second-factor-authentication.response';

export enum AuthenticationResultType {
  // auth process end -> user has logged in successfully
  succeeded = 'succeeded',

  // first step of 2-factor auth was successful
  succeededFirstStep = 'succeededFirstStep',

  // invalid (first) token
  tokenInvalid = 'tokenInvalid',

  // invalid input, captcha is necessary
  captcha = 'captcha',

  secondFactorInvalid = 'secondFactorInvalid',

  // token does not match tenant
  tenantInvalid = 'tenantInvalid',

  // the temp key is no longer valid - user must start at the beginning of 2fa
  expired = 'expired',

  // unknown / unmapped error
  unknown = 'unknown',
}

export interface IAuthenticationResult {
  success: boolean;
  resultType: AuthenticationResultType;
  requestStatus?: RequestStateType;
  payload?:
    | IFirstFactorAuthenticationResponse
    | ISecondFactorAuthenticationResponse;
  captchaErrorCodes?: string[];
}
