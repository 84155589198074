import { Component, Input, OnChanges } from '@angular/core';
import { MrcTrackingService } from '../../../../../../../core-lib/src/lib/core/services/tracking/tracking.service';
import { Risk } from '../../../../interfaces';
import { StepStateService } from '../../../../services/step-navigation/step-state.service';

@Component({
  selector: 'idv-questionnaire-completed',
  templateUrl: './questionnaire-completed.component.html',
  styleUrls: ['./questionnaire-completed.component.scss'],
})
export class QuestionnaireCompletedComponent implements OnChanges {
  @Input()
  risk: Risk;
  riskLabel: string;

  constructor(
    private stepStateService: StepStateService,
    private trackingService: MrcTrackingService
  ) {}

  goToNextStep() {
    this.trackingService.trackEvent('idv_risk_completed_next_btn_clicked', {
      riskClass: this.risk ? this.risk.riskClass : 'unknown',
    });
    this.stepStateService.goToNextRiskStep();
  }

  ngOnChanges(): void {
    this.riskLabel = this.risk ? this.risk.labels.join(', ') : '';
  }

  get hasQuestions(): boolean {
    return this.risk?.questionnaire?.questionGroups?.length > 0;
  }
}
