import {
  Directive,
  forwardRef,
  ElementRef,
  HostListener,
  HostBinding,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: 'input[type=text][numberFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFormatDirective),
      multi: true,
    },
  ],
})
export class NumberFormatDirective implements ControlValueAccessor {
  @HostBinding('disabled') isDisabled: boolean;

  @Input() groupSize: number;
  @Input() digitsCount: number;
  @Input() separator = '-';

  private previousFormattedText = '';

  @HostListener('blur') onTouched = () => {};

  private onChange = (_: string) => {};

  constructor(private input: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  writeValue(text: string): void {
    // Required to detect changes to the input since IE throws indistinguishable input events on focus and blur as well,
    // and we don't want to change the caret position back into the input field in those cases
    if (this.previousFormattedText !== text) {
      const normalizedText = text || '';
      const filteredInputText = this.onlyDigitsFilter(normalizedText);
      let unformattedText = this.stripSeparator(filteredInputText);
      unformattedText = !!this.digitsCount
        ? unformattedText.substr(0, this.digitsCount)
        : unformattedText;

      const formattedText = !!this.separator
        ? this.insertSeparator(unformattedText)
        : unformattedText;

      const nextCaretPosition = this.getNextCaretPosition();

      this.previousFormattedText = formattedText;
      this.input.nativeElement.value = formattedText;
      this.input.nativeElement.selectionStart = nextCaretPosition;
      this.input.nativeElement.selectionEnd = nextCaretPosition;

      this.onChange(unformattedText);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  get formattingRegExp() {
    return new RegExp(`(.{${this.groupSize}})(?!$)`, 'g');
  }

  private onlyDigitsFilter(input: string) {
    return input.replace(/[^0-9]*/g, '');
  }

  private stripSeparator(formattedText: string) {
    return formattedText.replace(this.separator, '');
  }

  private insertSeparator(unformattedText: string) {
    return unformattedText
      .replace(this.formattingRegExp, '$1' + this.separator)
      .trim();
  }

  private getNextCaretPosition() {
    const inputElement = this.input.nativeElement;
    const inputText = inputElement.value;
    const caretPosition = inputElement.selectionStart;
    const inputTextBeforeCaret = inputText.substring(0, caretPosition);

    const filteredInputTextBeforeCaret =
      this.onlyDigitsFilter(inputTextBeforeCaret);
    const unformattedInputTextBeforeCaret = this.stripSeparator(
      filteredInputTextBeforeCaret
    );
    const formattedInputTextBeforeCaret = this.insertSeparator(
      unformattedInputTextBeforeCaret
    );

    return formattedInputTextBeforeCaret.length;
  }
}
