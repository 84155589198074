<p>
  {{ 'handon-information-step.text' | translate }}
</p>
<div class="row">
  <div class="col">
    <label class="custom-control-container radio-label">
      <input
        type="radio"
        name="handOnInformationAllowed"
        value="yes"
        (change)="onSelectionChange(true)"
        required
      />
      {{ 'shared.yes' | translate }}
      <span class="checkmark-radio"></span>
    </label>

    <label class="custom-control-container radio-label">
      <input
        type="radio"
        name="handOnInformationAllowed"
        value="no"
        (change)="onSelectionChange(false)"
      />
      {{ 'shared.no' | translate }}
      <span class="checkmark-radio"></span>
    </label>
  </div>
</div>
