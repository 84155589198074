import { Injectable } from '@angular/core';
import { WindowService } from './window.service';

@Injectable({ providedIn: 'root' })
export class BrowserSnifferService {
  private userAgent: string;

  constructor(private windowService: WindowService) {
    this.userAgent = this.windowService.window.navigator.userAgent || '';
  }

  isFirefox(): boolean {
    return this.userAgent.toLowerCase().indexOf('firefox') >= 0;
  }

  isMsie(): boolean {
    const w = this.windowService.window as any;
    return !!w.MSInputMethodContext && !!w.document.documentMode;
  }
}
