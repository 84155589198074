<div class="row">
  <idv-questionnaire-heading
    class="col-12"
    [showSummary]="!embedded"
    [risk]="rootRisk"
  ></idv-questionnaire-heading>
</div>

<div
  class="row"
  *ngIf="
    questionnaires.length === 1 && questionnaires[0].questionGroups.length === 0
  "
>
  <div class="info-text col-12">
    {{ 'summary.no-questions-to-answer' | translate }}
  </div>
</div>

<ng-container *ngFor="let risk of riskWithRoutedRisks">
  <div
    class="row risk-row"
    *ngIf="
      questionnaires.length >= 1 && risk.questionnaire.questionGroups.length > 0
    "
  >
    <div class="col-12">
      <h3 class="summary-heading">
        {{
          'summary.risk-heading'
            | translate : { riskLabel: risk.labels.join(', ') }
        }}
      </h3>
      <!-- Flex-->
      <ng-container
        *ngFor="let questionGroup of risk.questionnaire.questionGroups"
      >
        <div class="d-flex">
          <div class="readonly-question">
            <idv-dynamic-question-group-control
              [questionGroup]="questionGroup"
              [readonlyMode]="true"
            ></idv-dynamic-question-group-control>
          </div>

          <!-- Edit button for md and higher -->
          <div class="edit-button-container">
            <idv-edit-button
              [routerLink]="
                getLinkToQuestion$(risk.id, questionGroup.id) | async
              "
              (click)="onClickEditButton(risk)"
            ></idv-edit-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<div
  *ngIf="!embedded"
  class="row mt-5"
>
  <div class="col-12">
    <button
      type="button"
      mat-raised-button
      color="primary"
      class="idv-form-button"
      (click)="goToNextStep()"
    >
      <span>{{ 'question-group.next' | translate }}</span>
      <idv-demo-label inBrackets></idv-demo-label>
    </button>
  </div>
</div>
