import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { UncaughtErrorService } from './uncaught-error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ExpectedErrorService } from './expected-error.service';
import { MrcTrackingService } from '../tracking/tracking.service';

@Injectable({ providedIn: 'root' })
export class UncaughtErrorHandler extends ErrorHandler {
  constructor(
    private uncaughtErrorService: UncaughtErrorService,
    private expectedErrorService: ExpectedErrorService,
    private injector: Injector
  ) {
    super();
  }

  handleError(error: any): void {
    const categorizedError = this.categorizeError(error);
    super.handleError(categorizedError);
  }

  private setUncaughtError(error: Error) {
    this.uncaughtErrorService.setError(error);
    this.trackError(error);
  }

  private setExpectedError(error: Error) {
    this.expectedErrorService.setError(error);
    this.trackError(error);
  }

  private trackError(error: Error) {
    const trackingService = this.injector.get(MrcTrackingService);
    trackingService.trackException(error);
  }

  categorizeError(error: any) {
    if (error instanceof Error) {
      this.setUncaughtError(error);
      return error;
    } else if (error instanceof HttpErrorResponse) {
      // In this case we have a change and display change-medical-risks-message
      if (error.status === 410 || error.status === 409) {
        this.setExpectedError(error.error);
        return error.error;
      }

      // Technical error
      const technicalError = new Error(
        `${error.message}. Body was: ${error.error}`
      );
      this.setUncaughtError(technicalError);
      return technicalError;
    } else if (error.error instanceof Error) {
      // This is typically the case for network-related errors
      this.setUncaughtError(error.erro);
      return error.error;
    }

    // Last attempt: stringify whatever we got and wrap it in an error
    const e = new Error(error.stack);
    this.setUncaughtError(e);
    return e;
  }
}
