import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  IdvEnvironment,
  IDV_ENV,
  QuestionGroup,
  Risk,
  RiskQuestionGroupContext,
} from '../../../../interfaces';
import { AppRoute } from '../../../../interfaces/app-routing.const';
import { RequestService } from '../../../../services/request/request.service';
import { StepStateService } from '../../../../services/step-navigation/step-state.service';
import { isRiskHiddenFromPhysician } from '../../../../utils/risks';
import { combineLatest, Observable, of } from 'rxjs';
import { MrcTrackingService } from '../../../../../../../core-lib/src/lib/core/services/tracking/tracking.service';
import { MatDialog } from '@angular/material/dialog';
import { ReanswerQuestionsDialogComponent } from '../../../../components/reanswer-questions-dialog/reanswer-questions-dialog.component';
import { take, tap } from 'rxjs/operators';
import { ResponsiveService } from '../../../../services/responsive/responsive.service';

@Component({
  selector: 'idv-risk-questionnaire',
  templateUrl: './risk-questionnaire.component.html',
  styleUrls: ['./risk-questionnaire.component.scss'],
})
export class RiskQuestionnaireComponent implements OnInit {
  risk: Risk;
  redirectedRiskId: string;
  riskQuestionGroupContext: RiskQuestionGroupContext;
  formGroup: UntypedFormGroup;
  backButtonRoute$: Observable<string[]>;

  public showGDQHint: boolean;
  public questionnaireMode: 'question' | 'completed' | 'synonym-added';

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private requestService: RequestService,
    private stepStateService: StepStateService,
    private trackingService: MrcTrackingService,
    private responsiveService: ResponsiveService,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      const riskId = params['id'];
      const questionGroupId = params['questionGroupId'];
      this.redirectedRiskId = params['routedId'] ?? null;

      this.questionnaireMode = 'question';

      this.requestService.findRiskById(riskId, (risk: Risk) => {
        this.risk = risk;
        this.showGDQHint =
          this.risk.askOnlyGdq &&
          this.risk.source === 'added-by-underwriter' &&
          this.environment.flavor.name === 'physician';
        if (this.risk && !isRiskHiddenFromPhysician(this.risk)) {
          this.loadQuestion(questionGroupId);
        } else {
          this.navigateToNextStep();
        }
      });
    });
  }

  private navigateToNextStep() {
    this.stepStateService.navigateToNextStep();
  }

  private loadQuestion(questionGroupId: string) {
    // NOTE: this is a workaround for browser-back mis-behavior
    if (!questionGroupId || questionGroupId === AppRoute.nextQuestion) {
      this.fetchNextRiskQuestionGroupContext(null);
    } else if (
      !this.riskQuestionGroupContext ||
      this.riskQuestionGroupContext.questionGroup?.id !== questionGroupId
    ) {
      this.fetchRiskQuestionGroupContextById(questionGroupId);
      this.trackingService.trackEvent('idv_risk_questionnaire_started', {
        riskClass: this.risk.riskClass,
      });
    }
  }

  get riskLabel() {
    return this.risk ? this.risk.labels.join(', ') : '';
  }

  private getBackButtonRoute$() {
    if (!!this.riskQuestionGroupContext?.previousQuestionGroupId) {
      return this.risk.id ===
        this.riskQuestionGroupContext.previousQuestionGroupRiskId
        ? this.stepStateService.getQuestionGroupRoute$(
            this.risk.id,
            this.riskQuestionGroupContext.previousQuestionGroupId
          )
        : this.stepStateService.getRoutedQuestionGroupRoute$(
            this.risk.id,
            this.riskQuestionGroupContext.previousQuestionGroupRiskId,
            this.riskQuestionGroupContext.previousQuestionGroupId
          );
    }
    return of(null);
  }

  fetchNextRiskQuestionGroupContext(answeredQuestionGroup: QuestionGroup) {
    if (this.risk && this.risk.id) {
      !!answeredQuestionGroup &&
        (answeredQuestionGroup.questionRiskId =
          this.redirectedRiskId ?? this.risk.id);
      this.requestService
        .proceedToNextQuestionGroup(this.risk.id, answeredQuestionGroup)
        .subscribe((riskQuestionGroupContext) => {
          this.onRiskQuestionGroupContextChanged(riskQuestionGroupContext);
          const wasRedirected =
            this.risk.id != riskQuestionGroupContext?.questionGroupRiskId;

          if (riskQuestionGroupContext.reopenedRiskIds?.length > 0) {
            this.displayReanswerQuestionsDialog(
              riskQuestionGroupContext.reopenedRiskIds
            );
            this.stepStateService.goToRisk(
              riskQuestionGroupContext.reopenedRiskIds[0]
            );
          } else if (riskQuestionGroupContext.questionGroup) {
            this.stepStateService.goToQuestionGroup(
              this.risk.id,
              this.riskQuestionGroupContext.questionGroup.id,
              wasRedirected
                ? this.riskQuestionGroupContext?.questionGroupRiskId
                : null
            );
            this.stepStateService.uncomplete(this.risk.id);
          } else {
            this.stepStateService.complete(this.risk.id);
            // There is no questionGoup to answer => see if coming from last question or from adding a synonym
            this.questionnaireMode =
              !!answeredQuestionGroup ||
              this.risk.questionnaire?.questionGroups?.length === 0
                ? 'completed'
                : 'synonym-added';
          }
        });
    }
  }

  private fetchRiskQuestionGroupContextById(questionGroupId: string) {
    this.requestService
      .getQuestionGroupById(
        this.risk.id,
        questionGroupId,
        this.redirectedRiskId
      )
      .subscribe(
        (riskQuestionGroupContext) => {
          this.onRiskQuestionGroupContextChanged(riskQuestionGroupContext);
        },
        () => {
          this.stepStateService.navigateToNextStep();
        }
      );
  }

  private onRiskQuestionGroupContextChanged(
    riskQuestionGroupContext: RiskQuestionGroupContext
  ): void {
    this.riskQuestionGroupContext = riskQuestionGroupContext;
    if (!!this.riskQuestionGroupContext?.questionGroup) {
      this.questionnaireMode = 'question';
    }

    this.backButtonRoute$ = this.getBackButtonRoute$();
  }

  private displayReanswerQuestionsDialog(reopenedRiskIds: Array<string>) {
    combineLatest(
      reopenedRiskIds.map((riskId) =>
        this.requestService.findOneRisk$((r) => r.id === riskId)
      )
    )
      .pipe(
        take(1),
        tap((res) => {
          const labels = res.reduce((flat, toFlatten) => {
            flat.push(toFlatten.labels.join(', '));
            return flat;
          }, new Array<string>());
          this.dialog.open(
            ReanswerQuestionsDialogComponent,
            this.responsiveService.enhanceDialogConfig({
              panelClass: 'big-modal',
              data: { labels },
            })
          );
        })
      )
      .subscribe();
  }
}
