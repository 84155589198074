import { Component, Input } from '@angular/core';

@Component({
  selector: 'mrc-activity-indicator',
  templateUrl: './activity-indicator.component.html',
  styleUrls: ['./activity-indicator.component.scss'],
})
export class ActivityIndicatorComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() variant: 'default' | 'primary' = 'default';

  constructor() {}
}
