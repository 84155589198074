import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SessionStorageService } from '../../../../../../../core-lib';
import { SessionKey } from '../../../../../../../idv-lib/src/lib/constants/session-key.constants';
import { EpilogueService } from '../../../../../../../idv-lib/src/lib/services/request/epilogue/epilogue.service';
import { RequestService } from '../../../../../../../idv-lib/src/lib/services/request/request.service';
import { ResponsiveService } from '../../../../../../../idv-lib/src/lib/services/responsive/responsive.service';
import { StepName, StepStateService } from '../../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'phy-confirm-completeness',
  templateUrl: './confirm-completeness.component.html',
})
export class ConfirmCompletenessComponent implements OnInit, OnDestroy {
  private notificationDialogRef: MatDialogRef<any>;

  @ViewChild('notificationDialog', { static: true })
  notificationDialog: TemplateRef<any>;
  @ViewChild('snackbarContainerRef', { read: ViewContainerRef })
  snackbarContainerRef: ViewContainerRef;

  @ViewChild('snackbarOrigin') snackbarOrigin: TemplateRef<any>;
  @ViewChild('snackbarTemplate') snackbarTemplate: TemplateRef<any>;

  private requestServiceSubscription: Subscription;
  private pdfServiceSubscription: Subscription;

  private snackBarSubscription: Subscription;
  private errorSnackbar: MatSnackBarRef<any>;

  catchAllQuestionValue = false;

  hasExistingDiseases: boolean;
  selectedProducts: Array<string>;
  isGeneralPhysicianRequest: boolean;

  hasErrorsCatchAll = true;
  showErrors = false;

  completenessConfirmed: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private epilogueService: EpilogueService,
    private requestService: RequestService,
    private stepStateService: StepStateService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.snackBarSubscription = this.requestService.request
      .pipe(filter((request) => !!request))
      .subscribe((request) => {
        this.hasExistingDiseases =
          request.medicalRisks &&
          request.medicalRisks.risks &&
          request.medicalRisks.risks.length > 0;
        this.selectedProducts = request.products.map(
          (product) => product.productId
        );

        this.isGeneralPhysicianRequest =
          this.requestService.isGeneralPhysicianRequest(request);
        if (!this.allStepsCompleted) {
          this.showErrorSnackbar();
        }
      });
  }

  get allStepsCompleted() {
    return this.stepStateService.areAllRequiredStepsCompleted();
  }

  showErrorSnackbar() {
    this.translateService
      .get([
        'confirm-completeness.missing-steps',
        'confirm-completeness.snackbar-action',
      ])
      .subscribe((values: any) => {
        this.errorSnackbar = this.snackBar.open(
          values['confirm-completeness.missing-steps'],
          values['confirm-completeness.snackbar-action'],
          {
            panelClass: [
              'sub-header-snack-bar',
              'error',
              'confirm-completeness-snack-bar',
            ],
            verticalPosition: 'top',
          }
        );

        this.errorSnackbar.onAction().subscribe(() => {
          this.stepStateService.goToNextRiskStep();
        });
      });
  }

  setCatchAllQuestionValueChange(value: boolean) {
    this.catchAllQuestionValue = value;
    this.hasErrorsCatchAll = !this.catchAllQuestionValue;
  }

  confirm() {
    this.closeNotificationModal();
    this.showErrors = true;
    if (this.catchAllQuestionValue) {
      this.confirmCompleteness();
    }
  }

  showNotificationModal() {
    this.notificationDialogRef = this.dialog.open(
      this.notificationDialog,
      this.responsiveService.enhanceDialogConfig({
        panelClass: ['padded-modal', 'big-modal'],
        disableClose: false,
      })
    );
  }

  closeNotificationModal() {
    this.notificationDialogRef.close();
  }

  private confirmCompleteness() {
    if (this.allStepsCompleted) {
      this.epilogueService
        .answerEpilogueQuestionnaire({
          epilogueConfirmed: true,
        })
        .subscribe(() => {
          this.downloadPdfAndSubmit();
        });
    }
  }

  private downloadPdfAndSubmit() {
    if (
      !this.sessionStorageService.getItem(SessionKey.requestSummaryPdfBase64)
    ) {
      this.pdfServiceSubscription = this.requestService
        .getRequestSummary()
        .subscribe((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            this.sessionStorageService.setItem(
              SessionKey.requestSummaryPdfBase64,
              reader.result.toString()
            );
          };
          reader.readAsDataURL(blob);
          this.requestService.submitRequest().subscribe(() => {
            this.stepStateService.complete(StepName.complete);
            this.stepStateService.lockAllStepsExcept(StepName.download); // does not unlock `download`
            this.stepStateService.unlock(StepName.download);
            this.stepStateService.navigateToNextStep();
          });
        });
    }
  }

  ngOnDestroy(): void {
    if (this.requestServiceSubscription) {
      this.requestServiceSubscription.unsubscribe();
    }
    if (this.snackBarSubscription) {
      this.snackBarSubscription.unsubscribe();
    }
    if (this.errorSnackbar) {
      this.errorSnackbar.dismiss();
    }
    if (this.pdfServiceSubscription) {
      this.pdfServiceSubscription.unsubscribe();
    }
  }
}
