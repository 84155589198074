<div class="logout-page">
  <div
    class="header"
    *ngIf="tenant$ | async"
  >
    <div class="container">
      <idv-tenant-panel></idv-tenant-panel>
    </div>
  </div>
  <main class="jumbotron dark-background">
    <div class="container">
      <div class="good-bye-view container-fluid reduced-width">
        <h1 class="text-center text-primary">
          <span class="material-icons done-icon-container">done</span>
        </h1>
        <h2 class="text-center">{{ 'good-bye-view.headline' | translate }}</h2>
        <p class="text-center message">
          {{ 'good-bye-view.message' | translate }}
        </p>
      </div>
    </div>
  </main>
</div>
<idv-material-footer [displayFAQs]="'true'"></idv-material-footer>
