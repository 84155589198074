import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Risk } from '../../../interfaces';
import { AppRoute } from '../../../interfaces/app-routing.const';
import { IDV_ENV, IdvEnvironment } from '../../../interfaces/idv-environment';
import { CompositeStepState } from '../../../services/step-navigation/composite-step-state';
import { StepState } from '../../../services/step-navigation/step-state';
import { StepStateService } from '../../../services/step-navigation/step-state.service';
import { MrcTrackingService } from '../../../../../../core-lib/src/public_api';

@Component({
  selector: 'idv-step-navigation-list',
  templateUrl: './step-navigation-list.component.html',
  styleUrls: ['./step-navigation-list.component.scss'],
})
export class StepNavigationListComponent implements OnInit, OnChanges {
  @Input() stepStates: StepState[];
  @Input() pseudoSteps: boolean;
  @Output() clickNavigationItem = new EventEmitter();

  clazz = '';

  flatSteps: StepState[];

  constructor(
    private stepStateService: StepStateService,
    private trackingService: MrcTrackingService,
    private router: Router,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {}

  ngOnInit(): void {
    this.clazz =
      this.environment.flavor.name === 'insured'
        ? 'multi-level'
        : 'single-level';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stepStates']) {
      this.prepareFlatSteps();
    }
  }

  private prepareFlatSteps(): void {
    this.flatSteps = [] as StepState[];
    this.stepStates.forEach((stepState) => {
      this.flatSteps.push(stepState);
      if (stepState.isComposite) {
        const composite = stepState as CompositeStepState;
        if (composite.subStepStates && composite.subStepStates.length) {
          this.flatSteps.push(...composite.subStepStates);
        }
      }
    });
  }

  onClickNavigationItem() {
    this.trackingService.trackEvent('idv_click_left_navigation');
    this.clickNavigationItem.emit();
  }

  getSteps() {
    return this.flatSteps;
  }

  getNextIncompleteStep(steps: StepState[]) {
    return steps.find((step: StepState) => !step.isCompleted);
  }

  isActiveWithinMe(step: StepState) {
    return step.flatStepStates.some((myStep) => this.isActive(myStep));
  }

  hasSubSteps(step: CompositeStepState) {
    return step.flatStepStates.length > 0;
  }

  isFirstSubStepCompleted(step: CompositeStepState) {
    return this.hasSubSteps(step) && step.flatStepStates[0].isCompleted;
  }

  isLastCompletedStep(step: StepState) {
    const allSteps = this.stepStateService.getFlatStepStates();
    const i = allSteps.findIndex((myStep) => step.name === myStep.name);
    if (i < allSteps.length - 1) {
      const nextStep = allSteps[i + 1];
      if (nextStep) {
        if (!nextStep.isCompleted && !this.router.url.includes(nextStep.name)) {
          return true;
        }
      }
    }
    return false;
  }

  getStepType(step: StepState): 'pseudo' | 'composite' | 'normal' | 'risk' {
    if (this.pseudoSteps) {
      return 'pseudo';
    }
    if (step.isComposite) {
      return 'composite';
    }
    return step.isRisk ? 'risk' : 'normal';
  }

  getRouterLinkForStep(step: StepState) {
    if (step.isRisk) {
      return this.getRouterLinkForRisk(step.risk);
    }
    return step.name;
  }

  private getRouterLinkForRisk(risk: Risk) {
    if (this.stepStateService.isCompleted(risk.id)) {
      return [AppRoute.questionnaireSummary, risk.id];
    }
    return [AppRoute.risk, risk.id];
  }

  getRouterLinkForComposite(step: StepState) {
    if (step.flatStepStates.length === 0) {
      return null;
    }
    if (step.isCompleted) {
      return this.getRouterLinkForStep(step.flatStepStates[0]);
    }
    return this.getRouterLinkForStep(
      this.getNextIncompleteStep(step.flatStepStates)
    );
  }

  isLastItemInComposite(step: StepState) {
    return this.stepStates[this.stepStates.length - 1] === step;
  }

  isActive(step: StepState): boolean {
    return (
      this.router.url.includes(step.name) ||
      this.isDescendantActive(step.risk?.routedRisks)
    );
  }

  isCompleted(stepName: string) {
    return this.stepStateService.isCompleted(stepName);
  }

  isLocked(stepName: string) {
    return this.stepStateService.isLocked(stepName);
  }

  isDescendantActive(descendants: Risk[]): boolean {
    return (
      descendants &&
      descendants.some(
        (descendant) =>
          this.router.url.includes(descendant.id) ||
          this.isDescendantActive(descendant.routedRisks)
      )
    );
  }
}
