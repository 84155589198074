import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UncaughtErrorService {
  private _uncaughtError = new BehaviorSubject<Error>(null);

  readonly uncaughtError = this._uncaughtError.asObservable();

  setError(error: Error): void {
    this._uncaughtError.next(error);
  }
}
