import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEnGb from '@angular/common/locales/en-GB';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnGbExtra from '@angular/common/locales/extra/en-GB';
import { TranslateService } from '@ngx-translate/core';

interface LocaleInfo {
  identifier: string;
  data: any;
  extraData?: any;
}

const languageLocaleInfoMap: { [key: string]: LocaleInfo } = {
  de: { identifier: 'de-DE', data: localeDe, extraData: localeDeExtra },
  en: { identifier: 'en-GB', data: localeEnGb, extraData: localeEnGbExtra },
};

function mapLanguageToLocaleInfo(language: string): LocaleInfo {
  return languageLocaleInfoMap[language] || languageLocaleInfoMap.de;
}

export function initializeLocale(translateService: TranslateService) {
  const { data, extraData, identifier } = mapLanguageToLocaleInfo(
    translateService.currentLang
  );
  registerLocaleData(data, extraData);
  return identifier;
}
