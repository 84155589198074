import { ValidatorFn, UntypedFormControl } from '@angular/forms';
import { PrecisionDate, MandatoryDateAccuracyUnit } from '../../interfaces';
import { Injectable } from '@angular/core';
import { PrecisionDateService } from '../../../../../core-lib/src/public_api';

@Injectable({ providedIn: 'root' })
export class DateBetweenValidatorFactory {
  constructor(private _precisionDateService: PrecisionDateService) {}

  public createDateBetweenValidator(
    minDate: PrecisionDate,
    maxDate: PrecisionDate,
    accuracyUnit: MandatoryDateAccuracyUnit
  ): ValidatorFn {
    return (control: UntypedFormControl) => {
      const givenDate = control.value as PrecisionDate;

      return givenDate != null &&
        (givenDate.compareTo(maxDate) > 0 || givenDate.compareTo(minDate) < 0)
        ? {
            rangeError: {
              given: this._precisionDateService.formatPrecisionDate(givenDate),
              max: this._precisionDateService.formatPrecisionDate(
                maxDate,
                accuracyUnit
              ),
              min: this._precisionDateService.formatPrecisionDate(
                minDate,
                accuracyUnit
              ),
            },
          }
        : null;
    };
  }
}
