import { QuestionInputValue } from './question-input-value.type';

export interface SelectableValue {
  id: string;
  displayName: string;
}

export function getSelectedId(
  selectableValues: SelectableValue[],
  checked: boolean
): string | null {
  if (selectableValues.length === 1) {
    return checked ? selectableValues[0].id : null; // legacy checkbox questions from Normrisk
  }
  if (selectableValues.length === 2) {
    return selectableValues[checked ? 0 : 1].id;
  }
  throw Error('selectableValues must have 1 or 2 elements');
}

export function isSelectedYes(
  selectableValues: SelectableValue[],
  id: QuestionInputValue
): boolean {
  return (
    Array.isArray(selectableValues) &&
    selectableValues.length > 0 &&
    selectableValues[0].id === id
  );
}
