import { Observable, Subject } from 'rxjs';
import { DemoModeService } from '../../../../../../../idv-lib/src/lib/services/config/demo-mode.service';
import {
  StepStateService,
  StepName,
} from '../../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { RequestService } from '../../../../../../../idv-lib/src/lib/services/request/request.service';
import { takeUntil, filter } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BasePaymentData } from '../../../../../../../idv-lib/src/lib/interfaces/base.payment-data';

export abstract class BasePaymentMethodComponent<T extends BasePaymentData> {
  public formGroup: UntypedFormGroup;
  protected shouldAllErrorsBeShown = false;

  private _requestHasPaymentData = false;
  private _destroyed$ = new Subject<void>();

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private _demoModeService: DemoModeService,
    private _stepStateService: StepStateService,
    private _requestService: RequestService
  ) {}

  onInit() {
    this._requestService.request
      .pipe(
        takeUntil(this._destroyed$),
        filter((request) => !!request)
      )
      .subscribe((request: any) => {
        if (request.paymentData) {
          this._requestHasPaymentData = true;
        }

        this.initPaymentData(request.paymentData);
      });
  }

  onDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  get canSkip(): boolean {
    return (
      !this._requestHasPaymentData &&
      this._stepStateService.canSkipCurrentStep(StepName.payment)
    );
  }

  get destroyed$(): Observable<void> {
    return this._destroyed$.asObservable();
  }

  public isDemoMode(): Observable<boolean> {
    return this._demoModeService.isDemoTenant;
  }

  public validateAndSavePaymentData() {
    if (this.formGroup.valid) {
      this.savePaymentData();
    } else {
      this.shouldAllErrorsBeShown = true;
    }
  }

  public onSkip(): void {
    this._stepStateService.skipCurrentStep(StepName.payment);
  }

  public hasErrorToDisplay(formControlName: string): boolean {
    return (
      this.shouldAllErrorsBeShown &&
      this.formGroup.controls[formControlName].invalid
    );
  }

  public getErrorToDisplayFor(formControlName: string) {
    const formControl = this.formGroup.get(formControlName);
    return formControl.errors;
  }

  private savePaymentData() {
    const paymentData = this.formValueToPaymentData();

    this._requestService.setPaymentData(paymentData).subscribe(() => {
      this.goToNextStep();
    });
  }

  private goToNextStep() {
    this.unlockStepsIfPossible();
    this._stepStateService.navigateToNextStep();
  }

  private unlockStepsIfPossible() {
    if (this._stepStateService.areAllRequiredStepsCompleted()) {
      this._stepStateService.unlock(StepName.complete);
    }
  }

  protected abstract initPaymentData(paymentData: any): void;

  protected abstract formValueToPaymentData(): T;
}
