import { formatPrecisionDate } from './precision-date-formatter';

export class PrecisionDate {
  day: number = null;
  month: number = null;
  year: number = null;
  monthUnknown?: boolean;

  constructor(
    firstArgument?: string | Date | number | PrecisionDate,
    month?: number,
    day?: number,
    monthUnknown?: boolean
  ) {
    if (firstArgument instanceof Date) {
      const date = firstArgument as Date;
      this.initFromDate(date);
    } else if (firstArgument instanceof PrecisionDate) {
      this.initFromNumbers(
        firstArgument.year,
        firstArgument.month,
        firstArgument.day,
        firstArgument.monthUnknown
      );
    } else if (typeof firstArgument === 'string') {
      const partialDateText = firstArgument as string;
      this.initFromString(partialDateText, monthUnknown);
    } else if (typeof firstArgument === 'number') {
      const year = firstArgument as number;
      this.initFromNumbers(year, month, day, monthUnknown);
    } else if (!!firstArgument) {
      throw new Error(
        `Invalid first argument of type ${typeof firstArgument} supplied to PrecisionDate constructor!
        Supported types are Date and string or three arguments of type number`
      );
    }
  }

  private initFromDate(date: Date) {
    this.year = date.getUTCFullYear();
    this.month = date.getUTCMonth() + 1;
    this.day = date.getUTCDate();
  }

  private initFromString(dateString: string, monthOptional?: boolean) {
    const regExMatch =
      dateString.match(/^(\d{0,4})-(\d{0,2})-(\d{0,2})$/) ||
      dateString.match(/^(\d{0,4})-(\d{0,2})/) ||
      dateString.match(/^(\d{0,4})/);

    if (!regExMatch) {
      throw new Error(
        `Invalid date string format supplied to PrecisionDate: '${dateString}' Supported formats: YYYY, YYYY-MM, YYYY-MM-DD`
      );
    }

    this.year = regExMatch[1] ? +regExMatch[1] : null;
    this.month = regExMatch[2] ? +regExMatch[2] : null;
    this.day = regExMatch[3] ? +regExMatch[3] : null;
    this.monthUnknown = monthOptional;
  }

  private initFromNumbers(
    year: number,
    month?: number,
    day?: number,
    monthOptional?: boolean
  ) {
    this.year = year;
    this.month = month;
    this.day = day;
    this.monthUnknown = monthOptional;
  }

  get isExistingDate() {
    const date = this.getDate();

    return (
      !!date &&
      date.getFullYear() === this.year &&
      (this.month == null || date.getMonth() === this.month - 1) &&
      (this.day == null || date.getDate() === this.day)
    );
  }

  getDate(): Date {
    return new Date(
      this.year,
      this.month ? this.month - 1 : 0,
      this.day ? this.day : 1
    );
  }

  compareTo(other: PrecisionDate): number {
    if (this.getDate() > other.getDate()) {
      return 1;
    } else if (this.getDate().getTime() === other.getDate().getTime()) {
      return 0;
    }
    return -1;
  }

  toString(): string {
    return formatPrecisionDate(this);
  }

  toJSON(): string {
    return this.toString();
  }
}
