import { map, share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TenantConfigService } from '../config/tenant-config.service';
import { EpilogueService } from '../request/epilogue/epilogue.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TenantSpecificTextService {
  public static paragraph2: string = 'paragraph2';
  public static paragraph3: string = 'paragraph3';

  private _tenantSpecificTexts$ = this.tenantConfigService.tenantConfig$.pipe(
    map((config) => config.texts)
  );

  private getBulletPointPerProduct$ = this.epilogueService
    .getBulletPointsPerProduct()
    .pipe(share());

  private _bulletPointsParagraphTwo$ = this.getBulletPointPerProduct$.pipe(
    map((x) => x[TenantSpecificTextService.paragraph2])
  );

  private _bulletPointsParagraphThree$ = this.getBulletPointPerProduct$.pipe(
    map((x) => x[TenantSpecificTextService.paragraph3])
  );

  constructor(
    private tenantConfigService: TenantConfigService,
    private epilogueService: EpilogueService
  ) {}

  getText(textKey: string) {
    return this._tenantSpecificTexts$.pipe(
      map((tenantSpecificTexts) => tenantSpecificTexts[textKey])
    );
  }

  getAllTexts() {
    return this._tenantSpecificTexts$;
  }

  getBulletPointsParagraphTwo(): Observable<Array<string>> {
    return this._bulletPointsParagraphTwo$;
  }

  getBulletPointsParagraphThree(): Observable<Array<string>> {
    return this._bulletPointsParagraphThree$;
  }
}
