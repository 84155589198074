import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SessionStorageService } from '../../../../../core-lib';
import { AuthenticationService } from '../../../../../idv-lib/src/lib/authentication/authentication.service';
import { SessionKey } from '../../../../../idv-lib/src/lib/constants/session-key.constants';
import { AppRoute } from '../../../../../idv-lib/src/lib/interfaces/app-routing.const';
import { RequestService } from '../../../../../idv-lib/src/lib/services/request/request.service';
import { CompositeStepState } from '../../../../../idv-lib/src/lib/services/step-navigation/composite-step-state';
import { StepState } from '../../../../../idv-lib/src/lib/services/step-navigation/step-state';
import { StepName, StepStateService } from '../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { TenantService } from '../../../../../idv-lib/src/lib/services/tenant/tenant.service';
import { isRiskHiddenFromPhysician, isRiskTreeFinished } from '../../../../../idv-lib/src/lib/utils/risks';
import { Request, Risk } from '../../../../../idv-lib/src/public_api';

@Injectable({ providedIn: 'root' })
export class PhyStepStateService extends StepStateService implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    protected authenticationService: AuthenticationService,
    requestService: RequestService,
    private _sessionStorageService: SessionStorageService,
    router: Router,
    tenantService: TenantService
  ) {
    super(requestService, router, tenantService);

    this.authenticationService.isLoggedIn
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this._sessionStorageService.removeItemByKey(
            SessionKey.addRiskStepCompleted
          );
        }
      });
  }

  protected getStepAfterRiskSteps(): string {
    return AppRoute.add;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    super.ngOnDestroy();
  }

  updateSteps(request: Request): CompositeStepState {
    const stepStates: StepState[] = [];
    this.stepStateRoot = new CompositeStepState('root', stepStates);

    const risks =
      request && request.medicalRisks ? request.medicalRisks.risks || [] : [];

    stepStates.push({
      name: StepName.payment,
      isCompleted: !!request?.paymentData,
    });

    stepStates.push({
      name: StepName.prologue,
      isCompleted: !!(
        request &&
        request.prologueQuestionnaire &&
        request.prologueQuestionnaire.completed
      ),
    });

    risks.forEach((risk) => {
      stepStates.push(
        ...this.getStepStatesForRiskRecursively(
          risk,
          !this.isPrologueStepCompleted(stepStates)
        )
      );
    });

    stepStates.push({
      name: StepName.add,
      isCompleted:
        this._sessionStorageService.getItem(SessionKey.addRiskStepCompleted) ===
        'true',
      isLocked: !this.isPrologueStepCompleted(stepStates),
    });

    stepStates.push({
      name: StepName.complete,
      isCompleted: false,
      isLocked: true,
    });

    stepStates.push({
      name: StepName.download,
      isCompleted: false,
      isLocked: true,
    });

    return this.stepStateRoot;
  }

  private getStepStatesForRiskRecursively(
    risk: Risk,
    locked: boolean,
    level = 0
  ): StepState[] {
    const riskStepStates = new Array<StepState>();

    if (!isRiskHiddenFromPhysician(risk)) {
      riskStepStates.push({
        name: risk.id,
        isCompleted: isRiskTreeFinished(risk),
        isRisk: true,
        risk: risk,
        isLocked: locked,
        class: `nav-item-level-${level}`,
      });

      risk.routedRisks.forEach((routedRisk) => {
        riskStepStates.push(
          ...this.getStepStatesForRiskRecursively(routedRisk, locked, level + 1)
        );
      });
    }

    return riskStepStates;
  }

  private isPrologueStepCompleted(steps: StepState[]) {
    const prologueStep: StepState = steps.find(
      (s) => s.name === StepName.prologue
    );
    return prologueStep && prologueStep.isCompleted;
  }

  areAllRequiredStepsCompleted() {
    return (
      this.stepExists(StepName.payment) &&
      this.getStep(StepName.payment).isCompleted &&
      this.stepExists(StepName.prologue) &&
      this.getStep(StepName.prologue).isCompleted &&
      this.areAllQuestionnairesCompleted() &&
      this.stepExists(StepName.add) &&
      this.getStep(StepName.add).isCompleted
    );
  }

  complete(name: string) {
    const step = this.getStep(name);
    step.isCompleted = true;

    if (name === StepName.add) {
      this._sessionStorageService.setItem(
        SessionKey.addRiskStepCompleted,
        'true'
      );
    }

    if (
      this.areAllRequiredStepsCompleted() &&
      !this.isCompleted(StepName.complete)
    ) {
      this.unlock(StepName.complete);
    }
  }

  uncomplete(name: string) {
    const step = this.getStep(name);
    step.isCompleted = false;

    if (name === StepName.add) {
      this._sessionStorageService.setItem(
        SessionKey.addRiskStepCompleted,
        'false'
      );
    }
  }
}
