import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectableValue } from '../../interfaces/selectable-value';

@Component({
  selector: 'idv-dropdown-list-control',
  templateUrl: './dropdown-list-control.component.html',
})
export class DropdownListControlComponent {
  @Input() selectableValues: SelectableValue[];
  @Input() formControlToUse: UntypedFormControl;

  constructor() {}
}
