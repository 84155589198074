import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '../../../../../../../core-lib';
import { SessionKey } from '../../../../constants/session-key.constants';
import { Applicant, Request } from '../../../../interfaces';
import { FileSaveHelperService } from '../../../../services/misc/file-save-helper.service';
import { RequestService } from '../../../../services/request/request.service';
import {
  StepName,
  StepStateService,
} from '../../../../services/step-navigation/step-state.service';
import { TenantService } from '../../../../services/tenant/tenant.service';

@Component({
  selector: 'idv-pdf-download-screen',
  templateUrl: './pdf-download-screen.component.html',
  styleUrls: ['./pdf-download-screen.component.scss'],
})
export class PdfDownloadScreenComponent implements OnInit, OnDestroy {
  request: Request;
  downloaded = false;

  private requestServiceSubscription: Subscription;

  constructor(
    private requestService: RequestService,
    private translateService: TranslateService,
    private sessionStorageService: SessionStorageService,
    private stepStateService: StepStateService,
    private fileSaveHelperService: FileSaveHelperService,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.requestServiceSubscription = this.requestService.request.subscribe(
      (request) => (this.request = request)
    );
  }

  saveSummaryToFileSystem() {
    this.downloaded = true;
    const pdfAsBase64 = this.sessionStorageService.getItem(
      SessionKey.requestSummaryPdfBase64
    );
    const blob = this.fileSaveHelperService.dataURItoBlob(pdfAsBase64);
    saveAs(blob, this.getFileName());
  }

  redirectToLogoutPage() {
    this.stepStateService.complete(StepName.download);
    this.stepStateService.navigateToLogout(this.request?.createdWithLanguage);
  }

  private getFileName(): string {
    const label = this.translateService.instant(
      'print-questioning-details.filename-part'
    );
    const applicant = this.request.applicant || ({} as Applicant);
    const { initials } = applicant;
    const tenant = this.tenantService.current;
    const baseName = [tenant, label, initials]
      .filter((s) => !!s)
      .map((s) => s.trim().replace(/ +/g, '-').replace(/\./g, ''))
      .join('-');
    return `${baseName}.pdf`;
  }

  ngOnDestroy(): void {
    if (this.requestServiceSubscription) {
      this.requestServiceSubscription.unsubscribe();
    }
  }
}
