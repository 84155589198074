<mat-form-field
  appearance="fill"
  class="form-field-m"
>
  <mat-label>{{ 'date-input.label' | translate }}</mat-label>
  <input
    id="txt_date_input"
    type="text"
    inputmode="numeric"
    [placeholder]="'date-input.placeholders.' + placeholderSuffix | translate"
    [mask]="textMask"
    [dropSpecialCharacters]="false"
    [leadZeroDateTime]="true"
    [disabled]="isDisabled"
    (blur)="onTouched()"
    [ngModel]="dateText"
    (ngModelChange)="handleDateTextChange($event)"
    matInput
  />
</mat-form-field>
