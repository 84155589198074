import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConfigAssetService } from '../../services/config/config-asset.service';

@Component({
  selector: 'idv-munichre-data-processing-statement-dialog',
  templateUrl: './munichre-data-processing-statement-dialog.component.html',
})
export class MunichReDataProcessingStatementDialogComponent implements OnInit {
  public htmlFile$: Observable<string>;

  constructor(
    private _configAssetService: ConfigAssetService,
    private _httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.htmlFile$ =
      this._configAssetService.munichReDataProcessingStatementUrl.pipe(
        switchMap((url) => this._httpClient.get(url, { responseType: 'text' }))
      );
  }
}
