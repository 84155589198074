import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

interface Jwt {
  [claimType: string]: any;
}

@Injectable({ providedIn: 'root' })
export class JwtDecodingService {
  decodeJwt(jwt: string) {
    return jwt_decode<Jwt>(jwt);
  }
}
