import { HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ActivityTrackingService } from '../services/misc/activity-tracking.service';

export const silentHeader = { Pragma: 'silent' };

@Injectable()
export class ActivityTrackingInterceptor implements HttpInterceptor {
  constructor(private activityTrackingService: ActivityTrackingService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get('Pragma') === 'silent') {
      const requestWithoutSilentHeader = this.deleteSilentHeader(request);
      return next.handle(requestWithoutSilentHeader);
    }

    return this.activityTrackingService.track(next.handle(request));
  }

  private deleteSilentHeader(request: HttpRequest<any>) {
    const headersWithoutSilentHeader = request.headers.delete('Pragma');
    return request.clone({ headers: headersWithoutSilentHeader });
  }
}
