import { Directive, EventEmitter } from '@angular/core';

/**
 * Examples:
 * MockDirective({ selector: '[some-directive]' });
 * MockDirective({ selector: '[some-directive]', inputs: ['some-input', 'some-other-input'] });
 *
 * See https://angular.io/docs/ts/latest/guide/attribute-directives.html for a list
 * of supported properties.
 */

export function MockDirective(options: Directive): any {
  const metadata: Directive = {
    selector: options.selector,
    inputs: options.inputs,
    outputs: options.outputs || [],
    host: options.host,
    exportAs: options.exportAs,
  };

  class Mock {
    [key: string]: EventEmitter<any>;
  }

  metadata.outputs.forEach((method) => {
    Mock.prototype[method] = new EventEmitter<any>();
  });

  return Directive(metadata)(Mock as any);
}
