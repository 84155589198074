<div
  *ngIf="usePlainInput; else niceInput"
  class="plain-input mdl-textfield"
>
  <input
    type="text"
    class="mdl-textfield__input"
    [mask]="maskPattern"
    [thousandSeparator]="thousandSeparator"
    [formControl]="formControlToUse"
  />
</div>
<ng-template #niceInput>
  <mat-form-field
    appearance="fill"
    class="form-field-m"
  >
    <input
      type="text"
      matInput
      [mask]="maskPattern"
      [thousandSeparator]="thousandSeparator"
      [formControl]="formControlToUse"
    />
  </mat-form-field>
</ng-template>
