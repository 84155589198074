import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DocumentTitleService {
  constructor(
    private _translateService: TranslateService,
    private _titleService: Title
  ) {}

  public init() {
    this._translateService.onLangChange
      .asObservable()
      .pipe(
        tap(() => {
          this._titleService.setTitle(
            this._translateService.instant('application-name')
          );
        })
      )
      .subscribe();
  }
}
