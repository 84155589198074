import { Component } from '@angular/core';
import { BasePaymentMethodComponent } from '../base.payment-method.component';
import { NO_HTML_TAGS_ALLOWED_PATTERN } from '../../../../../utilities/allowed-characters.const';
import { DemoModeService } from '../../../../../../../../idv-lib/src/lib/services/config/demo-mode.service';
import { StepStateService } from '../../../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { RequestService } from '../../../../../../../../idv-lib/src/lib/services/request/request.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  ibanValidator,
  sepaReferenceValidator,
} from '../../../../../../../../core-lib/src/public_api';
import { takeUntil } from 'rxjs/operators';
import { IbanPaymentData } from '../../../../../../../../idv-lib/src/lib/interfaces/iban-payment-data';
import { createNoWhitespaceValidator } from '../../../../../../../../core-lib/src/lib/core/validation/no-whitespace.validator';

const DEFAULT_IBAN_FOR_DEMO_MODE = 'DE12 5001 0517 0648 4898 90';

@Component({
  selector: 'idv-iban-payment-method',
  templateUrl: './iban-payment-method.component.html',
  styleUrls: ['./iban-payment-method.component.scss'],
})
export class IbanPaymentMethodComponent extends BasePaymentMethodComponent<IbanPaymentData> {
  allowedCharacterPattern = NO_HTML_TAGS_ALLOWED_PATTERN;

  constructor(
    demoModeService: DemoModeService,
    stepStateService: StepStateService,
    requestService: RequestService,
    formBuilder: UntypedFormBuilder
  ) {
    super(formBuilder, demoModeService, stepStateService, requestService);
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      paymentInformationFormat: [''],
      name: [
        '',
        Validators.compose([
          Validators.required,
          createNoWhitespaceValidator(),
          Validators.maxLength(70),
        ]),
      ],
      accountHolderName: [
        '',
        Validators.compose([
          createNoWhitespaceValidator(),
          Validators.maxLength(70),
        ]),
      ],
      iban: ['', Validators.compose([Validators.required, ibanValidator])],
      reference: [
        '',
        Validators.compose([sepaReferenceValidator, Validators.maxLength(140)]),
      ],
    });

    super.onInit();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  public stripInvalidIbanCharacters(formattedText: string) {
    return formattedText.replace(/[^\dA-Za-z]/g, '').toUpperCase();
  }

  protected initPaymentData(requestPaymentData?: any) {
    const paymentData = requestPaymentData || {
      paymentInformationFormat: '',
      name: '',
      accountHolderName: '',
      iban: '',
      reference: '',
    };

    if (!paymentData.accountHolderName) {
      paymentData.accountHolderName = '';
    }

    this.formGroup.setValue(paymentData);

    if (paymentData.iban === '') {
      this.isDemoMode()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((isDemo) => {
          if (isDemo) {
            this.formGroup.controls.iban.setValue(DEFAULT_IBAN_FOR_DEMO_MODE);
          } else {
            this.formGroup.controls.iban.reset();
          }
        });
    }
  }

  protected formValueToPaymentData(): IbanPaymentData {
    const formGroupValue = this.formGroup.value;

    return {
      paymentInformationFormat: 'iban',
      name: formGroupValue?.name,
      accountHolderName: formGroupValue?.accountHolderName,
      reference: formGroupValue?.reference,
      iban: formGroupValue?.iban?.replace(/\s/g, ''),
    };
  }
}
