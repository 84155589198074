import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  MrcAuthenticationModule,
  MrcComponentsModule,
} from '../../../../core-lib';
import { IdvComponentsModule } from '../components/idv-components.module';
import { IdvMaterialModule } from '../idv-material.module';
import { IdvQuestionnairesModule } from '../questionaires/idv-questionnaires.module';
import { AuthenticationGuard } from './authentication.guard';
import { AuthenticationService } from './authentication.service';
import { DataPrivacyDialogComponent } from './data-privacy-dialog/data-privacy-dialog.component';
import { GoodByeViewComponent } from './good-bye-view/good-bye-view.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginDependentComponent } from './login-dependent/login-dependent.component';
import { LoginStepsComponent } from './login-steps/login-steps.component';
import { BirthdateFactorComponent } from './second-factor-components';
import { SessionErrorMessageComponent } from './session-error-message/session-error-message.component';
import { MrcCaptchaModule } from '../../../../core-lib/src/lib/core/captcha/mrc-captcha.module';
import { MunichReDataProcessingStatementDialogComponent } from './munichre-data-processing-statement-dialog/munichre-data-processing-statement-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { EmailFactorComponent } from './second-factor-components/email-factor-component/email-factor.component';

const exportedDeclarations = [
  LandingPageComponent,
  GoodByeViewComponent,
  DataPrivacyDialogComponent,
  MunichReDataProcessingStatementDialogComponent,
];

const secondFactorComponents = [BirthdateFactorComponent, EmailFactorComponent];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MrcAuthenticationModule,
    MrcComponentsModule,
    IdvMaterialModule,
    IdvComponentsModule,
    IdvQuestionnairesModule,
    MrcCaptchaModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    LoginDependentComponent,
    LoginStepsComponent,
    SessionErrorMessageComponent,
    ...secondFactorComponents,
    ...exportedDeclarations,
  ],
  exports: [...exportedDeclarations],
  providers: [AuthenticationService, AuthenticationGuard],
})
export class IdvAuthenticationModule {}
