<form
  *ngIf="questionGroup && formGroup"
  class="form-group"
  (ngSubmit)="submitAnsweredQuestionGroup()"
  [formGroup]="formGroup"
>
  <!-- Header -->
  <h4 id="mrc_q_lipids_heading">{{ (questionGroup?.questions)[0].text }}</h4>

  <!-- Material checkbox -->
  <div>
    <mat-checkbox
      class="custom-control-container checkbox-label no-information-checkbox"
      id="mrc_q_lipids_no_information_available"
      formControlName="noValuesCheckbox"
    >
      {{ checkboxText }}
    </mat-checkbox>
  </div>

  <!-- Question -->
  <h5 id="mrc_q_lipids_unit">{{ lipidsUnitQuestion.text }}</h5>

  <!-- Radio -->
  <ng-container>
    <div class="radio-group">
      <mat-radio-group [formControlName]="'lipidsUnit'">
        <mat-radio-button [value]="lipidsUnitQuestion.selectableValues[0].id">
          {{ lipidsUnitQuestion.selectableValues[0].displayName }}
        </mat-radio-button>
        <mat-radio-button [value]="lipidsUnitQuestion.selectableValues[1].id">
          {{ lipidsUnitQuestion.selectableValues[1].displayName }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </ng-container>

  <!-- mg/dl -->
  <ng-container
    *ngIf="getFormControlsPerUnit() === 'mgdlValues'"
    formGroupName="mgdlValues"
  >
    <!-- Recent lipids -->
    <div
      formArrayName="recentLipidsValue"
      class="recent-lipids"
    >
      <!-- Header -->
      <div class="recent-lipids-header">
        <h5>{{ (questionGroup?.questions)[3].text }}</h5>
      </div>

      <div
        *ngFor="
          let item of formGroup.controls.mgdlValues.controls.recentLipidsValue
            .controls;
          let i = index
        "
        [formArrayName]="i"
      >
        <!-- Input 1 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(cholesterolValues1MgdlQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{ mask(cholesterolValues1MgdlQuestion.maxDecimalPlaces) }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="0"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[0])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[0]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
        <!-- Input 2 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(cholesterolHdlValues1MgdlQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{
              mask(cholesterolHdlValues1MgdlQuestion.maxDecimalPlaces)
            }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="1"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[1])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[1]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
        <!-- Input 3 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(triglycerideValues1MgdlQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{ mask(triglycerideValues1MgdlQuestion.maxDecimalPlaces) }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="2"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[2])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[2]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- Past lipids -->
    <!-- Header -->
    <div class="past-lipids-header">
      <h5>{{ (questionGroup?.questions)[7].text }}</h5>
    </div>
    <!-- Container -->
    <div
      formArrayName="pastLipidsValue"
      class="past-lipids"
    >
      <div
        *ngFor="
          let item of formGroup.controls.mgdlValues.controls.pastLipidsValue
            .controls;
          let i = index
        "
        [formArrayName]="i"
      >
        <!-- Input 1 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(cholesterolValues1MgdlQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{ mask(cholesterolValues1MgdlQuestion.maxDecimalPlaces) }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="0"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[0])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[0]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
        <!-- Input 2 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(cholesterolHdlValues1MgdlQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{
              mask(cholesterolHdlValues1MgdlQuestion.maxDecimalPlaces)
            }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="1"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[1])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[1]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
        <!-- Input 3 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(triglycerideValues1MgdlQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{ mask(triglycerideValues1MgdlQuestion.maxDecimalPlaces) }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="2"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[2])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[2]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <!-- mmol/l -->
  <ng-container
    *ngIf="getFormControlsPerUnit() === 'mmollValues'"
    formGroupName="mmollValues"
  >
    <!-- Recent lipids -->
    <div
      formArrayName="recentLipidsValue"
      class="recent-lipids"
    >
      <!-- Header -->
      <div class="recent-lipids-header">
        <h5>{{ (questionGroup?.questions)[14].text }}</h5>
      </div>

      <div
        *ngFor="
          let item of formGroup.controls.mmollValues.controls.recentLipidsValue
            .controls;
          let i = index
        "
        [formArrayName]="i"
      >
        <!-- Input 1 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(cholesterolValues1MmollQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{ mask(cholesterolValues1MmollQuestion.maxDecimalPlaces) }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="0"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[0])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[0]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
        <!-- Input 2 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(cholesterolHdlValues1MmollQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{
              mask(cholesterolHdlValues1MmollQuestion.maxDecimalPlaces)
            }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="1"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[1])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[1]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
        <!-- Input 3 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(triglycerideValues1MmollQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{ mask(triglycerideValues1MmollQuestion.maxDecimalPlaces) }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="2"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[2])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[2]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- Past lipids -->
    <!-- Header -->
    <div class="past-lipids-header">
      <h5>{{ (questionGroup?.questions)[18].text }}</h5>
    </div>
    <!-- Container -->
    <div
      formArrayName="pastLipidsValue"
      class="past-lipids"
    >
      <div
        *ngFor="
          let item of formGroup.controls.mmollValues.controls.pastLipidsValue
            .controls;
          let i = index
        "
        [formArrayName]="i"
      >
        <!-- Input 1 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(cholesterolValues1MmollQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{ mask(cholesterolValues1MmollQuestion.maxDecimalPlaces) }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="0"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[0])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[0]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
        <!-- Input 2 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(cholesterolHdlValues1MmollQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{
              mask(cholesterolHdlValues1MmollQuestion.maxDecimalPlaces)
            }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="1"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[1])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[1]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
        <!-- Input 3 -->
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <mat-label>{{
            getLabel$(triglycerideValues1MmollQuestion.text) | async
          }}</mat-label>
          <input
            matInput
            mask="{{ mask(triglycerideValues1MmollQuestion.maxDecimalPlaces) }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            type="text"
            formControlName="2"
          />
          <mat-error
            *ngIf="areAllErrorMessagesShown || hasError(item.controls[2])"
          >
            <idv-error-help-block
              [errors]="errorsOnField(item.controls[2]) || errorsOnField(item)"
            >
            </idv-error-help-block>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <!-- Error message for fields must be filled -->
  <div class="error-container">
    <idv-error-help-block
      *ngIf="areAllErrorMessagesShown"
      [errors]="
        errorsOnField(
          formGroup.controls.mmollValues.controls.pastLipidsValue
        ) ||
        errorsOnField(
          formGroup.controls.mmollValues.controls.recentLipidsValue
        ) ||
        errorsOnField(formGroup.controls.mgdlValues.controls.pastLipidsValue) ||
        errorsOnField(formGroup.controls.mgdlValues.controls.recentLipidsValue)
      "
    ></idv-error-help-block>
  </div>

  <!-- Button container -->
  <idv-questionnaire-previous-next
    *ngIf="!readonlyMode"
    class="mt-5"
    [backButtonRouterLink]="backButtonRouterLink"
    [canProceed]="!!formGroup"
  ></idv-questionnaire-previous-next>
</form>
