import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LinksConfiguration } from '../../../../interfaces/links-configuration';
import { TenantConfigService } from '../../../../services/config/tenant-config.service';

@Component({
  selector: 'idv-data-protection-confirmation',
  templateUrl: './data-protection-confirmation.component.html',
  styleUrls: ['./data-protection-confirmation.component.scss'],
})
export class DataProtectionConfirmationComponent implements OnInit, OnDestroy {
  @Output()
  dataPrivacyConfirmation = new EventEmitter<boolean>();
  private linksConfig: LinksConfiguration;
  private configAssetServiceSubscription: Subscription;

  constructor(
    private tenantConfigService: TenantConfigService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.configAssetServiceSubscription =
      this.tenantConfigService.tenantConfig$.subscribe((config) => {
        this.linksConfig = config.links;
      });
  }

  // MAYDO subscribe to language changes and only then reconstruct this
  get dataPrivacyStatement(): string {
    return this.translateService.instant('data-protection-confirmation.text', {
      uri: this.privacyStatementUrl,
    });
  }

  get privacyStatementUrl() {
    return this.linksConfig?.privacyStatement?.url || '';
  }

  updateCheckboxValue(value: boolean) {
    this.dataPrivacyConfirmation.emit(value);
  }

  ngOnDestroy(): void {
    if (this.configAssetServiceSubscription) {
      this.configAssetServiceSubscription.unsubscribe();
    }
  }
}
