<!-- 
  ngIf is necessary in order to create an entirely new form. 
  * If the same form is used, the submitted state is not removed from the form when editing its controls 
-->
<div *ngIf="displayForm">
  <form
    *ngIf="!!questionGroup"
    (ngSubmit)="submitAnsweredQuestionGroup()"
    [formGroup]="formGroup"
  >
    <div
      *ngFor="let question of questionGroup?.questions"
      [class.form-group]="!isMultiSelectQuestionGroup"
    >
      <h4
        *ngIf="question?.text"
        class="question-text"
      >
        {{ question.text }}<br />
        <small *ngIf="isMultiSelectQuestionGroup">
          ({{ 'question-group.multiselect-hint' | translate }})
        </small>
      </h4>
      <idv-dynamic-control
        *ngIf="question.id"
        [question]="question"
        [formControlToUse]="formGroup.controls[question.id]"
        [showErrors]="areAllErrorMessagesShown"
      ></idv-dynamic-control>
      <br
        *ngIf="
          errorsOnField(question.id) &&
          question.type !== 'textOnly' &&
          areAllErrorMessagesShown
        "
      />
      <idv-error-help-block
        *ngIf="question.type !== 'textOnly' && areAllErrorMessagesShown"
        [errors]="errorsOnField(question.id)"
      ></idv-error-help-block>
    </div>
    <idv-questionnaire-previous-next
      *ngIf="!readonlyMode"
      class="mt-5"
      [backButtonRouterLink]="backButtonRouterLink"
      [canProceed]="!!formGroup"
      [hideBackButton]="!backButtonRouterLink"
    ></idv-questionnaire-previous-next>
  </form>
</div>
