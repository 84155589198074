import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { IdvLanguageService } from './language/idv-language.service';

@NgModule({
  providers: [],
})
export class IdvServicesModule {
  static forRoot(): ModuleWithProviders<IdvServicesModule> {
    return {
      ngModule: IdvServicesModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (ls: IdvLanguageService) => () => ls.init(),
          deps: [IdvLanguageService],
          multi: true,
        },
      ],
    };
  }
}
