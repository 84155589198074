import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CloseModalHeaderComponent } from './close-modal-header/close-modal-header.component';
import { IdvMaterialModule } from '../idv-material.module';
import { CommonModule } from '@angular/common';
import { CookieNotificationComponent } from './cookie-notification/cookie-notification.component';
import { ApplicationInfoDialogComponent } from './application-info-dialog/application-info-dialog.component';
import { DemoIndicatorComponent } from './demo-indicator/demo-indicator.component';
import { DemoLabelComponent } from './demo-label/demo-label.component';
import { ErrorScreenComponent } from './error-screen/error-screen.component';
import { FaqDialogComponent } from './faq-dialog/faq-dialog.component';
import { ChangedMedicalRisksMessageComponent } from './changed-medical-risks-message/changed-medical-risks-message.component';
import { FooterComponent } from './footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { MrcComponentsModule } from '../../../../core-lib';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { MaterialFooterComponent } from './material-footer/material-footer.component';
import { TenantPanelComponent } from './tenant-panel/tenant-panel.component';
import { ClickTrackerDirective } from './click-tracker/click-tracker.directive';
import { AddTenantClassDirective } from './add-tenant-class.directive';
import { ReanswerQuestionsDialogComponent } from './reanswer-questions-dialog/reanswer-questions-dialog.component';
import { OverlayDialogComponent } from './overlay-dialog/overlay-dialog.component';
import { DialogWrapperComponent } from './dialog-wrapper/dialog-wrapper.component';

const importsAndExports = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  MrcComponentsModule,
  IdvMaterialModule,
];

const exportedDeclarations = [
  ApplicationInfoDialogComponent,
  ChangedMedicalRisksMessageComponent,
  CloseModalHeaderComponent,
  CookieNotificationComponent,
  DemoIndicatorComponent,
  DemoLabelComponent,
  ErrorScreenComponent,
  FaqDialogComponent,
  ReanswerQuestionsDialogComponent,
  FooterComponent,
  LanguageSelectorComponent,
  MaterialFooterComponent,
  TenantPanelComponent,
  ClickTrackerDirective,
  AddTenantClassDirective,
  OverlayDialogComponent,
  DialogWrapperComponent,
];

@NgModule({
  imports: [...importsAndExports],
  declarations: [...exportedDeclarations],
  exports: [...importsAndExports, ...exportedDeclarations],
})
export class IdvComponentsModule {}
