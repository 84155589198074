<idv-close-modal-header></idv-close-modal-header>

<div mat-dialog-content>
  <h2 class="mt-0">{{ 'risk-questionnaire.remove-risk' | translate }}</h2>
  <h4
    [wordBreakingHtml]="
      'remove-risk-confirmation-dialog.message'
        | translate : { riskLabel: riskLabel }
    "
  ></h4>

  <div [class.has-error]="showErrors">
    <idv-remove-risk-reasons
      *ngIf="!(risk.source === 'added-by-physician')"
      (reasonChange)="setReason($event)"
    ></idv-remove-risk-reasons>
    <idv-error-help-block
      *ngIf="showErrors"
      [errors]="{ required: true }"
    ></idv-error-help-block>
  </div>
</div>

<div mat-dialog-actions>
  <button
    mat-button
    [mat-dialog-close]
    id="btn_idv_decline_remove_risk"
    color="primary"
  >
    {{ 'remove-risk-confirmation-dialog.decline' | translate }}
  </button>

  <button
    id="btn_idv_confirm_remove_risk"
    mat-button
    color="primary"
    (click)="removeRisk()"
  >
    {{ 'remove-risk-confirmation-dialog.confirm' | translate }}
  </button>
</div>
