import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Request as IdvRequest } from '../../../../../idv-lib';
import { Subscription } from 'rxjs';
import { MrcTrackingService, WindowService } from '../../../../../core-lib';
import { IDV_ENV, IdvEnvironment } from '../../interfaces/idv-environment';
import { RequestService } from '../request/request.service';
import { TenantService } from '../tenant/tenant.service';
import { filter, tap } from 'rxjs/operators';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable({ providedIn: 'root' })
export class DevTrackingService
  extends MrcTrackingService
  implements OnDestroy
{
  private readonly requestServiceSubscription: Subscription;
  private correlationId: string;

  constructor(
    private router: Router,
    private windowService: WindowService,
    private tenantService: TenantService,
    private requestService: RequestService,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {
    super();
    this.requestServiceSubscription = this.requestService.request
      .pipe(
        filter((request) => !!request),
        tap((request: IdvRequest) => {
          this.correlationId = request.correlationId;
        })
      )
      .subscribe();
    this.setupRouteChangeTracking(this.router);
    this.setupPageUnloadTracking(this.windowService);
    this.trackInitialPageLoad(this.windowService, 'idv_initial_page_load');
  }

  private log(fnName: string, ...args: any): void;
  private log(): void {
    const console = this.windowService.window['console'];
    if (console && typeof console.log === 'function') {
      console.log.apply(console, arguments);
    }
  }

  protected enrichProps(properties: { [name: string]: string }): {
    [name: string]: string;
  } {
    if (!properties) {
      properties = {};
    }
    properties.app = 'idv';
    properties.flavor = this.environment.flavor.name;
    if (!('pageName' in properties)) {
      properties.pageName = this.findPageName(this.router);
    }

    if (this.tenantService.current) {
      if (!properties.tenant) {
        properties.tenant = this.tenantService.current;
      }
    }

    if (this.correlationId) {
      if (!properties.correlationId) {
        properties.correlationId = this.correlationId;
      }
    }
    return properties;
  }

  trackPageView(
    name?: string,
    uri?: string,
    properties?: { [name: string]: string },
    pageType?: string,
    refUri?: string,
    isLoggedIn?: boolean
  ) {
    this.log(
      'trackPageView',
      name,
      uri,
      this.enrichProps(properties),
      pageType,
      refUri,
      isLoggedIn
    );
  }

  startTrackPage(name?: string) {
    this.log('startTrackPage', name);
  }

  stopTrackPage(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ) {
    this.log(
      'stopTrackPage',
      name,
      url,
      this.enrichProps(properties),
      measurements
    );
  }

  trackEvent(name: string, properties?: { [name: string]: string }) {
    this.log('trackEvent', name, this.enrichProps(properties));
  }

  trackMetric(
    name: string,
    average: number,
    sampleCount?: number,
    min?: number,
    max?: number,
    properties?: { [name: string]: string }
  ) {
    this.log('trackMetric', name, average, sampleCount, min, max, properties);
  }

  trackException(
    exception: Error,
    properties?: { [name: string]: string },
    severityLevel?: SeverityLevel,
    id?: string
  ) {
    this.log(
      'trackException',
      exception,
      this.enrichProps(properties),
      severityLevel,
      id
    );
  }

  trackTrace(
    message: string,
    properties?: { [name: string]: string },
    severityLevel?: SeverityLevel
  ) {
    this.log(
      'trackTrace',
      message,
      this.enrichProps(properties),
      severityLevel
    );
  }

  trackDependency(
    id: string,
    responseCode: number,
    name?: string,
    duration?: number,
    success?: boolean,
    correlationContext?: string,
    target?: string,
    type?: string,
    properties?: { [key: string]: any },
    startTime?: Date,
    data?: string
  ) {
    this.log(
      'trackDependency',
      id,
      responseCode,
      name,
      duration,
      success,
      correlationContext,
      target,
      type,
      this.enrichProps(properties),
      startTime,
      data
    );
  }

  flush() {
    this.log('flush');
  }

  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string) {
    this.log('setAuthenticatedUserContext', authenticatedUserId, accountId);
  }

  clearAuthenticatedUserContext() {
    this.log('clearAuthenticatedUserContext');
  }

  ngOnDestroy(): void {
    if (this.requestServiceSubscription) {
      this.requestServiceSubscription.unsubscribe();
    }
  }
}
