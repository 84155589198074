import { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, forwardRef } from '@angular/core';

/**
 * Examples:
 * MockFormControl({ selector: 'some-component' });
 * MockFormControl({ selector: 'some-component', inputs: ['some-input', 'some-other-input'] });
 *
 * See https://angular.io/docs/ts/latest/api/core/index/Component-decorator.html for a list
 * of supported properties.
 */

export function MockFormControl(options: Component): any {
  const metadata: Component = {
    selector: options.selector,
    template: options.template || '',
    inputs: options.inputs,
    outputs: options.outputs || [],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => Mock),
        multi: true,
      },
    ],
  };

  class Mock implements ControlValueAccessor {
    private onChange = (_value: any) => {};
    private onTouched = () => {};

    writeValue(value: any): any {
      this.onChange(value);
    }

    registerOnChange(onChange: any): void {
      this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void {
      this.onTouched = onTouched;
    }

    setDisabledState?(_isDisabled: boolean): void {}

    touch(): void {
      this.onTouched();
    }
  }

  metadata.outputs.forEach((method) => {
    const prop = method as keyof typeof Mock.prototype;
    (Mock.prototype[prop] as any) = new EventEmitter<any>();
  });

  return Component(metadata)(Mock as any);
}
