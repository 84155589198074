import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ResponsiveService } from '../../../services/responsive/responsive.service';
import { StepState } from '../../../services/step-navigation/step-state';

@Component({
  selector: 'idv-master-detail-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  public isCompact$: Observable<boolean>;

  @Input()
  hierarchicalStepStates: StepState[];

  @Input()
  pseudoSteps: boolean;

  @Input()
  applicantInfo: string | null;

  @Input()
  showPaperDownload: boolean;

  @Output()
  downloadPaper = new EventEmitter<void>();

  constructor(private responsiveService: ResponsiveService) {}

  ngOnInit() {
    this.isCompact$ = this.responsiveService.isCompact$;
  }

  ngOnDestroy(): void {}
}
