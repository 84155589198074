import { RiskSummary } from './risk-summary';
import { IbanPaymentData } from './iban-payment-data';
import { AccountPaymentData } from './account-payment-data';

export interface RequestSummary {
  prologueSummary: RiskSummary;
  epilogueSummary: RiskSummary;
  risks: RiskSummary[];
  paymentData: IbanPaymentData | AccountPaymentData;
}
