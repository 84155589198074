import { ValueRange } from './value-range';
import { QuestionInputValue } from './question-input-value.type';
import { QuestionType } from './question.type';
import { SelectableValue } from './selectable-value';
import { DatePrecision } from './date-precison';

export interface Question {
  id?: string;
  name?: string;
  type: QuestionType;
  text?: string;
  isMandatory?: boolean;
  helpText?: string;
  selectableValues?: SelectableValue[];
  selection?: QuestionInputValue;
  selectionArray?: QuestionInputValue[];
  precision?: DatePrecision;
  numericRange?: ValueRange<number>;
  maxDecimalPlaces?: number;
  dateRange?: ValueRange<Date>;
  monthUnknown?: boolean;
}
