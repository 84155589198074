import { Pipe, PipeTransform } from '@angular/core';

export type mapCallbackFn<T> = (value: T, index: number, array: T[]) => string;

@Pipe({
  name: 'mrcArrayToString',
  pure: true,
})
export class ArrayToStringPipe implements PipeTransform {
  transform(
    array: any[],
    maxItems?: number,
    extractor?: string | mapCallbackFn<any>,
    separator: string = ', '
  ): string {
    if (Array.isArray(array)) {
      let shortened: boolean;
      if (maxItems != null && !isNaN(maxItems) && array.length > maxItems) {
        array = array.slice(0, maxItems);
        shortened = true;
      } else {
        shortened = false;
      }
      if (typeof extractor === 'function') {
        array = array.map(extractor);
      } else if (typeof extractor === 'string') {
        array = array.map((elt) => elt[extractor]);
      }
      if (shortened) {
        array.push('…');
      }
      return array.filter((i) => i != null && i !== '').join(separator);
    } else if (array == null) {
      return '';
    }
    return array;
  }
}
