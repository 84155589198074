<div class="modal-dialog modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <h4>{{ 'shared.version-info' | translate }}</h4>
    </div>
    <div class="modal-body">
      <p *ngFor="let applicationVersion of applicationVersions | async">
        <b>{{ applicationVersion.name }}</b>
        <br />
        {{ applicationVersion.version }}
      </p>
    </div>
  </div>
</div>
