import { UntypedFormControl } from '@angular/forms';
import { PrecisionDate } from '../../../../../idv-lib/src/lib/interfaces';

export function isValidPrecisionDate(formControl: UntypedFormControl) {
  const date: any = formControl.value;
  // null is also valid . the 'required' validator is responsible for handling null or empty value
  if (date == null || date === '') {
    return null;
  }

  if (date instanceof PrecisionDate && date.isExistingDate) {
    return null;
  }

  return {
    invalidDate: true,
  };
}
