<div class="row">
  <idv-gdq-hint *ngIf="showGDQHint"></idv-gdq-hint>

  <div class="col-12 col-sm-11 col-lg-12 offset-sm-1 offset-lg-0">
    <idv-questionnaire-heading
      *ngIf="!!risk"
      [showSummary]="false"
      [risk]="risk"
      [riskQuestionGroupContext]="riskQuestionGroupContext"
    ></idv-questionnaire-heading>

    <ng-container [ngSwitch]="questionnaireMode">
      <ng-template ngSwitchCase="question">
        <idv-dynamic-question-group-control
          [backButtonRouterLink]="backButtonRoute$ | async"
          [questionGroup]="riskQuestionGroupContext?.questionGroup"
          (questionGroupAnswer)="fetchNextRiskQuestionGroupContext($event)"
        ></idv-dynamic-question-group-control>
      </ng-template>
      <ng-template ngSwitchCase="completed">
        <idv-questionnaire-completed
          [risk]="risk"
        ></idv-questionnaire-completed>
      </ng-template>
      <ng-template ngSwitchCase="synonym-added">
        <idv-synonym-added [risk]="risk"></idv-synonym-added>
      </ng-template>
    </ng-container>
  </div>
</div>
