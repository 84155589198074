<ng-container *ngIf="linksConfig$ | async as linksConfig">
  <footer
    *ngIf="
      linksConfig.imprint.isShown ||
      linksConfig.legalNotice.isShown ||
      linksConfig.privacyStatement.isShown ||
      linksConfig.faq.isShown
    "
  >
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-auto footer-links-container">
          <div class="d-block right-padded">
            <p *ngIf="linksConfig.imprint.isShown">
              <a
                class="footer-link"
                [href]="linksConfig.imprint.url"
                target="_blank"
                idvClickTracker="idv_open_imprint?source=mat-footer"
                rel="noopener noreferrer"
              >
                {{ 'footer.imprint' | translate }}
              </a>
            </p>

            <p *ngIf="linksConfig.legalNotice.isShown">
              <a
                class="footer-link"
                [href]="linksConfig.legalNotice.url"
                target="_blank"
                idvClickTracker="idv_open_legal_notice?source=mat-footer"
                rel="noopener noreferrer"
              >
                {{ 'footer.legal-notice' | translate }}
              </a>
            </p>

            <p *ngIf="linksConfig.privacyStatement.isShown">
              <a
                class="footer-link"
                [href]="linksConfig.privacyStatement.url"
                target="_blank"
                idvClickTracker="idv_open_privacy_statement?source=mat-footer"
                rel="noopener noreferrer"
              >
                {{ 'footer.privacy-statement' | translate }}
              </a>
            </p>

            <p *ngIf="displayFAQs && linksConfig.faq.isShown">
              <a
                class="footer-link"
                (click)="openFaqModal()"
                href="javascript:void(0)"
                idvClickTracker="idv_open_faq?source=mat-footer"
                rel="noopener noreferrer"
              >
                {{ 'footer.faq' | translate }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</ng-container>
