import { ValidationErrors } from '@angular/forms';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface ErrorBlockItem {
  key: string;
  error: string;
}

@Component({
  selector: 'idv-error-help-block',
  templateUrl: './error-help-block.component.html',
  styleUrls: ['./error-help-block.component.scss'],
})
export class ErrorHelpBlockComponent implements OnChanges {
  @Input()
  errors: ValidationErrors;

  errorArray: ErrorBlockItem[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errors']) {
      this.errorArray = this.buildErrorArray();
    }
  }

  private buildErrorArray(): ErrorBlockItem[] {
    if (this.errors != null) {
      return Object.keys(this.errors).map((keyError) => {
        return { key: keyError, error: this.errors[keyError] };
      });
    }
    return [];
  }
}
