import {
  Directive,
  HostBinding,
  OnDestroy,
  OnInit,
  Input,
  Inject,
} from '@angular/core';
import { TenantService } from '../services/tenant/tenant.service';
import { Subscription } from 'rxjs';
import { IdvEnvironment, IDV_ENV } from '../interfaces/idv-environment';

@Directive({
  selector: '[addTenantClass]',
})
export class AddTenantClassDirective implements OnInit, OnDestroy {
  @Input('class')
  @HostBinding('class')
  class: string;

  private subscription: Subscription;
  private originalClass: string;

  constructor(
    private tenantService: TenantService,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {}

  ngOnInit() {
    this.originalClass = this.class ? this.class : '';
    this.subscription = this.tenantService.tenant.subscribe((val: string) => {
      this.class =
        this.originalClass + ' ' + this.environment.flavor.name + ' ' + val;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
