<mat-form-field>
  <mat-select
    [(value)]="selectedLanguage"
    (selectionChange)="onSelectLanguage()"
  >
    <mat-option
      *ngFor="let language of languages"
      [value]="language"
    >
      {{ getLanguageDisplayName(language) }}
    </mat-option>
  </mat-select>
</mat-form-field>
