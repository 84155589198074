import { AbstractControl, ValidationErrors } from '@angular/forms';

export const accessTokenLength = 12;

export function accessTokenLengthValidator(
  control: AbstractControl
): ValidationErrors {
  let token = control.value as string;

  // Do not validate empty values, that's the job of Validators.required
  if (!token) {
    return null;
  }

  token = token.replace(/[^a-z0-9]/gi, '');

  const tokenLength = token.length;

  const isTokenLengthCorrect = tokenLength === accessTokenLength;

  return isTokenLengthCorrect ? null : { invalidTokenLength: tokenLength };
}
