import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import {
  accessTokenLengthValidator,
  conditionalValidator,
  SessionStorageService,
} from '../../../../../core-lib';
import { MrcTrackingService } from '../../../../../core-lib/src/lib/core/services/tracking/tracking.service';
import { SessionKey } from '../../constants/session-key.constants';
import { RequestStateType } from '../../interfaces';
import { AppRoute } from '../../interfaces/app-routing.const';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { TenantService } from '../../services/tenant/tenant.service';
import {
  AuthenticationService,
  expirationQueryParameter,
} from '../authentication.service';
import { DataPrivacyDialogComponent } from '../data-privacy-dialog/data-privacy-dialog.component';
import {
  IAuthenticationResult,
  AuthenticationResultType,
} from '../model/authentication-result';
import { StepStateService } from '../../services/step-navigation/step-state.service';
import { TenantConfigService } from '../../services/config/tenant-config.service';
import { OverlayDialogComponent } from '../../components/overlay-dialog/overlay-dialog.component';
import { MfaStrategy } from '../model/mfa-strategy';

const captchaRequiredError = { captchaRequired: true };

@Component({
  selector: 'idv-login-dependent',
  templateUrl: './login-dependent.component.html',
  styleUrls: ['./login-dependent.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: '0', opacity: 0 }),
        animate('300ms ease-in', style({ 'max-height': '40px', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ 'max-height': '0', opacity: 0 })),
      ]),
    ]),
  ],
})
export class LoginDependentComponent implements OnInit, OnDestroy {
  @Input() tenant: string;
  @Input() accessToken: string;
  @Output() onLoginStarted = new EventEmitter<void>();

  public isSessionExpiredMessageVisible$: Observable<boolean>;
  public formGroup: UntypedFormGroup;
  public showCaptcha = false;
  public siteKey: string;
  public readonly mask = 'AAAA AAAA AAAA';

  areAllErrorMessagesShown = false;
  loginStarted: boolean;

  private customValidationErrors: ValidationErrors = null;

  private invalidRequestStates: RequestStateType[] = [
    'cancellation-applicant',
    'cancellation-traditional-process',
    'cancellation-no-response',
    'cancellation-other',
    'access-code-expired',
  ];
  private destroyed$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private stepStateService: StepStateService,
    private activatedRoute: ActivatedRoute,
    private tenantConfigService: TenantConfigService,
    private sessionStorageService: SessionStorageService,
    private translateService: TranslateService,
    private tenantService: TenantService,
    private trackingService: MrcTrackingService
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      accessToken: [
        '',
        Validators.compose([Validators.required, accessTokenLengthValidator]),
      ],
      captchaToken: [
        '',
        {
          validator: conditionalValidator(
            () => this.showCaptcha,
            Validators.required
          ),
        },
      ],
    });

    this.formGroup.controls.accessToken.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.customValidationErrors = null;
      });

    this.onLoginStarted.pipe(first()).subscribe(() => {
      this.loginStarted = true;
    });

    this.isSessionExpiredMessageVisible$ = combineLatest([
      this.activatedRoute.queryParams.pipe(
        map(({ origin }) => origin === expirationQueryParameter.origin)
      ),
      this.tenantService.tenant,
    ]).pipe(map(([isExpired, tenant]) => isExpired && !tenant));

    this.tenantConfigService.tenantConfig$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((config) => {
        this.showCaptcha = config.captcha.captchaIsRequired;
        this.siteKey = config.captcha.siteKey;
      });

    if (this.accessToken) {
      this.formGroup.get('accessToken').setValue(this.accessToken);
      this.loginStarted = true;
    }
  }

  get isErrorMessageShown() {
    return this.areAllErrorMessagesShown;
  }

  get accessTokenErrors() {
    return (
      this.formGroup.controls.accessToken.errors || this.customValidationErrors
    );
  }

  get captchaTokenErrors() {
    return this.formGroup.controls.captchaToken.errors
      ? captchaRequiredError
      : null;
  }

  get errors() {
    return (
      this.formGroup.controls.accessToken.errors || this.customValidationErrors
    );
  }

  get currentLanguage() {
    return this.translateService.currentLang;
  }

  isInvalidState(state: RequestStateType) {
    return this.invalidRequestStates.includes(state);
  }

  openGDPRDialog() {
    this.dialog.open(
      DataPrivacyDialogComponent,
      this.responsiveService.enhanceDialogConfig({
        panelClass: 'super-big-modal',
      })
    );
  }

  submit() {
    this.areAllErrorMessagesShown = true;
    if (this.formGroup.valid) {
      const { accessToken: accessTokenMasked, captchaToken } =
        this.formGroup.value;
      // when using `text-mask`, the spacing/slash characters are part of the value -> delete them
      const accessToken = accessTokenMasked.replace(/[ -]/g, '');

      this.loginFirstStep(accessToken, captchaToken);
    }
  }

  private loginFirstStep(accessToken: string, captchaToken: string) {
    this.authenticationService
      .twoFactorLoginFirstStep(accessToken, captchaToken, this.tenant)
      .subscribe((response) => {
        if (response.success) {
          const tenant = this.tenantService.current;
          this.onSubmitSuccess();

          if (
            !response.payload?.mfaType ||
            response.payload?.mfaType === MfaStrategy.NoAuth
          ) {
            this.stepStateService.navigateToNextStep();
          } else {
            // When navigating to 2FA page, keep all query parameters from landing page
            this.router.navigate(
              [AppRoute.secondAuthFactor, tenant, response.payload.mfaType],
              {
                queryParamsHandling: 'merge',
                queryParams: { lang: response.payload.requestLanguage },
                state: { payload: response.payload },
              }
            );
          }
        } else {
          this.onSubmitError(response);
        }
      });
  }

  private onSubmitSuccess() {
    this.customValidationErrors = null;
    this.sessionStorageService.removeItemByKey(
      SessionKey.requestSummaryPdfBase64
    );
  }

  private onSubmitError(result: IAuthenticationResult) {
    this.formGroup.controls.captchaToken.reset();

    if (result.resultType === AuthenticationResultType.tokenInvalid) {
      this.setAccessTokenErrors(result);
      this.trackingService.trackEvent('idv_login_failed');
    } else if (result.resultType === AuthenticationResultType.captcha) {
      this.showCaptcha = true;
      this.trackingService.trackEvent('idv_login_failed_showing_captcha');
    } else if (result.resultType === AuthenticationResultType.tenantInvalid) {
      this.dialog.open(OverlayDialogComponent, {
        panelClass: 'super-big-modal',
        disableClose: true,
        data: {
          content: this.translateService.instant(
            'landing-page.invalid-tenant-overlay-text'
          ),
        },
      });
      this.trackingService.trackEvent('idv_login_failed_wrong_tenant');
    } else {
      this.customValidationErrors = { unknownError: true };
    }
  }

  private setAccessTokenErrors(details: IAuthenticationResult) {
    if (details && this.isInvalidState(details.requestStatus)) {
      this.customValidationErrors = { inactiveToken: true };
    } else {
      this.customValidationErrors = { tokenMustBeValid: true };
    }
    // Material needs to have errors on the field in order to show the error
    this.formGroup.controls.accessToken.setErrors(this.customValidationErrors);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
