import { UntypedFormControl } from '@angular/forms';

export function createAccountNumberValidator() {
  return (control: UntypedFormControl) => {
    if (control.value === null || control.value === undefined) {
      return null;
    }

    const regExp = /^\d{8}$/g;
    return regExp.test(control.value.toString())
      ? null
      : {
          accountNumberError: true,
        };
  };
}
