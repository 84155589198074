import {
  Directive,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const groupLength = 4;
const formattingRegExp = new RegExp(`(.{${groupLength}})`, 'g');

@Directive({
  selector:
    // tslint:disable-next-line:directive-selector max-line-length
    'input[type=text][formControlName][blockGrouped],textarea[formControlName][blockGrouped],input[type=text][formControl][blockGrouped],textarea[formControl][blockGrouped],input[type=text][ngModel][blockGrouped],textarea[ngModel][blockGrouped]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-use-before-declare
      useExisting: forwardRef(() => BlockGroupedDirective),
      multi: true,
    },
  ],
})
export class BlockGroupedDirective implements ControlValueAccessor, OnChanges {
  @HostBinding('disabled') isDisabled: boolean;

  @Input() maxUngroupedLength: number;

  private previousFormattedText = '';

  @Input() inputTextFilter = (inputText: string) => inputText;

  @HostListener('blur') onTouched = () => {};

  private onChange = (_: string) => {};

  constructor(private input: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('maxUngroupedLength' in changes) {
      const newMaxUngroupedLength = changes.maxUngroupedLength.currentValue;
      const insertedSpacesCount = Math.max(
        Math.ceil(newMaxUngroupedLength / groupLength) - 1,
        0
      );
      const maxLengthIncludingSpaces =
        newMaxUngroupedLength + insertedSpacesCount;
      this.input.nativeElement.setAttribute(
        'maxlength',
        maxLengthIncludingSpaces
      );
    }
  }

  @HostListener('input', ['$event.target.value'])
  writeValue(text: string): void {
    // Required to detect changes to the input since IE throws indistinguishable input events on focus and blur as well,
    // and we don't want to change the caret position back into the input field in those cases
    if (this.previousFormattedText !== text) {
      const normalizedText = text || '';

      const filteredInputText = this.inputTextFilter(normalizedText);
      const unformattedText = stripSpaces(filteredInputText);
      const formattedText = insertSpaces(unformattedText);

      const nextCaretPosition = this.getNextCaretPosition();

      this.previousFormattedText = formattedText;
      this.input.nativeElement.value = formattedText;
      this.input.nativeElement.selectionStart = nextCaretPosition;
      this.input.nativeElement.selectionEnd = nextCaretPosition;
      this.onChange(unformattedText);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private getNextCaretPosition() {
    const inputElement = this.input.nativeElement;
    const inputText = inputElement.value;
    const caretPosition = inputElement.selectionStart;
    const inputTextBeforeCaret = inputText.substring(0, caretPosition);

    const filteredInputTextBeforeCaret =
      this.inputTextFilter(inputTextBeforeCaret);
    const unformattedInputTextBeforeCaret = stripSpaces(
      filteredInputTextBeforeCaret
    );
    const formattedInputTextBeforeCaret = insertSpaces(
      unformattedInputTextBeforeCaret
    );

    return formattedInputTextBeforeCaret.length;
  }
}

function stripSpaces(formattedText: string) {
  return formattedText.replace(/ /g, '');
}

function insertSpaces(unformattedText: string) {
  return unformattedText.replace(formattingRegExp, '$1 ').trim();
}
