import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take, filter } from 'rxjs/operators';
import { Risk } from '../../../../../../../idv-lib/src/lib/interfaces';
import { RequestService } from '../../../../../../../idv-lib/src/lib/services/request/request.service';
import { StepStateService } from '../../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { MrcTrackingService } from '../../../../../../../core-lib/src/lib/core/services/tracking/tracking.service';

@Component({
  selector: 'idv-replace-risk-component',
  templateUrl: './replace-risk.component.html',
})
export class ReplaceRiskComponent implements OnInit {
  risk: Risk;

  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private requestService: RequestService,
    private stepStateService: StepStateService,
    private trackingService: MrcTrackingService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.requestService.request
        .pipe(
          take(1),
          filter((request) => !!request)
        )
        .subscribe((request) => {
          const riskId = params['id'];
          this.risk = request.medicalRisks.risks.find((r) => r.id === riskId);
        });
    });
  }

  // MAYDO build translation only when risk or language change
  get replaceRiskHeader(): string {
    return this.translateService.instant(
      'replace-medical-risk-questionnaire.replaced-by',
      {
        risk: this.risk ? this.risk.labels.join(', ') : '',
      }
    );
  }

  onRiskReplaced(newRisk: Risk) {
    this.trackingService.trackEvent('idv_replace_risk', {
      source: this.risk.source,
      riskClass: this.risk.riskClass,
      newRiskClass: newRisk.riskClass,
    });

    this.navigateToRiskQuestionnaire(newRisk.id);
  }

  onRiskReplaceCancelled() {
    if (!this.risk) {
      return;
    }
    if (this.stepStateService.isCompleted(this.risk.id)) {
      this.stepStateService.goToNextRiskStep();
    } else {
      this.stepStateService.goToRisk(this.risk.id);
    }
  }

  private navigateToRiskQuestionnaire(riskId: string) {
    if (this.stepStateService.stepExists(riskId)) {
      this.stepStateService.goToRisk(riskId);
    }
  }
}
