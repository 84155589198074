import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Config } from '../interfaces';
import { IDV_ENV, IdvEnvironment } from '../interfaces/idv-environment';

const cacheHttp =
  (cacheKey: string, cacheStorage: any) => (source: Observable<any>) => {
    if (!cacheStorage[cacheKey]) {
      cacheStorage[cacheKey] = source.pipe(shareReplay(1));
    }
    return cacheStorage[cacheKey];
  };

@Injectable({ providedIn: 'root' })
export class ApiService {
  protected cachedHttpRequests: any;
  protected configApiBaseUrl = `${this.environment.apiHost}/idv/config`;
  protected configAssetApiBaseUrl = `${this.environment.apiHost}/idv/config/assets`;
  protected configAssetHost = `${this.environment.assetHost}`;

  constructor(
    private httpClient: HttpClient,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {
    this.cachedHttpRequests = {};
  }

  loadTenantConfiguration$(
    tenant: string,
    language: string
  ): Observable<Config> {
    return this.httpClient
      .get<Config>(`${this.configApiBaseUrl}/tenant/${tenant}`)
      .pipe(
        cacheHttp(
          `tenant-config-${tenant}-${language}`,
          this.cachedHttpRequests
        )
      );
  }

  loadHtmlAsset$(assetPath: string): Observable<string> {
    return this.httpClient.get(this.getApiAssetUrl(assetPath), {
      responseType: 'text',
    });
  }

  loadBlobAsset$(assetPath: string): Observable<Blob> {
    return this.httpClient.get(this.getApiAssetUrl(assetPath), {
      responseType: 'blob',
    });
  }

  getApiAssetUrl(assetPath: string, language?: string) {
    const url = `${this.configAssetApiBaseUrl}/${assetPath}?application=${this.environment.application}`;
    return language ? `${url}&language=${language}` : url;
  }

  appendCssAssetGlobally(cssAssetUrl: string, language: string) {
    this.loadCssFile(this.getApiAssetUrl(cssAssetUrl, language));
  }

  protected loadCssFile(fileUri: string) {
    if (!document.getElementById(fileUri)) {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.id = fileUri;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = fileUri;
      link.media = 'all';
      head.appendChild(link);
    }
  }
}
