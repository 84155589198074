import { Component, Inject, Input } from '@angular/core';
import { IDV_ENV, IdvEnvironment } from '../../interfaces/idv-environment';

@Component({
  selector: 'idv-login-steps',
  templateUrl: './login-steps.component.html',
  styleUrls: ['./login-steps.component.scss'],
})
export class LoginStepsComponent {
  @Input() public loginEntered = false;

  loginSteps: number[];

  constructor(@Inject(IDV_ENV) environment: IdvEnvironment) {
    this.loginSteps =
      environment.flavor.loginStepCount === 3 ? [0, 1, 2] : [0, 1, 2, 3];
  }
}
