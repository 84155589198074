import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { WordBreakOpportunityService } from '../core/services/misc/word-break-opportunity.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[wordBreakingHtml]',
})
export class WordBreakingDirective implements OnChanges {
  @Input() wordBreakingHtml: string;

  constructor(
    private wordBreakOpportunityService: WordBreakOpportunityService
  ) {}

  @HostBinding('class.wordbreak-by-browser-dictionary') cssEnabled = true;

  @HostBinding('innerHtml')
  innerHtml: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['wordBreakingHtml']) {
      this.innerHtml =
        this.wordBreakOpportunityService.enhanceWithLineBreakOpportunities(
          this.wordBreakingHtml
        );
    }
  }
}
