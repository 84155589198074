import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { initializeLocale } from './initialize-locale';
import { WindowService } from './services/misc/window.service';
import { MrcCaptchaModule } from './captcha/mrc-captcha.module';
import { DocumentTitleService } from './services/i18n/document-title.service';
import { LanguageService } from './services/language/language.service';

export function HttpLoaderFactory(
  httpClient: HttpClient,
  windowService: WindowService
) {
  const cacheBuster =
    windowService.window.location.hostname === 'localhost'
      ? ''
      : '?cb=' + Date.now().toString(36);
  return new TranslateHttpLoader(
    httpClient,
    'assets/i18n/',
    '.json' + cacheBuster
  );
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, WindowService],
      },
    }),
    MrcCaptchaModule.forRoot(),
  ],
  declarations: [],
  providers: [],
  exports: [],
})
export class MrcCoreModule {
  static forRoot(): ModuleWithProviders<MrcCoreModule> {
    return {
      ngModule: MrcCoreModule,
      providers: [
        {
          provide: LOCALE_ID,
          deps: [TranslateService],
          useFactory: initializeLocale,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (ls: LanguageService) => () => ls.init(),
          deps: [LanguageService],
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (ds: DocumentTitleService) => () => {
            ds.init();
          },
          deps: [DocumentTitleService],
          multi: true,
        },
      ],
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: MrcCoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'MrcCoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}

export * from './interceptors';
export * from './services/error/expected-error.service';
export * from './services/error/uncaught-error.service';
export * from './services/error/uncaught-error-handler';
export * from './services/misc/activity-tracking.service';
export * from './services/misc/browser-sniffer.service';
export * from './services/misc/precision-date.service';
export * from './services/misc/window.service';
export * from './services/misc/word-break-opportunity.service';
export * from './services/misc/prevent-browser-back.guard';
export * from './services/storage/local-storage.service';
export * from './services/storage/session-storage.service';
export * from './services/tracking/tracking.service';
export * from './validation';
export * from './initialize-locale';
