<div class="row">
  <div class="col-12">
    <h2 class="screen-header">
      {{ 'add-diseases.add-headline' | translate }}
    </h2>

    <idv-additional-diseases-step
      [hasExistingDiseases]="hasExistingDiseases"
      [selectedProducts]="selectedProducts"
      [isGeneralPhysicianRequest]="isGeneralPhysicianRequest"
    >
    </idv-additional-diseases-step>

    <div class="mt-5">
      <idv-search-disease
        (added)="goToRisk($event)"
        (onCancel)="goToNextStep()"
      ></idv-search-disease>
    </div>
  </div>
</div>
