<div
  *ngIf="isShown"
  class="cookie-notification navbar navbar-fixed-bottom"
>
  <div class="container my-3">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="hideCookieDisclaimer()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <small [innerHTML]="cookieNotificationTranslated"> </small>
  </div>
</div>
