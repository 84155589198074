<!-- 
  ngIf is necessary in order to create an entirely new form. 
  * If the same form is used, the submitted state is not removed from the form when editing its controls 
-->
<div>
  <div class="selected-risks">
    <div
      class="selected-risk d-flex justify-content-between align-items-center"
      *ngFor="let selectedRisk of selectedRisks"
    >
      <div>{{ selectedRisk }}</div>
      <div
        class="remove-risk-button"
        (click)="removeRiskFromArray(selectedRisk)"
      >
        <mat-icon>close</mat-icon>
      </div>
    </div>
  </div>

  <idv-search-risk-control
    (onRiskSelected)="onRiskSelected($event)"
    [confirmUnknownRisks]="true"
  ></idv-search-risk-control>
</div>
