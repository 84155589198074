<div class="site">
  <div class="landing-page">
    <div class="landing-page-header justify-content-center align-items-stretch">
      <div class="container">
        <div class="row">
          <div class="col">
            <idv-tenant-panel
              [showLanguageSelector]="showLanguageSelector$ | async"
            ></idv-tenant-panel>
          </div>
        </div>
      </div>
    </div>
    <div class="jumbotron dark-background">
      <div class="justify-content-between">
        <div class="container container-jumbotron">
          <div class="row row-jumbotron tenant-stage">
            <div class="col-lg-6 col-xl-7 col-jumbotron">
              <div class="slogan-explanation-container">
                <div class="d-inline-block">
                  <h1 class="slogan">
                    {{ 'landing-page.slogan' | translate }}
                  </h1>
                  <p class="explanation">
                    {{ 'landing-page.explanation' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-5">
              <div class="steps-and-login-wrapper row">
                <div class="col-12 col-md-6 col-lg-12">
                  <idv-login-steps
                    *ngIf="showLoginSteps; else largeIntroText"
                    [loginEntered]="loginEntered"
                  ></idv-login-steps>
                </div>
                <div class="col-12 col-md-6 col-lg-12">
                  <ng-template #largeIntroText>
                    <h1 class="login-header">
                      {{ 'login.intro-text' | translate }}
                    </h1>
                  </ng-template>
                  <div>
                    <idv-login-dependent
                      [tenant]="tenant"
                      [accessToken]="accessToken$ | async"
                      (onLoginStarted)="loginEntered = true"
                    ></idv-login-dependent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div
        *ngIf="landingTextTranslation$ | async as landingText"
        class="row text-container"
      >
        <div
          class="col-12 certificate"
          [ngClass]="(hasCertificateIcon$ | async) ? 'col-md-4' : 'col-md-6'"
        >
          <div class="info-text">
            <span>{{ landingText?.gdprInfo }}</span>
            <span
              ><a
                idvClickTracker="idv_open_gdpr_dialog?source=landing_page"
                [innerHTML]="landingText?.gdprInfoLink"
                (click)="openGDPRDialog()"
              ></a
            ></span>
          </div>
        </div>

        <div
          *ngIf="hasCertificateIcon$ | async"
          class="col-12 col-md-4 certificate certificate-centered"
        >
          <img
            [src]="certificateIcon$ | async"
            class="BadgeGDPR"
          />
        </div>

        <div
          class="col-12 certificate"
          [ngClass]="(hasCertificateIcon$ | async) ? 'col-md-4' : 'col-md-6'"
        >
          <div
            class="info-text"
            idvClickTracker="idv_open_tuev_info"
            [innerHTML]="landingText?.tuevInfo"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <idv-material-footer [displayFAQs]="'true'"></idv-material-footer>
</div>
