import { Injectable } from '@angular/core';
import { filter, map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { TenantService } from '../tenant/tenant.service';
import { combineLatest, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Config } from '../../interfaces';
import { LanguageService } from '../../../../../core-lib/src/lib/core/services/language/language.service';

@Injectable({ providedIn: 'root' })
export class TenantConfigService {
  tenantConfig$: Observable<Config>;

  constructor(
    private apiService: ApiService,
    private tenantService: TenantService,
    private languageService: LanguageService
  ) {
    this.tenantConfig$ = combineLatest([
      this.tenantService.tenant,
      this.languageService.applicationLanguageAvailable$.pipe(
        map(() => this.languageService.getCurrentLanguage())
      ),
    ]).pipe(
      distinctUntilChanged(
        (oldValue, newValue) =>
          oldValue[0] === newValue[0] && oldValue[1] === newValue[1]
      ),
      filter(([tenant, language]) => !!tenant && !!language),
      switchMap(([tenant, language]) => {
        return this.apiService.loadTenantConfiguration$(tenant, language);
      })
    );
  }
}
