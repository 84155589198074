import { timer as observableTimer, Observable, TimeoutError } from 'rxjs';

import { mergeMap, timeout, retryWhen } from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';

import { HttpRetryParams } from '../../interfaces/http-retry-params';

const defaultParams: HttpRetryParams = {
  timeoutInMs: 30000,
  retryIntervalInMs: 1000,
  maximumRetries: 2,
};

// not @Injectable(), have to use a factory to provide an instance of this class!
export class RetryInterceptor implements HttpInterceptor {
  constructor(private params?: HttpRetryParams) {
    if (!this.params) {
      this.params = defaultParams;
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(this.params.timeoutInMs),
      retryWhen((errors) => {
        let retries = 0;

        return errors.pipe(
          mergeMap((error) => {
            if (
              isRetriableError(error) &&
              retries < this.params.maximumRetries
            ) {
              retries++;
              return observableTimer(this.params.retryIntervalInMs);
            }

            throw error;
          })
        );
      })
    );
  }
}

function isRetriableError(error: any) {
  return (
    error instanceof TimeoutError ||
    (error instanceof HttpErrorResponse && isNetworkError(error.error))
  );
}

function isNetworkError(error: any) {
  return error instanceof ProgressEvent && error.type === 'error';
}
