import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PrecisionDate, ValueRange } from '../../interfaces';
import { DatePrecision } from '../../interfaces/date-precison';

@Component({
  selector: 'idv-date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.scss'],
})
export class DateControlComponent implements OnChanges {
  @Input() precision: DatePrecision;
  @Input() dateRange: ValueRange<Date>;
  @Input() monthRequiredFromYear: number;
  @Input() formControlToUse: UntypedFormControl;
  @Input() showErrors: boolean; // true at least when form has been submitted

  between: ValueRange<PrecisionDate>;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateRange']) {
      this.between = {
        min: this.dateRange ? new PrecisionDate(this.dateRange.min) : null,
        max: this.dateRange ? new PrecisionDate(this.dateRange.max) : null,
      };
    }
  }
}
