<div class="d-flex flex-row">
  <button
    *ngIf="!hideBackButton"
    id="btn_idv_previous_question"
    type="button"
    mat-stroked-button
    class="idv-form-button idv-form-back-button"
    color="primary"
    [disabled]="!backButtonRouterLink"
    [routerLink]="backButtonRouterLink"
  >
    <span class="d-none d-md-block">{{
      'question-group.previous' | translate
    }}</span>
    <mat-icon class="d-md-none">arrow_back</mat-icon>
  </button>
  <button
    id="btn_idv_next_question"
    type="submit"
    mat-raised-button
    class="idv-form-button"
    color="primary"
    [disabled]="!canProceed"
  >
    <span>{{ 'question-group.next' | translate }}</span>
    <idv-demo-label inBrackets></idv-demo-label>
  </button>
</div>
