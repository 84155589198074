import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[onlyNumbers]',
})
export class OnlyNumbersDirective {
  el: any;

  constructor(protected elementRef: ElementRef, protected control: NgControl) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (this.isSpecialKey(e)) {
      return;
    }

    if (this.isCopyPasting(e)) {
      return;
    }

    const key = e['char'] ? e['char'] : e.key;
    if (/^[0-9\.,]$/.test(key)) {
      return;
    }

    e.preventDefault();
  }

  @HostListener('keyup', ['$event']) onKeyUp(e: any) {
    if (this.isPasting(e)) {
      this.setValue(this.removeAlphaNumericals(e.target.value));
    }
  }

  private isSpecialKey(e: KeyboardEvent): boolean {
    return [8, 9, 13, 27, 35, 36, 37, 38, 39, 46].indexOf(e.keyCode) !== -1;
  }

  private isCopyPasting(e: KeyboardEvent): boolean {
    return e.ctrlKey === true && [65, 67, 86, 88].indexOf(e.keyCode) !== -1;
  }

  private isPasting(e: KeyboardEvent): boolean {
    return e.ctrlKey === true && e.keyCode === 86;
  }

  private removeAlphaNumericals(val: string): string {
    return val.replace(/[^\d,\.-]/g, '');
  }

  private setValue(value: any) {
    if (this.control.control) {
      this.control.control.setValue(value);
    } else {
      this.el.value = value;
    }
  }
}
