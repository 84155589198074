import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';

// will prevent user from going back
@Injectable()
export class PreventBrowserBackGuard {
  private backClicked: boolean | null = null;

  constructor(private locationStrategy: LocationStrategy) {}

  initializeAndActivate() {
    if (this.backClicked === null) {
      this.backClicked = false;
      // check if back or forward button is pressed.
      this.locationStrategy.onPopState(() => {
        this.backClicked = true;
        return false;
      });
    }
  }

  canDeactivate() {
    if (this.backClicked === true) {
      this.backClicked = false;
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }
}
