import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  constructor() {}

  setItem(key: string, data: string) {
    sessionStorage.setItem(key, data);
  }

  getItem(key: string) {
    return sessionStorage.getItem(key);
  }

  setObject<T>(key: string, object: T) {
    sessionStorage.setItem(key, JSON.stringify(object));
  }

  getObject<T>(key: string): T {
    const value = this.getItem(key);
    if (!value) {
      return null;
    }
    return JSON.parse(value) as T;
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  clear() {
    sessionStorage.clear();
  }

  public removeItemByKey(key: string): void {
    this.removeItem(key);
  }

  public removeAllItems(): void {
    this.clear();
  }
}
