// parts of a route, some of them can be put together, e.g. risk/add
export enum AppRoute {
  login = '/',
  secondAuthFactor = '2fa',
  logout = 'logout',
  dataPrivacy = 'dataprivacy',
  payment = 'payment', // physician only
  intro = 'intro',
  prologue = 'prologue',
  overview = 'overview', // a bad name, should be something like 'main'
  add = 'add',
  replace = 'replace',
  risk = 'risk',
  nextQuestion = 'next',
  questionnaireSummary = 'summary',
  complete = 'complete',
  download = 'download',
  redirect = 'redirect',
  underwriterHints = 'underwriter-hints',
}
