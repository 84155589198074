import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MrcTrackingService } from '../../../../../core-lib/src/lib/core/services/tracking/tracking.service';

export interface ClickTrackerInput {
  name: string;
  properties?: { [key: string]: string };
}

@Directive({
  selector: '[idvClickTracker]',
})
export class ClickTrackerDirective {
  // can handle:
  //
  // - simple names, e.g. "idv_home_clicked"
  // - names with params, e.g. "idv_abc?source=sidenav&p1=42"
  // - objects with interface ClickTrackerInput
  //
  // for examples see unit-test
  @Input()
  idvClickTracker: string | ClickTrackerInput;

  constructor(
    private trackingService: MrcTrackingService,
    private router: Router
  ) {}

  @HostListener('click')
  clicked() {
    try {
      const { name, properties } = this.convertInputToClickTrackerInput();
      this.trackingService.trackEvent(name, properties);
    } catch (e) {
      this.trackingService.trackException(e, {
        handledAt: '[idvClickTracker]',
      });
    }
  }

  private convertInputToClickTrackerInput(): ClickTrackerInput {
    if (typeof this.idvClickTracker === 'string') {
      const qPos = this.idvClickTracker.indexOf('?');
      if (qPos < 0) {
        return { name: this.idvClickTracker || 'undefined' };
      }
      const name = this.idvClickTracker.substring(0, qPos);
      const tree = this.router.parseUrl(this.idvClickTracker);
      const properties = tree.queryParams;
      return { name, properties };
    }
    return this.idvClickTracker || { name: 'undefined' };
  }
}
