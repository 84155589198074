import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Question } from '../../../../interfaces';

@Component({
  selector: 'idv-dynamic-control',
  templateUrl: './dynamic-control.component.html',
})
export class DynamicControlComponent implements OnChanges {
  @Input() question: Question;
  @Input() formControlToUse: UntypedFormControl;
  @Input() showErrors: boolean; // aka. form submitted

  monthRequiredFromYear: number | null;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['question']) {
      if (
        this.question &&
        this.question.precision &&
        this.question.precision.unit === 'monthOptional'
      ) {
        const currentYear = new Date().getUTCFullYear();
        this.monthRequiredFromYear =
          currentYear + this.question.precision.monthFrom;
      }
    }
  }
}
