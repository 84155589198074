import { Component, EventEmitter, Optional, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdvBreakpoints } from '../../services/responsive/breakpoints';

@Component({
  selector: 'idv-close-modal-header',
  templateUrl: './close-modal-header.component.html',
  styleUrls: ['./close-modal-header.component.scss'],
})
export class CloseModalHeaderComponent {
  @Output()
  close = new EventEmitter<void>();

  visible$: Observable<boolean>;

  constructor(
    @Optional() private dialogRef: MatDialogRef<any>,
    breakpointObserver: BreakpointObserver
  ) {
    this.visible$ = breakpointObserver
      .observe(IdvBreakpoints.small)
      .pipe(map((state: BreakpointState) => state.matches));
  }

  onClick() {
    if (this.close.observers.length) {
      this.close.emit();
    } else if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
