<idv-questionnaire-heading
  [showSummary]="false"
  [risk]="risk"
  [displayContextMenu]="false"
></idv-questionnaire-heading>

<ng-container>
  <div class="row">
    <div class="col">
      <h3 class="mt-0">{{ replaceRiskHeader }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col form-group">
      <div class="mt-5">
        <idv-search-disease
          [riskToReplace]="risk"
          [cancelButtonText]="'shared.cancel' | translate"
          [confirmButtonText]="
            'replace-medical-risk-questionnaire.submit' | translate
          "
          (added)="onRiskReplaced($event)"
          (onCancel)="onRiskReplaceCancelled()"
        >
        </idv-search-disease>
      </div>
    </div>
  </div>
</ng-container>
