import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectableValue } from '../../interfaces/selectable-value';

@Component({
  selector: 'idv-radio-list-control',
  templateUrl: './radio-list-control.component.html',
  styles: [
    `
      .mat-radio-button {
        font-weight: 500 !important;
      }
    `,
  ],
})
export class RadioListControlComponent {
  @Input() selectableValues: SelectableValue[];
  @Input() formControlToUse: UntypedFormControl;
  @Input() radioListName: string;

  constructor() {}
}
