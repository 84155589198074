import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { UncaughtErrorService, WindowService } from '../../../../../core-lib';
import { IDV_ENV, IdvEnvironment } from '../../interfaces/idv-environment';
import { Observable, Subject } from 'rxjs';
import { TenantConfigService } from '../../services/config/tenant-config.service';

@Component({
  selector: 'idv-error-screen',
  templateUrl: './error-screen.component.html',
  styleUrls: ['./error-screen.component.scss'],
})
export class ErrorScreenComponent implements OnInit, OnDestroy {
  advancedErrorMode: boolean;

  public applicationVersions: Observable<
    Array<{ name: string; version: string }>
  >;

  uncaughtError = this._uncaughtErrorService.uncaughtError;

  readonly currentTime: Date;

  private _destroyed$ = new Subject<void>();

  constructor(
    private _uncaughtErrorService: UncaughtErrorService,
    private _windowService: WindowService,
    private _tenantConfigService: TenantConfigService,
    @Inject(IDV_ENV) environment: IdvEnvironment
  ) {
    this.advancedErrorMode = environment.isErrorDisplayedAsDefault;
    this.currentTime = new Date();
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyEvent(event: KeyboardEvent) {
    if (this.isShortcutPressed(event)) {
      event.preventDefault();
      this.toggleAdvancedErrorMode();
    }
  }

  ngOnInit() {
    this.applicationVersions = this._tenantConfigService.tenantConfig$.pipe(
      takeUntil(this._destroyed$),
      map((config) => this.extractNamesAndVersions(config.versionInfo))
    );
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  get currentUrl() {
    return this._windowService.window.location.href;
  }

  reloadWindow() {
    this._windowService.window.location.reload();
  }

  toggleAdvancedErrorMode(): void {
    this.advancedErrorMode = !this.advancedErrorMode;
  }

  private extractNamesAndVersions(versionInfo: {
    [applicationName: string]: string;
  }): { name: string; version: string }[] {
    return Object.keys(versionInfo).map((applicationName) => ({
      name: applicationName,
      version: versionInfo[applicationName],
    }));
  }

  private isShortcutPressed(event: KeyboardEvent): boolean {
    return event.key === 'b' && event.ctrlKey && event.altKey;
  }
}
