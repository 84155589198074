import { AbstractControl, ValidationErrors } from '@angular/forms';

export function bloodpressureValuesRequiredValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (
    (control.value[0] && control.value[1]) ||
    (!control.value[0] && !control.value[1])
  ) {
    const errorSystolic = control['controls'][0].errors;
    const errorDiastolic = control['controls'][1].errors;
    if (errorSystolic) {
      control['controls'][0].setErrors({
        completeBloodpressureValuesRequired: null,
      });
      control['controls'][0].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
    if (errorDiastolic) {
      control['controls'][1].setErrors({
        completeBloodpressureValuesRequired: null,
      });
      control['controls'][1].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
    return null;
  }

  if (!control.value[0] && control.value[1]) {
    control['controls'][0].setErrors({
      completeBloodpressureValuesRequired: true,
    });
  }
  if (control.value[0] && !control.value[1]) {
    control['controls'][1].setErrors({
      completeBloodpressureValuesRequired: true,
    });
  }
  return { completeBloodpressureValuesRequired: true };
}

export function bloodpressureInputRequiredValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (
    !control.value[0][0] &&
    !control.value[0][1] &&
    !control.value[1][0] &&
    !control.value[1][1]
  ) {
    return { completeBloodpressureInputRequired: true };
  }
  return null;
}

// NOTE: this function has been regenerated from NPM package, because
//       it was lost (published version 0.0.4 does not match to master of 2019.05.14)
export function bloodPressure24hInputRequiredValidator(
  control: AbstractControl
): ValidationErrors | null {
  const systolicValue = control['controls']['bloodPressureReadings'].value[0];
  const diastolicValue = control['controls']['bloodPressureReadings'].value[1];
  // Only invalid if both are null Different validator
  if (!systolicValue && !diastolicValue) {
    return { required: true };
  }
  return null;
}

export function systolicMinusDiastolicGreaterThan(
  control: AbstractControl
): ValidationErrors | null {
  if (control.value[0] && control.value[1]) {
    if (Number(control.value[0]) - Number(control.value[1]) > 5) {
      const errorsSystolic = control['controls'][0].errors;
      const errorsDiastolic = control['controls'][1].errors;
      if (errorsDiastolic && errorsSystolic) {
        control['controls'][0].setErrors({
          systolicMinusDiastolicGreaterThan: null,
        });
        control['controls'][1].setErrors({
          systolicMinusDiastolicGreaterThan: null,
        });
        control['controls'][0].updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
        control['controls'][1].updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      }
      return null;
    }

    control['controls'][0].setErrors({
      systolicMinusDiastolicGreaterThan: true,
    });
    control['controls'][1].setErrors({
      systolicMinusDiastolicGreaterThan: true,
    });
    return { systolicMinusDiastolicGreaterThan: true };
  }

  return null;
}
