<div class="row">
  <div class="col-12">
    <h2 class="screen-header">{{ 'pdf-download.headline' | translate }}</h2>
  </div>
</div>

<div class="row mt-3">
  <div class="col-12">
    <h3>{{ 'pdf-download.explanation-text' | translate }}</h3>
  </div>
</div>

<div class="row mt-3">
  <div class="col-12 col-md-6">
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="saveSummaryToFileSystem()"
      idvClickTracker="idv_save_summary_btn_clicked"
      class="button download-btn"
    >
      <div class="icon"><mat-icon>get_app</mat-icon></div>
      {{ 'pdf-download.download-button' | translate }}
    </button>
    <button
      type="button"
      mat-stroked-button
      color="primary"
      class="button"
      (click)="redirectToLogoutPage()"
      idvClickTracker="idv_pdf_logout_btn_clicked"
    >
      {{ 'pdf-download.logout' | translate }}
    </button>
  </div>
</div>
