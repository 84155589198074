import { Questionnaire } from './questionnaire';
import { RiskSource } from './risk-source.type';

export interface Risk {
  id?: string;
  riskClass: string;
  labels: string[];
  source: RiskSource;
  questionnaire?: Questionnaire;
  routedRisks?: Risk[];
  subStatus?: {
    readdedRisk?: string;
  };
  askOnlyGdq: boolean;
}
