import { Location } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { MrcTrackingService } from '../../../../../../core-lib/src/lib/core/services/tracking/tracking.service';
import { ResponsiveService } from '../../../services/responsive/responsive.service';
import { AuthenticationService } from '../../authentication.service';
import {
  IAuthenticationResult,
  AuthenticationResultType,
} from '../../model/authentication-result';
import { SessionErrorMessageComponent } from '../../session-error-message/session-error-message.component';
import { StepStateService } from '../../../services/step-navigation/step-state.service';
import { TenantConfigService } from '../../../services/config/tenant-config.service';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from '../../../services/tenant/tenant.service';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { SecondFactorAuthenticationFormValue } from '../../model/form-value/second-factor-authentication.form-value';

const captchaRequiredError = { captchaRequired: true };

export abstract class BaseSecondFactorComponent {
  formGroup: UntypedFormGroup;
  showErrors = false;
  showCaptcha = false;
  siteKey: string;
  canProceed: boolean;
  tenant: string;
  destroyed$ = new Subject<void>();

  protected constructor(
    protected _tenantConfigService: TenantConfigService,
    protected _authenticationService: AuthenticationService,
    protected _trackingService: MrcTrackingService,
    protected _location: Location,
    protected _dialog: MatDialog,
    protected _responsiveService: ResponsiveService,
    protected _stepStateService: StepStateService,
    protected _translateService: TranslateService,
    protected _tenantService: TenantService
  ) {}

  onInit() {
    this._tenantConfigService.tenantConfig$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((config) => {
        this.siteKey = config.captcha.siteKey;
      });

    this._tenantService.tenant.pipe(take(1)).subscribe((tenant) => {
      this.tenant = tenant;
    });

    this.formGroup = this.createFormGroup();
    this.formGroup.statusChanges.subscribe((status) => {
      this.canProceed = status === 'VALID';
    });
  }

  abstract createFormGroup(): UntypedFormGroup;

  abstract getFormResult(): SecondFactorAuthenticationFormValue;

  abstract setAccessTokenErrors(details: IAuthenticationResult): void;

  abstract getEndpointSuffix(): string;

  get captchaTokenErrors() {
    return this.formGroup.controls.captchaToken.errors
      ? captchaRequiredError
      : null;
  }

  get currentLanguage(): Observable<string> {
    return this._translateService.onLangChange.pipe(
      takeUntil(this.destroyed$),
      map((evt) => evt.lang),
      startWith(this._translateService.currentLang)
    );
  }

  submit() {
    if (this.canProceed) {
      const payload = this.getFormResult();

      this._authenticationService
        .twoFactorLoginSecondStep(
          payload,
          this.getEndpointSuffix(),
          this.tenant
        )
        .subscribe((result) => {
          if (result.success) {
            this._stepStateService.navigateToNextStep();
          } else {
            this.onSubmitError(result);
          }
        });
    } else {
      this.showErrors = true;
    }
  }

  private onSubmitError(result: IAuthenticationResult) {
    this.formGroup.controls.captchaToken.reset();
    this.showErrors = true;

    if (result.resultType === AuthenticationResultType.captcha) {
      this.showCaptcha = true;
      this._trackingService.trackEvent(
        'idv_login_failed_second_factor_showing_captcha'
      );
    } else if (result.resultType === AuthenticationResultType.expired) {
      this._trackingService.trackEvent(
        'idv_login_failed_second_factor_expired'
      );
      this.showSessionExpiredModal();
    } else {
      this._trackingService.trackEvent('idv_login_failed_second_factor');
      this.setAccessTokenErrors(result);
    }
  }

  private showSessionExpiredModal() {
    const dialogRef: MatDialogRef<SessionErrorMessageComponent> =
      this._dialog.open(
        SessionErrorMessageComponent,
        this._responsiveService.enhanceDialogConfig({
          panelClass: 'big-modal',
          data: 'two-factor-authentication.session-expired',
        })
      );
    dialogRef.afterClosed().subscribe(() => this._location.back());
  }

  onDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
