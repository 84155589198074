<select
  class="form-control"
  [formControl]="formControlToUse"
>
  <option
    [value]="null"
    disabled
  >
    {{ 'dropdown-list-control.please-select' | translate }}
  </option>
  <option
    *ngFor="let selectableValue of selectableValues"
    [value]="selectableValue.id"
  >
    {{ selectableValue.displayName }}
  </option>
</select>
