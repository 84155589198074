<ng-template payment-component-host></ng-template>

<div
  id="idv_payment_step_paper_download_section"
  class="paper-download-section"
  *ngIf="showPaperDownload$ | async"
>
  <div class="d-flex flex-column pt-5">
    <div class="form-field-m d-flex flex-row pt-5 align-items-center">
      <div class="flex-fill">
        <div class="horizontal-line"></div>
      </div>
      <div class="px-4 or-wrapper">
        {{ 'payment-data-view.or' | translate }}
      </div>
      <div class="flex-fill">
        <div class="horizontal-line"></div>
      </div>
    </div>
  </div>
  <div class="form-field-m d-flex flex-column align-items-center">
    <div class="pt-5">
      <a
        (click)="downloadReport()"
        idvClickTracker="idv_payment_download_paper_popup?source=content"
      >
        {{ 'payment-data-view.download_report' | translate }}
      </a>
    </div>
  </div>
</div>
