import {
  Component,
  OnDestroy,
  ViewChild,
  forwardRef,
  HostBinding,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { RiskListItem } from '../../interfaces';
import { SearchRiskControlComponent } from '../search-risk/search-risk-control.component';

@Component({
  selector: 'idv-enhanced-search-control',
  templateUrl: './enhanced-search-control.component.html',
  styleUrls: ['./enhanced-search-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnhancedSearchControlComponent),
      multi: true,
    },
  ],
})
export class EnhancedSearchControlComponent
  implements OnDestroy, ControlValueAccessor
{
  @ViewChild(SearchRiskControlComponent)
  searchRiskControl: SearchRiskControlComponent;

  @HostBinding('class.disabled') get disabled() {
    return this._disabled;
  }

  private _destroyed$ = new Subject<void>();
  private _disabled = false;

  public selectedRisks = new Array<string>();
  public onChange = (_: any) => {};
  public onTouched = () => {};

  constructor() {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onRiskSelected(risk: string | RiskListItem) {
    if (!!risk) {
      this.addToSelectedRisksArray(
        this.isRiskListItem(risk) ? risk.label : risk
      );
      this.searchRiskControl.searchRiskControl.setValue('');
    }
  }

  public removeRiskFromArray(riskLabel: string) {
    if (this._disabled) {
      return;
    }
    const index = this.selectedRisks.indexOf(riskLabel);
    this.selectedRisks.splice(index, 1);
    this.onSelectionChanged();
  }

  public writeValue(value: Array<string>) {
    this.selectedRisks = value;
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public setDisabledState(_isDisabled: boolean): void {
    this._disabled = _isDisabled;
  }

  private addToSelectedRisksArray(riskLabel: string) {
    if (this._disabled) {
      return;
    }
    this.selectedRisks = this.selectedRisks ?? new Array<string>();

    if (
      this.selectedRisks.findIndex(
        (sr) => sr.trim().toLowerCase() === riskLabel.trim().toLowerCase()
      ) === -1
    ) {
      this.selectedRisks.push(riskLabel);
      this.onSelectionChanged();
    }
  }

  private isRiskListItem(input: RiskListItem | string): input is RiskListItem {
    return (input as RiskListItem).label !== undefined;
  }

  private onSelectionChanged() {
    this.onChange(this.selectedRisks);
  }
}
