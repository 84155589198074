import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { MrcTrackingService } from '../../../../../../../../core-lib/src/lib/core/services/tracking/tracking.service';
import {
  RemoveRiskReasonType,
  Risk,
} from '../../../../../../../../idv-lib/src/lib/interfaces';
import { RequestService } from '../../../../../../../../idv-lib/src/lib/services/request/request.service';
import { StepStateService } from '../../../../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';

@Component({
  templateUrl: './remove-risk-dialog.component.html',
})
export class RemoveRiskDialogComponent {
  showErrors = false;
  reason: RemoveRiskReasonType;
  riskLabel: string;

  constructor(
    public dialogRef: MatDialogRef<RemoveRiskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private requestService: RequestService,
    private stepStateService: StepStateService,
    private trackingService: MrcTrackingService
  ) {
    this.riskLabel = this.data.risk.labels.join(', ');
  }

  get risk(): Risk {
    return this.data.risk;
  }

  setReason(reason: RemoveRiskReasonType) {
    this.reason = reason;
    this.showErrors = false;
  }

  private proceed = (reAnswerRiskIds: Array<string>) => {
    this.reason = null;
    this.dialogRef.close({ reAnswerRiskIds });
    this.stepStateService.goToNextRiskStep();
  };

  removeRisk() {
    if (this.risk.source === 'added-by-underwriter') {
      if (this.reason) {
        this.requestService
          .removeMedicalRisk(this.risk.id, this.reason)
          .pipe(tap((response) => this.proceed(response.reAnswerRiskIds)))
          .subscribe();

        this.trackingService.trackEvent('idv_remove_risk', {
          source: this.risk.source,
          riskClass: this.risk.riskClass,
          reason: this.reason,
        });
      } else {
        this.showErrors = true;
      }
    } else if (this.risk.source === 'added-by-physician') {
      this.requestService
        .removeMedicalRisk(this.risk.id)
        .pipe(tap((response) => this.proceed(response.reAnswerRiskIds)))
        .subscribe();

      this.trackingService.trackEvent('idv_remove_risk', {
        source: this.risk.source,
        riskClass: this.risk.riskClass,
      });
    }
  }
}
