<idv-master-detail-main
  [hierarchicalStepStates]="getHierarchicalStepStates()"
  [applicantInfo]="applicantInfo$ | async"
  [showPaperDownload]="(showPaperDownload$ | async) && !isDownloadPageActive"
  (downloadPaper)="downloadPaper()"
>
  <phy-header
    [applicantInfo]="applicantInfo$ | async"
    [showPaperDownload]="(showPaperDownload$ | async) && !isDownloadPageActive"
    (downloadPaper)="downloadPaper()"
  >
  </phy-header>
</idv-master-detail-main>
