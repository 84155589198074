import {
  Component,
  OnDestroy,
  OnInit,
  ComponentFactoryResolver,
  ViewChild,
  Type,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, tap, map } from 'rxjs/operators';
import { TenantConfigService } from '../../../../../../idv-lib/src/lib/services/config/tenant-config.service';
import { PaymentMethodComponentResolver } from './payment-methods/services/payment-method-component-resolver.service';
import { BasePaymentMethodComponent } from './payment-methods/base.payment-method.component';
import { MatDialog } from '@angular/material/dialog';
import { DownloadPdfDialogComponent } from '../../../components/download-pdf-dialog/download-pdf-dialog.component';
import { ResponsiveService } from '../../../../../../idv-lib/src/lib/services/responsive/responsive.service';
import { PaymentComponentHostDirective } from './directives/payment-host.directive';
import { BasePaymentData } from '../../../../../../idv-lib/src/lib/interfaces/base.payment-data';

@Component({
  selector: 'idv-payment-step',
  templateUrl: './payment-step.component.html',
  styleUrls: ['./payment-step.component.scss'],
})
export class PaymentStepComponent implements OnInit, OnDestroy {
  public showPaperDownload$: Observable<boolean>;

  private _destroyed$ = new Subject<void>();

  @ViewChild(PaymentComponentHostDirective, { static: true })
  paymentComponentHost: PaymentComponentHostDirective;

  constructor(
    private _paymentMethodComponentResolver: PaymentMethodComponentResolver,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _tenantConfigService: TenantConfigService,
    private _dialog: MatDialog,
    private _responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    this._tenantConfigService.tenantConfig$
      .pipe(
        takeUntil(this._destroyed$),
        tap((tenantConfig) => {
          const paymentComponent = this._paymentMethodComponentResolver.resolve(
            tenantConfig.paymentInformationFormat
          );
          if (paymentComponent != null) {
            this.setPaymentMethodComponent(paymentComponent);
          }
        })
      )
      .subscribe();

    this.showPaperDownload$ = this._tenantConfigService.tenantConfig$.pipe(
      map((config) => config.allowsPaperDownload)
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public downloadReport(): void {
    this._dialog.open(
      DownloadPdfDialogComponent,
      this._responsiveService.enhanceDialogConfig({
        panelClass: 'big-modal',
        data: {},
      })
    );
  }

  private setPaymentMethodComponent(
    component: Type<BasePaymentMethodComponent<BasePaymentData>>
  ) {
    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.paymentComponentHost.viewContainerRef;

    viewContainerRef.clear();
    viewContainerRef.createComponent(componentFactory);
  }
}
