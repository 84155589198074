import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { DemoModeService } from '../../services/config/demo-mode.service';

@Component({
  selector: 'idv-demo-label',
  templateUrl: './demo-label.component.html',
})
export class DemoLabelComponent implements OnChanges {
  @Input() inBrackets: boolean | string = false;

  readonly isDemoMode: Observable<boolean>;
  isInBrackets: boolean;

  constructor(demoModeService: DemoModeService) {
    this.isDemoMode = demoModeService.isDemoTenant;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inBrackets']) {
      this.isInBrackets =
        (typeof this.inBrackets === 'string' && this.inBrackets !== 'false') ||
        (typeof this.inBrackets === 'boolean' && this.inBrackets !== false);
    }
  }
}
