import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MrcMaterialModule } from '../mrc-material.module';
import { GoTopButtonComponent } from './go-top-button/go-top-button.component';
import { ArrayToStringPipe } from './pipes/array-to-string.pipe';
import { WordBreakingDirective } from './word-breaking.directive';
import { FullscreenActivityIndicatorComponent } from './fullscreen-activity-indicator/fullscreen-activity-indicator.component';
import { ActivityIndicatorComponent } from './activity-indicator/activity-indicator.component';
import { ErrorHelpBlockComponent } from './error-help-block/error-help-block.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { BlockGroupedDirective } from './block-grouped.directive';
import { NumberFormatDirective } from './number-format.directive';

const exportedDeclarations = [
  WordBreakingDirective,
  FullscreenActivityIndicatorComponent,
  ActivityIndicatorComponent,
  ErrorHelpBlockComponent,
  GoTopButtonComponent,
  BlockGroupedDirective,
  NumberFormatDirective,
  HighlightPipe,
  ArrayToStringPipe,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MrcMaterialModule,
  ],
  declarations: [...exportedDeclarations],
  exports: [...exportedDeclarations],
  providers: [HighlightPipe, ArrayToStringPipe],
})
export class MrcComponentsModule {}

export * from './fullscreen-activity-indicator/fullscreen-activity-indicator.component';
export * from './pipes/highlight.pipe';
export * from './pipes/array-to-string.pipe';
