import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IdvComponentsModule } from '../../../../idv-lib/src/lib/components/idv-components.module';
import { IdvMaterialModule } from '../../../../idv-lib/src/lib/idv-material.module';
import { DownloadPdfDialogComponent } from './download-pdf-dialog/download-pdf-dialog.component';

const exportedDeclarations = [DownloadPdfDialogComponent];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IdvComponentsModule,
    IdvMaterialModule,
  ],
  declarations: [...exportedDeclarations],
  exports: [...exportedDeclarations],
})
export class PhyComponentsModule {}
