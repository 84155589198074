<form
  *ngIf="questionGroup && formGroup"
  class="form-group"
  (ngSubmit)="submitAnsweredQuestionGroup()"
  [formGroup]="formGroup"
>
  <div class="d-flex flex-column">
    <h4>{{ mainHeadlineQuestion.text }}</h4>

    <div class="no-information-checkbox">
      <mat-checkbox
        class="custom-control-container checkbox-label"
        id="mrc_q_bloodpressure_24h_no_information_available"
        formControlName="noInformationAvailable"
      >
        {{ checkboxText }}
      </mat-checkbox>
    </div>

    <ng-container>
      <div class="radio-group">
        <mat-radio-group [formControlName]="'timeRange'">
          <mat-radio-button [value]="timeRangeQuestion.selectableValues[0].id">
            {{ timeRangeQuestion.selectableValues[0].displayName }}
          </mat-radio-button>
          <mat-radio-button [value]="timeRangeQuestion.selectableValues[1].id">
            {{ timeRangeQuestion.selectableValues[1].displayName }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </ng-container>

    <div class="mt-5">
      <div
        class="input-group"
        formArrayName="bloodPressureReadings"
      >
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <input
            matInput
            mask="{{ mask }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            [formControlName]="0"
            [between]="systolicBetweenValue"
            placeholder="{{
              getLabel$('numeric-input.placeholders.bloodpressure-systolic')
                | async
            }}"
          />
          <mat-error
            *ngIf="hasError(formGroup.get('bloodPressureReadings').controls[0])"
          >
            <idv-error-help-block
              [errors]="
                errorsOnField(
                  formGroup.get('bloodPressureReadings').controls[0]
                )
              "
            ></idv-error-help-block>
          </mat-error>
        </mat-form-field>
        <div class="field-separator">/</div>
        <mat-form-field
          appearance="fill"
          class="form-field-s"
        >
          <input
            matInput
            mask="{{ mask }}"
            thousandSeparator="{{ thousandSeparator }}"
            decimalMarker="{{ decimalSeparator }}"
            [formControlName]="1"
            [between]="diastolicBetweenValue"
            placeholder="{{
              getLabel$('numeric-input.placeholders.bloodpressure-diastolic')
                | async
            }}"
          />
          <mat-error
            *ngIf="hasError(formGroup.get('bloodPressureReadings').controls[1])"
          >
            <idv-error-help-block
              [errors]="
                errorsOnField(
                  formGroup.get('bloodPressureReadings').controls[1]
                )
              "
            ></idv-error-help-block>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <br />
  <div class="d-flex flex-column">
    <idv-error-help-block
      *ngIf="showRequiredMessage"
      [errors]="formGroup.errors"
    ></idv-error-help-block>

    <idv-questionnaire-previous-next
      *ngIf="!readonlyMode"
      class="mt-5"
      [backButtonRouterLink]="backButtonRouterLink"
      [canProceed]="!!formGroup"
    ></idv-questionnaire-previous-next>
  </div>
</form>
