import { Pipe, PipeTransform } from '@angular/core';

/**
 * Examples:
 * MockPipe({ name: 'some-pipe' });
 * MockPipe({ name: 'some-pipe' }, someTransformFunction);
 *
 * See https://angular.io/docs/ts/latest/api/core/index/Pipe-interface.html for a list
 * of supported properties.
 */

const identity = (input: any) => input;

export function MockPipe(
  metadata: Pipe,
  transform: (value: any, ...args: any[]) => any = identity
): Pipe {
  class Mock implements PipeTransform {
    transform = transform;
  }

  return Pipe(metadata)(Mock as any);
}
