<div class="row">
  <div class="col-12 col-sm-11 col-lg-12 offset-sm-1 offset-lg-0">
    <h2>
      {{ 'two-factor-authentication.birthdate-factor.headline' | translate }}
    </h2>

    <div class="mt-3">
      <p>
        {{
          'two-factor-authentication.birthdate-factor.textParagraph1'
            | translate
        }}
      </p>
    </div>
    <form
      class="form-group"
      (ngSubmit)="submit()"
      [formGroup]="formGroup"
      novalidate
    >
      <div class="mt-3">
        <mat-form-field
          [appearance]="formFieldAppearance"
          class="form-field-m"
        >
          <mat-label>
            {{
              'two-factor-authentication.birthdate-factor.headline' | translate
            }}
            <ng-template [ngIf]="formFieldAppearance === 'legacy'">
              <span> </span>
              {{
                'two-factor-authentication.birthdate-factor.placeholder'
                  | translate
              }}
            </ng-template>
          </mat-label>
          <!-- see https://stackoverflow.com/a/51516180/2176962 -->
          <input
            matInput
            inputmode="numeric"
            [mask]="textMask"
            [leadZeroDateTime]="true"
            [dropSpecialCharacters]="false"
            [validation]="false"
            autocomplete="off"
            formControlName="birthDate"
            [placeholder]="
              'two-factor-authentication.birthdate-factor.placeholder'
                | translate
            "
          />
          <input
            type="text"
            style="display: none"
            [matDatepicker]="birthDatepicker"
            (dateChange)="datePicked($event.value)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="birthDatepicker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            startView="multi-year"
            #birthDatepicker
          ></mat-datepicker>
        </mat-form-field>
        <div *ngIf="showErrors && !!errors">
          <idv-error-help-block [errors]="errors"></idv-error-help-block>
        </div>
      </div>

      <ng-container *ngIf="showCaptcha && siteKey">
        <div class="form-group group-captcha">
          <div class="captcha-container">
            <idv-mt-captcha
              #captcha
              formControlName="captchaToken"
              required
              [sitekey]="siteKey"
              [lang]="currentLanguage | async"
              [action]="'2fa'"
            ></idv-mt-captcha>
          </div>
          <div class="error-message">
            <idv-error-help-block
              *ngIf="showErrors && !!captchaTokenErrors"
              [errors]="captchaTokenErrors"
            ></idv-error-help-block>
          </div>
        </div>
      </ng-container>

      <div class="mt-5">
        <button
          id="btn_idv_next_question"
          type="submit"
          mat-raised-button
          class="idv-form-button"
          color="primary"
          [disabled]="!canProceed"
        >
          <span>{{ 'question-group.next' | translate }}</span>
          <idv-demo-label inBrackets></idv-demo-label>
        </button>
      </div>
    </form>
  </div>
</div>
