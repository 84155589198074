import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../../idv-lib/src/lib/authentication/authentication.guard';
import { DataPrivacyDialogComponent } from '../../../idv-lib/src/lib/authentication/data-privacy-dialog/data-privacy-dialog.component';
import { GoodByeViewComponent } from '../../../idv-lib/src/lib/authentication/good-bye-view/good-bye-view.component';
import { LandingPageComponent } from '../../../idv-lib/src/lib/authentication/landing-page/landing-page.component';
// tslint:disable-next-line:max-line-length
import { ApplicationInfoDialogComponent } from '../../../idv-lib/src/lib/components/application-info-dialog/application-info-dialog.component';
import { AppRoute } from '../../../idv-lib/src/lib/interfaces/app-routing.const';
// tslint:disable-next-line:max-line-length
import { PdfDownloadScreenComponent } from '../../../idv-lib/src/lib/questionaires/step-components/pdf-download-step/pdf-download-screen/pdf-download-screen.component';
// tslint:disable-next-line:max-line-length
import { RiskQuestionnaireComponent } from '../../../idv-lib/src/lib/questionaires/step-components/questions/risk-questionnaire/risk-questionnaire.component';
// tslint:disable-next-line:max-line-length
import { SummaryEntityComponent } from '../../../idv-lib/src/lib/questionaires/step-components/questions/summary-entity/summary-entity.component';
import { StepGuard } from '../../../idv-lib/src/lib/services/step-navigation/step.guard';
import { MainComponent } from './questionnaires/master-detail-main/main.component';
import { AddDiseasesComponent } from './questionnaires/step-components/add-risk-step/add-diseases/add-diseases.component';
// tslint:disable-next-line:max-line-length
import { ConfirmCompletenessComponent } from './questionnaires/step-components/epilogue-step/confirm-completeness/confirm-completeness.component';
import { PaymentStepComponent } from './questionnaires/step-components/payment-step/payment-step.component';
import { PrologueStepComponent } from './questionnaires/step-components/prologue-step/prologue-step.component';
import { ReplaceRiskComponent } from './questionnaires/step-components/questions/replace-risk/replace-risk.component';

const routes: Routes = [
  {
    path: 'info',
    component: ApplicationInfoDialogComponent,
    data: { pageName: 'info' },
  },
  {
    path: AppRoute.dataPrivacy + '/:tenant',
    component: DataPrivacyDialogComponent,
    data: { pageName: 'dataprivacy' },
  },
  {
    path: AppRoute.logout,
    component: GoodByeViewComponent,
    data: {
      disableTenantSession: true,
      pageName: 'logout',
    },
  },
  {
    path: AppRoute.logout + '/:tenant',
    component: GoodByeViewComponent,
    data: { pageName: 'logout_tenant' },
  },
  {
    path: '',
    pathMatch: 'full',
    component: LandingPageComponent,
    data: {
      isDefaultLandingPage: true,
    },
  },
  {
    path: ':tenant',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LandingPageComponent,
        data: {
          isLandingPage: true,
          pageName: 'landing_page',
        },
      },
      {
        path: AppRoute.overview,
        component: MainComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [StepGuard],
        children: [
          {
            path: AppRoute.payment,
            component: PaymentStepComponent,
            data: { pageName: 'payment' },
          },
          {
            path: AppRoute.prologue,
            component: PrologueStepComponent,
            data: { pageName: 'prologue' },
          },
          {
            path: AppRoute.add,
            component: AddDiseasesComponent,
            data: { pageName: 'add_disease' },
          },
          {
            path: AppRoute.questionnaireSummary + '/:id',
            component: SummaryEntityComponent,
            data: { pageName: 'summary' },
          },
          {
            path: AppRoute.risk + '/:id',
            component: RiskQuestionnaireComponent,
            data: { pageName: 'risk_questionnaire' },
          },
          {
            path: AppRoute.risk + '/' + AppRoute.replace + '/:id',
            component: ReplaceRiskComponent,
            data: { pageName: 'risk_replace' },
          },
          {
            path: AppRoute.risk + '/:id/:questionGroupId',
            component: RiskQuestionnaireComponent,
            data: { pageName: 'risk_questionnaire_group' },
          },
          {
            path:
              AppRoute.risk +
              '/:id/' +
              AppRoute.redirect +
              '/:routedId/:questionGroupId',
            component: RiskQuestionnaireComponent,
            data: { pageName: 'redirected_risk_questionnaire_group' },
          },
          {
            path: AppRoute.complete,
            component: ConfirmCompletenessComponent,
            data: { pageName: 'completeness' },
          },
          {
            path: AppRoute.download,
            component: PdfDownloadScreenComponent,
            data: { pageName: 'pdf_download' },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
