<div class="question-wrapper">
  <div
    *ngIf="readonlyMode"
    class="readonly"
  ></div>
  <ng-container
    *ngIf="questionGroup"
    [ngSwitch]="questionGroup.questionGroupType"
  >
    <ng-container *ngSwitchCase="'mrc_bloodpressure'">
      <idv-bloodpressure
        [readonlyMode]="readonlyMode"
        [backButtonRouterLink]="backButtonRouterLink"
        [questionGroup]="questionGroup"
        (questionGroupAnswer)="onAnswerReceived($event)"
      ></idv-bloodpressure>
    </ng-container>

    <idv-bloodpressure-24h
      *ngSwitchCase="'mrc_bloodpressure_24h'"
      [readonlyMode]="readonlyMode"
      [backButtonRouterLink]="backButtonRouterLink"
      [questionGroup]="questionGroup"
      (questionGroupAnswer)="onAnswerReceived($event)"
    ></idv-bloodpressure-24h>
    <idv-height-weight-group-control
      *ngSwitchCase="'HeightWeightQuestionGroup'"
      [questionGroup]="questionGroup"
      (questionGroupAnswer)="onAnswerReceived($event)"
    ></idv-height-weight-group-control>

    <ng-container *ngSwitchCase="'mrc_lipids'">
      <idv-lipids
        [readonlyMode]="readonlyMode"
        [backButtonRouterLink]="backButtonRouterLink"
        [questionGroup]="questionGroup"
        (questionGroupAnswer)="onAnswerReceived($event)"
      ></idv-lipids>
    </ng-container>

    <idv-tickbox-group
      *ngSwitchCase="'TickboxGroup.Mandatory'"
      [readonlyMode]="readonlyMode"
      [backButtonRouterLink]="backButtonRouterLink"
      [questionGroup]="questionGroup"
      (questionGroupAnswer)="onAnswerReceived($event)"
    ></idv-tickbox-group>
    <idv-question-group
      *ngSwitchDefault
      [readonlyMode]="readonlyMode"
      [backButtonRouterLink]="backButtonRouterLink"
      [questionGroup]="questionGroup"
      (questionGroupAnswer)="onAnswerReceived($event)"
    ></idv-question-group>
  </ng-container>
</div>
