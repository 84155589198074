import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { conditionalValidator } from '../../../../../../../../core-lib';
import { LanguageService } from '../../../../../../../../core-lib/src/lib/core/services/language/language.service';
import {
  bloodpressureInputRequiredValidator,
  bloodpressureValuesRequiredValidator,
  minMaxValidator,
  systolicMinusDiastolicGreaterThan,
} from '../../../../../../../../core-lib/src/lib/core/validation';
import { Question, QuestionGroup } from '../../../../../interfaces';
import { QuestionnairesHelperService } from '../../../../../services/risks/questionnaires-helper.service';

const bloodPressureAvailability = {
  true: 'mrc_q_bloodpressure_available_yes',
  false: 'mrc_q_bloodpressure_available_no',
};

@Component({
  selector: 'idv-bloodpressure',
  templateUrl: './bloodpressure.component.html',
  styleUrls: ['./bloodpressure.component.scss'],
})
export class BloodpressureComponent implements OnChanges, OnDestroy {
  @Input()
  questionGroup: QuestionGroup;
  @Input()
  backButtonRouterLink: any[];
  @Input()
  readonlyMode = false;

  @Output()
  questionGroupAnswer = new EventEmitter<QuestionGroup>();

  formGroup: UntypedFormGroup;

  bloodpressureHeadingQuestion: Question;
  isInformationAvailableQuestion: Question;
  recentBloodpressureHeadingQuestion: Question;
  recentSystolic1Question: Question;
  recentDiastolic1Question: Question;
  recentSystolic2Question: Question;
  recentDiastolic2Question: Question;
  previousBloodpressureHeadingQuestion: Question;
  pastSystolic1Question: Question;
  pastDiastolic1Question: Question;
  pastSystolic2Question: Question;
  pastDiastolic2Question: Question;

  private formSub1: Subscription;
  private formSub2: Subscription;

  private rememberedRecentValues: object;
  private rememberedPreviousValues: object;

  startDateRecent: Date;
  startDatePast: Date;
  endDatePast: Date;
  checkboxText: string;

  constructor(
    private languageService: LanguageService,
    private questionnairesHelperService: QuestionnairesHelperService
  ) {}

  areAllErrorMessagesShown = false;

  get thousandSeparator() {
    return this.languageService.getThousandSeparator();
  }

  get decimalSeparator() {
    return this.languageService.getDecimalSeparator();
  }

  get mask() {
    return 'separator.0';
  }

  getLabel$(textKey: string): Observable<string> {
    return this.questionnairesHelperService.getLabel$(
      this.isBloodpressureInformationAvailable(),
      textKey,
      this.readonlyMode
    );
  }

  private findQuestionByName(name: string): Question {
    const question = this.questionGroup.questions.find((q) => q.name === name);
    if (!question) {
      throw Error('question "' + name + '" not in questionGroup');
    }
    return question;
  }

  private setQuestions() {
    this.bloodpressureHeadingQuestion = this.findQuestionByName(
      'q_bloodpressure_heading'
    );
    this.isInformationAvailableQuestion = this.findQuestionByName(
      'q_bloodpressure_available_with_values'
    );
    this.recentBloodpressureHeadingQuestion = this.findQuestionByName(
      'q_bloodpressure_1_heading_new'
    );
    this.recentSystolic1Question = this.findQuestionByName(
      'q_bloodpressure_1_1_sys'
    );
    this.recentDiastolic1Question = this.findQuestionByName(
      'q_bloodpressure_1_1_dia'
    );
    this.recentSystolic2Question = this.findQuestionByName(
      'q_bloodpressure_1_2_sys'
    );
    this.recentDiastolic2Question = this.findQuestionByName(
      'q_bloodpressure_1_2_dia'
    );
    this.previousBloodpressureHeadingQuestion = this.findQuestionByName(
      'q_bloodpressure_2_heading_new'
    );
    this.pastSystolic1Question = this.findQuestionByName(
      'q_bloodpressure_2_1_sys'
    );
    this.pastDiastolic1Question = this.findQuestionByName(
      'q_bloodpressure_2_1_dia'
    );
    this.pastSystolic2Question = this.findQuestionByName(
      'q_bloodpressure_2_2_sys'
    );
    this.pastDiastolic2Question = this.findQuestionByName(
      'q_bloodpressure_2_2_dia'
    );
  }

  isRecentBloodpressureInputRequired(): boolean {
    if (this.formGroup) {
      const previousValuesEntered =
        (this.formGroup.controls.previousBloodpressureReadings.value[0][0] !==
          null &&
          this.formGroup.controls.previousBloodpressureReadings.value[0][1] !==
            null) ||
        (this.formGroup.controls.previousBloodpressureReadings.value[1][0] !==
          null &&
          this.formGroup.controls.previousBloodpressureReadings.value[1][1] !==
            null);
      return (
        this.isBloodpressureInformationAvailable() && !previousValuesEntered
      );
    }
    return true;
  }

  isPreviousBloodpressureInputRequired(): boolean {
    if (this.formGroup) {
      const recentValuesEntered =
        (this.formGroup.controls.recentBloodpressureReadings.value[0][0] !==
          null &&
          this.formGroup.controls.recentBloodpressureReadings.value[0][1] !==
            null) ||
        (this.formGroup.controls.recentBloodpressureReadings.value[1][0] !==
          null &&
          this.formGroup.controls.recentBloodpressureReadings.value[1][1] !==
            null);
      return this.isBloodpressureInformationAvailable() && !recentValuesEntered;
    }
    return true;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('questionGroup' in simpleChanges) {
      this.setQuestions();
      this.createFormGroup();
      this.initFormAfterCreation();
      if (this.readonlyMode) {
        this.formGroup.disable();
      } else {
        this.unsubscribeFormSubs();
        this.formSub1 = this.formGroup.valueChanges.subscribe(() => {
          this.formGroup.controls.recentBloodpressureReadings.updateValueAndValidity(
            {
              emitEvent: false,
            }
          );
          this.formGroup.controls.previousBloodpressureReadings.updateValueAndValidity(
            {
              emitEvent: false,
            }
          );
        });

        this.formSub2 =
          this.formGroup.controls.noValuesCheckbox.valueChanges.subscribe(
            () => {
              if (this.isBloodpressureInformationAvailable()) {
                this.enableFieldsAndRestoreEnteredValues();
              } else {
                this.disableFieldsAndStoreEnteredValues();
              }
            }
          );
      }
    }
  }

  private isBloodpressureInformationAvailable() {
    return !this.formGroup.controls.noValuesCheckbox.value;
  }

  private initFormAfterCreation() {
    this.calculateDatesForQuestions();

    this.checkboxText =
      this.isInformationAvailableQuestion.selectableValues.find(
        (answer) => answer.id === bloodPressureAvailability['false']
      ).displayName;

    if (
      this.isInformationAvailableQuestion.selection ===
      bloodPressureAvailability['false']
    ) {
      this.disableFieldsAndStoreEnteredValues();
      this.formGroup.controls.noValuesCheckbox.setValue(true);
    } else {
      this.formGroup.controls.noValuesCheckbox.setValue(false);
    }
  }

  submitAnsweredQuestionGroup() {
    this.areAllErrorMessagesShown = true;

    if (this.formGroup.valid) {
      const answeredQuestionGroup = this.createAnsweredQuestionGroup();

      this.questionGroupAnswer.emit(answeredQuestionGroup);
    } else {
      this.areAllErrorMessagesShown = true;
    }
  }

  createAnsweredQuestionGroup(): QuestionGroup {
    const { id, questionGroupType, assessmentFactor } = this.questionGroup;
    const answeredQuestions: Question[] = [];

    const recentBloodpressureReadings = this.formGroup.controls
      .recentBloodpressureReadings as UntypedFormArray;
    const previousBloodpressureReadings = this.formGroup.controls
      .previousBloodpressureReadings as UntypedFormArray;

    answeredQuestions.push(this.bloodpressureHeadingQuestion);
    answeredQuestions.push({
      ...this.isInformationAvailableQuestion,
      selection:
        bloodPressureAvailability[
          this.isBloodpressureInformationAvailable().toString()
        ],
    });

    answeredQuestions.push(this.recentBloodpressureHeadingQuestion);

    answeredQuestions.push({
      ...this.recentSystolic1Question,
      selection: (<UntypedFormArray>recentBloodpressureReadings.controls[0])
        .controls[0].value,
    });
    answeredQuestions.push({
      ...this.recentDiastolic1Question,
      selection: (<UntypedFormArray>recentBloodpressureReadings.controls[0])
        .controls[1].value,
    });

    answeredQuestions.push({
      ...this.recentSystolic2Question,
      selection: (<UntypedFormArray>recentBloodpressureReadings.controls[1])
        .controls[0].value,
    });
    answeredQuestions.push({
      ...this.recentDiastolic2Question,
      selection: (<UntypedFormArray>recentBloodpressureReadings.controls[1])
        .controls[1].value,
    });

    answeredQuestions.push(this.previousBloodpressureHeadingQuestion);

    answeredQuestions.push({
      ...this.pastSystolic1Question,
      selection: (<UntypedFormArray>previousBloodpressureReadings.controls[0])
        .controls[0].value,
    });
    answeredQuestions.push({
      ...this.pastDiastolic1Question,
      selection: (<UntypedFormArray>previousBloodpressureReadings.controls[0])
        .controls[1].value,
    });

    answeredQuestions.push({
      ...this.pastSystolic2Question,
      selection: (<UntypedFormArray>previousBloodpressureReadings.controls[1])
        .controls[0].value,
    });
    answeredQuestions.push({
      ...this.pastDiastolic2Question,
      selection: (<UntypedFormArray>previousBloodpressureReadings.controls[1])
        .controls[1].value,
    });

    return {
      id,
      questionGroupType,
      assessmentFactor,
      questions: answeredQuestions,
    };
  }

  hasError(formControl: AbstractControl): boolean {
    return formControl && formControl.invalid;
  }

  errorsOnField(formControl: AbstractControl): ValidationErrors {
    return this.hasError(formControl) ? formControl.errors : null;
  }

  disableFieldsAndStoreEnteredValues() {
    this.formGroup.controls.recentBloodpressureReadings.disable();
    this.formGroup.controls.previousBloodpressureReadings.disable();
    this.rememberedRecentValues =
      this.formGroup.controls.recentBloodpressureReadings.value;
    this.rememberedPreviousValues =
      this.formGroup.controls.previousBloodpressureReadings.value;
    this.formGroup.controls.recentBloodpressureReadings.reset();
    this.formGroup.controls.previousBloodpressureReadings.reset();
  }

  enableFieldsAndRestoreEnteredValues() {
    this.formGroup.controls.recentBloodpressureReadings.enable();
    this.formGroup.controls.previousBloodpressureReadings.enable();

    if (this.rememberedRecentValues) {
      this.formGroup.controls.recentBloodpressureReadings.setValue(
        this.rememberedRecentValues
      );
    }
    if (this.rememberedPreviousValues) {
      this.formGroup.controls.previousBloodpressureReadings.setValue(
        this.rememberedPreviousValues
      );
    }
  }

  calculateDatesForQuestions() {
    const startDateRecent = new Date();
    startDateRecent.setDate(startDateRecent.getDate() - 90);
    this.startDateRecent = startDateRecent;

    const startDatePast = new Date();
    startDatePast.setDate(startDatePast.getDate() - 720);
    this.startDatePast = startDatePast;

    const endDatePast = new Date();
    endDatePast.setDate(endDatePast.getDate() - 120);
    this.endDatePast = endDatePast;
  }

  private createFormGroup() {
    this.formGroup = new UntypedFormGroup({
      noValuesCheckbox: new UntypedFormControl(false),
      recentBloodpressureReadings: new UntypedFormArray(
        [
          new UntypedFormArray(
            [
              new UntypedFormControl(this.recentSystolic1Question.selection, [
                minMaxValidator(this.recentSystolic1Question.numericRange),
              ]),
              new UntypedFormControl(this.recentDiastolic1Question.selection, [
                minMaxValidator(this.recentDiastolic1Question.numericRange),
              ]),
            ],
            [
              bloodpressureValuesRequiredValidator,
              systolicMinusDiastolicGreaterThan,
            ]
          ),
          new UntypedFormArray(
            [
              new UntypedFormControl(this.recentSystolic2Question.selection, [
                minMaxValidator(this.recentSystolic2Question.numericRange),
              ]),
              new UntypedFormControl(this.recentDiastolic2Question.selection, [
                minMaxValidator(this.recentDiastolic2Question.numericRange),
              ]),
            ],
            [
              bloodpressureValuesRequiredValidator,
              systolicMinusDiastolicGreaterThan,
            ]
          ),
        ],
        [
          conditionalValidator(
            () => this.isRecentBloodpressureInputRequired(),
            bloodpressureInputRequiredValidator
          ),
        ]
      ),
      previousBloodpressureReadings: new UntypedFormArray(
        [
          new UntypedFormArray(
            [
              new UntypedFormControl(this.pastSystolic1Question.selection, [
                minMaxValidator(this.pastSystolic1Question.numericRange),
              ]),
              new UntypedFormControl(this.pastDiastolic1Question.selection, [
                minMaxValidator(this.pastDiastolic1Question.numericRange),
              ]),
            ],
            [
              bloodpressureValuesRequiredValidator,
              systolicMinusDiastolicGreaterThan,
            ]
          ),
          new UntypedFormArray(
            [
              new UntypedFormControl(this.pastSystolic2Question.selection, [
                minMaxValidator(this.pastSystolic2Question.numericRange),
              ]),
              new UntypedFormControl(this.pastDiastolic2Question.selection, [
                minMaxValidator(this.pastDiastolic2Question.numericRange),
              ]),
            ],
            [
              bloodpressureValuesRequiredValidator,
              systolicMinusDiastolicGreaterThan,
            ]
          ),
        ],
        [
          conditionalValidator(
            () => this.isPreviousBloodpressureInputRequired(),
            bloodpressureInputRequiredValidator
          ),
        ]
      ),
    });
  }

  private unsubscribeFormSubs() {
    this.formSub1 && this.formSub1.unsubscribe();
    this.formSub2 && this.formSub2.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribeFormSubs();
  }
}
