// tslint:disable:max-line-length
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IdvComponentsModule } from '../../../../idv-lib/src/lib/components/idv-components.module';
import { IdvInputControlsModule } from '../../../../idv-lib/src/lib/input-controls/idv-input-controls.module';
import { IdvQuestionnairesModule } from '../../../../idv-lib/src/lib/questionaires/idv-questionnaires.module';
import { MainComponent } from './master-detail-main/main.component';
import { AddDiseasesComponent } from './step-components/add-risk-step/add-diseases/add-diseases.component';
import { AdditionalDiseasesStepComponent } from './step-components/add-risk-step/additional-diseases-step/additional-diseases-step.component';
import { SearchDiseaseComponent } from './step-components/add-risk-step/search-disease/search-disease.component';
import { AdditionalDiseasesTextComponent } from './step-components/epilogue-step/additional-diseases-text/additional-diseases-text.component';
import { ConfirmCompletenessComponent } from './step-components/epilogue-step/confirm-completeness/confirm-completeness.component';
import { HandonInformationStepComponent } from './step-components/epilogue-step/handon-information-step/handon-information-step.component';
import { PaymentStepComponent } from './step-components/payment-step/payment-step.component';
import { AccountPaymentMethodComponent } from './step-components/payment-step/payment-methods/account/account-payment-method.component';
import { IbanPaymentMethodComponent } from './step-components/payment-step/payment-methods/iban/iban-payment-method.component';
import { PrologueStepComponent } from './step-components/prologue-step/prologue-step.component';
import { RemoveRiskDialogComponent } from './step-components/questions/remove-risk/remove-risk-dialog/remove-risk-dialog.component';
import { RemoveRiskReasonsComponent } from './step-components/questions/remove-risk/remove-risk-reasons/remove-risk-reasons.component';
import { ReplaceRiskComponent } from './step-components/questions/replace-risk/replace-risk.component';
import { PhyComponentsModule } from '../components/phy-components.module';
import { HeaderComponent } from '../components/header/header.component';
import { PaymentMethodComponentResolver } from './step-components/payment-step/payment-methods/services/payment-method-component-resolver.service';
import { PaymentComponentHostDirective } from './step-components/payment-step/directives/payment-host.directive';

@NgModule({
  imports: [
    IdvComponentsModule,
    IdvInputControlsModule,
    RouterModule,
    IdvQuestionnairesModule,
    PhyComponentsModule,
  ],
  exports: [],
  declarations: [
    MainComponent,
    ConfirmCompletenessComponent,
    PaymentStepComponent,
    PrologueStepComponent,
    AddDiseasesComponent,
    AdditionalDiseasesTextComponent,
    HandonInformationStepComponent,
    AdditionalDiseasesStepComponent,
    RemoveRiskDialogComponent,
    RemoveRiskReasonsComponent,
    ReplaceRiskComponent,
    SearchDiseaseComponent,
    HeaderComponent,
    AccountPaymentMethodComponent,
    IbanPaymentMethodComponent,
    PaymentComponentHostDirective,
  ],
  providers: [PaymentMethodComponentResolver],
})
export class PhyQuestionnairesModule {}
