import {
  Component,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'idv-step-navigation-label',
  templateUrl: './step-navigation-label.component.html',
  styleUrls: ['./step-navigation-label.component.scss'],
})
export class StepNavigationLabelComponent implements AfterViewInit {
  @Input() label: string;

  hasTooltip: boolean;

  @ViewChild('labelRef') labelRef: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    const offsetWidth = this.labelRef.nativeElement.offsetWidth;
    const scrollWidth = this.labelRef.nativeElement.scrollWidth;

    setTimeout(() => {
      this.hasTooltip = offsetWidth < scrollWidth;
    });
  }
}
