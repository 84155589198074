<div
  [formGroup]="formGroup"
  class="ym-container"
>
  <div class="year-column">
    <mat-form-field
      appearance="fill"
      class="form-field-s"
    >
      <mat-label>{{ 'month-year-input.year.label' | translate }}</mat-label>
      <input
        matInput
        class="e2e_ym_input_year"
        type="number"
        inputmode="numeric"
        [placeholder]="'month-year-input.year.placeholder' | translate"
        (blur)="onTouched()"
        formControlName="year"
      />
      <mat-error *ngIf="visibleErrors(formGroup, 'year')"></mat-error>
    </mat-form-field>
  </div>
  <div class="month-column">
    <mat-form-field
      appearance="fill"
      class="form-field-s"
    >
      <mat-label>{{ 'month-year-input.month.label' | translate }}</mat-label>
      <input
        matInput
        class="e2e_ym_input_month"
        type="number"
        inputmode="numeric"
        [placeholder]="'month-year-input.month.placeholder' | translate"
        (blur)="onTouched()"
        formControlName="month"
      />
      <mat-error *ngIf="visibleErrors(formGroup, 'month')"></mat-error>
    </mat-form-field>

    <div
      class="month-unknown"
      [class.month-unknown-visible]="
        monthUnknownControl.enabled || monthUnknownControl.value
      "
    >
      <mat-checkbox
        formControlName="monthUnknown"
        class="e2e_ym_input_month_unknown"
      >
        <div
          class="legal-checkbox-label"
          [class.unknown-not-checked-error]="
            visibleErrors(formGroup, 'monthUnknown')
          "
        >
          {{ 'month-year-input.month-unknown.label' | translate }}
        </div>
      </mat-checkbox>
    </div>
  </div>
</div>
