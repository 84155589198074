import { Component } from '@angular/core';

@Component({
  template: `
    <h1 mat-dialog-title>{{ 'shared.notification.heading' | translate }}</h1>
    <mat-dialog-content>
      {{ 'search-disease.unreasonable-warning-modal' | translate }}
    </mat-dialog-content>
    <div mat-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        [mat-dialog-close]
        cdkFocusInitial
      >
        {{ 'shared.ok' | translate }}
      </button>
    </div>
  `,
  styles: [
    `
      mat-dialog-content {
        line-height: 1.6em;
      }
    `,
  ],
})
export class UnreasonableRiskWarningComponent {
  public constructor() {}
}
