import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  conditionalValidator,
  MrcTrackingService,
} from '../../../../../../core-lib/src/public_api';
import { TenantConfigService } from '../../../services/config/tenant-config.service';
import { ResponsiveService } from '../../../services/responsive/responsive.service';
import { StepStateService } from '../../../services/step-navigation/step-state.service';
import { TenantService } from '../../../services/tenant/tenant.service';
import { AuthenticationService } from '../../authentication.service';
import {
  AuthenticationResultType,
  IAuthenticationResult,
} from '../../model/authentication-result';
import { IEmailSecondFactorAuthenticationFormValue } from '../../model/form-value/email.second-factor-authentication.form-value';
import { MfaStrategy } from '../../model/mfa-strategy';
import { IEmailAuthenticationResponse } from '../../model/response/first-factor-authentication.response';
import { BaseSecondFactorComponent } from '../base-second-factor-component/base.second-factor-component';

@Component({
  templateUrl: './email-factor.component.html',
  styleUrls: ['./email-factor.component.scss'],
})
export class EmailFactorComponent
  extends BaseSecondFactorComponent
  implements OnInit, OnDestroy
{
  public textMask = 'AAAAAA';

  private _customValidationErrors: ValidationErrors = null;
  private _mainText: Observable<string>;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    tenantConfigService: TenantConfigService,
    authenticationService: AuthenticationService,
    trackingService: MrcTrackingService,
    location: Location,
    dialog: MatDialog,
    responsiveService: ResponsiveService,
    stepStateService: StepStateService,
    translateService: TranslateService,
    tenantService: TenantService
  ) {
    super(
      tenantConfigService,
      authenticationService,
      trackingService,
      location,
      dialog,
      responsiveService,
      stepStateService,
      translateService,
      tenantService
    );
  }

  ngOnInit() {
    super.onInit();

    const payload: IEmailAuthenticationResponse =
      history.state?.payload ?? null;

    this._mainText = this._translateService.stream(
      'two-factor-authentication.email-factor.textParagraph1',
      {
        encryptedMailAddress: payload?.emailHint ?? '***',
      }
    );

    this.formGroup.controls.token.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this._customValidationErrors = null;
      });
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get errors() {
    return this.formGroup.controls.token.errors || this._customValidationErrors;
  }

  get mainText() {
    return this._mainText;
  }

  public getEndpointSuffix(): string {
    return MfaStrategy.Email;
  }

  createFormGroup() {
    return this._formBuilder.group({
      token: [null, Validators.pattern(/^\d{6}$/g)],
      captchaToken: [
        '',
        {
          validator: conditionalValidator(
            () => this.showCaptcha,
            Validators.required
          ),
        },
      ],
    });
  }

  getFormResult() {
    const emailToken = this.formGroup.controls.token.value;
    const captchaToken = this.formGroup.controls.captchaToken.value;

    const result: IEmailSecondFactorAuthenticationFormValue = {
      emailToken,
    };

    if (captchaToken) {
      result.captchaToken = captchaToken;
    }

    return result;
  }

  setAccessTokenErrors(result: IAuthenticationResult): void {
    if (result.resultType === AuthenticationResultType.secondFactorInvalid) {
      this._customValidationErrors = { tokenInvalid: null };
    } else {
      this._customValidationErrors = { unknownError: null };
    }
  }
}
