import { HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SessionTokenStorageService } from './session-token-storage.service';

@Injectable()
export class AuthorizationHeaderInterceptor implements HttpInterceptor {
  constructor(private sessionTokenStorageService: SessionTokenStorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const sessionToken = this.sessionTokenStorageService.getToken();

    if (sessionToken) {
      const extendedRequest = extendRequestWithAuthorizationHeader(
        request,
        sessionToken
      );
      return next.handle(extendedRequest);
    }

    return next.handle(request);
  }
}

function extendRequestWithAuthorizationHeader(
  request: HttpRequest<any>,
  sessionToken: string
) {
  const extendedHeaders = request.headers.set(
    'Authorization',
    'Custom ' + sessionToken
  );

  return request.clone({
    headers: extendedHeaders,
  });
}
