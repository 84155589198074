import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantConfigService } from '../../services/config/tenant-config.service';

type NameVersion = { name: string; version: string };

@Component({
  selector: 'idv-application-info-dialog',
  templateUrl: './application-info-dialog.component.html',
  styleUrls: ['./application-info-dialog.component.scss'],
})
export class ApplicationInfoDialogComponent {
  @Output() hideAnimationFinished = new EventEmitter<void>();

  readonly applicationVersions: Observable<NameVersion[]>;

  constructor(private tenantConfigService: TenantConfigService) {
    this.applicationVersions = this.tenantConfigService.tenantConfig$.pipe(
      map((config) => this.extractNamesAndVersions(config.versionInfo))
    );
  }

  private extractNamesAndVersions(versionInfo: {
    [applicationName: string]: string;
  }): NameVersion[] {
    return Object.keys(versionInfo).map((applicationName) => ({
      name: applicationName,
      version: versionInfo[applicationName],
    }));
  }
}
