<!-- Edit button for md and higher -->
<button
  (click)="onClickEditButton()"
  mat-button
  color="primary"
  [disableRipple]="true"
  [routerLink]="routerLink"
  class="edit-button-text"
>
  {{ 'summary.edit' | translate }}
</button>

<!-- button for sm and smaller -->
<button
  (click)="onClickEditButton()"
  mat-icon-button
  [disableRipple]="true"
  [routerLink]="routerLink"
  class="edit-button-icon"
>
  <mat-icon color="primary">edit</mat-icon>
</button>
