import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'idv-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss'],
})
export class EditButtonComponent {
  @Input() routerLink: RouterLink;
  @Output() clickEmitter = new EventEmitter();

  constructor() {}

  onClickEditButton() {
    this.clickEmitter.emit();
  }
}
