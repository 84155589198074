import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, of, ReplaySubject } from 'rxjs';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';
import { SessionKey } from '../../constants/session-key.constants';
import {
  CheckTenantRequest,
  CheckTenantResponse,
  Request,
} from '../../interfaces';
import { RequestService } from '../request/request.service';
import { SessionStorageService } from '../../../../../core-lib';
import { HttpClient } from '@angular/common/http';
import { IdvEnvironment, IDV_ENV } from '../../interfaces/idv-environment';

@Injectable({ providedIn: 'root' })
export class TenantService {
  public tenant: Observable<string>;
  public current: string;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private requestService: RequestService,
    private sessionStorage: SessionStorageService,
    private httpClient: HttpClient,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {
    const tenantSubject = new ReplaySubject<string>(1);
    this.tenant = tenantSubject.asObservable().pipe(
      startWith(null),
      combineLatest(
        this.router.events.pipe(startWith(null), shareReplay(1)),
        this.authService.isLoggedIn.pipe(startWith(null), shareReplay(1))
      ),
      switchMap((combinedLatest) => this.calculateTenant(combinedLatest)),
      map((tenant) => (tenant ? tenant.toLocaleLowerCase() : tenant)),
      distinctUntilChanged(),
      tap((tenant) => {
        this.current = tenant;
      })
    );
  }

  public checkTenant(tenant: string): Observable<CheckTenantResponse> {
    const request: CheckTenantRequest = {
      tenantName: tenant,
    };

    const url = `${this.environment.apiHost}/idv/tenant/check`;
    return this.httpClient.post<CheckTenantResponse>(url, request);
  }

  private calculateTenant(combinedLatest: any): Observable<string> {
    const isLoggedIn: boolean = combinedLatest[2];
    const activatedRoute: ActivatedRoute =
      this.router.routerState.root.children[0];
    const routeParamMap: ParamMap = activatedRoute
      ? activatedRoute.snapshot.paramMap
      : null;

    if (isLoggedIn) {
      return this.requestService.request.pipe(
        filter((request) => !!request),
        map((request: Request) => request.tenant),
        tap((tenant) => this.storeTenantInSession(tenant))
      );
    } else if (this.hasTenantParam(routeParamMap)) {
      return of(routeParamMap.get('tenant'));
    } else if (this.isTenantSessionEnabled(activatedRoute)) {
      return of(this.getTenantFromSession());
    }
    return of(null);
  }

  private hasTenantParam(routeParamMap: ParamMap) {
    return routeParamMap && routeParamMap.has('tenant');
  }

  private isTenantSessionEnabled(activatedRoute: ActivatedRoute) {
    return !(
      activatedRoute &&
      activatedRoute.routeConfig.data &&
      activatedRoute.routeConfig.data['disableTenantSession']
    );
  }

  private getTenantFromSession() {
    return this.sessionStorage.getItem(SessionKey.tenant);
  }

  private storeTenantInSession(tenant: string) {
    this.sessionStorage.setItem(SessionKey.tenant, tenant);
  }
}
