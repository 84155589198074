import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class QuestionnairesHelperService {
  private noValuesText$: Observable<string>;

  constructor(private translateService: TranslateService) {
    this.noValuesText$ = this.translateService.onLangChange.pipe(
      switchMap(() => {
        return this.translateService.get('shared.no-values');
      }),
      startWith(this.translateService.instant('shared.no-values'))
    );
  }

  getLabel$(
    areValuesAvailable: boolean,
    textKey: string,
    readonlyMode: boolean
  ): Observable<string> {
    if (readonlyMode && !areValuesAvailable) {
      return this.noValuesText$;
    } else if (readonlyMode && areValuesAvailable) {
      return of('');
    } else if (areValuesAvailable) {
      return this.translateService.get(textKey);
    }

    return this.noValuesText$;
  }
}
