import { NgModule, ModuleWithProviders } from '@angular/core';
import { MtCaptchaDomService } from './services/mt-captcha-dom.service';
import { MtCaptchaComponent } from './components/mt-captcha.component';

@NgModule({
  providers: [MtCaptchaDomService],
  declarations: [MtCaptchaComponent],
  exports: [MtCaptchaComponent],
})
export class MrcCaptchaModule {
  static forRoot(): ModuleWithProviders<MrcCaptchaModule> {
    return {
      ngModule: MrcCaptchaModule,
      providers: [MtCaptchaDomService],
    };
  }
}
