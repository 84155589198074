<ng-container [ngSwitch]="precision.unit">
  <idv-month-year-input
    *ngSwitchCase="'monthOptional'"
    [between]="between"
    [monthRequiredFromYear]="monthRequiredFromYear"
    [formControl]="formControlToUse"
    [showErrors]="showErrors"
  ></idv-month-year-input>

  <idv-date-input
    *ngSwitchDefault
    [accuracyUnit]="precision.unit"
    [formControl]="formControlToUse"
  ></idv-date-input>
</ng-container>
