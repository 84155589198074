<idv-close-modal-header></idv-close-modal-header>

<div mat-dialog-content>
  <h2>{{ 'faq-page.faq' | translate }}</h2>

  <div class="faq-sitemap">
    <div
      class="faq-sitemap-category"
      *ngFor="let faqCategory of faqCategories?.categories; let i = index"
    >
      <h3>{{ faqCategory.title }}</h3>
      <a
        *ngFor="let faqItem of faqCategory.faqItems; let j = index"
        (click)="scrollToFaqItem('faqc' + i + 'it' + j)"
        >{{ faqItem.title }}</a
      >
    </div>
  </div>

  <div class="faq-content">
    <ng-container
      *ngFor="let faqCategory of faqCategories?.categories; let i = index"
    >
      <h3>{{ faqCategory.title }}</h3>
      <ng-container *ngFor="let faqItem of faqCategory.faqItems; let j = index">
        <h4 [attr.id]="'faqc' + i + 'it' + j">{{ faqItem.title }}</h4>
        <div
          class="modal-text"
          [innerHTML]="faqItem.content"
        ></div>
      </ng-container>
    </ng-container>
  </div>

  <mrc-go-top-button></mrc-go-top-button>
</div>
