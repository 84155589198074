<form>
  <idv-search-risk-control
    [description]="'search-disease.unreasonable-warning' | translate"
  ></idv-search-risk-control>

  <div class="d-flex flex-column flex-md-row form-buttons mt-5">
    <button
      id="btn_idv_do_not_add_disease"
      type="button"
      mat-stroked-button
      color="primary"
      (click)="cancel()"
    >
      {{ cancelButtonText }}
    </button>
    <button
      type="submit"
      id="btn_idv_add_disease"
      mat-raised-button
      color="primary"
      (click)="submit()"
      [disabled]="!hasSelection"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</form>
