import { UntypedFormControl, ValidatorFn } from '@angular/forms';

export function monthOptionalYearValidator(): ValidatorFn {
  return (control: UntypedFormControl) => {
    return control.value !== null &&
      (control.value.year === undefined || control.value.year === null)
      ? {
          required: true,
        }
      : null;
  };
}
