import { Injectable } from '@angular/core';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { TenantService } from '../tenant/tenant.service';
import { Observable, combineLatest } from 'rxjs';
import { ApiService } from '../api.service';
import { LanguageService } from '../../../../../core-lib/src/lib/core/services/language/language.service';

export enum ConfigAssetId {
  'pre-contractual-disclosure' = 'precontractualdisclosure',
  'pre-contractual-disclosure-pdf' = 'pdf-precontractualdisclosure',
  'dataPrivacyStatement' = 'dataprivacy',
  'faq' = 'faq',
  'logo' = 'logo',
  'globalCss' = 'global.css',
  'landingPageBackground' = 'background',
  'certificateIcon' = 'certificate-icon',
  'munichReDataProcessingStatement' = 'munichre_data_processing_statement',
}

@Injectable({ providedIn: 'root' })
export class ConfigAssetService {
  constructor(
    private _apiService: ApiService,
    private _tenantService: TenantService,
    private _languageService: LanguageService
  ) {
    this.trigger$
      .pipe(
        tap((triggers) => {
          this._apiService.appendCssAssetGlobally(
            `${triggers.tenant}/${ConfigAssetId.globalCss}`,
            triggers.language
          );
        })
      )
      .subscribe();
  }

  private readonly trigger$ = combineLatest(
    this._tenantService.tenant,
    this._languageService.applicationLanguageAvailable$.pipe(
      map(() => this._languageService.getCurrentLanguage())
    )
  ).pipe(
    map((triggers) => {
      return { tenant: triggers[0], language: triggers[1] };
    }),
    filter((triggers) => !!triggers.tenant && !!triggers.language)
  );

  public readonly tenantDataPrivacyStatementUrl = this.trigger$.pipe(
    map((triggers) =>
      this._apiService.getApiAssetUrl(
        `${triggers.tenant}/${ConfigAssetId.dataPrivacyStatement}`
      )
    )
  );

  public readonly tenantFaqUrl = this.trigger$.pipe(
    map((triggers) =>
      this._apiService.getApiAssetUrl(`${triggers.tenant}/${ConfigAssetId.faq}`)
    )
  );

  public readonly logoUrl = this.trigger$.pipe(
    map((triggers) =>
      this._apiService.getApiAssetUrl(
        `${triggers.tenant}/${ConfigAssetId.logo}`,
        triggers.language
      )
    )
  );

  public readonly certificateIconUrl = this.trigger$.pipe(
    map((triggers) =>
      this._apiService.getApiAssetUrl(
        `${triggers.tenant}/${ConfigAssetId.certificateIcon}`,
        triggers.language
      )
    )
  );

  public readonly munichReDataProcessingStatementUrl = this.trigger$.pipe(
    map((triggers) =>
      this._apiService.getApiAssetUrl(
        `${triggers.tenant}/${ConfigAssetId.munichReDataProcessingStatement}`
      )
    )
  );

  loadMultiLanguageHtmlAsset$(assetId: string): Observable<string> {
    return this.getTenantPrefixedPath$(assetId).pipe(
      switchMap((path: string) => this._apiService.loadHtmlAsset$(path))
    );
  }

  loadMultiLanguageBlobAsset$(assetId: string): Observable<Blob> {
    return this.getTenantPrefixedPath$(assetId).pipe(
      switchMap((path: string) => this._apiService.loadBlobAsset$(path))
    );
  }

  protected getTenantPrefixedPath$(path: string): Observable<string> {
    return this._tenantService.tenant.pipe(
      filter((tenant) => !!tenant),
      map((tenant) => `${tenant}/${path}`)
    );
  }
}
