import { HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ContentTypeHeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const extendedRequest = this.extendRequestWithContentTypeHeader(request);
    return next.handle(extendedRequest);
  }

  private extendRequestWithContentTypeHeader(request: HttpRequest<any>) {
    const extendedHeaders = request.headers.set(
      'Content-Type',
      'application/json'
    );

    return request.clone({
      headers: extendedHeaders,
    });
  }
}
