import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
  Risk,
  RiskListItem,
} from '../../../../../../../idv-lib/src/lib/interfaces';
import { RequestService } from '../../../../../../../idv-lib/src/lib/services/request/request.service';
import { SearchRiskControlComponent } from '../../../../../../../idv-lib/src/lib/input-controls/search-risk/search-risk-control.component';
import { ReplaceMedicalRiskResponse } from '../../../../../../../idv-lib/src/lib/interfaces/replace-medical-risk-response';
import { ReanswerQuestionsDialogComponent } from '../../../../../../../idv-lib/src/lib/components/reanswer-questions-dialog/reanswer-questions-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ResponsiveService } from '../../../../../../../idv-lib/src/lib/services/responsive/responsive.service';

@Component({
  selector: 'idv-search-disease',
  templateUrl: './search-disease.component.html',
  styleUrls: ['./search-disease.component.scss'],
})
export class SearchDiseaseComponent implements OnDestroy {
  @Input() riskToReplace: Risk;

  @Input() set cancelButtonText(buttonText: string) {
    this._cancelButtonText = buttonText;
  }

  @Input() set confirmButtonText(buttonText: string) {
    this._confirmButtonText = buttonText;
  }

  @Output() added = new EventEmitter<Risk>();
  @Output() onCancel = new EventEmitter<void>();

  @ViewChild(SearchRiskControlComponent)
  searchRiskControl: SearchRiskControlComponent;

  private _cancelButtonText: string;
  private _confirmButtonText: string;
  private _destroyed$ = new Subject<void>();

  constructor(
    private requestService: RequestService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private responsiveService: ResponsiveService
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  get cancelButtonText() {
    return this._cancelButtonText
      ? this._cancelButtonText
      : this.translateService.instant('add-diseases.no-next');
  }

  get confirmButtonText() {
    return this._confirmButtonText
      ? this._confirmButtonText
      : this.translateService.instant('add-diseases.add-button');
  }

  public cancel() {
    this.onCancel.emit();
  }

  get hasSelection() {
    return !!this.searchRiskControl?.currentSelection;
  }

  submit(): void {
    this.searchRiskControl.armInputWarning();

    const currentSelection = this.searchRiskControl.currentSelection;

    if (!this.riskToReplace) {
      //adding
      if (this.isRiskListItem(currentSelection)) {
        // real risk
        this.addRisk(currentSelection);
      } else {
        // gdq
        this.addUnknownRisk(currentSelection);
      }
    } else {
      //replacing
      if (this.isRiskListItem(currentSelection)) {
        // real risk
        this.replaceWithSelectedRisk(currentSelection);
      } else {
        // gdq
        this.replaceWithUnknownRisk(currentSelection);
      }
    }
  }

  private addRisk(selectedRisk: RiskListItem) {
    this.requestService
      .addMedicalRisk(selectedRisk)
      .pipe(take(1))
      .subscribe((risk: Risk) => {
        this.added.emit(risk);
      });
  }

  private addUnknownRisk(unknownRisk: string): void {
    this.requestService
      .addUnknownMedicalRisk(unknownRisk)
      .pipe(take(1))
      .subscribe((risk) => {
        this.added.emit(risk);
      });
  }

  private replaceWithSelectedRisk(selectedRisk: RiskListItem): void {
    this.requestService
      .replaceMedicalRisk(selectedRisk, this.riskToReplace)
      .pipe(take(1))
      .subscribe((response) => this.onRiskReplaced(response));
  }

  private replaceWithUnknownRisk(unknownRisk: string): void {
    this.requestService
      .replaceMedicalRiskWithUnknown(unknownRisk, this.riskToReplace)
      .subscribe((response) => this.onRiskReplaced(response));
  }

  private onRiskReplaced(response: ReplaceMedicalRiskResponse): void {
    this.added.emit(response.risk);

    response?.reAnswerRiskIds?.length > 0 &&
      this.displayReanswerQuestionsModal(response.reAnswerRiskIds);
  }

  private displayReanswerQuestionsModal(riskIds: Array<string>) {
    combineLatest(
      riskIds.map((riskId) =>
        this.requestService.findOneRisk$((r) => r.id === riskId)
      )
    )
      .pipe(
        take(1),
        tap((res) => {
          const labels = res.reduce((flat, toFlatten) => {
            flat.push(toFlatten.labels.join(', '));
            return flat;
          }, new Array<string>());
          this.dialog.open(
            ReanswerQuestionsDialogComponent,
            this.responsiveService.enhanceDialogConfig({
              panelClass: 'big-modal',
              data: { labels },
            })
          );
        })
      )
      .subscribe();
  }

  private isRiskListItem(input: RiskListItem | string): input is RiskListItem {
    return (input as RiskListItem).label !== undefined;
  }
}
