import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { StepStateService } from './step-state.service';
import { AppRoute } from '../../interfaces/app-routing.const';

@Injectable({ providedIn: 'root' })
export class StepGuard {
  constructor(private stepStateService: StepStateService) {}

  canActivateChild(route: ActivatedRouteSnapshot) {
    const targetRoute = route.url[0].path.toLowerCase().trim();
    if (targetRoute === AppRoute.risk) {
      return true;
    } else if (targetRoute === AppRoute.questionnaireSummary) {
      return true;
    }
    return !this.stepStateService.isLocked(targetRoute);
  }
}
