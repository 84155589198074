import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ScriptService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  addScript(src: string, async = true, innerText: string = null) {
    const head = this.document.getElementsByTagName('head')?.item(0);

    if (!!head) {
      const script = document.createElement('script');
      script.async = async;

      if (!!src) {
        script.src = src;
      }
      if (!!innerText) {
        script.innerText = innerText;
      }

      head.appendChild(script);
    }
  }
}
