<idv-close-modal-header></idv-close-modal-header>

<mat-dialog-content>
  {{ reanswerMessage }}
</mat-dialog-content>

<div mat-dialog-actions>
  <button
    type="button"
    mat-button
    [mat-dialog-close]
    color="primary"
  >
    {{ 'shared.ok' | translate }}
  </button>
</div>
