import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CorrelationIdService } from '../../../../../../core-lib';
import {
  EpilogueConfirmation,
  IDV_ENV,
  IdvEnvironment,
} from '../../../interfaces';

@Injectable({ providedIn: 'root' })
export class EpilogueService {
  constructor(
    private httpClient: HttpClient,
    private correlationIdService: CorrelationIdService,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {}

  public getBulletPointsPerProduct(): Observable<{
    [key: string]: Array<string>;
  }> {
    return this.httpClient.get<{ [key: string]: Array<string> }>(
      `${
        this.environment.apiHost
      }/idv/${this.correlationIdService.getCorrelationIdFromSessionToken()}
      /epilogue/bulletpoints`
    );
  }

  public answerEpilogueQuestionnaire(
    epilogueConfirmation: EpilogueConfirmation
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${
        this.environment.apiHost
      }/idv/${this.correlationIdService.getCorrelationIdFromSessionToken()}/epilogue/answer`,
      epilogueConfirmation
    );
  }
}
