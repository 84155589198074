import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { conditionalValidator } from '../../../../../../../../core-lib';
import { LanguageService } from '../../../../../../../../core-lib/src/lib/core/services/language/language.service';
import {
  bloodPressure24hInputRequiredValidator,
  bloodpressureValuesRequiredValidator,
  minMaxValidator,
  systolicMinusDiastolicGreaterThan,
} from '../../../../../../../../core-lib/src/lib/core/validation';
import {
  getSelectedId,
  isSelectedYes,
  Question,
  QuestionGroup,
} from '../../../../../interfaces';
import { QuestionnairesHelperService } from '../../../../../services/risks/questionnaires-helper.service';

const bloodPressure24Availability = {
  true: 'mrc_q_bloodpressure_24h_no_information_available_yes',
  false: 'mrc_q_bloodpressure_24h_no_information_available_no',
};

@Component({
  selector: 'idv-bloodpressure-24h',
  templateUrl: './bloodpressure-24h.component.html',
  styleUrls: ['./bloodpressure-24h.component.scss'],
})
export class Bloodpressure24HComponent implements OnChanges, OnDestroy {
  @Input()
  questionGroup: QuestionGroup;
  @Input()
  backButtonRouterLink: any[];
  @Input()
  readonlyMode = false;

  @Output()
  questionGroupAnswer = new EventEmitter<QuestionGroup>();

  formGroup: UntypedFormGroup;

  areAllErrorMessagesShown = false;
  showRequiredMessage = false;

  mainHeadlineQuestion: Question;
  noInformationAvailableQuestion: Question;
  timeRangeQuestion: Question;
  systolicQuestion: Question;
  systolicBetweenValue: string | null;
  diastolicQuestion: Question;
  diastolicBetweenValue: string | null;
  checkboxText: string;

  private rememberedBloodPressureReadings = {};

  private sub1: Subscription;

  constructor(
    private languageService: LanguageService,
    private questionnairesHelperService: QuestionnairesHelperService
  ) {}

  get thousandSeparator() {
    return this.languageService.getThousandSeparator();
  }

  get decimalSeparator() {
    return this.languageService.getDecimalSeparator();
  }

  get mask() {
    return 'separator.0';
  }

  getLabel$(textKey: string): Observable<string> {
    return this.questionnairesHelperService.getLabel$(
      !this.formGroup.get('noInformationAvailable').value,
      textKey,
      this.readonlyMode
    );
  }

  private findQuestionByName(name: string): Question {
    const question = this.questionGroup.questions.find((q) => q.name === name);
    if (!question) {
      throw Error('question "' + name + '" not in questionGroup');
    }
    return question;
  }

  private setQuestions() {
    this.mainHeadlineQuestion = this.findQuestionByName(
      'q_bloodpressure_24h_heading'
    );

    this.noInformationAvailableQuestion = this.findQuestionByName(
      'q_bloodpressure_24h_no_information_available'
    );
    let selectableValues = this.noInformationAvailableQuestion.selectableValues;
    let selectableValue = selectableValues?.find(
      (answer) => answer.id === bloodPressure24Availability['true']
    );
    this.checkboxText = selectableValue?.displayName;

    this.timeRangeQuestion = this.findQuestionByName(
      'q_bloodpressure_24h_timerange'
    );
    this.systolicQuestion = this.findQuestionByName('q_bloodpressure_24h_sys');

    this.systolicBetweenValue = this.systolicQuestion.numericRange
      ? this.systolicQuestion.numericRange.min +
        ',' +
        this.systolicQuestion.numericRange.max
      : null;

    this.diastolicQuestion = this.findQuestionByName('q_bloodpressure_24h_dia');
    this.diastolicBetweenValue = this.diastolicQuestion.numericRange
      ? this.systolicQuestion.numericRange.min +
        ',' +
        this.systolicQuestion.numericRange.max
      : null;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('questionGroup' in simpleChanges) {
      this.setQuestions();
      this.createFormGroup();
      this.initFormAfterCreation();

      if (this.readonlyMode) {
        this.formGroup.disable();
      } else {
        this.sub1 && this.sub1.unsubscribe();
        this.sub1 =
          this.formGroup.controls.noInformationAvailable.valueChanges.subscribe(
            (newValue) => {
              if (newValue) {
                this.disableFieldsAndStoreEnteredValues();
              } else {
                this.enableFieldsAndRestoreEnteredValues();
              }
            }
          );
      }
    }
  }

  private initFormAfterCreation() {
    const question = this.noInformationAvailableQuestion;
    if (isSelectedYes(question.selectableValues, question.selection)) {
      this.disableFieldsAndStoreEnteredValues();
    }
  }

  disableFieldsAndStoreEnteredValues() {
    this.formGroup.controls.timeRange.disable();
    this.formGroup.controls.bloodPressureReadings.disable();
    this.rememberedBloodPressureReadings =
      this.formGroup.controls.bloodPressureReadings.value;
    this.formGroup.controls.bloodPressureReadings.reset();
  }

  enableFieldsAndRestoreEnteredValues() {
    this.formGroup.controls.timeRange.enable();
    this.formGroup.controls.bloodPressureReadings.enable();
    if (this.rememberedBloodPressureReadings) {
      this.formGroup.controls.bloodPressureReadings.setValue(
        this.rememberedBloodPressureReadings
      );
    }
  }

  submitAnsweredQuestionGroup() {
    this.areAllErrorMessagesShown = true;
    this.showRequiredMessage = true;

    if (this.formGroup.valid || this.formGroup.disabled) {
      const { id, questionGroupType, assessmentFactor } = this.questionGroup;
      const answeredQuestions: Question[] = [];

      const noInformationAvailable = getSelectedId(
        this.noInformationAvailableQuestion.selectableValues,
        this.formGroup.controls.noInformationAvailable.value
      );

      const timeRange = this.formGroup.controls.timeRange as UntypedFormControl;

      answeredQuestions.push(this.mainHeadlineQuestion);

      answeredQuestions.push({
        ...this.noInformationAvailableQuestion,
        selection: noInformationAvailable,
      });

      answeredQuestions.push({
        ...this.timeRangeQuestion,
        selection: timeRange.value,
      });

      answeredQuestions.push({
        ...this.systolicQuestion,
        selection:
          this.formGroup.controls.bloodPressureReadings['controls'][0].value,
      });

      answeredQuestions.push({
        ...this.diastolicQuestion,
        selection:
          this.formGroup.controls.bloodPressureReadings['controls'][1].value,
      });

      const answeredQuestionGroup: QuestionGroup = {
        id,
        questionGroupType,
        assessmentFactor,
        questions: answeredQuestions,
      };
      this.questionGroupAnswer.emit(answeredQuestionGroup);
    }
  }

  hasError(formControl: UntypedFormControl): boolean {
    return formControl && formControl.invalid;
  }

  errorsOnField(formControl: UntypedFormControl): ValidationErrors {
    return this.hasError(formControl) ? formControl.errors : null;
  }

  private createFormGroup() {
    const noInfo = isSelectedYes(
      this.noInformationAvailableQuestion.selectableValues,
      this.noInformationAvailableQuestion.selection
    );

    this.formGroup = new UntypedFormGroup(
      {
        noInformationAvailable: new UntypedFormControl(noInfo),
        timeRange: new UntypedFormControl(
          this.timeRangeQuestion.selection ||
            this.timeRangeQuestion.selectableValues[0].id,
          Validators.required
        ),
        bloodPressureReadings: new UntypedFormArray(
          [
            new UntypedFormControl(this.systolicQuestion.selection, [
              minMaxValidator(this.systolicQuestion.numericRange),
            ]),
            new UntypedFormControl(this.diastolicQuestion.selection, [
              minMaxValidator(this.diastolicQuestion.numericRange),
            ]),
          ],
          [
            bloodpressureValuesRequiredValidator,
            systolicMinusDiastolicGreaterThan,
          ]
        ),
      },
      [
        conditionalValidator(
          () => this.isInformationAvailable(),
          bloodPressure24hInputRequiredValidator
        ),
      ]
    );
  }

  private isInformationAvailable() {
    return (
      this.formGroup && !this.formGroup.controls.noInformationAvailable.value
    );
  }

  ngOnDestroy(): void {
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
  }
}
