<p [innerHtml]="paragraphOneText$ | async"></p>

<div
  class="row"
  *ngIf="checkboxes?.length > 0"
>
  <div
    *ngFor="let checkbox of checkboxes; index as i"
    class="col-12"
  >
    <mat-checkbox
      type="checkbox"
      [ngModel]="checkboxes[i].checked"
      (ngModelChange)="updateCheckboxModel(i, $event)"
      [id]="'e2e-confirm-completeness-checkbox-' + i"
    >
      <div class="pl-2">
        <p [innerHtml]="checkboxes[i].text"></p>
        <ul>
          <li
            *ngFor="let bulletPoint of checkboxes[i].bulletPoints"
            [innerHtml]="bulletPoint"
          ></li>
        </ul>
        <p
          *ngFor="let addOn of checkboxes[i].addOns"
          [innerHtml]="addOn | async"
        ></p>
      </div>
    </mat-checkbox>
  </div>
</div>
