import { Applicant } from './applicant';
import { MedicalRisks } from './medical-risks';
import { PastimeRisks } from './pastime-risks';
import { Product } from './product';
import { Questionnaire } from './questionnaire';
import { RequestSubStatus } from './request-sub-status';
import { AccountPaymentData } from './account-payment-data';
import { IbanPaymentData } from './iban-payment-data';
import { IUnderwriterHints } from './underwriter-hints';
import { InsuredHints } from './insured-hints';

export interface Request {
  correlationId: string;
  requestId: string;
  tenant: string;
  products: Product[];
  requestType: string;
  medicalRisks: MedicalRisks;
  pastimeRisks: PastimeRisks;
  paymentData?: IbanPaymentData | AccountPaymentData;
  applicant: Applicant;
  prologueQuestionnaire?: Questionnaire;
  epilogueQuestionnaire?: Questionnaire;
  subStatus?: RequestSubStatus;
  bmiSourceData: {
    measurementSource: 'physician' | 'underwriter' | 'not-applicable';
    measurementDate?: Date;
    isMeasurementDateRequested: boolean;
  };
  contractId?: string; // only filled for insured
  createdWithLanguage?: string;
  underwriterHints: IUnderwriterHints;
  insuredHints: InsuredHints;
}
