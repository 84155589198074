import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Questionnaire } from '../../../../interfaces/questionnaire';
import { Risk } from '../../../../interfaces/risk';
import { RequestService } from '../../../../services/request/request.service';
import { StepStateService } from '../../../../services/step-navigation/step-state.service';
import { convertDateStringsToDateAndPrecisionDateOfQuestionGroup } from '../../../../utils/risks';
import { MrcTrackingService } from '../../../../../../../core-lib/src/public_api';

@Component({
  selector: 'idv-summary-entity',
  templateUrl: './summary-entity.component.html',
  styleUrls: ['./summary-entity.component.scss'],
})
export class SummaryEntityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() risk: Risk;

  // true when component is used in normal flow, not as router-outlet
  embedded: boolean = false;

  riskId: string;
  riskWithRoutedRisks: Risk[] = [];
  questionnaires: Questionnaire[] = [];
  rootRisk: Risk;

  private requestServiceSubscription: Subscription;

  constructor(
    private requestService: RequestService,
    private stepStateService: StepStateService,
    private activatedRoute: ActivatedRoute,
    private trackingService: MrcTrackingService
  ) {}

  ngOnInit() {
    if (this.risk) {
      this.embedded = true;
      this.riskId = this.risk.id;
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.riskId = params.id;

        this.requestServiceSubscription = this.requestService
          .findOneRisk$((r) => r.id === this.riskId)
          .subscribe((risk: Risk) => {
            this.prepareRisk(risk);
          });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['risk'] && this.risk) {
      this.prepareRisk(this.risk);
    }
  }

  private prepareRisk(risk: Risk) {
    this.rootRisk = risk;
    this.questionnaires = [];
    this.riskWithRoutedRisks = [];
    this.getAllQuestionGroupsFromRisks(this.rootRisk);
    this.questionnaires.forEach((questionnaire) => {
      questionnaire.questionGroups.map((questionGroup) =>
        convertDateStringsToDateAndPrecisionDateOfQuestionGroup(questionGroup)
      );
    });
  }

  goToNextStep() {
    this.stepStateService.goToNextRiskStep();
  }

  getLinkToQuestion$(questionRiskId: string, questionGroupId: string) {
    return this.riskId === questionRiskId
      ? this.stepStateService.getQuestionGroupRoute$(
          this.riskId,
          questionGroupId
        )
      : this.stepStateService.getRoutedQuestionGroupRoute$(
          this.riskId,
          questionRiskId,
          questionGroupId
        );
  }

  onClickEditButton(risk: Risk) {
    this.trackingService.trackEvent('idv_click_edit_button', {
      riskClass: risk.riskClass,
      riskLabel: risk.labels.join(', '),
    });
  }

  private getAllQuestionGroupsFromRisks(risk: Risk) {
    if (risk && risk.questionnaire) {
      this.riskWithRoutedRisks.push(risk);
      this.questionnaires.push(risk.questionnaire);
      if (risk.routedRisks && risk.routedRisks.length > 0) {
        risk.routedRisks.forEach((routedRisk) => {
          this.getAllQuestionGroupsFromRisks(routedRisk);
        });
      }
    }
  }

  ngOnDestroy(): void {
    if (this.requestServiceSubscription) {
      this.requestServiceSubscription.unsubscribe();
    }
  }
}
