import { first } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationGuard {
  constructor(private authenticationService: AuthenticationService) {}

  canActivate() {
    return this.authenticationService.isLoggedIn.pipe(first());
  }
}
