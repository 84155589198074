import { ValidationErrors, AbstractControl } from '@angular/forms';
import { uniq } from 'lodash';
const allowedChars = "abcdefghijklmnopqrstuvwxyz0123456789':,-(+.)/ ";

export function sepaReferenceValidator(
  control: AbstractControl
): ValidationErrors {
  const reference = control.value;
  const invalidChars = findInvalidChars(reference);
  if (invalidChars.length === 0) {
    return null;
  }
  return { invalidCharsContained: { chars: invalidChars } };
}

function findInvalidChars(input: string): string[] {
  if (input === '') {
    return [];
  }
  const inputChars = input.toLowerCase().split('');
  const allInvalidChars = inputChars.map((c) =>
    charNotContainedInString(c, allowedChars)
  );
  const uniqueChars = uniq(allInvalidChars);
  return uniqueChars.filter((v) => v !== null);
}

function charNotContainedInString(char: string, input: string): string {
  if (input.indexOf(char) === -1) {
    return char;
  }
  return null;
}
