<p [innerHtml]="paragraph1$ | async"></p>
<p [innerHtml]="paragraph2$ | async"></p>
<ul>
  <li
    *ngFor="let bulletPoint of bulletPointsParagraphTwo$ | async"
    [innerHtml]="bulletPoint"
  ></li>
</ul>
<p [innerHtml]="paragraph3$ | async"></p>
<ul>
  <li
    *ngFor="let bulletPoint of bulletPointsParagraphThree$ | async"
    [innerHtml]="bulletPoint"
  ></li>
</ul>
<p [innerHtml]="paragraph3AddOn$ | async"></p>
