import { Injectable } from '@angular/core';
import { SessionKey } from '../../../../idv-lib/src/lib/constants/session-key.constants';
import { SessionStorageService } from '../core/services/storage/session-storage.service';

@Injectable({ providedIn: 'root' })
export class SessionTokenStorageService {
  constructor(private sessionStorageService: SessionStorageService) {}

  storeToken(token: string): void {
    this.sessionStorageService.setItem(SessionKey.tokenKey, token);
  }

  removeToken(): void {
    this.sessionStorageService.removeItem(SessionKey.tokenKey);
  }

  getToken(): string {
    return this.sessionStorageService.getItem(SessionKey.tokenKey);
  }

  getTemporaryAuthGUID(): string {
    return this.sessionStorageService.getItem(SessionKey.secondFactorToken);
  }

  storeTemporaryAuthGUID(guid: string): void {
    this.sessionStorageService.setItem(SessionKey.secondFactorToken, guid);
  }

  removeTemporaryAuthGUID(): void {
    this.sessionStorageService.removeItem(SessionKey.secondFactorToken);
  }
}
