import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'idv-handon-information-step',
  templateUrl: './handon-information-step.component.html',
  styleUrls: ['./handon-information-step.component.scss'],
})
export class HandonInformationStepComponent {
  @Output() handonAllowed = new EventEmitter<boolean>();

  constructor() {}

  onSelectionChange(value: boolean) {
    this.handonAllowed.emit(value);
  }
}
