<div class="flex-container">
  <div
    class="main-container"
    [ngClass]="{ 'container container-below-header': !(isCompact$ | async) }"
  >
    <idv-step-navigation
      [stepStates]="hierarchicalStepStates"
      [pseudoSteps]="pseudoSteps"
      [applicantInfo]="applicantInfo"
      [showPaperDownload]="showPaperDownload"
      (downloadPaper)="downloadPaper.emit()"
      class="full-width"
    >
      <router-outlet> </router-outlet>
    </idv-step-navigation>

    <ng-content></ng-content>
  </div>

  <idv-material-footer></idv-material-footer>
</div>
