import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialogConfig } from '@angular/material/dialog';
import { IdvBreakpoints } from './breakpoints';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  public isCompact$: Observable<boolean> = this.breakpointObserver
    .observe('(max-width: 991px)')
    .pipe(map((result: any) => result.matches));

  constructor(private breakpointObserver: BreakpointObserver) {}

  enhanceDialogConfig<D = any>(config: MatDialogConfig<D>): MatDialogConfig<D> {
    const fullscreen =
      ((config.panelClass || '').includes('big-modal') ||
        (config.panelClass || '').includes('super-big-modal')) &&
      this.breakpointObserver.isMatched(IdvBreakpoints.small);

    if (fullscreen) {
      Object.assign(config, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
      });
    }
    return config;
  }
}
