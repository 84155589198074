import { finalize, delay, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActivityTrackingService {
  private pendingActivityCount = new BehaviorSubject(0);

  public hasPendingActivities = this.pendingActivityCount.pipe(
    map((count) => count > 0)
  )
    ? this.pendingActivityCount.asObservable().pipe(
        delay(300),
        map((pendingActivityCount) => pendingActivityCount > 0)
      )
    : this.pendingActivityCount
        .asObservable()
        .pipe(map((pendingActivityCount) => pendingActivityCount > 0));

  constructor(private router: Router) {
    this.router.events.subscribe((routerEvent) =>
      this.processRouterEvent(routerEvent)
    );
  }

  track<T>(activity: Observable<T>) {
    let hasActivityBeenCounted = false;

    const trackedActivity = new Observable<T>((subscriber) => {
      if (!hasActivityBeenCounted) {
        this.increasePendingActivityCount();
        hasActivityBeenCounted = true;
      }
      activity.subscribe(subscriber);
    }).pipe(
      finalize(() => {
        if (hasActivityBeenCounted) {
          this.decreasePendingActivityCount();
        }
      })
    );

    return trackedActivity;
  }

  private processRouterEvent(routerEvent: Event) {
    if (routerEvent instanceof NavigationStart) {
      this.increasePendingActivityCount();
    } else if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.decreasePendingActivityCount();
    }
  }

  private increasePendingActivityCount() {
    this.pendingActivityCount.next(this.pendingActivityCount.getValue() + 1);
  }

  private decreasePendingActivityCount() {
    this.pendingActivityCount.next(this.pendingActivityCount.getValue() - 1);
  }
}
