import { IdvFlavour } from '../../../idv-lib/src/lib/interfaces/idv-flavour';
// tslint:disable-next-line:max-line-length
import { RemoveRiskDialogComponent } from '../app/questionnaires/step-components/questions/remove-risk/remove-risk-dialog/remove-risk-dialog.component';

export const phyFlavor: IdvFlavour = {
  name: 'physician',
  loginStepCount: 4,
  showLoginSteps: true,
  routeAfterLogin: 'payment',
  isEditRiskEnabled: true,
  components: {
    RemoveRiskDialogComponent: RemoveRiskDialogComponent,
  },
  summaryPdfPath: 'pdf/summary',
  apiPrefix: 'idv',
};
