import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Observable,
  of,
  ReplaySubject,
  OperatorFunction,
  combineLatest,
  iif,
} from 'rxjs';
import {
  catchError,
  map,
  take,
  shareReplay,
  tap,
  switchMap,
} from 'rxjs/operators';
import {
  JwtDecodingService,
  SessionTokenStorageService,
  WindowService,
} from '../../../../core-lib';
import {
  instanceOfAccessTokenValidationResponse,
  instanceOfCaptchaValidationResponse,
  Request,
} from '../interfaces';
import { AppRoute } from '../interfaces/app-routing.const';
import { IDV_ENV, IdvEnvironment } from '../interfaces/idv-environment';
import {
  IAuthenticationResult,
  AuthenticationResultType,
} from './model/authentication-result';
import { RequestService } from '../services/request/request.service';
import { LanguageService } from '../../../../core-lib/src/lib/core/services/language/language.service';
import { IFirstFactorAuthenticationResponse } from './model/response/first-factor-authentication.response';
import { MfaStrategy } from './model/mfa-strategy';
import { IAccessTokenLoginRequest } from './model/request/access-token-login.request';
import { SecondFactorAuthenticationRequest } from './model/request/second-factor.authentication.request';
import { SecondFactorAuthenticationFormValue } from './model/form-value/second-factor-authentication.form-value';
import { ISecondFactorAuthenticationResponse } from './model/response/second-factor-authentication.response';

export const expirationQueryParameter = { origin: 'expiration' };

@Injectable()
export class AuthenticationService {
  private logoutTimeout: number;
  private readonly _isLoggedIn = new ReplaySubject<boolean>(1);

  readonly isLoggedIn = this._isLoggedIn.asObservable();

  constructor(
    private httpClient: HttpClient,
    private sessionTokenStorageService: SessionTokenStorageService,
    private router: Router,
    private requestService: RequestService,
    private jwtDecodingService: JwtDecodingService,
    private windowService: WindowService,
    private languageService: LanguageService,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {
    this.initLoginStatus();
  }

  twoFactorLoginFirstStep(
    authenticationToken: string,
    captchaToken: string,
    tenant: string
  ): Observable<IAuthenticationResult> {
    const url = `${this.environment.apiHost}/idv/auth/firstFactor`;
    const loginRequest: IAccessTokenLoginRequest = {
      authenticationToken,
      captchaToken,
      tenant,
    };

    this.sessionTokenStorageService.removeToken();

    return this.httpClient
      .post<IFirstFactorAuthenticationResponse>(url, loginRequest)
      .pipe(
        switchMap((res) =>
          iif(
            () => res.mfaType === MfaStrategy.NoAuth,
            //NoAuth Flow
            of(res).pipe.apply(of(res), this.getAuthenticationPipe(1)),
            //2FA Flow
            of({}).pipe(
              tap(() =>
                this.sessionTokenStorageService.storeTemporaryAuthGUID(
                  res.sessionToken
                )
              ),
              map(() => {
                return {
                  success: true,
                  resultType: AuthenticationResultType.succeededFirstStep,
                  payload: res,
                };
              })
            )
          )
        ),
        catchError((error: any) => {
          const result = this.mapToAuthenticationResult(error, 1);
          this.setLoggedOut();
          return of({
            ...result,
            requestLanguage: null,
            mfaType: null,
          });
        })
      );
  }

  twoFactorLoginSecondStep(
    formValue: SecondFactorAuthenticationFormValue,
    secondFactorSuffix: string,
    tenant: string
  ): Observable<IAuthenticationResult> {
    const url = `${this.environment.apiHost}/idv/auth/secondFactor/${secondFactorSuffix}`;

    const payload: SecondFactorAuthenticationRequest = {
      ...formValue,
      tempKey: this.sessionTokenStorageService.getTemporaryAuthGUID(),
      tenant: tenant,
    };

    if (!payload.tempKey) {
      // should only happen when user deliberately clears session storage while in the process of login
      return of({
        success: false,
        resultType: AuthenticationResultType.expired,
      });
    }

    const sessionToken$ =
      this.httpClient.post<ISecondFactorAuthenticationResponse>(url, payload);
    return sessionToken$.pipe.apply(
      sessionToken$,
      this.getAuthenticationPipe(2)
    );
  }

  private getAuthenticationPipe(
    stepNumber: number
  ): OperatorFunction<IFirstFactorAuthenticationResponse, any>[] {
    return [
      switchMap(({ sessionToken }) => this.setLoggedIn(sessionToken)),

      map(() => {
        return {
          success: true,
          resultType: AuthenticationResultType.succeeded,
        };
      }),

      take(1),

      catchError((error: any) => {
        const result = this.mapToAuthenticationResult(error, stepNumber);
        return of(result);
      }),
    ];
  }

  private setLoggedIn(sessionToken: string): Observable<Request> {
    this.setAutoLogoutTimeout(sessionToken);
    this.sessionTokenStorageService.storeToken(sessionToken);
    this.sessionTokenStorageService.removeTemporaryAuthGUID();

    return combineLatest([
      this.requestService.refetchRequest(),
      this.languageService.applicationLanguageAvailable$.pipe(shareReplay(1)),
    ]).pipe(
      switchMap(([request, langAvailable]) => {
        if (langAvailable) {
          this._isLoggedIn.next(true);
        }
        return of(request);
      })
    );
  }

  private setLoggedOut(completely = true) {
    this.clearAutoLogoutTimeout();
    this.sessionTokenStorageService.removeToken();
    if (completely) {
      this.sessionTokenStorageService.removeTemporaryAuthGUID();
    }
    this._isLoggedIn.next(false);
    this.requestService.clearRequest();
  }

  private mapToAuthenticationResult(
    error: any,
    step: number
  ): IAuthenticationResult {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 410) {
        return {
          success: false,
          resultType: AuthenticationResultType.expired,
        };
      } else if (error.status === 422) {
        const details = error.error;
        if (instanceOfAccessTokenValidationResponse(details)) {
          return {
            success: false,
            resultType: !details.isAccessTokenValid
              ? step === 1
                ? AuthenticationResultType.tokenInvalid
                : AuthenticationResultType.secondFactorInvalid
              : !details.isTenantValid
              ? AuthenticationResultType.tenantInvalid
              : AuthenticationResultType.unknown,
            requestStatus: details.requestStatus,
          };
        }
        if (instanceOfCaptchaValidationResponse(details)) {
          return {
            success: false,
            resultType: AuthenticationResultType.captcha,
            captchaErrorCodes: details.captchaErrorCodes,
          };
        }
        return {
          success: false,
          resultType: AuthenticationResultType.unknown,
        };
      }
    }
    throw error;
  }

  logOut() {
    this.setLoggedOut();
  }

  private initLoginStatus() {
    const window = this.windowService.window;
    // We cannot use the Router here because the router.url is not initialized correctly yet for some reason
    // ^^^^^^^^^ This is a service, angular always injects the Root-Route when initializing a service,
    // ^^^^^^^^^ therefore we will never get the ActivatedRoute in a service.
    const currentRoute = window.location?.pathname;
    const sessionToken = this.sessionTokenStorageService.getToken();

    if (!this.isLoginRoute(currentRoute) && !!sessionToken) {
      this.setLoggedIn(sessionToken).pipe(take(1)).subscribe();
    } else {
      // being in 2nd step of 2-factor-auth is a very special case: not logged in nor logged out
      const completely =
        !this.sessionTokenStorageService.getTemporaryAuthGUID();
      this.setLoggedOut(completely);
    }
  }

  private setAutoLogoutTimeout(sessionToken: string) {
    this.clearAutoLogoutTimeout();
    const millisecondsUntilSessionExpiry =
      this.getMillisecondsUntilSessionExpiry(sessionToken);
    const window = this.windowService.window;
    this.logoutTimeout = window.setTimeout(() => {
      this.logOut();
      this.router.navigate([AppRoute.login], {
        queryParams: expirationQueryParameter,
      });
    }, millisecondsUntilSessionExpiry);
  }

  private clearAutoLogoutTimeout() {
    if (this.logoutTimeout) {
      const window = this.windowService.window;
      window.clearTimeout(this.logoutTimeout);
      this.logoutTimeout = undefined;
    }
  }

  private getMillisecondsUntilSessionExpiry(sessionToken: string) {
    const decodedSessionToken = this.jwtDecodingService.decodeJwt(sessionToken);
    const expiryTimestampMs =
      Number.parseInt(decodedSessionToken.exp, 10) * 1000;
    const expiryTimeoutMs = expiryTimestampMs - Date.now();
    return expiryTimeoutMs;
  }

  private isLoginRoute(currentRoute: string): boolean {
    return currentRoute === AppRoute.login;
  }
}
