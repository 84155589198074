import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ArrayToStringPipe } from '../../../../../core-lib/src/lib/components/pipes/array-to-string.pipe';
import { AppRoute } from '../../../../../idv-lib/src/lib/interfaces/app-routing.const';
import { RequestService } from '../../../../../idv-lib/src/lib/services/request/request.service';
import { ResponsiveService } from '../../../../../idv-lib/src/lib/services/responsive/responsive.service';
import { StepStateService } from '../../../../../idv-lib/src/lib/services/step-navigation/step-state.service';
import { DownloadPdfDialogComponent } from '../../components/download-pdf-dialog/download-pdf-dialog.component';
import { TenantConfigService } from '../../../../../idv-lib/src/lib/services/config/tenant-config.service';
import { Request } from '../../../../../idv-lib/src/public_api';

@Component({
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit, OnDestroy {
  isCompact$: Observable<boolean>;

  applicantInfo$: Observable<string> = of(null);
  showPaperDownload$: Observable<boolean>;

  constructor(
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private stepStateService: StepStateService,
    private requestService: RequestService,
    private tenantConfigService: TenantConfigService,
    private router: Router,
    private translateService: TranslateService,
    private arrayToString: ArrayToStringPipe
  ) {}

  getHierarchicalStepStates() {
    return this.stepStateService.getHierarchicalStepStates();
  }

  ngOnInit() {
    this.isCompact$ = this.responsiveService.isCompact$;

    this.requestService.request
      .pipe(
        filter((request) => !!request),
        map((request) => {
          if (!request.applicant) {
            return {} as Request;
          }
          return request;
        })
      )
      .subscribe((request: Request) => {
        this.applicantInfo$ = this.personalInfo(request);
        this.translateService.get(this.genderKey(request)).subscribe(() => {
          this.applicantInfo$ = this.personalInfo(request);
        });
        this.translateService.onLangChange.subscribe(() => {
          this.applicantInfo$ = this.personalInfo(request);
        });
      });

    this.showPaperDownload$ = this.tenantConfigService.tenantConfig$.pipe(
      map((config) => config.allowsPaperDownload)
    );
  }

  genderKey(request: Request) {
    const gender = request.applicant && request.applicant.gender;
    if (['male', 'female'].includes(gender)) {
      return ['shared', 'genders', gender].join('.');
    } else {
      return '';
    }
  }

  personalInfo(request: Request): Observable<string> {
    let keyGender = this.genderKey(request);
    let genderTranslated =
      keyGender == '' ? '' : this.translateService.instant(keyGender);
    let personalInfo = this.arrayToString.transform([
      request.applicant.initials,
      genderTranslated,
      request.applicant.birthYear,
    ]);
    return of(personalInfo);
  }

  get isDownloadPageActive() {
    return this.router.url.includes(AppRoute.download);
  }

  downloadPaper() {
    this.dialog.open(
      DownloadPdfDialogComponent,
      this.responsiveService.enhanceDialogConfig({
        panelClass: 'big-modal',
        data: {},
      })
    );
  }

  ngOnDestroy(): void {}
}
