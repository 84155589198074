import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  createMaxDecimalPlacesValidator,
  createNumericValueRangeValidator,
  requiredNumber,
} from '../../../../../../../core-lib';
import { Question } from '../../../../interfaces';
import { QuestionGroup } from '../../../../interfaces';
import { StepStateService } from '../../../../services/step-navigation/step-state.service';
import { isQuestionAnswerable } from '../question-utils/is-question-answerable';
import { MrcTrackingService } from '../../../../../../../core-lib';

const heightUnitQuestionId = 'prologue_q_bmi_heightUnit';
const heightMetricSelectionId = 'prologue_q_bmi_heightUnit_metric';
const heightImperialSelectionId = 'prologue_q_bmi_heightUnit_imperial';
const heightQuestionId = 'prologue_q_bmi_height';
const heightFeetQuestionId = 'prologue_q_bmi_heightFeet';
const heightInchesQuestionId = 'prologue_q_bmi_heightInches';
const weightUnitQuestionId = 'prologue_q_bmi_weightUnit';
const weightMetricSelectionId = 'prologue_q_bmi_weightUnit_metric';
const weightImperialSelectionId = 'prologue_q_bmi_weightUnit_imperial';
const weightImperialPoundsOnlySelectionId =
  'prologue_q_bmi_weightUnit_imperialPoundsOnly';
const weightQuestionId = 'prologue_q_bmi_weight';
const weightStonesQuestionId = 'prologue_q_bmi_weightStones';
const weightPoundsQuestionId = 'prologue_q_bmi_weightPounds';
const weightPoundsOnlyQuestionId = 'prologue_q_bmi_weightPoundsOnly';

@Component({
  selector: 'idv-height-weight-group-control',
  templateUrl: './height-weight-group-control.component.html',
  styleUrls: ['./height-weight-group-control.component.scss'],
})
export class HeightWeightGroupControlComponent implements OnChanges {
  @Input()
  questionGroup: QuestionGroup;

  @Output()
  questionGroupAnswer = new EventEmitter<QuestionGroup>();

  public formGroup: UntypedFormGroup;
  alreadyHasValues = false;

  public areAllErrorMessagesShown = false;

  private rememberedValues: { [questionId: string]: number } = {};
  private heightUnitSelection: string;
  private weightUnitSelection: string;

  constructor(
    private stepStateService: StepStateService,
    private trackingService: MrcTrackingService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('questionGroup' in changes) {
      this.updateFormGroup();
    }
  }

  get noInformationAvailableQuestion() {
    return this.questionGroup.questions[0];
  }
  get noInformationAvailableFormControlName() {
    return this.noInformationAvailableQuestion.id;
  }
  get noInformationAvailableCheckedSelectionId() {
    return this.noInformationAvailableQuestion.selectableValues[1].id;
  }
  get noInformationAvailableUncheckedSelectionId() {
    return this.noInformationAvailableQuestion.selectableValues[0].id;
  }

  get heightUnitFormControlName() {
    return this.heightUnitQuestion?.id;
  }
  get weightUnitFormControlName() {
    return this.weightUnitQuestion?.id;
  }

  get heightUnitQuestion() {
    return this.questionGroup.questions.find(
      (x) => x.id === heightUnitQuestionId
    );
  }
  get weightUnitQuestion() {
    return this.questionGroup.questions.find(
      (x) => x.id === weightUnitQuestionId
    );
  }

  get heightQuestions() {
    return this.questionGroup.questions.filter(
      (x) => x.id.includes('height') && !x.id.includes('Unit')
    );
  }
  get weightQuestions() {
    return this.questionGroup.questions.filter(
      (x) => x.id.includes('weight') && !x.id.includes('Unit')
    );
  }

  get getRelevantHeightQuestions() {
    if (this.heightUnitSelection) {
      return this.getQuestionsForUnitSelection(this.heightUnitSelection);
    }

    return this.heightQuestions;
  }
  get getRelevantWeightQuestions() {
    if (this.weightUnitSelection) {
      return this.getQuestionsForUnitSelection(this.weightUnitSelection);
    }

    return this.weightQuestions;
  }

  public getExampleNumberValue(questionId: string) {
    switch (questionId) {
      case heightQuestionId:
        return '180';
      case heightFeetQuestionId:
        return '5';
      case heightInchesQuestionId:
        return '10';
      case weightQuestionId:
        return '80';
      case weightStonesQuestionId:
        return '12';
      case weightPoundsQuestionId:
        return '8';
      case weightPoundsOnlyQuestionId:
        return '170';
      default:
        return '';
    }
  }

  private getQuestionsForUnitSelection(selection: string): Question[] {
    const relatedQuestionIds = this.getQuestionIdsForUnitSelection(selection);
    return this.questionGroup.questions.filter((x) =>
      relatedQuestionIds.includes(x.id)
    );
  }

  private getQuestionIdsForUnitSelection(selection: string): string[] {
    switch (selection) {
      case heightImperialSelectionId:
        return [heightFeetQuestionId, heightInchesQuestionId];
      case heightMetricSelectionId:
        return [heightQuestionId];
      case weightImperialSelectionId:
        return [weightStonesQuestionId, weightPoundsQuestionId];
      case weightImperialPoundsOnlySelectionId:
        return [weightPoundsOnlyQuestionId];
      case weightMetricSelectionId:
        return [weightQuestionId];
      default:
        return [];
    }
  }

  private betweenValidator(question: Question): ValidatorFn[] {
    if (question.numericRange) {
      return [
        createNumericValueRangeValidator(
          question.numericRange.min,
          question.numericRange.max
        ),
      ];
    }
    return [];
  }

  private requiredIntMinMax(question: Question): ValidatorFn[] {
    return [
      requiredNumber,
      createMaxDecimalPlacesValidator(0),
      ...this.betweenValidator(question),
    ];
  }

  submitAnsweredQuestionGroup() {
    if (!this.alreadyHasValues) {
      this.areAllErrorMessagesShown = true;

      if (this.formGroup.valid || this.formGroup.disabled) {
        const { id, questions } = this.questionGroup;
        const questionGroupType = this.questionGroup.questionGroupType;
        const assessmentFactor = this.questionGroup.assessmentFactor;
        const answeredQuestions = questions.map((question) =>
          this.createAnsweredQuestion(question)
        );

        const answeredQuestionGroup: QuestionGroup = {
          id,
          questions: answeredQuestions,
          questionGroupType: questionGroupType,
          assessmentFactor: assessmentFactor,
        };

        this.trackingService.trackEvent('idv_next_height_weight_clicked');
        this.questionGroupAnswer.emit(answeredQuestionGroup);
      }
    } else {
      this.trackingService.trackEvent('idv_next_height_weight_clicked');
      this.stepStateService.goToNextRiskStep();
    }
  }

  hasError(formControlName: string): boolean {
    const formControl = this.getField(formControlName);
    return formControl && formControl.invalid && this.areAllErrorMessagesShown;
  }

  getErrorsToDisplayFor(formControlName: string): ValidationErrors {
    return this.hasError(formControlName)
      ? this.getField(formControlName).errors
      : null;
  }

  private getField(formControlName: string): AbstractControl {
    return this.formGroup && this.formGroup.get(formControlName);
  }

  private updateFormGroup() {
    this.areAllErrorMessagesShown = false;
    if (this.questionGroup && this.questionGroup.questions) {
      this.heightUnitSelection = this.heightUnitQuestion?.selection as string;
      this.weightUnitSelection = this.weightUnitQuestion?.selection as string;
      const relevantQuestions = this.getRelevantHeightQuestions.concat(
        this.getRelevantWeightQuestions
      );

      this.alreadyHasValues = relevantQuestions.some(
        (question) => question.selection != null
      );

      const validationsByQuestionId: { [questionId: string]: ValidatorFn[] } =
        {};
      relevantQuestions.forEach(
        (question) =>
          (validationsByQuestionId[question.id] =
            this.requiredIntMinMax(question))
      );

      const formGroupStructure = this.questionGroup.questions.reduce(
        (accumulator, question) => {
          if (isQuestionAnswerable(question)) {
            if (question.id === this.noInformationAvailableQuestion.id) {
              const selection =
                question.selection ===
                this.noInformationAvailableCheckedSelectionId;
              accumulator[question.id] = new UntypedFormControl(selection);
            } else {
              accumulator[question.id] = new UntypedFormControl(
                question.selection,
                validationsByQuestionId[question.id]
              );
            }
          }
          return accumulator;
        },
        {}
      );

      this.formGroup = new UntypedFormGroup(formGroupStructure);

      if (
        this.alreadyHasValues ||
        this.formGroup.controls[this.noInformationAvailableFormControlName]
          .value === true
      ) {
        this.formGroup.disable();
      }

      this.formGroup.controls[
        this.noInformationAvailableFormControlName
      ].valueChanges.subscribe((value) => {
        if (value === true) {
          this.heightQuestions.forEach((x) => {
            const formControl = this.formGroup.controls[x.id];
            this.rememberedValues[x.id] = formControl.value;
            formControl.disable();
            formControl.reset();
          });
          this.weightQuestions.forEach((x) => {
            const formControl = this.formGroup.controls[x.id];
            this.rememberedValues[x.id] = formControl.value;
            formControl.disable();
            formControl.reset();
          });
          if (this.heightUnitQuestion) {
            const heightUnitFormControl =
              this.formGroup.controls[this.heightUnitQuestion.id];
            this.rememberedValues[this.heightUnitQuestion.id] =
              heightUnitFormControl.value;
            heightUnitFormControl.setValue(
              this.heightUnitQuestion?.selection as string
            );
          }
          if (this.weightUnitQuestion) {
            const weightUnitFormControl =
              this.formGroup.controls[this.weightUnitQuestion.id];
            this.rememberedValues[this.weightUnitQuestion.id] =
              weightUnitFormControl.value;
            weightUnitFormControl.setValue(
              this.weightUnitQuestion?.selection as string
            );
          }
          this.disableFormGroupIfExists(this.heightUnitFormControlName);
          this.disableFormGroupIfExists(this.weightUnitFormControlName);
        } else if (value === false) {
          this.enableFormGroupIfExists(this.heightUnitFormControlName);
          this.enableFormGroupIfExists(this.weightUnitFormControlName);
          if (this.heightUnitQuestion) {
            const heightUnitFormControl =
              this.formGroup.controls[this.heightUnitQuestion.id];
            heightUnitFormControl.setValue(
              this.rememberedValues[this.heightUnitQuestion.id]
            );
          }
          if (this.weightUnitQuestion) {
            const weightUnitFormControl =
              this.formGroup.controls[this.weightUnitQuestion.id];
            weightUnitFormControl.setValue(
              this.rememberedValues[this.weightUnitQuestion.id]
            );
          }
          this.heightQuestions.forEach((x) => {
            const formControl = this.formGroup.controls[x.id];
            formControl.setValue(this.rememberedValues[x.id]);
            formControl.enable();
          });
          this.weightQuestions.forEach((x) => {
            const formControl = this.formGroup.controls[x.id];
            formControl.setValue(this.rememberedValues[x.id]);
            formControl.enable();
          });
        }
      });

      if (this.heightUnitSelection && this.weightUnitSelection) {
        // if there is no unit selection, than it is not allowed for this tenant
        this.formGroup.controls[
          this.heightUnitFormControlName
        ].valueChanges.subscribe((value) => {
          this.getQuestionsForUnitSelection(this.heightUnitSelection).forEach(
            (x) => {
              const formControl = this.formGroup.controls[x.id];
              formControl.clearValidators();
              formControl.reset();
            }
          );
          this.heightUnitSelection = value;
          this.getQuestionsForUnitSelection(value).forEach((x) => {
            this.formGroup.controls[x.id].setValidators(
              this.requiredIntMinMax(x)
            );
          });
        });
        this.formGroup.controls[
          this.weightUnitFormControlName
        ].valueChanges.subscribe((value) => {
          this.getQuestionsForUnitSelection(this.weightUnitSelection).forEach(
            (x) => {
              const formControl = this.formGroup.controls[x.id];
              formControl.clearValidators();
              formControl.reset();
            }
          );
          this.weightUnitSelection = value;
          this.getQuestionsForUnitSelection(value).forEach((x) => {
            this.formGroup.controls[x.id].setValidators(
              this.requiredIntMinMax(x)
            );
          });
        });
      }
    } else {
      this.formGroup = new UntypedFormGroup({});
    }
  }

  private disableFormGroupIfExists(formControlName: string) {
    const formControl = this.formGroup.controls[formControlName];
    if (formControl) {
      formControl.disable();
    }
  }
  private enableFormGroupIfExists(formControlName: string) {
    const formControl = this.formGroup.controls[formControlName];
    if (formControl) {
      formControl.enable();
    }
  }

  private createAnsweredQuestion(question: Question): Question {
    if (question.id === this.noInformationAvailableQuestion.id) {
      return isQuestionAnswerable(question)
        ? {
            ...question,
            selection:
              this.formGroup.controls[question.id].value === true
                ? this.noInformationAvailableCheckedSelectionId
                : this.noInformationAvailableUncheckedSelectionId,
          }
        : {
            ...question,
          };
    }
    return isQuestionAnswerable(question)
      ? {
          ...question,
          selection: this.formGroup.controls[question.id].value,
        }
      : {
          ...question,
        };
  }
}
