import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SelectableValue } from '../../interfaces';

@Component({
  selector: 'idv-check-box-control',
  templateUrl: './check-box-control.component.html',
  styles: [
    `
      .mat-checkbox {
        font-weight: 500 !important;
      }
    `,
  ],
})
export class CheckBoxControlComponent {
  @Input() selectableValues: SelectableValue[];
  @Input() formControlToUse: UntypedFormControl;

  value: string;

  constructor() {}

  onChange(event: MatCheckboxChange) {
    this.value = event.checked
      ? this.selectableValues[0].id
      : (this.selectableValues[1] && this.selectableValues[1].id) || null;
    this.formControlToUse.setValue(this.value);
  }
}
