import { Injectable, OnDestroy } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Subscription, Observable, iif } from 'rxjs';
import { first, map, filter } from 'rxjs/operators';
import { Request } from '../../interfaces';
import { AppRoute } from '../../interfaces/app-routing.const';
import { RequestService } from '../request/request.service';
import { TenantService } from '../tenant/tenant.service';
import { CompositeStepState } from './composite-step-state';

export enum StepName {
  intro = 'intro',
  payment = 'payment',
  prologue = 'prologue',
  add = 'add',
  complete = 'complete',
  download = 'download',
  underwriterHints = 'underwriter-hints',
}

@Injectable()
export abstract class StepStateService implements OnDestroy {
  protected stepStateRoot: CompositeStepState;
  protected stepStateSubscription: Subscription;

  protected constructor(
    protected requestService: RequestService,
    protected router: Router,
    protected tenantService: TenantService
  ) {
    this.stepStateRoot = new CompositeStepState('uninitialized', []);
    this.stepStateSubscription = this.requestService.request
      .pipe(filter((request) => !!request))
      .subscribe((request) => {
        this.updateSteps(request);
      });
  }

  protected abstract updateSteps(request: Request): CompositeStepState;

  protected abstract getStepAfterRiskSteps(): string;

  ngOnDestroy() {
    this.stepStateSubscription.unsubscribe();
  }

  public getHierarchicalStepStates() {
    return this.stepStateRoot.hierarchicalStepStates;
  }

  isCompleted(name: string): boolean {
    const step = this.getStep(name);
    return step ? step.isCompleted : false;
  }

  isLocked(name: string): boolean {
    const step = this.getStep(name);
    return step ? step.isLocked : false;
  }

  complete(name: string) {
    const step = this.getStep(name);
    if (step) {
      step.isCompleted = true;
    }
  }

  uncomplete(name: string) {
    const step = this.getStep(name);
    if (step) {
      step.isCompleted = false;
    }
  }

  unlock(name: string) {
    const step = this.getStep(name);
    if (step) {
      step.isLocked = false;
    }
  }

  getFlatStepStates() {
    return this.stepStateRoot.flatStepStates;
  }

  abstract areAllRequiredStepsCompleted(): boolean;

  getNextNotCompletedRiskStepName() {
    const remainingUncompletedRiskSteps = this.getFlatStepStates().filter(
      (step) => step.isRisk && !step.isCompleted
    );
    if (remainingUncompletedRiskSteps.length > 0) {
      return remainingUncompletedRiskSteps[0].name;
    }
    return null;
  }

  getFirstNotCompletedAndNotLockedStepRoute() {
    const steps = this.getFlatStepStates().filter(
      (step) => !step.isCompleted && !step.isLocked
    );
    if (steps && steps.length > 0) {
      if (steps[0].isRisk) {
        return [AppRoute.overview, AppRoute.risk, steps[0].name];
      }
      return [AppRoute.overview, steps[0].name];
    }
    return null;
  }

  getNextNotCompletedAndNotLockedStepRoute(currentStepName: string) {
    const steps = this.getFlatStepStates().filter(
      (step) =>
        !step.isCompleted && !step.isLocked && step.name !== currentStepName
    );
    if (steps && steps.length > 0) {
      if (steps[0].isRisk) {
        return [AppRoute.overview, AppRoute.risk, steps[0].name];
      }
      return [AppRoute.overview, steps[0].name];
    }
    return null;
  }

  lockAllStepsExcept(excepted: string) {
    this.getFlatStepStates()
      .filter((step) => step.name !== excepted)
      .forEach((step) => {
        step.isLocked = true;
      });
  }

  stepExists(name: string) {
    return this.getFlatStepStates().some((s) => s.name === name);
  }

  protected getStep(name: string) {
    return this.getFlatStepStates().find((s) => s.name === name);
  }

  protected areAllQuestionnairesCompleted() {
    return this.getFlatStepStates()
      .filter((steps) => steps.isRisk)
      .every((step) => step.isCompleted);
  }

  // ---------- formerly found in StepNavigationService: ---------------

  goToRisk(riskId: string) {
    this.getRiskRoute$(riskId)
      .pipe(first())
      .subscribe((route: string[]) => {
        this.router.navigate(route);
      });
  }

  getRiskRoute$(riskId: string): Observable<string[]> {
    return this.tenantService.tenant.pipe(
      map((tenant) => ['/', tenant, AppRoute.overview, AppRoute.risk, riskId])
    );
  }

  getReplaceRiskRoute$(riskId: string): Observable<string[]> {
    return this.tenantService.tenant.pipe(
      map((tenant) => [
        '/',
        tenant,
        AppRoute.overview,
        AppRoute.risk,
        AppRoute.replace,
        riskId,
      ])
    );
  }

  getQuestionGroupRoute$(riskId: string, questionGroupId: string) {
    return this.tenantService.tenant.pipe(
      map((tenant) => [
        '/',
        tenant,
        AppRoute.overview,
        AppRoute.risk,
        riskId,
        questionGroupId,
      ])
    );
  }

  getRoutedQuestionGroupRoute$(
    riskId: string,
    routedToRiskId: string,
    questionGroupId: string
  ) {
    return this.tenantService.tenant.pipe(
      map((tenant) => [
        '/',
        tenant,
        AppRoute.overview,
        AppRoute.risk,
        riskId,
        AppRoute.redirect,
        routedToRiskId,
        questionGroupId,
      ])
    );
  }

  goToQuestionGroup(
    riskId: string,
    questionGroupId: string,
    routedToRiskId?: string
  ) {
    iif(
      () => !!routedToRiskId,
      this.getRoutedQuestionGroupRoute$(
        riskId,
        routedToRiskId,
        questionGroupId
      ),
      this.getQuestionGroupRoute$(riskId, questionGroupId)
    )
      .pipe(first())
      .subscribe((route: string[]) => {
        this.router.navigate(route);
      });
  }

  goToStepAfterRiskSteps() {
    this.tenantService.tenant.pipe(first()).subscribe((tenant) => {
      const step = this.getStepAfterRiskSteps();
      this.router.navigate([tenant, AppRoute.overview, step]);
    });
  }

  goToNextRiskStep() {
    this.tenantService.tenant.pipe(first()).subscribe((tenant) => {
      const nextStep = this.getNextNotCompletedRiskStepName();
      if (nextStep) {
        this.router.navigate([
          tenant,
          AppRoute.overview,
          AppRoute.risk,
          nextStep,
        ]);
      } else {
        this.goToStepAfterRiskSteps();
      }
    });
  }

  goToRiskSummaryStep(riskId: string) {
    this.tenantService.tenant.pipe(first()).subscribe((tenant) => {
      if (this.isCompleted(riskId)) {
        this.router.navigate([
          tenant,
          AppRoute.overview,
          AppRoute.questionnaireSummary,
          riskId,
        ]);
      }
    });
  }

  navigateToNextStep() {
    this.tenantService.tenant.pipe(first()).subscribe((tenant) => {
      const routeToNextStep = this.getFirstNotCompletedAndNotLockedStepRoute();
      if (routeToNextStep) {
        this.router.navigate([tenant, ...routeToNextStep]);
      } else {
        throw new Error('Unable to navigate to next step');
      }
    });
  }

  canSkipCurrentStep(currentStepName: string): boolean {
    const nextStep =
      this.getNextNotCompletedAndNotLockedStepRoute(currentStepName);
    return !!nextStep;
  }

  skipCurrentStep(currentStepName: string): void {
    this.tenantService.tenant.pipe(first()).subscribe((tenant) => {
      const routeToNextStep =
        this.getNextNotCompletedAndNotLockedStepRoute(currentStepName);

      if (!!routeToNextStep) {
        this.router.navigate([tenant, ...routeToNextStep]);
      }
    });
  }

  navigateToLogout(language: string) {
    this.tenantService.tenant.pipe(first()).subscribe((tenant) => {
      const queryParams: Params = {
        lang: language,
      };
      if (tenant) {
        this.router.navigate([AppRoute.logout, tenant], { queryParams });
      } else {
        this.router.navigate([AppRoute.logout], { queryParams });
      }
    });
  }
}
