export interface CaptchaValidationResponse {
  isCaptchaValid: boolean;
  captchaErrorCodes?: string[];
}

export function instanceOfCaptchaValidationResponse(
  object: any
): object is CaptchaValidationResponse {
  return 'isCaptchaValid' in object;
}
