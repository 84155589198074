import { HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDV_ENV, IdvEnvironment } from '../interfaces/idv-environment';

@Injectable()
export class ApplicationNameHeaderInterceptor implements HttpInterceptor {
  constructor(@Inject(IDV_ENV) private environment: IdvEnvironment) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const extendedRequest =
      this.extendRequestWithApplicationNameHeader(request);
    return next.handle(extendedRequest);
  }

  private extendRequestWithApplicationNameHeader(request: HttpRequest<any>) {
    const applicationName = this.environment.application;
    const extendedHeaders = request.headers.set(
      'application-name',
      applicationName
    );

    return request.clone({
      headers: extendedHeaders,
    });
  }
}
