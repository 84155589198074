<main class="dark-background">
  <div class="modal-position">
    <div class="error-message-view container-fluid">
      <h1 class="text-center text-primary">
        <img
          class="error-icon"
          src="assets/img/error-icon.png"
        />
      </h1>
      <h2 class="text-center">{{ 'error-screen.headline' | translate }}</h2>
      <p class="text-center">
        {{ 'error-screen.an-error-occurred' | translate }}
      </p>

      <div *ngIf="advancedErrorMode">
        <h5>{{ 'error-screen.advanced-error-headline' | translate }}</h5>
        <div class="small mb-4">
          <h6>{{ 'shared.version-info' | translate }}:</h6>
          <ng-container
            *ngFor="let applicationVersion of applicationVersions | async"
          >
            {{ applicationVersion.name }}: {{ applicationVersion.version }}
            <br />
          </ng-container>
        </div>
        <h6>{{ 'error-screen.advanced-error-url' | translate }}</h6>
        <code>{{ currentUrl }}</code>
        <h6>{{ 'error-screen.advanced-error-time' | translate }}</h6>
        <code>{{ currentTime | date : 'long' }}</code>
        <h6>{{ 'error-screen.advanced-error-details' | translate }}</h6>
        <code>{{ uncaughtError | async }}</code>
      </div>

      <div class="dialog-action">
        <button
          color="primary"
          mat-raised-button
          (click)="reloadWindow()"
        >
          {{ 'error-screen.reload-button-label' | translate }}
        </button>
      </div>
    </div>
  </div>
</main>
<idv-footer></idv-footer>
