import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValid } from 'iban';

export function ibanValidator(control: AbstractControl): ValidationErrors {
  const iban = control.value;

  return isValid(iban)
    ? null
    : {
        ibanMustMatchChecksum: true,
      };
}
