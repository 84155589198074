import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RemoveRiskReasonType } from '../../../../../../../../idv-lib/src/lib/interfaces';

@Component({
  selector: 'idv-remove-risk-reasons',
  templateUrl: './remove-risk-reasons.component.html',
  styles: [
    `
      .mat-radio-button {
        font-weight: 500 !important;
      }
    `,
  ],
})
export class RemoveRiskReasonsComponent implements OnInit {
  @Output() reasonChange = new EventEmitter<RemoveRiskReasonType>();

  ngOnInit() {}

  onSelectionChange(value: RemoveRiskReasonType) {
    this.reasonChange.emit(value);
  }
}
