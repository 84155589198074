import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { initializeLocale } from './core/initialize-locale';

const importsAndExports = [MatButtonModule, MatIconModule, MatDialogModule];

@NgModule({
  imports: importsAndExports,
  exports: importsAndExports,
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { autoFocus: false, hasBackdrop: true },
    },
    {
      provide: MAT_DATE_LOCALE,
      deps: [TranslateService],
      useFactory: initializeLocale,
    },
  ],
})
export class MrcMaterialModule {}
