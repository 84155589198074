import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TenantConfigService } from './tenant-config.service';

@Injectable({ providedIn: 'root' })
export class DemoModeService {
  isDemoTenant: Observable<boolean>;

  constructor(private tenantConfigService: TenantConfigService) {
    this.isDemoTenant = this.tenantConfigService.tenantConfig$.pipe(
      map((config) => config.isTest),
      shareReplay({
        // see https://blog.angularindepth.com/rxjs-whats-changed-with-sharereplay-65c098843e95
        bufferSize: 1,
        refCount: true,
      })
    );
  }
}
