import { StepState } from './step-state';

// MAYDO make immutable and cache outcome of expensive get operations, especially `flatStepStates`
export class CompositeStepState implements StepState {
  constructor(public name: string, public subStepStates: StepState[]) {}

  get isComposite() {
    return true;
  }

  get isLocked() {
    return this.subStepStates.every(
      (stepState: StepState) => stepState.isLocked
    );
  }

  get isCompleted() {
    return this.subStepStates.every(
      (stepState: StepState) => stepState.isCompleted
    );
  }

  get hierarchicalStepStates() {
    return this.subStepStates;
  }

  get flatStepStates() {
    return this.subStepStates.reduce(
      (accumulatedStepsStates: StepState[], stepState: StepState) => {
        if (stepState.isComposite) {
          return accumulatedStepsStates.concat(stepState.flatStepStates);
        }

        accumulatedStepsStates.push(stepState);
        return accumulatedStepsStates;
      },
      []
    );
  }
}
