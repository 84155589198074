import { Injectable } from '@angular/core';
import { JwtDecodingService } from './jwt-decoding.service';
import { SessionTokenStorageService } from './session-token-storage.service';

@Injectable({ providedIn: 'root' })
export class CorrelationIdService {
  constructor(
    private sessionTokenStorageService: SessionTokenStorageService,
    private jwtDecodingService: JwtDecodingService
  ) {}

  public getCorrelationIdFromSessionToken() {
    const sessionToken = this.sessionTokenStorageService.getToken();
    if (!sessionToken) {
      return '';
    }
    const decodedToken = this.jwtDecodingService.decodeJwt(sessionToken);
    return decodedToken['cor'];
  }
}
