const breakpointMins = [0, 576, 768, 992, 1200];

const XS = 0;
const SM = 1;
const MD = 2;
const LG = 3;
const XL = 4;

function min(index: number) {
  return breakpointMins[index];
}

function minWidthQuery(index: number) {
  return `(min-width: ${min(index)}px)`;
}

function maxWidthQuery(index: number) {
  return `(max-width: ${min(index) - 0.1}px)`;
}

function maxHeightQuery(index: number) {
  return `(max-height: ${min(index) - 0.1}px)`;
}

export const IdvBreakpoints = {
  xsMin: breakpointMins[XS],
  smMin: breakpointMins[SM],
  mdMin: breakpointMins[MD],
  lgMin: breakpointMins[LG],
  xlMin: breakpointMins[XL],

  minSmQuery: minWidthQuery(SM),
  minMdQuery: minWidthQuery(MD),
  minLgQuery: minWidthQuery(LG),
  minXlQuery: minWidthQuery(XL),

  small: `screen and (orientation: portrait) and ${maxWidthQuery(
    SM
  )}, screen and (orientation: landscape) and ${maxHeightQuery(SM)}`,
};
