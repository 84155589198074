import { Component, OnDestroy, OnInit } from '@angular/core';
import { FaqCategories } from './faq';
import { ConfigAssetService } from '../../services/config/config-asset.service';
import {
  switchMap,
  tap,
  takeUntil,
  distinctUntilChanged,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Component({
  selector: 'idv-faq-dialog',
  templateUrl: './faq-dialog.component.html',
  styleUrls: ['./faq-dialog.component.scss'],
})
export class FaqDialogComponent implements OnInit, OnDestroy {
  public faqCategories: FaqCategories = {
    categories: [],
  };

  private _destroyed$ = new Subject<void>();

  constructor(
    private configAssetService: ConfigAssetService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.configAssetService.tenantFaqUrl
      .pipe(
        takeUntil(this._destroyed$),
        distinctUntilChanged(),
        switchMap((url) => this.httpClient.get<FaqCategories>(url)),
        tap((categories) => {
          this.faqCategories = categories;
        })
      )
      .subscribe();
  }

  public scrollToFaqItem(faqItemId: string) {
    document.querySelector('#' + faqItemId).scrollIntoView();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
