import { InjectionToken } from '@angular/core';
import { HttpRetryParams } from '../../../../core-lib';
import { Config } from './config';
import { IdvFlavour } from './idv-flavour';

export interface IdvEnvironment {
  production: boolean;
  apiHost: string;
  apiConfigHost: string;
  assetHost: string;
  isErrorScreenEnabled: boolean;
  isErrorDisplayedAsDefault: boolean;
  httpRequests: HttpRetryParams;
  defaultConfig: Config;
  flavor: IdvFlavour;
  application: string;
}

export const IDV_ENV = new InjectionToken<IdvEnvironment>('IdvEnvironment');
