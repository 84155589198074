export type RequestStateType =
  | 'created'
  | 'activated'
  | 'assessed'
  | 'assessing'
  | 'immediate-decision'
  | 'cancellation-applicant'
  | 'cancellation-traditional-process'
  | 'cancellation-no-response'
  | 'cancellation-other'
  | 'access-code-expired';
