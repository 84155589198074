<div
  [lang]="currentLanguage"
  *ngIf="
    !(isErrorScreenEnabled && uncaughtError) && (isAppLanguageLoaded$ | async)
  "
  addTenantClass
>
  <router-outlet></router-outlet>
</div>

<idv-error-screen
  *ngIf="isErrorScreenEnabled && uncaughtError"
></idv-error-screen>
<mrc-fullscreen-activity-indicator></mrc-fullscreen-activity-indicator>
<idv-changed-medical-risks-message></idv-changed-medical-risks-message>
