import { Component, Input } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ConfigAssetService } from '../../services/config/config-asset.service';
import { takeUntil, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'idv-tenant-panel',
  templateUrl: './tenant-panel.component.html',
  styleUrls: ['./tenant-panel.component.scss'],
})
export class TenantPanelComponent {
  @Input() showLanguageSelector: boolean;

  private _destroyed$ = new Subject<void>();

  public logo$: Observable<string>;

  constructor(private _configAssetService: ConfigAssetService) {}

  ngOnInit() {
    this.logo$ = this._configAssetService.logoUrl.pipe(
      takeUntil(this._destroyed$),
      distinctUntilChanged(),
      map((url) => `url(${url})`)
    );
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
