import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { ValueRange } from '../../../../../idv-lib/src/lib/interfaces';

export function createNumericValueRangeValidator(
  lowerBoundInclusive: number,
  upperBoundInclusive: number
): ValidatorFn {
  return (control: UntypedFormControl) => {
    if (control.value === null || control.value === undefined) {
      return null;
    }

    const onlyDots = control.value.toString().replace(',', '.');
    const floatParsed = parseFloat(onlyDots);
    if (isNaN(onlyDots)) {
      return {
        nanError: true,
      };
    }

    return floatParsed > upperBoundInclusive ||
      floatParsed < lowerBoundInclusive
      ? {
          rangeError: {
            given: floatParsed,
            max: upperBoundInclusive,
            min: lowerBoundInclusive,
          },
        }
      : null;
  };
}

export function minMaxValidator(range: ValueRange<number>) {
  return createNumericValueRangeValidator(range.min, range.max);
}
