import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  DateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { initializeLocale } from '../../../core-lib/src/lib/core/initialize-locale';
import { I18nMatDateAdapter } from './utils/i18n-mat-date-adapter';

const importsAndExports = [
  MatButtonModule,
  MatCheckboxModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatIconModule,
  MatMenuModule,
  MatToolbarModule,
  MatDialogModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatAutocompleteModule,
  LayoutModule,
  OverlayModule,
  MatSidenavModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatTooltipModule,
];

@NgModule({
  imports: importsAndExports,
  exports: importsAndExports,
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { autoFocus: false, hasBackdrop: true },
    },
    {
      provide: MAT_DATE_LOCALE,
      deps: [TranslateService],
      useFactory: initializeLocale,
    },
    { provide: DateAdapter, useClass: I18nMatDateAdapter },
  ],
})
export class IdvMaterialModule {}
