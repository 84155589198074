import { AfterViewInit, Component } from '@angular/core';

import { ActivityTrackingService } from '../../core/services/misc/activity-tracking.service';

@Component({
  selector: 'mrc-fullscreen-activity-indicator',
  templateUrl: './fullscreen-activity-indicator.component.html',
  styleUrls: ['./fullscreen-activity-indicator.component.scss'],
})
export class FullscreenActivityIndicatorComponent implements AfterViewInit {
  public isSemiTransparent = false;

  constructor(private activityTrackingService: ActivityTrackingService) {}

  get isVisible() {
    return this.activityTrackingService.hasPendingActivities;
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.isSemiTransparent = true;
    }, 0);
  }
}
