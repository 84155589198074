<idv-close-modal-header></idv-close-modal-header>

<div mat-dialog-content>
  <h2 class="mt-0">{{ i18nPrefix + '.heading' | translate }}</h2>
  <p
    class="modal-text"
    [wordBreakingHtml]="i18nPrefix + '.explanation' | translate"
  ></p>
</div>

<div mat-dialog-actions>
  <button
    type="button"
    mat-button
    [mat-dialog-close]
    color="primary"
  >
    {{ 'shared.close' | translate }}
  </button>
</div>
