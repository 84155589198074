import { Injectable } from '@angular/core';
import { ScriptService } from '../../services/dom/script.service';
import { MtCaptchaState } from '../interfaces/mt-captcha-state';

@Injectable()
export class MtCaptchaDomService {
  constructor(private _scriptService: ScriptService) {}

  initialize(): void {
    const src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';
    const src2 = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';

    this._scriptService.addScript(src, true);
    this._scriptService.addScript(src2, true);

    (<any>window).mtcaptchaConfig = {
      render: 'explicit',
      renderQueue: [],
    };
  }

  getCaptchaRenderQueue(): Array<string> {
    return (<any>window).mtcaptchaConfig?.renderQueue;
  }

  registerCaptcha(id: string, callback: (data: MtCaptchaState) => void) {
    (<any>window).mtcaptchaConfig.jsverified = callback;
    (<any>window).mtcaptchaConfig.renderQueue.push(id);
  }

  resetCaptcha(id: string) {
    (<any>window).mtcaptcha.resetUI(id);
  }
}
