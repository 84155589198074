<form
  [formGroup]="formGroup"
  (ngSubmit)="submitAnsweredQuestionGroup()"
>
  <h4
    *ngIf="headerText"
    class="question-text"
    id="h4_idv_tickboxgroup_heading"
  >
    {{ headerText }}<br />
    <small> ({{ 'question-group.tickbox-hint' | translate }}) </small>
  </h4>

  <div
    formArrayName="tickQuestions"
    *ngFor="
      let question of formGroup.controls.tickQuestions['controls'];
      let i = index
    "
  >
    <mat-checkbox
      *ngIf="i > 0"
      type="checkbox"
      class="custom-control-container checkbox-label full-width"
      [formControlName]="i"
      [id]="'cbx_idv_tickboxgroup_checkbox_' + i"
      [class.error]="question.invalid ? true : false"
      [ngClass]="{ 'e2e-is-exclusive': questionGroup.questions[i].isExclusive }"
    >
      {{ questionGroup.questions[i].selectableValues[0].displayName }}
    </mat-checkbox>
  </div>
  <div
    *ngIf="showErrors || containsTickExclusiveError"
    class="has-error"
  >
    <idv-error-help-block
      [errors]="errorsOnTickBoxForm()"
    ></idv-error-help-block>
  </div>

  <idv-questionnaire-previous-next
    *ngIf="!readonlyMode"
    class="mt-5"
    [backButtonRouterLink]="backButtonRouterLink"
    [canProceed]="!!formGroup"
  ></idv-questionnaire-previous-next>
</form>
