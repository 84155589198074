import {
  Input,
  Directive,
  HostListener,
  ElementRef,
  forwardRef,
  Renderer2,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/* This ControlValueAccessor overrides the default Angular implementation for checkboxes with a given value attribute
 * so that they don't simply provide true or false but rather the given value or undefined, respectively.
 */

export const CUSTOM_CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-use-before-declare
  useExisting: forwardRef(() => CustomCheckboxControlValueAccessor),
  multi: true,
};

@Directive({
  // tslint:disable-next-line:directive-selector
  selector:
    'input[type=checkbox][formControlName][value],input[type=checkbox][formControl][value],input[type=checkbox][ngModel][value]',
  providers: [CUSTOM_CHECKBOX_VALUE_ACCESSOR],
})
// tslint:disable-next-line:directive-class-suffix
export class CustomCheckboxControlValueAccessor
  implements ControlValueAccessor
{
  @Input() uncheckedValue: any;
  @Input() value: any;
  onChange = (_: any) => {};
  @HostListener('blur') onTouched = () => {};

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  writeValue(value: any): void {
    const shouldBeChecked = value === this.value;
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'checked',
      shouldBeChecked
    );
  }
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'disabled',
      isDisabled
    );
  }

  @HostListener('change', ['$event.target.checked'])
  public handleChange(checked: boolean) {
    const value = checked ? this.value : this.uncheckedValue;
    this.onChange(value);
  }
}
