<p class="info-text">
  <ng-container
    *ngIf="hasQuestions; then hasQuestionsBlock; else hasNoQuestionsBlock"
  ></ng-container>
  <ng-template #hasQuestionsBlock>{{
    'success-view.success-message' | translate : { riskName: riskLabel }
  }}</ng-template>
  <ng-template #hasNoQuestionsBlock
    >{{ 'success-view.no-questions-to-answer' | translate }}.</ng-template
  >
</p>
<div class="mt-5">
  <button
    type="button"
    id="btn_idv_next_questionnaire"
    type="submit"
    mat-raised-button
    class="idv-form-button"
    color="primary"
    (click)="goToNextStep()"
  >
    <span>{{ 'question-group.next' | translate }}</span>
    <idv-demo-label inBrackets></idv-demo-label>
  </button>
</div>
