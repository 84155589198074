<p class="info-text">
  {{ 'synonym-added.success-message' | translate : { riskName: riskLabel } }}
</p>
<div class="mt-5">
  <button
    type="button"
    id="btn_idv_review_answers"
    mat-stroked-button
    class="idv-form-button"
    color="primary"
    (click)="reviewAnswers()"
  >
    <span class="d-none d-md-block">{{
      'question-group.review-answers' | translate
    }}</span>
    <span class="d-md-none">{{
      'question-group.review-answers-short' | translate
    }}</span>
  </button>
  <button
    type="button"
    id="btn_idv_synonym_added_next_questionnaire"
    type="submit"
    mat-raised-button
    class="idv-form-button"
    color="primary"
    (click)="goToNextStep()"
  >
    <span>{{ 'question-group.next' | translate }}</span>
    <idv-demo-label inBrackets></idv-demo-label>
  </button>
</div>
