import { LocalStorageService } from '../../../../../core-lib';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TenantConfigService } from '../../services/config/tenant-config.service';
import { LinksConfiguration } from '../../interfaces/links-configuration';

const localStorageKey = 'cookie_disclaimer_hidden';

@Component({
  selector: 'idv-cookie-notification',
  templateUrl: './cookie-notification.component.html',
  styleUrls: ['./cookie-notification.component.scss'],
})
export class CookieNotificationComponent implements OnInit, OnDestroy {
  private configAssetServiceSubscription: Subscription;

  private linksConfig: LinksConfiguration;
  isShown = !this.localStorageService.getItem(localStorageKey);

  constructor(
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private tenantConfigService: TenantConfigService
  ) {}

  ngOnInit() {
    this.configAssetServiceSubscription =
      this.tenantConfigService.tenantConfig$.subscribe((config) => {
        this.linksConfig = config.links;
      });
  }

  hideCookieDisclaimer() {
    this.isShown = false;
    this.localStorageService.setItem(localStorageKey, 'true');
  }

  // MAYDO subscribe on language change and update public items only then, not with each tick()
  get cookieNotificationTranslated(): string {
    return this.translateService.instant('cookie-notification.disclaimer', {
      uri: this.privacyStatementUrl,
    });
  }

  get privacyStatementUrl(): string {
    return this.linksConfig ? this.linksConfig.privacyStatement.url : '';
  }

  ngOnDestroy(): void {
    if (this.configAssetServiceSubscription) {
      this.configAssetServiceSubscription.unsubscribe();
    }
  }
}
