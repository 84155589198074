// tslint:disable:max-line-length
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IdvComponentsModule } from '../components/idv-components.module';
import { IdvInputControlsModule } from '../input-controls/idv-input-controls.module';
import { MainComponent } from './master-detail/master-detail-main/main.component';
import { StepNavigationComponent } from './master-detail/step-navigation/step-navigation.component';
import { DataProtectionConfirmationComponent } from './step-components/epilogue-step/data-protection-confirmation/data-protection-confirmation.component';
import { PdfDownloadScreenComponent } from './step-components/pdf-download-step/pdf-download-screen/pdf-download-screen.component';
import { Bloodpressure24HComponent } from './step-components/questions/custom-question-components/bloodpressure-24h/bloodpressure-24h.component';
import { BloodpressureComponent } from './step-components/questions/custom-question-components/bloodpressure/bloodpressure.component';
import { TickboxGroupComponent } from './step-components/questions/custom-question-components/tickbox-group/tickbox-group.component';
import { DynamicControlComponent } from './step-components/questions/dynamic-control/dynamic-control.component';
import { DynamicQuestionGroupControlComponent } from './step-components/questions/dynamic-question-group-control/dynamic-question-group-control.component';
import { HeightWeightGroupControlComponent } from './step-components/questions/height-weight-group-control/height-weight-group-control.component';
import { QuestionGroupComponent } from './step-components/questions/question-group/question-group.component';
import { QuestionnaireCompletedComponent } from './step-components/questions/questionnaire-completed/questionnaire-completed.component';
import { QuestionnaireHeadingComponent } from './step-components/questions/questionnaire-heading/questionnaire-heading.component';
import { RiskQuestionnaireComponent } from './step-components/questions/risk-questionnaire/risk-questionnaire.component';
import { SummaryEntityComponent } from './step-components/questions/summary-entity/summary-entity.component';
import { QuestionnairePreviousNextComponent } from './step-components/questions/questionnaire-previous-next/questionnaire-previous-next.component';
import { StepNavigationListComponent } from './master-detail/step-navigation-list/step-navigation-list.component';
import { LipidsComponent } from './step-components/questions/custom-question-components/lipids/lipids.component';
import { StepNavigationLabelComponent } from './master-detail/step-navigation-label/step-navigation-label.component';
import { UnreasonableRiskWarningComponent } from './step-components/unreasonable-risk-warning/unreasonable-risk-warning.component';
import { SynonymAddedComponent } from './step-components/questions/synonym-added/synonym-added.component';
import { EditButtonComponent } from './step-components/epilogue-step/edit-button/edit-button.component';
import { NgxMaskModule } from 'ngx-mask';
import { GdqHintComponent } from './step-components/questions/gdq-hint/gdq-hint.component';

const exportedDeclarations = [
  DataProtectionConfirmationComponent,
  PdfDownloadScreenComponent,
  DynamicControlComponent,
  QuestionGroupComponent,
  QuestionnaireCompletedComponent,
  QuestionnaireHeadingComponent,
  QuestionnairePreviousNextComponent,
  DynamicQuestionGroupControlComponent,
  BloodpressureComponent,
  Bloodpressure24HComponent,
  LipidsComponent,
  TickboxGroupComponent,
  HeightWeightGroupControlComponent,
  RiskQuestionnaireComponent,
  SummaryEntityComponent,
  StepNavigationComponent,
  StepNavigationListComponent,
  StepNavigationLabelComponent,
  MainComponent,
  UnreasonableRiskWarningComponent,
  SynonymAddedComponent,
  EditButtonComponent,
];

@NgModule({
  imports: [
    IdvComponentsModule,
    IdvInputControlsModule,
    RouterModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [...exportedDeclarations, GdqHintComponent],
  exports: [...exportedDeclarations],
})
export class IdvQuestionnairesModule {}
