import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {
  lipidsInputRequiredValidator,
  lipidValueHdlNotGreaterCholesterolValueValidator,
  lipidValuesCholesterolRequiredValidator,
  minMaxValidator,
  conditionalValidator,
  createNumberValidator,
} from '../../../../../../../../core-lib/src/lib/core/validation';
import { Question, QuestionGroup } from '../../../../../interfaces';
import { QuestionnairesHelperService } from '../../../../../services/risks/questionnaires-helper.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { LanguageService } from '../../../../../../../../core-lib/src/lib/core/services/language/language.service';

// DISCLAIMER: This component was copied from another project, I did not write this source code

const lipidsAvailability = {
  true: 'mrc_q_lipids_no_information_available_no',
  false: 'mrc_q_lipids_no_information_available_yes',
};

@Component({
  selector: 'idv-lipids',
  templateUrl: './lipids.component.html',
  styleUrls: ['./lipids.component.scss'],
})
export class LipidsComponent implements OnChanges, OnDestroy {
  @Input()
  questionGroup: QuestionGroup;
  @Input()
  backButtonRouterLink: any[];
  @Input()
  readonlyMode = false;

  @Output()
  questionGroupAnswer = new EventEmitter<QuestionGroup>();

  formGroup: UntypedFormGroup;

  lipidsHeadingQuestion: Question;
  isInformationAvailableQuestion: Question;
  lipidsUnitQuestion: Question;
  recentValuesMgdlHeadingQuestion: Question;
  cholesterolValues1MgdlQuestion: Question;
  cholesterolHdlValues1MgdlQuestion: Question;
  triglycerideValues1MgdlQuestion: Question;
  previousValuesMgdlHeadingQuestion: Question;
  cholesterolValues2MgdlQuestion: Question;
  cholesterolHdlValues2MgdlQuestion: Question;
  triglycerideValues2MgdlQuestion: Question;
  cholesterolValues3MgdlQuestion: Question;
  cholesterolHdlValues3MgdlQuestion: Question;
  triglycerideValues3MgdlQuestion: Question;
  recentValuesMmolHeadingQuestion: Question;
  cholesterolValues1MmollQuestion: Question;
  cholesterolHdlValues1MmollQuestion: Question;
  triglycerideValues1MmollQuestion: Question;
  previousValuesMmolHeadingQuestion: Question;
  cholesterolValues2MmollQuestion: Question;
  cholesterolHdlValues2MmollQuestion: Question;
  triglycerideValues2MmollQuestion: Question;
  cholesterolValues3MmollQuestion: Question;
  cholesterolHdlValues3MmollQuestion: Question;
  triglycerideValues3MmollQuestion: Question;

  private formSubscriptions: Subscription[];

  private rememberedValuesMmoll = {};
  private rememberedValuesMgdl = {};

  areAllErrorMessagesShown = false;
  checkboxText: string;

  constructor(
    private questionnairesHelperService: QuestionnairesHelperService,
    private languageService: LanguageService
  ) {}

  private initFormAfterCreation() {
    if (!this.getUnit() && !this.lipidsUnitQuestion.selection) {
      this.formGroup.controls.lipidsUnit.setValue(
        this.lipidsUnitQuestion.selectableValues[0].id
      );
    }

    if (
      this.isInformationAvailableQuestion.selection ===
      lipidsAvailability['false']
    ) {
      this.disableFieldsAndStoreEnteredValues();
      this.formGroup.controls.noValuesCheckbox.setValue(true);
    } else {
      this.formGroup.controls.noValuesCheckbox.setValue(false);
    }

    this.checkboxText =
      this.isInformationAvailableQuestion.selectableValues.find(
        (answer) => answer.id === lipidsAvailability['false']
      ).displayName;

    this.resetUnusedValues();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('questionGroup' in simpleChanges) {
      this.setQuestions();
      this.createFormGroup();
      this.initFormAfterCreation();
      if (this.readonlyMode) {
        this.formGroup.disable();
      }
    }
  }

  get thousandSeparator() {
    return this.languageService.getThousandSeparator();
  }

  get decimalSeparator() {
    return this.languageService.getDecimalSeparator();
  }

  public mask(maxDecimalPlaces: number) {
    return `separator.${maxDecimalPlaces}`;
  }

  getUnit() {
    return this.formGroup && this.formGroup.get('lipidsUnit').value;
  }

  getFormControlsPerUnit() {
    const unit = this.getUnit();
    return unit === this.lipidsUnitQuestion.selectableValues[0].id
      ? 'mgdlValues'
      : 'mmollValues';
  }

  resetUnusedValues() {
    if (this.getFormControlsPerUnit() === 'mgdlValues') {
      this.formGroup.controls.mmollValues.reset();
    } else {
      this.formGroup.controls.mgdlValues.reset();
    }
  }

  submitAnsweredQuestionGroup() {
    if (this.formGroup.valid) {
      this.resetUnusedValues();
      const answeredQuestionGroup = this.createAnsweredQuestionGroup();
      this.questionGroupAnswer.emit(answeredQuestionGroup);
    } else {
      this.areAllErrorMessagesShown = true;
    }
  }

  getLabel$(textKey: string): Observable<string> {
    return this.questionnairesHelperService.getLabel$(
      this.areLipidsAvailable(),
      textKey,
      this.readonlyMode
    );
  }

  createAnsweredQuestionGroup(): QuestionGroup {
    const { id, questionGroupType, assessmentFactor } = this.questionGroup;
    const answeredQuestions: Question[] = [];

    const lipidsUnit = this.formGroup.controls.lipidsUnit as UntypedFormControl;

    const mgdlValuesRecent = this.formGroup.controls.mgdlValues.get(
      'recentLipidsValue'
    ) as UntypedFormArray;
    const mgdlValuesPast = this.formGroup.controls.mgdlValues.get(
      'pastLipidsValue'
    ) as UntypedFormArray;

    const mmollValuesRecent = this.formGroup.controls.mmollValues.get(
      'recentLipidsValue'
    ) as UntypedFormArray;
    const mmollValuesPast = this.formGroup.controls.mmollValues.get(
      'pastLipidsValue'
    ) as UntypedFormArray;

    answeredQuestions.push(this.lipidsHeadingQuestion);
    answeredQuestions.push({
      ...this.isInformationAvailableQuestion,
      selection: lipidsAvailability[this.areLipidsAvailable().toString()],
    });

    answeredQuestions.push({
      ...this.lipidsUnitQuestion,
      selection: lipidsUnit.value,
    });

    answeredQuestions.push(this.recentValuesMgdlHeadingQuestion);

    // Recent mg/dl
    answeredQuestions.push({
      ...this.cholesterolValues1MgdlQuestion,
      selection: mgdlValuesRecent.value[0][0],
    });

    answeredQuestions.push({
      ...this.cholesterolHdlValues1MgdlQuestion,
      selection: mgdlValuesRecent.value[0][1],
    });

    answeredQuestions.push({
      ...this.triglycerideValues1MgdlQuestion,
      selection: mgdlValuesRecent.value[0][2],
    });

    answeredQuestions.push(this.previousValuesMgdlHeadingQuestion);

    // Past mg/dl
    answeredQuestions.push({
      ...this.cholesterolValues2MgdlQuestion,
      selection: mgdlValuesPast.value[0][0],
    });

    answeredQuestions.push({
      ...this.cholesterolHdlValues2MgdlQuestion,
      selection: mgdlValuesPast.value[0][1],
    });

    answeredQuestions.push({
      ...this.triglycerideValues2MgdlQuestion,
      selection: mgdlValuesPast.value[0][2],
    });

    answeredQuestions.push({
      ...this.cholesterolValues3MgdlQuestion,
      selection: mgdlValuesPast.value[1][0],
    });

    answeredQuestions.push({
      ...this.cholesterolHdlValues3MgdlQuestion,
      selection: mgdlValuesPast.value[1][1],
    });

    answeredQuestions.push({
      ...this.triglycerideValues3MgdlQuestion,
      selection: mgdlValuesPast.value[1][2],
    });

    answeredQuestions.push(this.recentValuesMmolHeadingQuestion);

    // Recent mmol/l
    answeredQuestions.push({
      ...this.cholesterolValues1MmollQuestion,
      selection: mmollValuesRecent.value[0][0],
    });

    answeredQuestions.push({
      ...this.cholesterolHdlValues1MmollQuestion,
      selection: mmollValuesRecent.value[0][1],
    });

    answeredQuestions.push({
      ...this.triglycerideValues1MmollQuestion,
      selection: mmollValuesRecent.value[0][2],
    });

    answeredQuestions.push(this.previousValuesMmolHeadingQuestion);

    // Past mmol/l
    answeredQuestions.push({
      ...this.cholesterolValues2MmollQuestion,
      selection: mmollValuesPast.value[0][0],
    });

    answeredQuestions.push({
      ...this.cholesterolHdlValues2MmollQuestion,
      selection: mmollValuesPast.value[0][1],
    });

    answeredQuestions.push({
      ...this.triglycerideValues2MmollQuestion,
      selection: mmollValuesPast.value[0][2],
    });

    answeredQuestions.push({
      ...this.cholesterolValues3MmollQuestion,
      selection: mmollValuesPast.value[1][0],
    });

    answeredQuestions.push({
      ...this.cholesterolHdlValues3MmollQuestion,
      selection: mmollValuesPast.value[1][1],
    });

    answeredQuestions.push({
      ...this.triglycerideValues3MmollQuestion,
      selection: mmollValuesPast.value[1][2],
    });

    return {
      id,
      questionGroupType,
      assessmentFactor,
      questions: answeredQuestions,
    };
  }

  areLipidsAvailable() {
    if (this.formGroup) {
      return !this.formGroup.controls.noValuesCheckbox.value;
    }
    return false;
  }

  hasError(formControl: UntypedFormControl): boolean {
    return formControl && formControl.invalid;
  }

  errorsOnField(formControl: UntypedFormControl): ValidationErrors {
    return this.hasError(formControl) ? formControl.errors : null;
  }

  cholesterolRequiredErrorHelper(
    cholesterol: UntypedFormControl,
    hdl: UntypedFormControl
  ): boolean {
    if (!cholesterol.value && hdl.value) {
      return !hdl.hasError('rangeError');
    }
    return false;
  }

  hdlNotGreaterCholesterolErrorHelper(
    cholesterol: UntypedFormControl,
    hdl: UntypedFormControl
  ): boolean {
    if (cholesterol.value && hdl.value) {
      if (Number(cholesterol.value) < Number(hdl.value)) {
        return true;
      }
    }
    return false;
  }

  private findQuestionByName(name: string): Question {
    const question = this.questionGroup.questions.find((q) => q.name === name);
    if (!question) {
      throw Error('question "' + name + '" not in questionGroup');
    }
    return question;
  }

  private setQuestions() {
    this.lipidsHeadingQuestion = this.findQuestionByName('q_lipids_heading');
    this.isInformationAvailableQuestion = this.findQuestionByName(
      'q_lipids_no_information_available'
    );
    this.lipidsUnitQuestion = this.findQuestionByName('q_lipids_unit');
    this.recentValuesMgdlHeadingQuestion = this.findQuestionByName(
      'q_recent_heading_mgdl'
    );

    this.cholesterolValues1MgdlQuestion = this.findQuestionByName(
      'q_cholesterol_value_1_mgdl'
    );
    this.cholesterolHdlValues1MgdlQuestion = this.findQuestionByName(
      'q_cholesterol_hdl_1_mgdl'
    );
    this.triglycerideValues1MgdlQuestion = this.findQuestionByName(
      'q_triglyceride_1_mgdl'
    );

    this.previousValuesMgdlHeadingQuestion = this.findQuestionByName(
      'q_previous_heading_mgdl'
    );
    this.cholesterolValues2MgdlQuestion = this.findQuestionByName(
      'q_cholesterol_value_2_mgdl'
    );
    this.cholesterolHdlValues2MgdlQuestion = this.findQuestionByName(
      'q_cholesterol_hdl_2_mgdl'
    );
    this.triglycerideValues2MgdlQuestion = this.findQuestionByName(
      'q_triglyceride_2_mgdl'
    );

    this.cholesterolValues3MgdlQuestion = this.findQuestionByName(
      'q_cholesterol_value_3_mgdl'
    );
    this.cholesterolHdlValues3MgdlQuestion = this.findQuestionByName(
      'q_cholesterol_hdl_3_mgdl'
    );
    this.triglycerideValues3MgdlQuestion = this.findQuestionByName(
      'q_triglyceride_3_mgdl'
    );

    this.recentValuesMmolHeadingQuestion = this.findQuestionByName(
      'q_recent_heading_mmol'
    );
    this.cholesterolValues1MmollQuestion = this.findQuestionByName(
      'q_cholesterol_value_1_mmol'
    );
    this.cholesterolHdlValues1MmollQuestion = this.findQuestionByName(
      'q_cholesterol_hdl_1_mmol'
    );
    this.triglycerideValues1MmollQuestion = this.findQuestionByName(
      'q_triglyceride_1_mmol'
    );

    this.previousValuesMmolHeadingQuestion = this.findQuestionByName(
      'q_previous_heading_mmol'
    );
    this.cholesterolValues2MmollQuestion = this.findQuestionByName(
      'q_cholesterol_value_2_mmol'
    );
    this.cholesterolHdlValues2MmollQuestion = this.findQuestionByName(
      'q_cholesterol_hdl_2_mmol'
    );
    this.triglycerideValues2MmollQuestion = this.findQuestionByName(
      'q_triglyceride_2_mmol'
    );

    this.cholesterolValues3MmollQuestion = this.findQuestionByName(
      'q_cholesterol_value_3_mmol'
    );
    this.cholesterolHdlValues3MmollQuestion = this.findQuestionByName(
      'q_cholesterol_hdl_3_mmol'
    );
    this.triglycerideValues3MmollQuestion = this.findQuestionByName(
      'q_triglyceride_3_mmol'
    );
  }

  disableFieldsAndStoreEnteredValues() {
    this.formGroup.controls.lipidsUnit.disable();
    this.formGroup.controls.mmollValues.disable();
    this.formGroup.controls.mgdlValues.disable();
    this.rememberedValuesMmoll = this.formGroup.controls.mmollValues.value;
    this.rememberedValuesMgdl = this.formGroup.controls.mgdlValues.value;
    this.formGroup.controls.mmollValues.reset();
    this.formGroup.controls.mgdlValues.reset();
  }

  enableFieldsAndRestoreEnteredValues() {
    this.formGroup.controls.lipidsUnit.enable();
    this.formGroup.controls.mmollValues.enable();
    this.formGroup.controls.mgdlValues.enable();

    if (
      this.rememberedValuesMmoll &&
      'recentLipidsValue' in this.rememberedValuesMmoll &&
      'pastLipidsValue' in this.rememberedValuesMmoll
    ) {
      this.formGroup.controls.mmollValues.setValue(this.rememberedValuesMmoll);
    }
    if (
      this.rememberedValuesMgdl &&
      'recentLipidsValue' in this.rememberedValuesMgdl &&
      'pastLipidsValue' in this.rememberedValuesMgdl
    ) {
      this.formGroup.controls.mgdlValues.setValue(this.rememberedValuesMgdl);
    }
  }

  hasRecentLipidsMgdlValue() {
    if (this.formGroup) {
      const recentValues =
        this.formGroup.controls.mgdlValues.get('recentLipidsValue');
      return (
        recentValues.value[0][0] !== null ||
        recentValues.value[0][1] !== null ||
        recentValues.value[0][2] !== null
      );
    }
    return true;
  }

  hasPreviousLipidsMgdlValue() {
    if (this.formGroup) {
      const previousValues =
        this.formGroup.controls.mgdlValues.get('pastLipidsValue');
      return (
        previousValues.value[0][0] !== null ||
        previousValues.value[0][1] !== null ||
        previousValues.value[0][2] !== null ||
        previousValues.value[1][0] !== null ||
        previousValues.value[1][1] !== null ||
        previousValues.value[1][2] !== null
      );
    }
    return true;
  }

  hasRecentLipidsMmolValue() {
    if (this.formGroup) {
      const recentValues =
        this.formGroup.controls.mmollValues.get('recentLipidsValue');
      return (
        recentValues.value[0][0] !== null ||
        recentValues.value[0][1] !== null ||
        recentValues.value[0][2] !== null
      );
    }
    return true;
  }

  hasPreviousLipidsMmolValue() {
    if (this.formGroup) {
      const previousValues =
        this.formGroup.controls.mmollValues.get('pastLipidsValue');
      return (
        previousValues.value[0][0] !== null ||
        previousValues.value[0][1] !== null ||
        previousValues.value[0][2] !== null ||
        previousValues.value[1][0] !== null ||
        previousValues.value[1][1] !== null ||
        previousValues.value[1][2] !== null
      );
    }
    return true;
  }

  isRecentLipidsInputMmolRequired(): boolean {
    return (
      this.areLipidsAvailable() &&
      this.getFormControlsPerUnit() === 'mmollValues' &&
      !this.hasPreviousLipidsMmolValue()
    );
  }

  isPreviousLipidsInputMmollRequired(): boolean {
    return (
      this.areLipidsAvailable() &&
      this.getFormControlsPerUnit() === 'mmollValues' &&
      !this.hasRecentLipidsMmolValue()
    );
  }

  isRecentLipidsInputMgdlRequired(): boolean {
    return (
      this.areLipidsAvailable() &&
      this.getFormControlsPerUnit() === 'mgdlValues' &&
      !this.hasPreviousLipidsMgdlValue()
    );
  }

  isPreviousLipidsInputMgdlRequired(): boolean {
    return (
      this.areLipidsAvailable() &&
      this.getFormControlsPerUnit() === 'mgdlValues' &&
      !this.hasRecentLipidsMgdlValue()
    );
  }

  transferValuesToOtherUnit() {
    if (this.getFormControlsPerUnit() === 'mgdlValues') {
      if (
        this.hasRecentLipidsMmolValue() ||
        this.hasPreviousLipidsMmolValue()
      ) {
        this.formGroup.controls.mgdlValues.setValue(
          this.formGroup.controls.mmollValues.value
        );
        this.rememberedValuesMgdl = this.formGroup.controls.mgdlValues.value;
        this.rememberedValuesMmoll = {};
        this.formGroup.controls.mmollValues.reset();
      }
    } else if (this.getFormControlsPerUnit() === 'mmollValues') {
      if (
        this.hasRecentLipidsMgdlValue() ||
        this.hasPreviousLipidsMgdlValue()
      ) {
        this.formGroup.controls.mmollValues.setValue(
          this.formGroup.controls.mgdlValues.value
        );
        this.rememberedValuesMmoll = this.formGroup.controls.mmollValues.value;
        this.rememberedValuesMgdl = {};
        this.formGroup.controls.mgdlValues.reset();
      }
    }
  }

  createFormGroup() {
    this.unsubscribeAll();
    this.formGroup = new UntypedFormGroup({
      noValuesCheckbox: new UntypedFormControl(false),
      lipidsUnit: new UntypedFormControl(this.lipidsUnitQuestion.selection),
      mmollValues: new UntypedFormGroup({
        recentLipidsValue: new UntypedFormArray(
          [
            new UntypedFormArray(
              [
                new UntypedFormControl(
                  this.cholesterolValues1MmollQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolValues1MmollQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.cholesterolHdlValues1MmollQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolHdlValues1MmollQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.triglycerideValues1MmollQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.triglycerideValues1MmollQuestion.numericRange
                    ),
                  ]
                ),
              ],
              [
                lipidValuesCholesterolRequiredValidator,
                lipidValueHdlNotGreaterCholesterolValueValidator,
              ]
            ),
          ],
          conditionalValidator(
            () => this.isRecentLipidsInputMmolRequired(),
            lipidsInputRequiredValidator
          )
        ),
        pastLipidsValue: new UntypedFormArray(
          [
            new UntypedFormArray(
              [
                new UntypedFormControl(
                  this.cholesterolValues2MmollQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolValues2MmollQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.cholesterolHdlValues2MmollQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolHdlValues2MmollQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.triglycerideValues2MmollQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.triglycerideValues2MmollQuestion.numericRange
                    ),
                  ]
                ),
              ],
              [
                lipidValuesCholesterolRequiredValidator,
                lipidValueHdlNotGreaterCholesterolValueValidator,
              ]
            ),
            new UntypedFormArray(
              [
                new UntypedFormControl(
                  this.cholesterolValues3MmollQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolValues3MmollQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.cholesterolHdlValues3MmollQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolHdlValues3MmollQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.triglycerideValues3MmollQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.triglycerideValues3MmollQuestion.numericRange
                    ),
                  ]
                ),
              ],
              [
                lipidValuesCholesterolRequiredValidator,
                lipidValueHdlNotGreaterCholesterolValueValidator,
              ]
            ),
          ],
          conditionalValidator(
            () => this.isPreviousLipidsInputMmollRequired(),
            lipidsInputRequiredValidator
          )
        ),
      }),
      mgdlValues: new UntypedFormGroup({
        recentLipidsValue: new UntypedFormArray(
          [
            new UntypedFormArray(
              [
                new UntypedFormControl(
                  this.cholesterolValues1MgdlQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolValues1MgdlQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.cholesterolHdlValues1MgdlQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolHdlValues1MgdlQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.triglycerideValues1MgdlQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.triglycerideValues1MgdlQuestion.numericRange
                    ),
                  ]
                ),
              ],
              [
                lipidValuesCholesterolRequiredValidator,
                lipidValueHdlNotGreaterCholesterolValueValidator,
              ]
            ),
          ],
          conditionalValidator(
            () => this.isRecentLipidsInputMgdlRequired(),
            lipidsInputRequiredValidator
          )
        ),
        pastLipidsValue: new UntypedFormArray(
          [
            new UntypedFormArray(
              [
                new UntypedFormControl(
                  this.cholesterolValues2MgdlQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolValues2MgdlQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.cholesterolHdlValues2MgdlQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolHdlValues2MgdlQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.triglycerideValues2MgdlQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.triglycerideValues2MgdlQuestion.numericRange
                    ),
                  ]
                ),
              ],
              [
                lipidValuesCholesterolRequiredValidator,
                lipidValueHdlNotGreaterCholesterolValueValidator,
              ]
            ),
            new UntypedFormArray(
              [
                new UntypedFormControl(
                  this.cholesterolValues3MgdlQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolValues3MgdlQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.cholesterolHdlValues3MgdlQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.cholesterolHdlValues3MgdlQuestion.numericRange
                    ),
                  ]
                ),
                new UntypedFormControl(
                  this.triglycerideValues3MgdlQuestion.selection,
                  [
                    createNumberValidator,
                    minMaxValidator(
                      this.triglycerideValues3MgdlQuestion.numericRange
                    ),
                  ]
                ),
              ],
              [
                lipidValuesCholesterolRequiredValidator,
                lipidValueHdlNotGreaterCholesterolValueValidator,
              ]
            ),
          ],
          conditionalValidator(
            () => this.isPreviousLipidsInputMgdlRequired(),
            lipidsInputRequiredValidator
          )
        ),
      }),
    });

    this.formSubscriptions = [
      this.formGroup.valueChanges.subscribe(() => {
        this.formGroup
          .get('mgdlValues.recentLipidsValue')
          .updateValueAndValidity({
            emitEvent: false,
          });
        this.formGroup
          .get('mgdlValues.pastLipidsValue')
          .updateValueAndValidity({
            emitEvent: false,
          });
        this.formGroup
          .get('mmollValues.recentLipidsValue')
          .updateValueAndValidity({
            emitEvent: false,
          });
        this.formGroup
          .get('mmollValues.pastLipidsValue')
          .updateValueAndValidity({
            emitEvent: false,
          });
      }),

      this.formGroup.controls.noValuesCheckbox.valueChanges.subscribe(
        (newValue) => {
          if (newValue) {
            this.disableFieldsAndStoreEnteredValues();
          } else {
            this.enableFieldsAndRestoreEnteredValues();
          }
        }
      ),

      this.formGroup.controls.lipidsUnit.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(() => {
          if (this.formGroup.dirty) {
            this.transferValuesToOtherUnit();
          }
        }),
    ];
  }

  private unsubscribeAll(): void {
    if (this.formSubscriptions) {
      this.formSubscriptions.forEach((s) => s.unsubscribe());
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }
}
