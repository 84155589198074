import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'idv-overlay-dialog',
  templateUrl: './overlay-dialog.component.html',
})
export class OverlayDialogComponent {
  public content: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.content = data?.content ?? '';
  }
}
