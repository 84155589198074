import { PrecisionDate } from './precision-date';

export function formatPrecisionDate(precisionDate: PrecisionDate) {
  return formatDateAttributes(
    precisionDate.year,
    precisionDate.month,
    precisionDate.day
  );
}

export function formatDateAttributes(
  year: number,
  month?: number,
  day?: number
) {
  const formattedYear = year == null ? '' : toQuadrupleDigitString(year);
  const formattedMonth = month == null ? '' : '-' + toDoubleDigitString(month);
  const formattedDay = day == null ? '' : '-' + toDoubleDigitString(day);

  return formattedYear + formattedMonth + formattedDay;
}

function toDoubleDigitString(number: number): string {
  const paddedNumber = `00${number}`;
  return paddedNumber.slice(-2);
}

function toQuadrupleDigitString(number: number): string {
  const paddedNumber = `0000${number}`;
  return paddedNumber.slice(-4);
}
