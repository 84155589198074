import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LanguageService } from '../../../../../core-lib/src/lib/core/services/language/language.service';
import { BrowserSnifferService } from '../../../../../core-lib/src/lib/core/services/misc/browser-sniffer.service';

@Component({
  selector: 'idv-number-control',
  templateUrl: './number-control.component.html',
  styleUrls: ['./number-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberControlComponent implements OnInit {
  @Input() formControlToUse: UntypedFormControl;
  @Input() maxDecimalPlaces: number;

  usePlainInput: boolean;
  thousandSeparator = ',';
  maskPattern = `separator.2`;

  constructor(
    browserSniffer: BrowserSnifferService,
    private languageService: LanguageService
  ) {
    this.usePlainInput = browserSniffer.isFirefox();
  }

  ngOnInit(): void {
    this.maskPattern = this.getMaskPattern();
    this.thousandSeparator = this.languageService.getThousandSeparator();
    const numberValue = this.formControlToUse.value;
    if (!this.formControlToUse.value) {
      return;
    }

    const langDecimalSeparator = this.languageService.getDecimalSeparator();
    const hasLangDecimalSeparator = numberValue
      .toString()
      .indexOf(langDecimalSeparator);
    const currentDecimalSeparator = langDecimalSeparator === '.' ? ',' : '.';

    if (hasLangDecimalSeparator === -1) {
      const newValue = numberValue.replace(
        currentDecimalSeparator,
        langDecimalSeparator
      );
      this.formControlToUse.patchValue(newValue);
    }
  }

  getMaskPattern() {
    return !this.maxDecimalPlaces
      ? `separator.0`
      : `separator.${this.maxDecimalPlaces}`;
  }
}
