import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';
import {
  isValidPrecisionDate,
  PrecisionDateService,
} from '../../../../../core-lib';
import { DateAccuracyUnit, PrecisionDate } from '../../interfaces';

@Component({
  selector: 'idv-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    // tslint:disable-next-line:no-use-before-declare
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
    { provide: NG_VALIDATORS, useValue: isValidPrecisionDate, multi: true },
  ],
})
export class DateInputComponent implements ControlValueAccessor, OnChanges {
  @Input() accuracyUnit: DateAccuracyUnit;

  dateText = '';
  placeholderSuffix: string;
  textMask: string;

  isDisabled = false;
  onTouched = () => {};

  private onChange = (_: PrecisionDate) => {};

  constructor(private precisionDateService: PrecisionDateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('accuracyUnit' in changes) {
      this.textMask = this.precisionDateService.createTextMask(
        this.accuracyUnit
      );
      this.placeholderSuffix = this.accuracyUnit.toLowerCase() + '-precision';
    }
  }

  writeValue(date: PrecisionDate) {
    this.dateText = date
      ? this.precisionDateService.formatPrecisionDate(date)
      : '';
  }

  registerOnChange(fn: (date: PrecisionDate) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  handleDateTextChange(dateText: string) {
    this.dateText = dateText;
    this.onChange(this.precisionDateService.parsePrecisionDate(dateText));
  }
}
