import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { FaqDialogComponent } from '../faq-dialog/faq-dialog.component';
import { TenantConfigService } from '../../services/config/tenant-config.service';
import { takeUntil, map } from 'rxjs/operators';
import { LinksConfiguration } from '../../interfaces/links-configuration';

@Component({
  selector: 'idv-material-footer',
  templateUrl: './material-footer.component.html',
  styleUrls: [
    './material-footer.component.scss',
    './material-footer.component.mobile.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialFooterComponent implements OnInit, OnDestroy {
  @Input() displayFAQs = false;

  public linksConfig$: Observable<LinksConfiguration>;

  private _destroyed$ = new Subject<void>();

  constructor(
    private tenantConfigService: TenantConfigService,
    private dialog: MatDialog,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit() {
    this.linksConfig$ = this.tenantConfigService.tenantConfig$.pipe(
      takeUntil(this._destroyed$),
      map((config) => config.links)
    );
  }

  openFaqModal() {
    this.dialog.open(
      FaqDialogComponent,
      this.responsiveService.enhanceDialogConfig({
        data: {},
        panelClass: 'super-big-modal',
      })
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
