import { UntypedFormControl } from '@angular/forms';

export function createNumberValidator() {
  return (control: UntypedFormControl) => {
    if (control.value === null || control.value === undefined) {
      return null;
    }

    const regExp = /^(-?\d+[\.\,]\d+)$|^(-?\d+)$/g;
    return regExp.test(control.value.toString())
      ? null
      : {
          nanError: true,
        };
  };
}
