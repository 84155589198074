import { Platform } from '@angular/cdk/platform';
import { Injectable, Inject } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class I18nMatDateAdapter extends NativeDateAdapter {
  constructor(
    @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    platform: Platform
  ) {
    super(matDateLocale, platform);
  }

  parse(value: any): Date | null {
    if (typeof value === 'string') {
      const locale = (this.locale || 'de').substring(0, 2).toLowerCase();

      switch (locale) {
        case 'de':
        case 'en': // we now treat all 'en' locales as British, as well 'en-US' which has MDY-format
          return this.parseDateDMY(value.trim());
        default:
          return super.parse(value);
      }
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  // this is the handling for German/British date formats (e.g. "31.12.1990") -> dd.mm.yyyy
  private parseDateDMY(value: string): Date | null {
    let match = /^(\d{6}|\d{8})$/.exec(value);
    if (match) {
      return this.constructStrictDate(
        value.substring(4),
        value.substr(2, 2),
        value.substr(0, 2)
      );
    }
    match = /^(\d{1,2})[ .,/-](\d{1,2})[ .,/-](\d{2}|\d{4})$/.exec(value);
    if (match) {
      return this.constructStrictDate(match[3], match[2], match[1]);
    }
    return null;
  }

  private constructStrictDate(
    year: string,
    month: string,
    day: string
  ): Date | null {
    const y = +year;
    const m = +month;
    const d = +day;
    if (
      isNaN(m) ||
      m < 1 ||
      m > 12 ||
      isNaN(d) ||
      d < 1 ||
      d > 31 ||
      isNaN(y) ||
      y < 0 ||
      (y > 99 && y < 1900)
    ) {
      return null;
    }
    const date = new Date(Date.UTC(y, m - 1, d));
    if (date.getDate() === d && date.getMonth() === m - 1) {
      return date;
    }
    return null; // user might have entered s.t. like 30.2. (we're not lenient, we're strict!)
  }
}
