import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExpectedErrorService {
  private _expectedError = new BehaviorSubject<Error>(null);

  readonly expectedError = this._expectedError.asObservable();

  setError(error: Error): void {
    this._expectedError.next(error);
  }
}
