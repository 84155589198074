<div class="login">
  <form
    class="login-form"
    [formGroup]="formGroup"
    (ngSubmit)="submit()"
  >
    <div
      class="form-group group-input"
      [class.has-error]="isErrorMessageShown && !!accessTokenErrors"
    >
      <p
        *ngIf="isSessionExpiredMessageVisible$ | async"
        class="alert alert-warning text-center"
      >
        {{ 'login.login-expired' | translate }}
      </p>
      <div class="data-privacy-text">
        <span>{{ 'login.login-data-privacy-text' | translate }}</span>
        <span
          ><a
            (click)="openGDPRDialog()"
            [innerHTML]="'login.login-data-privacy-text-link' | translate"
            idvClickTracker="idv_open_gdpr_dialog?source=above_login"
          ></a
        ></span>
      </div>
      <br />
      <div
        class="full-width non-mat-form-field"
        [ngClass]="{ started: loginStarted }"
      >
        <input
          type="text"
          [mask]="mask"
          [showMaskTyped]="loginStarted"
          [validation]="false"
          id="tokenInput"
          formControlName="accessToken"
          autocomplete="off"
          [placeholder]="'login.input-placeholder' | translate"
          (focus)="onLoginStarted.next()"
        />
        <div
          *ngIf="isErrorMessageShown && !!accessTokenErrors"
          [@slideInOut]
          class="non-mat-error"
        >
          <idv-error-help-block
            [errors]="accessTokenErrors"
          ></idv-error-help-block>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showCaptcha && siteKey">
      <div class="form-group group-captcha">
        <div class="captcha-container">
          <idv-mt-captcha
            #captcha
            formControlName="captchaToken"
            required
            [sitekey]="siteKey"
            [lang]="currentLanguage"
            [action]="'login'"
          ></idv-mt-captcha>
        </div>
        <div class="error-message">
          <idv-error-help-block
            *ngIf="isErrorMessageShown && !!captchaTokenErrors"
            [errors]="captchaTokenErrors"
          ></idv-error-help-block>
        </div>
      </div>
    </ng-container>
    <div class="form-group group-submit-button">
      <button
        id="btn_idv_login"
        type="submit"
        [color]="'primary'"
        mat-raised-button
        class="full-width"
      >
        <span>{{ 'login.call-to-action' | translate }}</span>
      </button>
    </div>
  </form>
</div>
