import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';
import { DateAccuracyUnit } from '../../../../../idv-lib/src/lib/interfaces/date-accuracy-unit';
import { PrecisionDate } from '../../../../../idv-lib/src/lib/interfaces/precision-date';

export function createDateRangeValidator(
  lowerBoundInclusive: PrecisionDate,
  upperBoundInclusive: PrecisionDate,
  precision: DateAccuracyUnit
): ValidatorFn {
  return (c: UntypedFormControl) => {
    const date = c.value;
    return date
      ? validateRequiredFields(date, precision) ||
          validateLogicalBoundaries(date) ||
          validateIsExistingDate(date) ||
          validateNotGreaterThan(date, upperBoundInclusive) ||
          validateNotLessThan(date, lowerBoundInclusive)
      : null;
  };
}

function validateRequiredFields(
  date: PrecisionDate,
  precision: DateAccuracyUnit
): ValidationErrors {
  let validationErrors = null;

  if (precision === 'day' && date.day == null) {
    validationErrors = {
      ...(validationErrors as ValidationErrors),
      dayRequired: true,
    };
  }
  if ((precision === 'month' || precision === 'day') && date.month == null) {
    validationErrors = {
      ...validationErrors,
      monthRequired: true,
    };
  }
  if (date.year == null) {
    validationErrors = {
      ...validationErrors,
      yearRequired: true,
    };
  }

  return validationErrors;
}

function validateLogicalBoundaries(date: PrecisionDate): ValidationErrors {
  let validationErrors = null;
  if ((date.day != null && date.day < 1) || date.day > 31) {
    validationErrors = {
      ...(validationErrors as ValidationErrors),
      invalidDay: { value: date.day, min: 1, max: 31 },
    };
  }
  if ((date.month != null && date.month < 1) || date.month > 12) {
    validationErrors = {
      ...validationErrors,
      invalidMonth: { value: date.month, min: 1, max: 12 },
    };
  }

  if (date.year != null && date.year < 1900) {
    validationErrors = {
      ...validationErrors,
      invalidYear: { value: date.year, min: 1900 },
    };
  }

  return validationErrors;
}

function validateIsExistingDate(date: PrecisionDate): ValidationErrors {
  return date.isExistingDate
    ? null
    : {
        invalidDate: date,
      };
}

function validateNotGreaterThan(
  dateA: PrecisionDate,
  dateB: PrecisionDate
): ValidationErrors {
  return !dateB || dateA.compareTo(dateB) < 0
    ? null
    : {
        maxDate: { value: dateA, max: dateB },
      };
}

function validateNotLessThan(
  dateA: PrecisionDate,
  dateB: PrecisionDate
): ValidationErrors {
  return !dateB || dateA.compareTo(dateB) > 0
    ? null
    : {
        minDate: { value: dateA, min: dateB },
      };
}
