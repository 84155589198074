import { Injectable } from '@angular/core';
import { DateAccuracyUnit } from '../../../../../../idv-lib/src/lib/interfaces/date-accuracy-unit';
import { PrecisionDate } from '../../../../../../idv-lib/src/lib/interfaces/precision-date';
import { LanguageService } from '../language/language.service';

function parsePrecisionDate(precisionDateText: string, separator: string) {
  const dateFragments = precisionDateText
    .split(separator)
    .map((dateText) => parseInt(dateText, 10));

  if (dateFragments.length === 3) {
    const [day, month, year] = dateFragments;
    return new PrecisionDate(year, month, day);
  } else if (dateFragments.length === 2) {
    const [month, year] = dateFragments;
    return new PrecisionDate(year, month);
  } else if (dateFragments.length === 1) {
    const [year] = dateFragments;
    return new PrecisionDate(year);
  }

  throw new Error(
    `Invalid date string format supplied to parsePrecisionDate: '${precisionDateText}'
    Supported formats: YYYY, MM${separator}YYYY, DD${separator}MM${separator}YYYY`
  );
}

@Injectable({ providedIn: 'root' })
export class PrecisionDateService {
  constructor(private _languageService: LanguageService) {}

  formatPrecisionDate(
    precisionDate: PrecisionDate,
    accuracyUnit?: DateAccuracyUnit
  ): string {
    const { day, month, year } = precisionDate;
    const separator = this._languageService.getDateSeparator();

    const dayText = formatToDateFragment(day, separator);
    const monthText = formatToDateFragment(month, separator);

    switch (accuracyUnit) {
      case 'year':
        return year.toString();
        break;

      default:
        return dayText + monthText + year;
        break;
    }
  }

  parsePrecisionDate(precisionDateText: string): PrecisionDate {
    const separator = this._languageService.getDateSeparator();
    return precisionDateText
      ? parsePrecisionDate(precisionDateText, separator)
      : undefined;
  }

  createTextMask(precision: DateAccuracyUnit): string {
    const separator = this._languageService.getDateSeparator();

    return precision === 'day'
      ? this.createDayPrecisionTextMask(separator)
      : precision === 'month'
      ? this.createMonthPrecisionTextMask(separator)
      : this.createYearPrecisionTextMask();
  }

  private createDayPrecisionTextMask(separator: string): string {
    return `d0${separator}M0${separator}0000`;
  }

  private createMonthPrecisionTextMask(separator: string): string {
    return `M0${separator}0000`;
  }

  private createYearPrecisionTextMask(): string {
    return `0000`;
  }
}

function formatToDateFragment(value: number, separator: string) {
  return value ? ('0' + value).substr(-2) + separator : '';
}
