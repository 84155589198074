<ul [ngClass]="clazz">
  <ng-container *ngFor="let step of getSteps()">
    <ng-container [ngSwitch]="getStepType(step)">
      <ng-template ngSwitchCase="composite">
        <li
          class="nav-item nav-li-l1"
          [routerLink]="getRouterLinkForComposite(step)"
          [class.locked]="step.isLocked"
          [class.completed]="step.isCompleted"
          [class.active]="isActiveWithinMe(step)"
          [class.has-sub-steps]="hasSubSteps(step)"
          [class.first-sub-step-complete]="isFirstSubStepCompleted(step)"
          [class.last]="isLastCompletedStep(step)"
          (click)="onClickNavigationItem()"
        >
          <span class="step-label">{{
            'step-navigation.step-labels.' + step.name | translate
          }}</span>
        </li>
      </ng-template>

      <ng-template ngSwitchCase="pseudo">
        <li
          class="nav-item nav-li-l1 active"
          [class.locked]="true"
          [class.completed]="false"
          [class.last]="true"
          [class.nav-item-last-in-composite]="true"
          (click)="onClickNavigationItem()"
        >
          <span class="step-label">{{ step.name | translate }}</span>
        </li>
      </ng-template>

      <ng-template ngSwitchCase="risk">
        <li
          class="nav-item nav-li-l1"
          [ngClass]="step.class"
          [class.locked]="isLocked(step.name)"
          [class.completed]="isCompleted(step.name)"
          [class.active]="isActive(step)"
          [routerLink]="getRouterLinkForStep(step)"
          [class.last]="isLastCompletedStep(step)"
          [class.nav-item-last-in-composite]="isLastItemInComposite(step)"
          [class.has-next-sibling]="!step.isLastChild"
          (click)="onClickNavigationItem()"
        >
          <idv-step-navigation-label
            [label]="step.risk.labels.join(', ')"
          ></idv-step-navigation-label>
        </li>
      </ng-template>

      <ng-template ngSwitchDefault>
        <li
          class="nav-item nav-li-l1"
          [class.locked]="isLocked(step.name)"
          [class.completed]="isCompleted(step.name)"
          routerLinkActive="active"
          [routerLink]="getRouterLinkForStep(step)"
          [class.last]="isLastCompletedStep(step)"
          [class.nav-item-last-in-composite]="isLastItemInComposite(step)"
          (click)="onClickNavigationItem()"
        >
          <span class="step-label">{{
            'step-navigation.step-labels.' + step.name | translate
          }}</span>
        </li>
      </ng-template>
    </ng-container>
  </ng-container>
</ul>
