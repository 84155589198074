import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { WindowService } from '../../core/services/misc/window.service';

@Component({
  selector: 'mrc-go-top-button',
  template: `
    <button
      mat-stroked-button
      color="primary"
      class="to-top mat-elevation-z2"
      (click)="scrollTop($event)"
    >
      <mat-icon>keyboard_arrow_up</mat-icon>
      {{ 'shared.back-to-top' | translate }}
    </button>
  `,
  styleUrls: ['./go-top-button.component.scss'],
})
export class GoTopButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') clazz: string = '';

  private scrollContainer: HTMLElement;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Optional() private dialogRef: MatDialogRef<any>,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: any,
    private windowService: WindowService
  ) {}

  ngOnInit(): void {
    // determine scroll-container
    if (this.dialogRef) {
      const isDialogFullScreen =
        this.breakpointObserver.isMatched('(max-width: 767px)');
      const scrollContainerClosest = isDialogFullScreen
        ? '.mat-dialog-container'
        : '.mat-dialog-content';
      const child = this.scrollContainer || this.elementRef.nativeElement;
      this.scrollContainer = child.closest(scrollContainerClosest);
    }

    const target = this.scrollContainer || this.windowService.window;
    merge(
      fromEvent(target, 'scroll'),
      fromEvent(target, 'wheel'),
      fromEvent(target, 'resize')
    )
      .pipe(debounceTime(350), takeUntil(this.unsubscribe$))
      .subscribe(this.checkAndSetActiveState);
  }

  ngAfterViewInit(): void {
    if (this.scrollContainer) {
      this.correctPosition();
    }
  }

  scrollTop($event: any) {
    $event.preventDefault();
    if (this.scrollContainer) {
      this.scrollContainer.scrollTop = 0;
    } else {
      this.windowService.window.scrollTo(0, 0);
    }
  }

  checkAndSetActiveState = () => {
    const active = this.isActive();
    this.clazz = active ? 'active' : '';
    if (active && this.scrollContainer) {
      this.correctPosition();
    }
  };

  private isActive(): boolean {
    const viewportHeight = this.getViewportHeight();
    const scrollTop = this.getCurrentScrollTop();
    return scrollTop >= 0.5 * viewportHeight;
  }

  private correctPosition(): void {
    if (!this.scrollContainer) {
      return;
    }
    const elt = this.elementRef.nativeElement;
    const containerRect = this.scrollContainer.getBoundingClientRect();
    const viewportHeight = this.getViewportHeight();

    // 48: height of button; -16: margin of button; -16: distance between button and scroll-container
    let bottom = viewportHeight - containerRect.bottom - 48 - 16 - 16;
    if (bottom < 0) {
      bottom = 0;
    }

    this.renderer.setStyle(elt, 'bottom', `${bottom}px`);
  }

  private getViewportHeight(): number {
    return this.windowService.window.innerHeight;
  }

  private getCurrentScrollTop(): number {
    if (this.scrollContainer) {
      return this.scrollContainer.scrollTop;
    }
    return this.getWindowScrollTop();
  }

  private getWindowScrollTop(): number {
    if (this.scrollContainer) {
      return this.scrollContainer.scrollTop;
    }

    const window = this.windowService.window;
    if (typeof window.scrollY !== 'undefined' && window.scrollY >= 0) {
      return window.scrollY;
    }
    if (typeof window.pageYOffset !== 'undefined' && window.pageYOffset >= 0) {
      return window.pageYOffset;
    }
    if (
      typeof this.document.body.scrollTop !== 'undefined' &&
      this.document.body.scrollTop >= 0
    ) {
      return this.document.body.scrollTop;
    }

    return 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
