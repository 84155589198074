<!-- User has to add the risk again -->
<ng-template #addRiskModal>
  <h4 mat-dialog-title>{{ 'medical-risks-changed.heading' | translate }}</h4>

  <div mat-dialog-content>
    <p>{{ 'medical-risks-changed.greeting' | translate }}</p>
    <p
      [wordBreakingHtml]="'medical-risks-changed.text-add-question' | translate"
    ></p>
    <p>{{ 'medical-risks-changed.thank-you' | translate }}</p>
  </div>

  <!-- TODO: remove "Normrisk" from id -->
  <div
    mat-dialog-actions
    id="mow_idv_normriskchanged_addrisk"
  >
    <button
      type="button"
      mat-button
      [mat-dialog-close]
      color="primary"
    >
      {{ 'shared.close' | translate }}
    </button>
  </div>
</ng-template>

<!-- User has to reanswer questions again -->
<ng-template #reanswerQuestionModal>
  <h4 mat-dialog-title>{{ 'medical-risks-changed.heading' | translate }}</h4>

  <div mat-dialog-content>
    <p>{{ 'medical-risks-changed.greeting' | translate }}</p>
    <p
      [wordBreakingHtml]="
        'medical-risks-changed.text-reanswer-question' | translate
      "
    ></p>
    <p>{{ 'medical-risks-changed.thank-you' | translate }}</p>
  </div>

  <!-- TODO: remove "Normrisk" from id -->
  <div
    mat-dialog-actions
    id="mow_idv_normriskchanged_reanswerquestion"
  >
    <button
      type="button"
      mat-button
      [mat-dialog-close]
      color="primary"
    >
      {{ 'shared.close' | translate }}
    </button>
  </div>
</ng-template>
