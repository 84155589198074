import { AbstractControl, ValidatorFn } from '@angular/forms';

export function conditionalValidator(
  predicate: (control: AbstractControl) => boolean,
  validator: ValidatorFn
) {
  return (control: AbstractControl) => {
    return predicate(control) ? validator(control) : null;
  };
}
