import {
  PrecisionDate,
  QuestionGroup,
  Risk,
  RiskQuestionGroupContext,
} from '../interfaces';

export function isRiskHiddenFromPhysician(risk: Risk): boolean {
  if (wasRiskReaddedByPhysician(risk)) {
    return false;
  }

  return (
    risk.source === 'removed-by-physician' ||
    risk.source === 'removed-by-system' ||
    (isImmediatelyAssessedRisk(risk) &&
      (isAddedByUnderwriter(risk) || isAddedByPreAssessment(risk)))
  );
}

export function isRiskHiddenFromInsured(risk: Risk): boolean {
  return (
    risk.source === 'removed-by-system' ||
    (isImmediatelyAssessedRisk(risk) &&
      (isAddedByUnderwriter(risk) || isAddedByPreAssessment(risk)))
  );
}

export function isRiskTreeFinished(risk: Risk): boolean {
  return isRiskFinished(risk) && areChildRisksFinished(risk);
}

export function isRiskRemovable(risk: Risk): boolean {
  return risk.source !== 'added-by-pre-assessment';
}

function wasRiskReaddedByPhysician(risk: Risk): boolean {
  return risk.subStatus && risk.subStatus.readdedRisk === 'readded';
}

function isAddedByPreAssessment(risk: Risk) {
  return risk.source === 'added-by-pre-assessment';
}

function isAddedByUnderwriter(risk: Risk) {
  return risk.source === 'added-by-underwriter';
}

function isImmediatelyAssessedRisk(risk: Risk): boolean {
  return (
    risk &&
    risk.questionnaire &&
    risk.questionnaire.completed &&
    risk.questionnaire.questionGroups.length === 0 &&
    (!risk.routedRisks ||
      risk.routedRisks.every((routedRisk) =>
        isImmediatelyAssessedRisk(routedRisk)
      ))
  );
}

function isRiskFinished(risk: Risk): boolean {
  return risk ? risk.questionnaire.completed : false;
}

function areChildRisksFinished(risk: Risk): boolean {
  return risk.routedRisks.every((routedRisk) => isRiskTreeFinished(routedRisk));
}

export function convertDateStringsToDateAndPrecisionDate(
  riskQuestionGroupContext: RiskQuestionGroupContext
): RiskQuestionGroupContext {
  if (riskQuestionGroupContext) {
    convertDateStringsToDateAndPrecisionDateOfQuestionGroup(
      riskQuestionGroupContext.questionGroup
    );
  }
  return riskQuestionGroupContext;
}

export function convertDateStringsToDateAndPrecisionDateOfQuestionGroup(
  questionGroup: QuestionGroup
): QuestionGroup {
  if (questionGroup) {
    questionGroup.questions.forEach((question) => {
      if (question.type === 'date') {
        question.dateRange.min = new Date(question.dateRange.min as any);
        question.dateRange.max = new Date(question.dateRange.max as any);

        if (question.selection) {
          question.selection = new PrecisionDate(
            question.selection as any,
            null,
            null,
            question.monthUnknown
          );
        }
      }
    });
  }
  return questionGroup;
}

export function isRiskVisibleToInsured(risk: Risk): boolean {
  return !isRiskHiddenFromInsured(risk);
}
