<h4
  [wordBreakingHtml]="
    'remove-risk-confirmation-dialog.reason-question' | translate
  "
></h4>
<div class="row">
  <div class="col">
    <mat-radio-group>
      <div>
        <mat-radio-button
          name="reasonToDelete"
          (change)="onSelectionChange('unknown')"
          value="unknown"
          class="full-width"
          cdkFocusInitial="false"
          required
        >
          {{ 'remove-risk-confirmation-dialog.reason.unknown' | translate }}
        </mat-radio-button>
      </div>
      <div>
        <mat-radio-button
          name="reasonToDelete"
          value="refer-to-specialist"
          (change)="onSelectionChange('refer-to-specialist')"
          class="full-width"
          required
        >
          {{
            'remove-risk-confirmation-dialog.reason.refer-to-specialist'
              | translate
          }}
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
</div>
