import { HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class AcceptLanguageHeaderInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const extendedRequest = this.extendRequestWithAcceptLanguageHeader(request);
    return next.handle(extendedRequest);
  }

  private extendRequestWithAcceptLanguageHeader(request: HttpRequest<any>) {
    const translateService = this.injector.get(TranslateService);
    const currentLanguage = translateService.currentLang;

    return currentLanguage
      ? request.clone({
          headers: request.headers.set('Accept-Language', currentLanguage),
        })
      : request;
  }
}
