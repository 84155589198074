import { PaymentMethodType } from '../../../../../../../../idv-lib/src/lib/interfaces/payment-method.type';
import { BasePaymentMethodComponent } from '../base.payment-method.component';
import { Type, Injectable } from '@angular/core';
import { IbanPaymentMethodComponent } from '../iban/iban-payment-method.component';
import { AccountPaymentMethodComponent } from '../account/account-payment-method.component';
import { BasePaymentData } from '../../../../../../../../idv-lib/src/lib/interfaces/base.payment-data';

@Injectable()
export class PaymentMethodComponentResolver {
  constructor() {}

  public resolve(
    paymentMethod: PaymentMethodType
  ): Type<BasePaymentMethodComponent<BasePaymentData>> {
    switch (paymentMethod) {
      case 'iban':
        return IbanPaymentMethodComponent;
      case 'account':
        return AccountPaymentMethodComponent;
      default:
        return null;
    }
  }
}
