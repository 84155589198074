import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { WindowService } from '../misc/window.service';

export abstract class MrcTrackingService {
  private previousEvent: NavigationEnd;
  private previousPageName: string;

  protected constructor() {}

  protected setupRouteChangeTracking(router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.previousEvent) {
          const properties = { pageName: this.previousPageName || 'unknown' };
          this.stopTrackPage(
            this.previousEvent.url,
            this.previousEvent.urlAfterRedirects,
            this.enrichProps(properties)
          );
        }
        this.startTrackPage(event.url);
        this.previousEvent = event;
        this.previousPageName = this.findPageName(router);
      });
  }

  protected findPageName(router: Router): string {
    let lastChild = router.routerState.root;
    let depth = 0; // hopefully not necessary: protect against endless loop
    while (lastChild.firstChild && ++depth < 10) {
      lastChild = lastChild.firstChild;
    }
    return (
      lastChild &&
      lastChild.routeConfig &&
      lastChild.routeConfig.data &&
      lastChild.routeConfig.data['pageName']
    );
  }

  protected setupPageUnloadTracking(windowService: WindowService) {
    try {
      const window = windowService.window;
      window.addEventListener('beforeunload', () => {
        this.trackEvent('page_unload', this.enrichProps());
        this.flush();
      });
    } catch (error) {}
  }

  protected trackInitialPageLoad(
    windowService: WindowService,
    eventName = 'initial_page_load'
  ) {
    try {
      const window = windowService.window;
      const userAgent = window.navigator.userAgent;
      const viewportWidth = Math.max(
        window.document.documentElement.clientWidth,
        window.innerWidth || 0
      ).toString();
      const viewportHeight = Math.max(
        window.document.documentElement.clientHeight,
        window.innerHeight || 0
      ).toString();
      const screenWidth = window.screen.width.toString();
      const screenHeight = window.screen.height.toString();
      this.trackEvent(
        eventName,
        this.enrichProps({
          userAgent,
          viewportWidth,
          viewportHeight,
          screenWidth,
          screenHeight,
        })
      );
    } catch (error) {}
  }

  protected enrichProps(properties?: { [name: string]: string }): {
    [name: string]: string;
  } {
    return properties;
  }

  abstract trackPageView(
    name?: string,
    uri?: string,
    properties?: { [name: string]: string },
    pageType?: string,
    refUri?: string,
    isLoggedIn?: boolean
  ): void;

  abstract startTrackPage(name?: string): void;

  abstract stopTrackPage(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ): void;

  abstract trackEvent(
    name: string,
    properties?: { [name: string]: string }
  ): void;

  abstract trackMetric(
    name: string,
    average: number,
    sampleCount?: number,
    min?: number,
    max?: number,
    properties?: { [name: string]: string }
  ): void;

  abstract trackException(
    exception: Error,
    properties?: { [name: string]: string },
    severityLevel?: number,
    id?: string
  ): void;

  abstract trackTrace(
    message: string,
    properties?: { [name: string]: string },
    severityLevel?: number
  ): void;

  abstract trackDependency(
    id: string,
    responseCode: number,
    name?: string,
    duration?: number,
    success?: boolean,
    correlationContext?: string,
    target?: string,
    type?: string,
    properties?: { [key: string]: any },
    startTime?: Date,
    data?: string
  ): void;

  abstract flush(): void;

  abstract setAuthenticatedUserContext(
    authenticatedUserId: string,
    accountId?: string
  ): void;

  abstract clearAuthenticatedUserContext(): void;
}
