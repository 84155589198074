export interface LinksConfiguration {
  imprint: Link;
  legalNotice: Link;
  privacyStatement: Link;
  faq: Link;
  tuevReport: Link;
}

export interface Link {
  isShown: boolean;
  url?: string;
}
