<form
  *ngIf="!!questionGroup"
  (ngSubmit)="submitAnsweredQuestionGroup()"
  [formGroup]="formGroup"
>
  <div class="row">
    <div class="col-12">
      <h2 class="screen-header">
        {{ 'height-weight-group-control.headline' | translate }}
      </h2>
    </div>

    <div class="col-12">
      <p *ngIf="!alreadyHasValues">
        {{ 'height-weight-group-control.disclaimer' | translate }}
      </p>

      <p *ngIf="alreadyHasValues">
        {{ 'height-weight-group-control.disclaimer-after-saved' | translate }}
      </p>
    </div>

    <!-- is BMI available -->
    <div
      class="col-12 checkbox"
      [class.has-error]="areAllErrorMessagesShown && formGroup.invalid"
    >
      <mat-checkbox
        class="custom-control-container checkbox-label"
        [formControlName]="noInformationAvailableFormControlName"
        >{{ noInformationAvailableQuestion.text }}
      </mat-checkbox>
    </div>

    <div class="col-12">
      <div
        class="form-group"
        [class.has-error]="
          hasError(heightFormControlName) ||
          (areAllErrorMessagesShown && !!formGroup.errors)
        "
      >
        <!-- height -->
        <div>
          <mat-radio-group
            *ngIf="heightUnitQuestion"
            [formControlName]="heightUnitFormControlName"
          >
            <mat-radio-button
              *ngFor="let selection of heightUnitQuestion.selectableValues"
              [value]="selection.id"
            >
              {{ selection.displayName }}
            </mat-radio-button>
          </mat-radio-group>
          <p *ngFor="let question of getRelevantHeightQuestions">
            <mat-form-field
              appearance="fill"
              class="form-field-m"
            >
              <mat-label>{{ question.text }}</mat-label>
              <input
                id="{{ question.id }}"
                type="number"
                matInput
                [formControlName]="question.id"
              />
              <mat-hint
                >{{ 'height-weight-group-control.example' | translate }}
                {{ getExampleNumberValue(question.id) }}</mat-hint
              >
              <mat-error
                *ngIf="
                  areAllErrorMessagesShown &&
                  formGroup.invalid &&
                  !hasError(question.id)
                "
              >
                <idv-error-help-block
                  [errors]="formGroup.errors"
                ></idv-error-help-block>
              </mat-error>
              <mat-error *ngIf="hasError(question.id)">
                <idv-error-help-block
                  [errors]="getErrorsToDisplayFor(question.id)"
                ></idv-error-help-block>
              </mat-error>
            </mat-form-field>
          </p>
        </div>
        <!-- weight -->
        <div>
          <mat-radio-group
            *ngIf="weightUnitQuestion"
            [formControlName]="weightUnitFormControlName"
          >
            <mat-radio-button
              *ngFor="let selection of weightUnitQuestion.selectableValues"
              [value]="selection.id"
            >
              {{ selection.displayName }}
            </mat-radio-button>
          </mat-radio-group>
          <p *ngFor="let question of getRelevantWeightQuestions">
            <mat-form-field
              appearance="fill"
              class="form-field-m"
            >
              <mat-label>{{ question.text }}</mat-label>
              <input
                id="{{ question.id }}"
                type="number"
                matInput
                [formControlName]="question.id"
              />
              <mat-hint
                >{{ 'height-weight-group-control.example' | translate }}
                {{ getExampleNumberValue(question.id) }}</mat-hint
              >
              <mat-error
                *ngIf="
                  areAllErrorMessagesShown &&
                  formGroup.invalid &&
                  !hasError(question.id)
                "
              >
                <idv-error-help-block
                  [errors]="formGroup.errors"
                ></idv-error-help-block>
              </mat-error>
              <mat-error *ngIf="hasError(question.id)">
                <idv-error-help-block
                  [errors]="getErrorsToDisplayFor(question.id)"
                ></idv-error-help-block>
              </mat-error>
            </mat-form-field>
          </p>
        </div>
      </div>
    </div>
    <div class="col-12">
      <button
        id="btn_idv_next_height_weight"
        mat-raised-button
        class="idv-form-button"
        color="primary"
        type="submit"
        [disabled]="!formGroup"
      >
        <span>{{ 'question-group.next' | translate }}</span>
        <idv-demo-label inBrackets></idv-demo-label>
      </button>
    </div>
  </div>
</form>
