import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MrcComponentsModule } from '../../../../core-lib/src/lib/components/mrc-components.module';
import { IdvMaterialModule } from '../idv-material.module';
import { CheckBoxControlComponent } from './check-box-control/check-box-control.component';
import { CustomCheckboxControlValueAccessor } from './custom-checkbox-control-value-accessor';
import { DateControlComponent } from './date-control/date-control.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DropdownListControlComponent } from './dropdown-list-control/dropdown-list-control.component';
import { NumberControlComponent } from './number-control/number-control.component';
import { RadioListControlComponent } from './radio-list-control/radio-list-control.component';
import { MonthYearInputComponent } from './month-year-input/month-year-input.component';
import { OnlyNumbersDirective } from './only-numbers/only-numbers.directive';
import { EnhancedSearchControlComponent } from './enhanced-search-control/enhanced-search-control.component';
import { SearchRiskControlComponent } from './search-risk/search-risk-control.component';
import { NgxMaskModule } from 'ngx-mask';

const exportedDeclarations = [
  CheckBoxControlComponent,
  DateControlComponent,
  DateInputComponent,
  MonthYearInputComponent,
  DropdownListControlComponent,
  NumberControlComponent,
  RadioListControlComponent,
  EnhancedSearchControlComponent,
  SearchRiskControlComponent,
  CustomCheckboxControlValueAccessor,
  OnlyNumbersDirective,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MrcComponentsModule,
    IdvMaterialModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [...exportedDeclarations],
  exports: [...exportedDeclarations],
})
export class IdvInputControlsModule {}
