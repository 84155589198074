import { Component, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IDV_ENV,
  IdvEnvironment,
} from '../../../../interfaces/idv-environment';
import { Risk } from '../../../../interfaces/risk';
import { RiskQuestionGroupContext } from '../../../../interfaces/risk-question-group-context';
import { ResponsiveService } from '../../../../services/responsive/responsive.service';
import { StepStateService } from '../../../../services/step-navigation/step-state.service';
import { take, tap } from 'rxjs/operators';
import { ReanswerQuestionsDialogComponent } from '../../../../components/reanswer-questions-dialog/reanswer-questions-dialog.component';
import { RequestService } from '../../../../services/request/request.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'idv-questionnaire-heading',
  templateUrl: './questionnaire-heading.component.html',
  styleUrls: ['./questionnaire-heading.component.scss'],
})
export class QuestionnaireHeadingComponent {
  @Input()
  showSummary: boolean;
  @Input()
  risk: Risk;
  @Input()
  riskQuestionGroupContext: RiskQuestionGroupContext;
  @Input()
  displayContextMenu = this.environment.flavor.isEditRiskEnabled;

  constructor(
    public dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private stepStateService: StepStateService,
    private requestService: RequestService,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {}

  onClickRemoveRisk() {
    const component =
      this.environment.flavor.components['RemoveRiskDialogComponent'];
    if (!component) {
      throw Error('config error RRDC');
    }
    const dialogRef = this.dialog.open(
      component,
      this.responsiveService.enhanceDialogConfig({
        panelClass: 'big-modal',
        data: { risk: this.risk },
      })
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((response) => {
        response?.reAnswerRiskIds?.length > 0 &&
          this.displayReanswerQuestionsModal(response.reAnswerRiskIds);
      });
  }

  get replaceRiskLink$() {
    return this.stepStateService.getReplaceRiskRoute$(this.risk.id);
  }

  get isEditingDisabled() {
    return this.risk
      ? this.risk.source === 'added-by-pre-assessment' ||
          this.risk.source === 'added-by-risk-class-merge' ||
          this.risk.source === 'added-by-routing'
      : true;
  }

  get questionGroupRiskLabel(): string {
    return `\u00a0\u00a0\u2192\u00a0\u00a0${
      this.riskQuestionGroupContext?.questionGroupRiskLabel || ''
    }`;
  }

  get riskLabels(): string {
    return `${this.risk?.labels.join(', ') || ''}`;
  }

  get isDescendantQuestionGroup(): boolean {
    return (
      this.riskQuestionGroupContext?.questionGroup &&
      this.riskQuestionGroupContext.questionGroupRiskId !== this.risk.id
    );
  }

  private displayReanswerQuestionsModal(riskIds: Array<string>) {
    combineLatest(
      riskIds.map((riskId) =>
        this.requestService.findOneRisk$((r) => r.id === riskId)
      )
    )
      .pipe(
        take(1),
        tap((res) => {
          const labels = res.reduce((flat, toFlatten) => {
            flat.push(toFlatten.labels.join(', '));
            return flat;
          }, new Array<string>());
          this.dialog.open(
            ReanswerQuestionsDialogComponent,
            this.responsiveService.enhanceDialogConfig({
              panelClass: 'big-modal',
              data: { labels },
            })
          );
        })
      )
      .subscribe();
  }
}
