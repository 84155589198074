import { ValidatorFn } from '@angular/forms';

export function createMaxDecimalPlacesValidator(
  maxDecimalPlaces: number
): ValidatorFn {
  return !!maxDecimalPlaces
    ? (control) =>
        control.value != null
          ? validateMaxDecimalPlaces(control.value, maxDecimalPlaces)
          : null
    : maxDecimalPlaces === 0
    ? (control) =>
        control.value != null ? validateNoDecimalPlaces(control.value) : null
    : () => null;
}

function validateMaxDecimalPlaces(value: number, maxDecimalPlaces: number) {
  const givenDecimalPlaces = getDecimalPlaces(value);
  return givenDecimalPlaces <= maxDecimalPlaces
    ? null
    : {
        maxDecimalPlaces: { maxDecimalPlaces, givenDecimalPlaces },
      };
}

function validateNoDecimalPlaces(value: number) {
  const givenDecimalPlaces = getDecimalPlaces(value);
  return givenDecimalPlaces === 0
    ? null
    : {
        noDecimalPlaces: { givenDecimalPlaces },
      };
}

function getDecimalPlaces(value: number) {
  const valueTextRepresentation = value.toString(10);
  const onlyDots = valueTextRepresentation.replace(',', '.');
  const decimals = onlyDots.split('.')[1] || '';
  return decimals.length;
}
