import { Component, Input, Output, EventEmitter } from '@angular/core';
import { QuestionGroup } from '../../../../interfaces';

@Component({
  selector: 'idv-dynamic-question-group-control',
  templateUrl: './dynamic-question-group-control.component.html',
  styleUrls: ['./dynamic-question-group-control.component.scss'],
})
export class DynamicQuestionGroupControlComponent {
  @Input() questionGroup: QuestionGroup;
  @Input() backButtonRouterLink: any[];
  @Input() readonlyMode = false;

  @Output() questionGroupAnswer = new EventEmitter<QuestionGroup>();

  onAnswerReceived(answeredQuestionGroup: QuestionGroup) {
    this.questionGroupAnswer.emit(answeredQuestionGroup);
  }
}
